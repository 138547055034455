// react core
import { useContext, Fragment } from "react";

// imported animations
// import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesHexagon as iconInactive } from "@fortawesome/pro-thin-svg-icons";
// import { faQrcode as iconTicket } from "@fortawesome/pro-duotone-svg-icons";
// import { faClose as iconClose } from "@fortawesome/pro-regular-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
import { EventContext } from "pages/events/EventContext";
import { TextTag } from "components/utils/common/CommonTags";

function EventTitleTicker(props) {
  const user = props.user;
  const eventContext = useContext(EventContext);

  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
        overflow: "hidden",
      }}
    >
      {/* <Marquee speed={20} gradient={false} style={{ overflow: "hidden" }}>
      </Marquee> */}
      <Box className="box-default">
        <Box
          className={
            "box-inline bg-black-t50" +
            (!user.connected ? " action-pointer" : "")
          }
          sx={{
            pt: configEntzy.APP_SPACING_SM2X,
            pb: configEntzy.APP_SPACING_SM2X,
            pl: configEntzy.APP_SPACING_MD,
            pr: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
            maxWidth: "100%",
            overflow: "hidden",
          }}
          onClick={
            !user.connected
              ? () => {
                  props.drawerConnectToggle();
                }
              : undefined
          }
        >
          <Typography
            variant="h3"
            className="lower-case"
            color="primary"
            noWrap
          >
            <span>{configEntzy.URL_POINTERS.MAIN}</span>
            <span style={{ color: "white" }}>
              {eventContext.state.event.data.Url}
            </span>
          </Typography>
          {eventContext.state.event.data.Tagline && (
            <Typography
              variant="subtitle2"
              sx={{ mt: configEntzy.APP_SPACING_SM }}
              noWrap
            >
              <span style={{ color: "white" }}>
                {eventContext.state.event.data.Tagline}
              </span>
            </Typography>
          )}
          {!user.connected && (
            <Fragment>
              <Typography variant="subtitle2" color="secondary">
                <span>Welcome to eventuator check-in</span>
              </Typography>

              {eventContext.state.event.data.Access === "public" ? (
                <Fragment>
                  <Typography variant="subtitle2" color="white">
                    Have a look around to see what's happening
                  </Typography>
                  <Typography variant="subtitle2" color="white">
                    Connect to place date offers when ready
                  </Typography>
                </Fragment>
              ) : (
                <Typography variant="subtitle2" color="white">
                  Connect to get started
                </Typography>
              )}
            </Fragment>
          )}
        </Box>
        {!eventContext.state.event.data.Active && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <TextTag
              text={
                <span>
                  <FontAwesomeIcon
                    icon={iconInactive}
                    color="white"
                    fixedWidth
                  />
                  <span>&nbsp;&nbsp;</span>
                  <span>Eventuator is Inactive</span>
                  <span>&nbsp;&nbsp;</span>
                  <FontAwesomeIcon
                    icon={iconInactive}
                    color="white"
                    fixedWidth
                  />
                </span>
              }
              size="md"
              bgColor="black"
              color="highlight.dark"
              rounded={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default EventTitleTicker;
