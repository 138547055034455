// react core
import { useContext, useState, useEffect, useCallback } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { MainContext } from "components/main/MainContext";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { ActionButton } from "components/utils/common/CommonButtons";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { TextTag } from "components/utils/common/CommonTags";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-solid-svg-icons";

function ContactsList(props) {
  const mainContext = useContext(MainContext);

  const [showCreateContact, setShowCreateContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingContact, setLoadingContact] = useState(null);
  const [hydrated, setHydrated] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handlePullContactList = async (more) => {
    if (more) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    let response;
    response = await mainContext.preparePullContactList({
      nextToken: mainContext.state.productList.data.nextToken,
      more,
    });
    setLoading(false);
    setLoadingMore(false);
    if (response.alert) {
      mainContext.updateAlert(response);
      mainContext.pullContactList({
        data: null,
      });
    } else {
      mainContext.pullContactList({
        data: response.data,
        more,
      });
    }
  };
  const cbHandlePullContactList = useCallback(handlePullContactList, [
    mainContext,
  ]);

  const handleToggleCreateContact = async (event) => {
    setShowCreateContact(!showCreateContact);
  };
  const handleCreateContact = async (data) => {
    const contactName = data.filter((obj) => obj.id === "contact-name")[0]
      .value;
    if (contactName === "") {
      return {
        alert: true,
        message: "Please enter a contact name.",
      };
    }
    const response = await mainContext.prepareCreateContact({
      Name: contactName,
    });
    if (response.alert) {
      return response;
    }
    mainContext.createContact(response);
    // remove viewer user if entered via the viewer
    mainContext.setMemberContactViewer({
      open: mainContext.state.memberContact.viewer.open,
      user: null,
    });
    return { alert: false };
  };
  const cbHandleCreateContact = useCallback(handleCreateContact, [mainContext]);

  const handleConfirmDeleteContact = async (event, confirm, contactUserId) => {
    if (confirm === true) {
      setDialog({ visible: false });
      handleDeleteContact(contactUserId);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Delete Contact",
        description: "Are you sure you want to delete this contact?",
        cancel: (e) => handleConfirmDeleteContact(e, false, contactUserId),
        confirm: (e) => handleConfirmDeleteContact(e, true, contactUserId),
      });
    }
  };

  const handleDeleteContact = async (contactUserId) => {
    setLoadingContact(contactUserId);
    const response = await mainContext.prepareDeleteContact({
      ContactUserId: contactUserId,
    });
    setLoadingContact(null);
    if (response.alert) {
      mainContext.updateAlert(response);
      return response;
    }
    // add user interaction to response to update user list
    const userIndex = mainContext.state.interaction.users.findIndex(
      (user) => user.identity === contactUserId
    );
    if (userIndex !== -1) {
      response.data.User = mainContext.state.interaction.users[userIndex];
    }
    mainContext.deleteContact(response);
  };

  // pull messages on page load
  useEffect(() => {
    if (!hydrated && !mainContext.state.contactList.hydrated) {
      setHydrated(true);
      cbHandlePullContactList(false);
    }
  }, [
    hydrated,
    mainContext.state.contactList.hydrated,
    cbHandlePullContactList,
  ]);

  // check for viewer contact creations
  useEffect(() => {
    if (
      mainContext.state.memberContact.viewer.open &&
      mainContext.state.memberContact.viewer.user
    ) {
      // if viewer is open and user is set and user not in contact list open and populate add contact form
      const contactUserId =
        mainContext.state.memberContact.viewer.user.identity;
      const contactUserName = mainContext.state.memberContact.viewer.user.name;
      const contactIndex = mainContext.state.contactList.data.items.findIndex(
        (item) => item.ContactUserId === contactUserId
      );
      if (contactIndex === -1) {
        cbHandleCreateContact([
          {
            id: "contact-name",
            value: contactUserName,
          },
        ]);
      }
    }
  }, [
    mainContext.state.memberContact.viewer,
    mainContext.state.contactList.data.items,
    cbHandleCreateContact,
  ]);

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
        }}
        hidden={showCreateContact}
      >
        <ActionButton
          color={showCreateContact ? "bright" : "primary"}
          text={showCreateContact ? "Cancel" : "Add Contact"}
          size="small"
          onClick={handleToggleCreateContact}
        />
      </Box>
      <Box
        className="box-default fade-in"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
        }}
        hidden={!showCreateContact}
      >
        <Container maxWidth="xs">
          <InputForm
            navigate={props.navigate}
            fields={[
              {
                id: "contact-name",
                type: "text-standard",
                label: "Enter Entzy Name",
                value: "",
                alphanumeric: true,
                required: true,
                maxLength: 20,
                startAdornment: "@",
              },
            ]}
            submitText="Add Contact"
            callback={handleCreateContact}
            cancel={handleToggleCreateContact}
            cancelText="Cancel"
            cancelAutoConfirm={true}
            footNotes={["Enter the entzy @name of your contact"]}
          />
        </Container>
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG2X,
        }}
      >
        {loading || loadingMore ? (
          <ActionLoader />
        ) : (
          <Box className="box-default">
            {mainContext.state.contactList.hydrated &&
              mainContext.state.contactList.data.items.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className="box-inline"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <Box
                      className="box-default bg-black-t75 rounded"
                      sx={{
                        width: configEntzy.AVATAR_CONTAINER_MD,
                        minHeight: configEntzy.AVATAR_CONTAINER_MD,
                        pt: configEntzy.APP_SPACING_MD,
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Box className="box-inline">
                        <UserAvatar user={{ identity: item.ContactUserId }} />
                      </Box>
                      <Box
                        className="box-default zero-height"
                        sx={{
                          width: configEntzy.AVATAR_CONTAINER_MD,
                        }}
                        hidden={loadingContact !== item.ContactUserId}
                      >
                        <ActionLoader size="sm" />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD2X,
                          width: configEntzy.AVATAR_CONTAINER_MD,
                        }}
                      >
                        <TextTag
                          text={
                            <span>
                              <span>&nbsp;</span>
                              <span>Remove</span>
                              <span>&nbsp;&nbsp;</span>
                              <FontAwesomeIcon icon={iconDelete} />
                              <span>&nbsp;</span>
                            </span>
                          }
                          onClick={() =>
                            handleConfirmDeleteContact(
                              null,
                              null,
                              item.ContactUserId
                            )
                          }
                          bgColor="danger.light"
                          color="danger.dark"
                          size="md"
                          rounded={true}
                          fullWidth={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>
    </Box>
  );
}

export default ContactsList;
