// react core
// import { useState, useContext } from "react";

// material design
import Box from "@mui/material/Box";

// entzy components
import TransactionList from "../transaction/TransactionList";

function PaymentHistory(props) {
  return (
    <Box className="box-default">
      <TransactionList paymentView={true} />
    </Box>
  );
}

export default PaymentHistory;
