// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import AboutEvents from "./AboutEvents";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAtomAlt as iconEventuators } from "@fortawesome/pro-thin-svg-icons";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";

function AboutIndex(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box
        className="box-default"
        sx={{
          mb: configEntzy.APP_SPACING_HL2X,
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box
          className="box-default bg-black-t75"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <Box className="box-default">
            <Typography
              variant="h2"
              noWrap={true}
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              <FontAwesomeIcon icon={iconInfo} size="2x" />
            </Typography>
            <Typography
              variant="h2"
              noWrap={true}
              sx={{
                mt: configEntzy.APP_SPACING_LG,
                color: "orange",
              }}
            >
              About Entzy
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              Entz is short for entertainment.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              Entzy is an entertainment generator
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              that helps fill your calendar with events.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              We help planners bring people together
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              with event launchers called eventuators.
            </Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
              pb: configEntzy.APP_SPACING_XL,
            }}
          >
            <AboutEvents {...props} />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AboutIndex;
