// react core
import { useState } from "react";

// material design
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

// entzy config and models
import configEntzy from "components/config/ConfigEntzy";
// import { countriesList } from "models/Country";
// import { currenciesList } from "models/Currency";
// import { ActionBoxButton } from "components/utils/common/CommonButtons";

// fonts and colors
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMoneyBills as iconTicketCurrency } from "@fortawesome/pro-solid-svg-icons";

export function StandardSlider(props) {
  // const [value, setValue] = useState(props.value);
  const handleChange = (event, newValue) => {
    // setValue(newValue);
    props.onChange(newValue);
  };
  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_LG,
      }}
    >
      <Box className="box-default quarter-width text-left">
        <Typography variant="subtitle2">
          <span>
            &nbsp;{props.minValue} to {props.maxValue}
          </span>
        </Typography>
      </Box>
      <Box className="box-default half-width">
        <Typography variant="subtitle2">{props.label}</Typography>
      </Box>
      <Box className="box-default quarter-width text-right">
        <Typography variant="subtitle2">
          <span className="shadow-default bdp2">&nbsp;{props.value}&nbsp;</span>
        </Typography>
      </Box>
      <Box className="box-default">
        <Slider
          value={props.value}
          min={props.minValue}
          max={props.maxValue}
          size={props.size ? props.size : "medium"}
          valueLabelDisplay="auto"
          onChange={handleChange}
          marks
        />
      </Box>
    </Box>
  );
}

export function RangeStaticSlider(props) {
  const [minRange] = useState(props.minRange);
  // const [value, setValue] = useState(props.value);
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let val;
    if (activeThumb === 0) {
      val = [Math.min(newValue[0], props.value[1] - minRange), props.value[1]];
    } else {
      val = [props.value[0], Math.max(newValue[1], props.value[0] + minRange)];
    }
    // setValue(val);
    props.onChange(val);
  };

  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_LG,
      }}
    >
      <Box className="box-default quarter-width text-left">
        <Typography variant="subtitle2">
          <span className="shadow-default bdp2">
            &nbsp;{props.value[0]}&nbsp;
          </span>
        </Typography>
      </Box>
      <Box className="box-default half-width">
        <Typography variant="subtitle2">
          {props.label} {props.minValue} {props.maxValue}
        </Typography>
      </Box>
      <Box className="box-default quarter-width text-right">
        <Typography variant="subtitle2">
          <span className="shadow-default bdp2">
            &nbsp;{props.value[1]}&nbsp;
          </span>
        </Typography>
      </Box>
      <Box className="box-default">
        <Slider
          value={props.value}
          min={props.minValue}
          max={props.maxValue}
          size={props.size ? props.size : "medium"}
          valueLabelDisplay="auto"
          onChange={handleChange}
          disableSwap
        />
      </Box>
    </Box>
  );
}

export function RangeShiftSlider(props) {
  const [minRange] = useState(props.minRange);
  // const [value, setValue] = useState(props.value);
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let val;
    if (newValue[1] - newValue[0] < minRange) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minRange);
        val = [clamped, clamped + minRange];
      } else {
        const clamped = Math.max(newValue[1], minRange);
        val = [clamped - minRange, clamped];
      }
    } else {
      val = newValue;
    }
    // setValue(val);
    props.onChange(val);
  };

  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_LG,
      }}
    >
      <Box className="box-default quarter-width text-left">
        <Typography variant="subtitle2">
          <span className="shadow-default bdp2">
            &nbsp;{props.value[0]}&nbsp;
          </span>
        </Typography>
      </Box>
      <Box className="box-default half-width">
        <Typography variant="subtitle2">
          {props.label} {props.minValue} {props.maxValue}
        </Typography>
      </Box>
      <Box className="box-default quarter-width text-right">
        <Typography variant="subtitle2">
          <span className="shadow-default bdp2">
            &nbsp;{props.value[1]}&nbsp;
          </span>
        </Typography>
      </Box>
      <Box className="box-default">
        <Slider
          value={props.value}
          min={props.minValue}
          max={props.maxValue}
          size={props.size ? props.size : "medium"}
          valueLabelDisplay="auto"
          onChange={handleChange}
          disableSwap
        />
      </Box>
    </Box>
  );
}
