// react core
// import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";

// entzy context and services
// import { EventContext } from "pages/events/EventContext";

function ActionMessagingIdeas(props) {
  // const user = props.user;
  // const eventContext = useContext(EventContext);

  // const handleStagePostIdea = async (text) => {
  //   console.log("handleStagePostIdea", text);
  // };

  const IDEAS = [
    "Give me some ideas for events",
    "How can I plan an outdoors activity?",
    "Tell me what art events are like",
  ];

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pb: configEntzy.APP_SPACING_SM,
        }}
      >
        <Typography variant="subtitle2" className="text-default">
          Ask AI Examples
        </Typography>
      </Box>
      <Box
        className="box-default"
        sx={{
          pb: configEntzy.APP_SPACING_LG,
        }}
      >
        {IDEAS.map((idea, index) => (
          <Typography key={index} variant="body1">
            <em>{idea}</em>
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

export default ActionMessagingIdeas;
