// react core
import { useState, useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit as iconEdit } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-solid-svg-icons";
import { faStripe as iconIncome } from "@fortawesome/free-brands-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// entzy context and services
import { SettingsContext } from "pages/settings/SettingsContext";
import { ActiveTag } from "components/utils/common/CommonTags";
import {
  ActionAlert,
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

function PaymentsMethods(props) {
  const user = props.user;
  const userReload = props.userReload;
  const context = useContext(SettingsContext);

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);

  // capture current month and year to check if payment method is expired
  const method = context.state.settings.activePaymentMethod;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const methodExpiryMonth =
    method && method.expiryMonth ? method.expiryMonth * 1 : 0;
  const methodExpiryYear =
    method && method.expiryYear ? method.expiryYear * 1 + 2000 : 0;
  const methodExpired =
    methodExpiryYear < currentYear ||
    (methodExpiryYear === currentYear && methodExpiryMonth < currentMonth);

  // component functions
  const handleEdit = async () => {
    setEdit(!edit);
  };

  const handleDeleteExecute = async () => {
    const data = [
      {
        id: "payment-primary",
        value: {
          paymentId: context.state.settings.activePaymentMethod.cardDelete,
        },
      },
    ];
    return await handleFormCallback(data);
  };
  const handleDeleteConfirm = async (event, confirm) => {
    if (confirm) {
      setDialog(false);
      setLoading(true);
      await handleDeleteExecute();
      setLoading(false);
    } else {
      setDialog(!dialog);
    }
  };

  const handleFormCallback = async (data) => {
    const paymentSettings = data.filter(
      (obj) => obj.id === "payment-primary"
    )[0].value;
    if (
      paymentSettings &&
      paymentSettings.paymentId &&
      paymentSettings.paymentId.length
    ) {
      let results;
      const settingsUpdate = await context.prepareUpdateSettings({
        key: "PaymentId",
        value: paymentSettings.paymentId,
      });
      if (settingsUpdate.alert) {
        results = {
          alert: true,
          message: settingsUpdate.message,
        };
      } else {
        context.updateSettings(settingsUpdate);
        userReload();
        results = { alert: false };
      }
      return results;
    } else {
      return {
        alert: true,
        message: "A valid payment method required",
      };
    }
  };

  return (
    <Box className="box-default">
      {edit ? (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
            }}
          >
            <InputForm
              user={user}
              userReload={userReload}
              navigate={props.navigate}
              page={props.page}
              searchParams={props.searchParams}
              fields={[
                {
                  id: "payment-primary",
                  type: "payment-method",
                  label: "Primary payment method",
                  value: "",
                  required: true,
                },
              ]}
              submitType="auto"
              submitText="Save Payment Method"
              callback={handleFormCallback}
              confirmation="Payment method updated successfully"
            />
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              pb: configEntzy.APP_SPACING_XL,
            }}
          >
            <Button
              variant="contained"
              color="bright"
              size="medium"
              onClick={handleEdit}
            >
              Close
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
            pb: configEntzy.APP_SPACING_LG,
          }}
        >
          <Box className="box-default" hidden={loading}>
            <Box className="box-default">
              <ActiveTag
                value={context.state.user.status.payment_set}
                prefix="payment method"
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_LG }}
              hidden={!context.state.user.status.payment_set}
            >
              {context.state.settings.activePaymentMethod.alert ? (
                <ActionAlert
                  severity="error"
                  message={context.state.settings.activePaymentMethod.message}
                />
              ) : context.state.settings.activePaymentMethod.inactive ? (
                <Typography variant="h6" noWrap={true}>
                  NO ACTIVE PAYMENT METHOD
                </Typography>
              ) : (
                <Box
                  className="box-default bg-black-t50 shadow-light"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Grid container>
                    <Grid item xs={8} sx={{ textAlign: "left" }}>
                      <Typography variant="h6" noWrap={true}>
                        {context.state.settings.activePaymentMethod.cardType}
                      </Typography>
                      <Typography variant="subtitle2" noWrap={true}>
                        Ending{" "}
                        {context.state.settings.activePaymentMethod.lastDigits}
                        &nbsp;&nbsp; Expiry{" "}
                        {
                          context.state.settings.activePaymentMethod.expiryMonth
                        }{" "}
                        /{context.state.settings.activePaymentMethod.expiryYear}
                      </Typography>
                      {/* if payment method expired highlight a message  */}
                      {methodExpired && (
                        <Typography
                          variant="subtitle2"
                          noWrap={true}
                          sx={{ color: "error.main" }}
                        >
                          EXPIRED
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "right" }}>
                      <Typography variant="h6" noWrap={true}>
                        <FontAwesomeIcon
                          icon={iconEdit}
                          onClick={handleEdit}
                          style={{ cursor: "pointer" }}
                          fixedWidth
                        />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <FontAwesomeIcon
                          icon={iconDelete}
                          onClick={handleDeleteConfirm}
                          style={{ cursor: "pointer" }}
                          fixedWidth
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box className="box-default">
                    <ActionCheck
                      dialog={dialog}
                      title="Confirm deletion of active payment method?"
                      description="If you have any in-play ticket offers yet to launch,
                  this will trigger cancellation of those offers and your
                  riders activity will be limited to placing offers on
                  free events only"
                      cancel={(e) => handleDeleteConfirm(e, false)}
                      confirm={(e) => handleDeleteConfirm(e, true)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL,
              }}
              hidden={
                !context.state.settings.activePaymentMethod.alert &&
                !context.state.settings.activePaymentMethod.inactive
              }
            >
              <ActionBoxButton
                variant="contained"
                color="bright"
                size="medium"
                onClick={handleEdit}
                text="Add Payment Method"
                subtext="For ticket or other purchases"
                topIcon={
                  <FontAwesomeIcon
                    icon={iconIncome}
                    size="3x"
                    transform="up-2"
                    fixedWidth
                  />
                }
              />
            </Box>
          </Box>
        </Box>
      )}
      {loading && (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
            mb: configEntzy.APP_SPACING_XL,
          }}
        >
          <ActionLoader />
        </Box>
      )}
    </Box>
  );
}

export default PaymentsMethods;
