// react core
import { useContext } from "react";

// pull to refresh
import PullToRefresh from "react-simple-pull-to-refresh";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy context and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { PullLoader, PullPrompt } from "components/utils/common/CommonLoaders";
import { UserAvatar } from "components/utils/common/CommonAvatars";
// import {
//   DmTag,
//   TransactionTag,
//   RefreshTag,
// } from "components/utils/common/CommonTags";
import FallbackUserImage from "assets/banners/crowd-t50.png";

function WidgetUserBanner(props) {
  const mainContext = useContext(MainContext);

  const handleRefresh = async () => {
    const response = await Promise.all([
      mainContext.preparePullNotifications({
        Category: null,
      }),
      mainContext.preparePullViewerEvents({
        nextToken: null,
      }),
    ]);
    if (response.filter((item) => item.alert).length > 0) {
      mainContext.updateAlert({
        alert: true,
        message:
          "Hmm unable to refresh content on that try. Give it another go or contact us if this problem persists.",
      });
      return true;
    } else {
      mainContext.pullNotifications(response[0]);
      mainContext.pullViewerEvents(response[1]);
      return true;
    }
  };

  // const handleOpenDMs = () => {
  //   mainContext.setMemberTransactionViewer({
  //     open: false,
  //   });
  //   mainContext.setMemberMessageViewer({
  //     open: mainContext.state.memberMessaging.viewer.open ? false : true,
  //     user: null,
  //   });
  // };
  // const handleOpenTransactions = () => {
  //   mainContext.setMemberMessageViewer({
  //     open: false,
  //   });
  //   mainContext.setMemberTransactionViewer({
  //     open: mainContext.state.memberTransactions.viewer.open ? false : true,
  //   });
  // };

  return (
    <Box className="box-default">
      <Box className="box-default relative">
        <Box
          className="box-default"
          sx={{
            // mb: configEntzy.APP_SPACING_LG,
            "&::before": {
              content: `''`,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "url(" +
                (props.user.avatar.hydrated && props.user.avatar.images.lg
                  ? props.user.avatar.images.lg.data
                  : FallbackUserImage) +
                ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              filter:
                props.user.avatar.hydrated && props.user.avatar.images.lg
                  ? "blur(1px)"
                  : "none",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <PullToRefresh
            onRefresh={handleRefresh}
            refreshingContent={<PullLoader />}
            pullingContent={<PullPrompt />}
          >
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_LG,
              }}
            >
              <Container maxWidth="lg" disableGutters>
                <Grid container>
                  <Grid item xs={4}>
                    <Box className="box-default">
                      <Box
                        className="box-default text-left"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD2X,
                          pl: configEntzy.APP_SPACING_MD2X,
                        }}
                      >
                        {/* <Box className="box-default text-left">
                          <DmTag onClick={handleOpenDMs} />
                        </Box>
                        <Box
                          className="box-default text-left"
                          sx={{
                            pt: configEntzy.APP_SPACING_MD,
                          }}
                        >
                          <TransactionTag onClick={handleOpenTransactions} />
                        </Box> */}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className="box-inline action-pointer"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                      }}
                      onClick={
                        props.user.connected
                          ? () => props.menuSelectById("settings-profile")
                          : null
                      }
                    >
                      <UserAvatar user={props.user} size="md" avatarOnly />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      className="box-inline right"
                      sx={{
                        p: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      {/* <RefreshTag /> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Typography variant="h6" color="white">
                        {"@" + props.user.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </PullToRefresh>
        </Box>
      </Box>
    </Box>
  );
}

export default WidgetUserBanner;
