// material design
import Box from "@mui/material/Box";

// entzy components
import ActionEntrySelection from "./ActionEntrySelection";

function ActionEntryRider(props) {
  return (
    <Box className="box-default">
      <ActionEntrySelection {...props} type="rider" />
    </Box>
  );
}

export default ActionEntryRider;
