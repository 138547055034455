export default {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_GES0WkuAB',
    APP_CLIENT_ID: '7b8soqcma9hdse5ok2lsmkdtit',
    IDENTITY_POOL_ID: 'us-east-1:041c0d08-4ede-4ef1-84e0-481267bbfffb',
  },
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'storage-object-user-us-east-1-551730556480',
  },
  appsync: {
    REGION:'us-east-1',
    AUTH_TYPE: 'AWS_IAM',
    ENDPOINT: 'https://urkerq4crnbwno6ircodqen3gu.appsync-api.us-east-1.amazonaws.com/graphql',
  },
  google: {
    CLIENT_ID: '538211386362-rs1p63aokdamanmvpr8911am6tjjqhos.apps.googleusercontent.com',
    API_KEY: 'AIzaSyAZ7gXrSyi_qNcEJRGaXh-XAZQgiQKzp9E',
  },
  stripe: {
    PUBLIC_KEY: 'pk_live_IHEZmkk6QSUHZ48RdBsvBPcZ00T6qnLcDX',
    CONNECT_ID: 'ca_G9s2lqctmxHqMOWqMG1AfNppDCH1UqoI',
  },
  entzy: {
    ENV: 'production',
    DOMAIN: 'entzy.com',
    VERSION: '2.0.244',
    TIMESTAMP: '1727763773',
  }
}
