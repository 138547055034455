// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink as iconLink } from "@fortawesome/pro-duotone-svg-icons";
import { faText as iconText } from "@fortawesome/pro-duotone-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// entzy context and services
import { EventContext } from "pages/events/EventContext";

function ActionSharingRider(props) {
  const user = props.user;
  const context = useContext(EventContext);

  const handleRiderActionCallback = async (data) => {
    let url, text;
    url = data.filter((obj) => obj.id === "link-url")[0].value;
    text = data.filter((obj) => obj.id === "link-description")[0].value;
    if (text.replace(/\s/g, "").length === 0) text = "No description";
    if (!url.toLowerCase().startsWith("http")) url = "https://" + url;
    // prepare rider post
    if (!context.state.event.manager) {
      if (context.state.toolbox.isUrl(url)) {
        const response = await context.preparePostLink({
          EventId: context.state.event.data.EventId,
          LinkType: "url",
          LinkTarget: url,
          Title: "Shared on " + context.state.toolbox.getDomainFromUrl(url),
          Description: text,
        });
        if (!response.alert) {
          context.postLink(response.data);
          props.handleCloseAction();
        }
        return response;
      } else {
        return {
          alert: true,
          message: "URL seems invalid. Double check and retry.",
        };
      }
    } else {
      return {
        alert: true,
        message: "Event rider permissions required for this update",
      };
    }
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        <InputForm
          navigate={props.navigate}
          fields={[
            {
              id: "link-description",
              type: "text-standard",
              label: "Enter flyer description",
              placeholder: "Enter flyer description",
              value: "",
              required: true,
              maxLength: 90,
              disabled: !user.connected,
              startAdornment: (
                <FontAwesomeIcon icon={iconText} color="primary" fixedWidth />
              ),
            },
            {
              id: "link-url",
              type: "text-standard",
              label: "Enter site URL where you posted",
              placeholder: "https://",
              value: "",
              required: true,
              url: true,
              disabled: !user.connected,
              startAdornment: (
                <FontAwesomeIcon icon={iconLink} color="primary" fixedWidth />
              ),
            },
          ]}
          submitText="Post"
          disabledSubmit={!user.connected}
          disabledTitle="Connect to continue"
          disabledText="Tap here to activate sharing"
          disabledClick={context.toggleConnect}
          callback={handleRiderActionCallback}
        />
      </Box>
    </Box>
  );
}

export default ActionSharingRider;
