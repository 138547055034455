import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { createTheme } from "@mui/material/styles";
import { blueGrey, grey, orange, green, red } from "@mui/material/colors/";

let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: orange[400],
      main: orange[500],
      dark: orange[600],
      contrastText: "#111",
    },
    secondary: {
      light: orange[100],
      main: orange[200],
      dark: orange[300],
      contrastText: "#222",
    },
    tertiary: {
      light: orange[0],
      main: orange[50],
      dark: orange[100],
      contrastText: "#333",
    },
    highlight: {
      light: orange[600],
      main: orange[700],
      dark: orange[800],
      contrastText: "#fff",
    },
    active: {
      light: orange[500],
      main: orange[700],
      dark: orange[900],
      contrastText: "#fff",
    },
    success: {
      light: green[200],
      main: green[300],
      dark: green[400],
      contrastText: "#fff",
    },
    danger: {
      light: red[200],
      main: red[300],
      dark: red[400],
      contrastText: "#fff",
    },
    primaryDisabled: {
      light: grey[400],
      main: grey[500],
      dark: grey[600],
      contrastText: "#999",
    },
    secondaryDisabled: {
      light: grey[300],
      main: grey[400],
      dark: grey[500],
      contrastText: "#999",
    },
    bright: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#333",
    },
    clear: {
      light: grey[0],
      main: grey[0],
      dark: grey[0],
      contrastText: blueGrey[900],
    },
    dusk: {
      light: grey[700],
      main: grey[800],
      dark: grey[900],
      contrastText: "#fff",
    },
    darkClear: {
      light: grey[0],
      main: grey[0],
      dark: grey[0],
      contrastText: "#fff",
    },
    text: {
      primary: blueGrey[400],
      secondary: blueGrey[500],
      disabled: grey[400],
      hint: grey[300],
    },
  },

  components: {
    MuiCardMedia: {
      styleOverrides: {
        img: {
          height: 70,
          "@media (min-width:600px)": {
            height: 80,
          },
          "@media (min-width:900px)": {
            height: 90,
          },
          "@media (min-width:1200px)": {
            height: 100,
          },
          "@media (min-width:1500px)": {
            height: 110,
          },
          "@media (min-width:1800px)": {
            height: 120,
          },
        },
      },
    },
  },

  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "3.0rem",
      "@media (min-width:600px)": {
        fontSize: "3.10rem",
      },
      "@media (min-width:900px)": {
        fontSize: "3.20rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "3.20rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "3.20rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "3.20rem",
      },
    },
    h2: {
      fontSize: "2.40rem",
      "@media (min-width:600px)": {
        fontSize: "2.50rem",
      },
      "@media (min-width:900px)": {
        fontSize: "2.60rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "2.60rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "2.60rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "2.60rem",
      },
    },
    h3: {
      fontSize: "1.8rem",
      "@media (min-width:600px)": {
        fontSize: "1.90rem",
      },
      "@media (min-width:900px)": {
        fontSize: "2.00rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "2.00rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "2.00rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "2.00rem",
      },
    },
    h4: {
      fontSize: "1.40rem",
      "@media (min-width:600px)": {
        fontSize: "1.50rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.60rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.60rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "1.60rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.60rem",
      },
    },
    h5: {
      fontSize: "1.20rem",
      "@media (min-width:600px)": {
        fontSize: "1.30rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.40rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.40rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "1.40rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.40rem",
      },
    },
    h6: {
      fontSize: "1.00rem",
      "@media (min-width:600px)": {
        fontSize: "1.10rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.20rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.20rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "1.20rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.20rem",
      },
    },
    subtitle1: {
      fontSize: "0.80rem",
      "@media (min-width:600px)": {
        fontSize: "0.90rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.00rem",
      },
    },
    subtitle2: {
      fontSize: "0.80rem",
      "@media (min-width:600px)": {
        fontSize: "0.90rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "1.00rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.00rem",
      },
    },
    body1: {
      fontSize: "0.75rem",
      "@media (min-width:600px)": {
        fontSize: "0.80rem",
      },
      "@media (min-width:900px)": {
        fontSize: "0.85rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "0.90rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "0.95rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.00rem",
      },
    },
    body2: {
      fontSize: "0.75rem",
      fontWeight: 500,
      "@media (min-width:600px)": {
        fontSize: "0.80rem",
      },
      "@media (min-width:900px)": {
        fontSize: "0.85rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "0.90rem",
      },
      "@media (min-width:1500px)": {
        fontSize: "0.95rem",
      },
      "@media (min-width:1800px)": {
        fontSize: "1.00rem",
      },
    },
  },

  // MuiIcon: {
  //   styleOverrides: {
  //     root: {
  //       fontSize: '0.6rem',
  //       '@media (min-width:600px)': {
  //         fontSize: '0.8rem',
  //       },
  //       '@media (min-width:900px)': {
  //         fontSize: '1.0rem',
  //       }
  //     },
  //   },
  // },
});

export default theme;
