// react core
import { useContext, useState, useEffect, useRef } from "react";

// circular menu
import { Planet } from "react-planet";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSquare as iconSquare } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRight as iconArrow } from "@fortawesome/pro-solid-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { EVENT_MODULES } from "models/Structure";
// import { ActionButton } from "components/utils/common/CommonButtons";

// entzy components
import WidgetLaunchCounter from "components/event/widgets/WidgetLaunchCounter";

// derive active modules
const ACTIVE_MODULES = EVENT_MODULES.filter((obj) => obj.active);
const CORE_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && !obj.secondaryNav
);
const SECONDARY_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && obj.secondaryNav
);
const VISITING_RIDER_ACTIVE_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && obj.visitingRider
);
const VISITING_RIDER_CORE_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && !obj.secondaryNav && obj.visitingRider
);
const VISITING_RIDER_SECONDARY_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && obj.secondaryNav && obj.visitingRider
);
const BOOKEND_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && obj.bookend
);

function getModules(user, manager, filters) {
  let modules = ACTIVE_MODULES;
  if (!user.connected && !manager) {
    modules = VISITING_RIDER_ACTIVE_MODULES;
  }
  if (filters) {
    if (filters.core) {
      modules = CORE_MODULES;
      if (!user.connected && !manager) {
        modules = VISITING_RIDER_CORE_MODULES;
      }
    }
    if (filters.secondaryNav) {
      modules = SECONDARY_MODULES;
      if (!user.connected && !manager) {
        modules = VISITING_RIDER_SECONDARY_MODULES;
      }
    }
    if (filters.bookend) {
      modules = BOOKEND_MODULES;
    }
  }
  return modules;
}

function getModuleCounter(moduleId, eventUrl, notifications) {
  let counterShow, counterValue;
  if (moduleId === "messaging") {
    counterShow = true;
    counterValue = notifications.data.messages.filter(
      (obj) =>
        obj.MessageUrl.endsWith(configEntzy.URL_POINTERS.MAIN + eventUrl) &&
        !obj.MessageRead
    ).length;
  } else if (moduleId === "entry") {
    counterShow = true;
    counterValue = notifications.data.activity.filter(
      (obj) =>
        obj.MessageUrl.endsWith(configEntzy.URL_POINTERS.MAIN + eventUrl) &&
        !obj.MessageRead
    ).length;
  } else {
    counterShow = false;
    counterValue = 0;
  }
  return [counterShow, counterValue];
}

function getOrbitScaler(centerWidth) {
  return Math.floor(centerWidth * 1.2);
}

function ModuleButton(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const module = props.module;
  const isCircleMenu = props.sourceMenu === "circular";
  const isCircleButton = props.filters && props.filters.circle;
  const isSquareButton = props.filters && props.filters.square;
  const isButton = isCircleButton || isSquareButton;
  const isMini = props.filters && props.filters.mini;
  const isBookend = props.filters && props.filters.bookend;
  const isDark = props.filters && props.filters.dark;
  const showTips = props.filters && props.filters.tips;
  const showNumbers = props.filters && props.filters.numbers;

  let [counterShow, counterValue] = getModuleCounter(
    module.id,
    eventContext.state.event.data.Url,
    mainContext.state.notifications
  );

  const [animate, setAnimate] = useState(0);

  // toggle animation on a 6 second delay for N times
  useEffect(() => {
    const timer = setInterval(() => {
      setAnimate(animate + 1);
    }, 6000);
    if (animate > 21) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [animate]);

  const openTransact = async () => {
    if (user.connected) {
      mainContext.setPayDirectViewer({
        open: mainContext.state.payDirect.viewer.open ? false : true,
        recipient:
          configEntzy.URL_POINTERS.MAIN + eventContext.state.event.data.Url,
        currency: eventContext.state.event.data.Currency,
      });
    } else {
      mainContext.updateAlert({
        show: true,
        message: "You must be connected to transact",
      });
    }
  };

  return (
    <Box
      className={isBookend ? "box-inline " + module.float : "box-inline"}
      // sx={{
      //   fontSize: configEntzy.FONT_SCALE_LG,
      // }}
      sx={{
        color:
          eventContext.state.event.moduleSelected === module.id || isDark
            ? "orange"
            : "grey",
        whiteSpace: "nowrap",
      }}
    >
      <Box className="box-default">
        {showTips && module.tipText && (
          <Box
            className="box-abs bg-black-t50 pop-top"
            sx={{
              width: configEntzy.AVATAR_SIZE_MD,
              // top: isCircleMenu ? configEntzy.APP_SPACING_LG : undefined,
              left:
                isCircleMenu && props.index > 2
                  ? configEntzy.APP_SPACING_XS
                  : undefined,
              right:
                isCircleMenu && props.index < 3
                  ? configEntzy.APP_SPACING_XS
                  : undefined,
              mt: configEntzy.APP_SPACING_XL,
              ml: !isCircleMenu ? configEntzy.APP_SPACING_NMD2X : undefined,
              p: configEntzy.APP_SPACING_SM,
              borderRadius: configEntzy.BORDER_SIZE_LG,
              color: "white",
            }}
          >
            <Typography variant="body1">
              {eventContext.state.event.manager
                ? module.tipText.runners
                : module.tipText.riders}
            </Typography>
          </Box>
        )}
        <Typography
          variant="h6"
          sx={{
            mt: isMini ? 0 : configEntzy.APP_SPACING_LG,
            color:
              eventContext.state.event.moduleSelected === module.id || isDark
                ? "orange"
                : "grey",
          }}
        >
          <span
            className="fa-layers fa-2x fa-fw action-pointer"
            onClick={
              module.actionExit
                ? () => eventContext.exitEvent({ mainContext, eventContext })
                : module.id === "transact"
                ? () => openTransact()
                : module.id === "menu" && props.menuAction
                ? props.menuAction
                : () => eventContext.focusModule(module.id)
            }
          >
            {(isButton ||
              eventContext.state.event.moduleSelected === module.id) && (
              <FontAwesomeIcon
                icon={isCircleButton ? iconCircle : iconSquare}
                transform={isMini ? "grow-8 up-3" : "grow-26 down-2"}
                color={
                  eventContext.state.event.moduleSelected === module.id ||
                  isDark
                    ? "black"
                    : "white"
                }
                style={{
                  opacity:
                    eventContext.state.event.moduleSelected === module.id
                      ? 0.4
                      : 0.2,
                  filter: "drop-shadow(0.1rem 0.1rem 0.1rem black)",
                }}
              />
            )}
            {showNumbers && !showTips && module.icon.number && (
              <FontAwesomeIcon
                icon={module.icon.number}
                transform={isCircleMenu ? "up-20 left-10" : "up-20"}
              />
            )}
            <FontAwesomeIcon
              icon={module.icon.main}
              transform="shrink-4 up-3"
              color={
                eventContext.state.event.moduleSelected === module.id
                  ? "orange"
                  : isDark
                  ? "white"
                  : "#999"
              }
            />
            {counterShow && (
              <span
                className="fa-layers-counter fa-layers-top-right"
                style={{
                  backgroundColor: counterValue > 0 ? "red" : "white",
                  color: counterValue > 0 ? "white" : "black",
                  border:
                    counterValue > 0 ? "1px solid red" : "1px solid black",
                }}
              >
                {counterValue}
              </span>
            )}
          </span>
        </Typography>
      </Box>
      <Grow in={animate % 2 === 0} timeout={2000}>
        <Box className="box-default zero-height">
          <Typography variant="body2">{module.shortName}</Typography>
        </Box>
      </Grow>
      <Grow in={animate % 2 !== 0} timeout={2000}>
        <Box className="box-default zero-height">
          <Typography variant="body2">{module.shortSubtext}</Typography>
        </Box>
      </Grow>
      <Box className="box-default">
        <Typography variant="body2">&nbsp;</Typography>
      </Box>
    </Box>
  );
}

export function MenuEventInfo(props) {
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const user = props.user;
  const lightMode = props.light;
  const modules = getModules({ connected: true }, false, { core: true });
  const menuModule = EVENT_MODULES.filter((obj) => obj.id === "menu")[0];

  return (
    <Box
      id="anchor-menu-event"
      className="box-default full-height"
      sx={{
        p: configEntzy.APP_SPACING_SM,
      }}
    >
      {/* MENU BUTTON */}
      <Box
        className="box-default text-left"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
          pl: configEntzy.APP_SPACING_MD3X,
        }}
      >
        <Typography variant="h1" sx={{ overflow: "visible" }}>
          <span
            className="fa-layers fa-fw action-pointer"
            onClick={() => eventContext.focusModule("menu")}
          >
            <FontAwesomeIcon
              icon={iconCircle}
              transform="grow-10 down-8 left-1"
              style={{ color: "black" }}
            />
            <FontAwesomeIcon
              icon={menuModule.icon.main}
              transform="down-8 left-1"
              style={{ color: "white" }}
            />
          </span>
        </Typography>
        <Typography
          variant="subtitle2"
          color={lightMode ? "white" : "black"}
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          {menuModule.shortName} Information
        </Typography>
        <Typography variant="subtitle2" color={lightMode ? "white" : "black"}>
          Follow the eventuator brick road...
        </Typography>
      </Box>
      {/* INFO ON EACH CORE MENU ITEM */}
      <Box className="box-default">
        {modules.map((module, index) => {
          return (
            <Box
              key={"nav-" + module.id}
              className={
                eventContext.state.event.moduleSelected === module.id
                  ? "box-default bg-white rounded"
                  : "box-default action-pointer"
              }
              onClick={
                module.actionExit
                  ? () => eventContext.exitEvent({ mainContext, eventContext })
                  : () => eventContext.focusModule(module.id)
              }
              sx={{
                width: Math.floor((100 / CORE_MODULES.length) * 2) + "%",
                display: "inline-block",
                p: configEntzy.APP_SPACING_XS2X,
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG2X,
                  pl: configEntzy.APP_SPACING_XS,
                  pr: configEntzy.APP_SPACING_XS,
                }}
              >
                <Box className="box-default">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      overflow: "visible",
                      color: lightMode ? "white" : "black",
                      filter: "drop-shadow(0.1rem 0.1rem 0.1rem black)",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={module.icon.number}
                      size="3x"
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      transform="up-5"
                      color="white"
                    />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      overflow: "visible",
                      color: lightMode ? "white" : "black",
                      mt: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={module.icon.main}
                      size="3x"
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      transform="up-5"
                    />
                  </Typography>
                </Box>
                <Box
                  className="box-default upper-case"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD,
                  }}
                  hidden={props.footer}
                >
                  <Box className="box-default">
                    <Typography
                      variant="subtitle2"
                      color={lightMode ? "white" : "black"}
                    >
                      {module.shortName}
                    </Typography>
                  </Box>
                  <Box className="box-default">
                    <Box
                      className="box-inline bg-black-t50"
                      sx={{
                        mt: configEntzy.APP_SPACING_SM,
                        pt: configEntzy.APP_SPACING_XS,
                        pb: configEntzy.APP_SPACING_XS,
                        pl: configEntzy.APP_SPACING_SM,
                        pr: configEntzy.APP_SPACING_SM,
                        borderRadius: configEntzy.APP_SPACING_SM,
                      }}
                    >
                      <Typography variant="body2" color="primary">
                        &nbsp;{module.shortSubtext}&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Box
                    className="box-default half-width"
                    sx={{
                      height: [0, 3].includes(index)
                        ? configEntzy.APP_SPACING_XL
                        : [1, 4].includes(index)
                        ? configEntzy.APP_SPACING_XL2X
                        : configEntzy.APP_SPACING_XL3X,
                      borderRight: lightMode
                        ? configEntzy.BORDER_DASH_DSM
                        : configEntzy.BORDER_DASH_GSM,
                    }}
                  ></Box>
                  <Box
                    className="box-default half-width"
                    sx={{
                      height: [0, 3].includes(index)
                        ? configEntzy.APP_SPACING_XL
                        : [1, 4].includes(index)
                        ? configEntzy.APP_SPACING_XL2X
                        : configEntzy.APP_SPACING_XL3X,
                    }}
                  ></Box>
                  <Box
                    className="box-default bg-white-t50"
                    sx={{
                      p: configEntzy.APP_SPACING_SM2X,
                      border: lightMode
                        ? configEntzy.BORDER_DASH_DSM
                        : configEntzy.BORDER_DASH_GSM,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color={lightMode ? "white" : "black"}
                    >
                      {module.help}
                    </Typography>
                    {!user.connected && index > 2 && (
                      <Typography
                        variant="subtitle2"
                        color={lightMode ? "white" : "black"}
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <em>Connect to access</em>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* CONTINUE BUTTON */}
      <Box
        className="box-default text-right"
        sx={{
          mt: configEntzy.APP_SPACING_LG2X,
          pr: configEntzy.APP_SPACING_MD3X,
        }}
      >
        <Typography variant="h1" color={lightMode ? "white" : "black"}>
          <span
            className="fa-layers fa-fw action-pointer"
            onClick={() => eventContext.focusModule("launchpad")}
          >
            <FontAwesomeIcon
              icon={iconCircle}
              transform="grow-10 up-9"
              style={{ color: "black" }}
            />
            <FontAwesomeIcon
              icon={iconArrow}
              transform="up-9"
              style={{ color: "white" }}
            />
          </span>
        </Typography>
      </Box>
      {/* <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD2X,
          pb: configEntzy.APP_SPACING_MD2X,
        }}
      >
        <Box className="box-inline">
          <ActionButton
            variant="contained"
            size="medium"
            type="button"
            onClick={handleShowInfo}
            color={showInfo ? "bright" : "primary"}
            text={showInfo ? "Close X" : "Show Room Details"}
            endIcon={
              showInfo ? null : (
                <FontAwesomeIcon
                  icon={iconInfo}
                  style={{
                    fontSize: "inherit",
                  }}
                  fixedWidth
                />
              )
            }
          />
        </Box>
      </Box> */}
    </Box>
  );
}

// mini nav bar for use on all modules except menu
export function MenuEventCore(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const modules = getModules(
    user,
    eventContext.state.event.manager,
    props.filters
  );

  return (
    <Box className="box-default">
      {modules.map((module, index) => {
        return (
          <Box
            key={"nav-" + module.id}
            className={
              eventContext.state.event.moduleSelected === module.id
                ? "box-inline bg-white rounded"
                : "box-inline action-pointer"
            }
            onClick={
              module.actionExit
                ? () => eventContext.exitEvent({ mainContext, eventContext })
                : () => eventContext.focusModule(module.id)
            }
            sx={{
              width: Math.floor(100 / CORE_MODULES.length) + "%",
            }}
          >
            <Box
              className={
                eventContext.state.event.moduleSelected === module.id
                  ? "box-inline bg-white rounded"
                  : "box-inline action-pointer"
              }
              sx={{ p: configEntzy.APP_SPACING_SM }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={module.icon.main} size="2x" fixedWidth />
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

// top header nav for menu and exit
export function MenuEventBar(props) {
  const user = props.user;
  const eventContext = useContext(EventContext);
  const modules = getModules(
    user,
    eventContext.state.event.manager,
    props.filters
  );
  return (
    <Box
      className="box-default"
      sx={{
        mt: configEntzy.APP_SPACING_SM,
        pt: configEntzy.APP_SPACING_MD,
        pb: configEntzy.APP_SPACING_MD,
        // width: "auto",
      }}
    >
      <Container maxWidth="lg" disableGutters>
        {modules.map((module, index) => {
          return (
            <Box
              key={"nav-" + module.id}
              className="box-inline"
              sx={{
                width: Math.floor(100 / modules.length) + "%",
              }}
            >
              {props.filters.onlyModules &&
              !props.filters.onlyModules.includes(module.id) ? (
                <></>
              ) : (
                <ModuleButton
                  {...props}
                  key={module.id}
                  module={module}
                  index={index}
                  sourceMenu="bar"
                />
              )}
            </Box>
          );
        })}
      </Container>
    </Box>
  );
}

// main circular menu for use on event header
export function MenuEventCircular(props) {
  const user = props.user;
  const eventContext = useContext(EventContext);
  const modules = getModules(
    user,
    eventContext.state.event.manager,
    props.filters
  );
  const centerContentRef = useRef(null);
  const [orbitSize, setOrbitSize] = useState(getOrbitScaler(96));

  useEffect(() => {
    if (centerContentRef.current) {
      const cenWidth = centerContentRef.current.offsetWidth;
      setOrbitSize(getOrbitScaler(cenWidth));
    }
  }, [centerContentRef]);

  useEffect(() => {
    const handleResize = () => {
      if (centerContentRef.current) {
        const cenWidth = centerContentRef.current.offsetWidth;
        setOrbitSize(getOrbitScaler(cenWidth));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className="box-default relative">
      <Box
        className="box-inline"
        sx={{
          width: configEntzy.AVATAR_SIZE_MD,
          height: configEntzy.AVATAR_SIZE_MD,
        }}
      >
        <Planet
          centerContent={
            <Box
              ref={centerContentRef}
              className="box-inline"
              sx={{
                width: configEntzy.AVATAR_SIZE_MD,
                height: configEntzy.AVATAR_SIZE_MD,
                borderRadius: "50%",
              }}
            >
              <WidgetLaunchCounter {...props} />
            </Box>
          }
          open={true}
          hideOrbit={true}
          orbitRadius={orbitSize}
          bounceOnClose={true}
          // autoClose={true}
          // rotation={105}
          // bounceDirection="BOTTOM"
          // orbitColor="#000000"
          // satelliteOrientation="READABLE"
          // mass={4}
          // tension={500}
          // friction={19}
        >
          {modules.map((module, index) => {
            return (
              <ModuleButton
                {...props}
                key={module.id}
                module={module}
                index={index}
                sourceMenu="circular"
              />
            );
          })}
        </Planet>
      </Box>
    </Box>
  );
}
