// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
// import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPlus as iconAdd } from "@fortawesome/pro-thin-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { TicketViewer } from "components/event/lists/ListTickets";
import { FallbackMessage } from "components/event/lists/ListFallbacks";
import {
  ActionButton,
  ActionBoxButton,
} from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";

function ActionLaunchpadRunnerTickets(props) {
  const section = "tickets";
  const user = props.user;
  const editing = props.editing;
  const handleEdit = props.handleEdit;
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";
  // const handleRunnerActionCallback = props.handleRunnerActionCallback;
  const handleTicketActionCallback = props.handleTicketActionCallback;

  const addFormTicket = props.addFormTicket;
  const showAddTicket = props.showAddTicket;
  const handleShowAddTicket = props.handleShowAddTicket;

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  return (
    <Box id={anchorTop} className="box-default">
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Ticket Types
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Set tickets available for date offers</em>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mt: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <em>Ticket changes require a launch recalibration.</em>
        </Typography>
        <Typography variant="subtitle1">
          <em>Allow a few moments for updates to take effect</em>
        </Typography>
      </Box>
      <Box
        className={editing ? "box-default" : "box-default action-pointer"}
        sx={{
          mt: configEntzy.APP_SPACING_LG,
        }}
      >
        {!user.connected && editing && (
          <Box
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_SM,
            }}
          >
            <Box
              className="box-default shadow-light"
              sx={{
                pt: configEntzy.APP_SPACING_XL,
                pb: configEntzy.APP_SPACING_XL,
              }}
            >
              <TextTag text="Connect to edit tickets" />
            </Box>
          </Box>
        )}
        {eventContext.state.event.calendar.tickets.selectable.all.length > 0 ? (
          <Box className="box-default">
            {eventContext.state.event.calendar.tickets.selectable.all.map(
              (selectable, index) => {
                // find the midpoint for the edit icon
                // const arrayLength =
                //   eventContext.state.event.calendar.tickets.selectable.all
                //     .length;
                // const midPoint =
                //   arrayLength === 1 ? 1 : Math.floor(arrayLength / 2);

                return (
                  <Box
                    key={selectable.id}
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                    }}
                    onClick={
                      editing ? undefined : () => handleEdit(selectable.id)
                    }
                    hidden={editing && editing !== selectable.id}
                  >
                    <TicketViewer
                      user={user}
                      selectable={selectable}
                      triggers={eventContext.state.event.calendar.triggers}
                      limits={eventContext.state.event.calendar.limits}
                      editing={
                        user.connected && editing === selectable.id
                          ? true
                          : false
                      }
                      handleEdit={handleEdit}
                      listView={true}
                      editAction={handleTicketActionCallback}
                    />
                    {/* EDIT ICON IN MIDDLE of each ticket */}
                    {/* ----------------------------------------- */}
                    {editing !== selectable.id && (
                      <Box className="box-default zero-height">
                        <Typography variant="h1" color="primary">
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon
                              icon={iconCircle}
                              color="#ccc"
                              transform="grow-15 up-40"
                            />
                            <FontAwesomeIcon
                              icon={iconCircle}
                              color="white"
                              transform="grow-14 up-40"
                            />
                            <FontAwesomeIcon
                              icon={iconEdit}
                              transform="up-40"
                            />
                          </span>
                        </Typography>
                      </Box>
                    )}
                    {/* ----------------------------------------- */}
                  </Box>
                );
              }
            )}
          </Box>
        ) : (
          <FallbackMessage fallback="No ticket types on offer" room={true} />
        )}
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
        }}
        hidden={!editing}
      >
        <ActionButton
          color="bright"
          size="small"
          text="Exit Ticket Editor"
          onClick={() => handleEdit()}
          endIcon={<FontAwesomeIcon icon={iconClose} fixedWidth />}
        />
      </Box>
      {user.connected && !editing && (
        <Box className="box-default">
          <Box
            className={
              showAddTicket ? "box-default shadow-light" : "box-default"
            }
            sx={{
              mt: configEntzy.APP_SPACING_LG,
            }}
          >
            <ActionBoxButton
              variant="contained"
              size="medium"
              type="button"
              onClick={handleShowAddTicket}
              bgColor={showAddTicket ? "transparent" : "primary.main"}
              color={showAddTicket ? "white" : "black"}
              text={showAddTicket ? "Close" : "New Ticket"}
              fullWidth={true}
              endIcon={
                showAddTicket ? (
                  <FontAwesomeIcon
                    icon={iconClose}
                    style={{
                      fontSize: "inherit",
                    }}
                    transform="grow-4"
                    fixedWidth
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={iconAdd}
                    style={{
                      fontSize: "inherit",
                    }}
                    transform="grow-4"
                    fixedWidth
                  />
                )
              }
            />
          </Box>
          <Zoom in={showAddTicket}>
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <InputForm
                  navigate={props.navigate}
                  fields={[
                    {
                      id: "ticket-name",
                      type: "text-standard",
                      label: "Enter ticket name",
                      value: addFormTicket.ticketName,
                      maxLength: 50,
                      required: true,
                      border: true,
                      startAdornment:
                        eventContext.state.toolbox.getAdormentIcon(
                          "ticket-text"
                        ),
                    },
                    {
                      id: "ticket-price",
                      type: "text-standard",
                      label: "Enter ticket Price",
                      value: addFormTicket.ticketPrice,
                      numeric: true,
                      required: true,
                      border: true,
                      startAdornment:
                        eventContext.state.event.data.Currency.toUpperCase(),
                    },
                    {
                      id: "ticket-capacity",
                      type: "text-standard",
                      label: "Enter ticket capacity per launch",
                      value: addFormTicket.ticketCapacity,
                      numeric: true,
                      required: true,
                      border: true,
                      startAdornment:
                        eventContext.state.toolbox.getAdormentIcon("user-max"),
                    },
                    {
                      id: "ticket-group-size",
                      type: "selector-standard",
                      label: "Select maximum group size per ticket",
                      value: addFormTicket.ticketGroupSize,
                      options:
                        eventContext.state.event.calendar.tickets.groups
                          .selectable,
                      required: true,
                      startAdornment:
                        eventContext.state.toolbox.getAdormentIcon(
                          "user-group"
                        ),
                    },
                  ]}
                  submitText="Add Ticket"
                  callback={handleTicketActionCallback}
                />
              </Box>
            </Box>
          </Zoom>
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerTickets;
