// react core
import { useState, useContext, Fragment } from "react";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// entzy context and services
import { SettingsContext } from "pages/settings/SettingsContext";
import { serviceObjectImageState } from "services/storage/object";
import { timeout } from "models/Tools";

function ProfileAvatar(props) {
  const user = props.user;
  const context = useContext(SettingsContext);
  const [edit, setEdit] = useState(false);

  const handleEdit = async () => {
    setEdit(!edit);
  };

  const handleFormCallback = async (data) => {
    const uploadedImage = data.filter((obj) => obj.id === "profile-avatar")[0]
      .value;
    if (uploadedImage && uploadedImage.key && uploadedImage.key.length) {
      let count = 0,
        ready = false;
      if (uploadedImage.key === configEntzy.EMPTY_STRING_SET) {
        // this is a remove image request
        uploadedImage.preload = null;
        ready = true;
      } else {
        // for new image first check all resized images are available
        do {
          await timeout(count * 1000);
          ready = await serviceObjectImageState(uploadedImage.key);
          count++;
        } while (count < 10 && !ready);
      }
      // proceed when ready
      if (ready) {
        const settingsUpdate = await context.prepareUpdateSettings({
          key: "Avatar",
          value: uploadedImage.key,
          preload: uploadedImage.preload,
        });
        if (settingsUpdate.alert) {
          return {
            alert: true,
            message: settingsUpdate.message,
          };
        } else {
          context.updateSettings(settingsUpdate);
          props.userReload();
          return { alert: false };
        }
      } else {
        return {
          alert: true,
          message:
            "Profile image could not be processed. Give it another go or if this problem persists try another image size or format.",
        };
      }
    } else {
      return {
        alert: true,
        message: "Profile image file is required",
      };
    }
  };

  return (
    <Box
      className="box-default"
      sx={{ mt: configEntzy.APP_SPACING_MD, mb: configEntzy.APP_SPACING_LG }}
    >
      <Box className="box-default" hidden={edit}>
        <IconButton aria-label="back" onClick={handleEdit}>
          <Avatar
            className="bg-black-t10 shadow-default rounded"
            variant="rounded"
            src={
              context.state.user.avatar.hydrated &&
              context.state.user.avatar.images &&
              context.state.user.avatar.images.lg
                ? context.state.user.avatar.images.lg.data
                : null
            }
            alt="Avatar"
            sx={{
              width: configEntzy.AVATAR_SIZE_LG,
              height: configEntzy.AVATAR_SIZE_LG,
              margin: "0 auto",
              color: "divider",
              backgroundColor: "transparent",
            }}
          >
            <FontAwesomeIcon
              icon={context.state.constants.iconImageFallback}
              size="5x"
              color="#555"
            />
          </Avatar>
        </IconButton>
      </Box>
      <Box className="box-default">
        {edit && (
          <Container maxWidth="sm">
            <InputForm
              user={user}
              navigate={props.navigate}
              fields={[
                {
                  id: "profile-avatar",
                  type: "image-picker",
                  label: "Select an image",
                  value: "",
                  image:
                    context.state.user.avatar.hydrated &&
                    context.state.user.avatar.images &&
                    context.state.user.avatar.images.lg
                      ? context.state.user.avatar.images.lg.data
                      : null,
                  required: true,
                },
              ]}
              submitText="Look Good? Confirm Update"
              submitType="flow"
              openImagePicker={true}
              fallbackImage={context.state.constants.iconImageFallback}
              callback={handleFormCallback}
              confirmation="Profile image updated successfully"
            />
          </Container>
        )}
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_XL,
          pb: configEntzy.APP_SPACING_XL,
        }}
      >
        {edit ? (
          <Fragment>
            <Box
              className="box-default"
              sx={{ pt: configEntzy.APP_SPACING_LG }}
            >
              <Button
                variant="contained"
                color="bright"
                size="medium"
                onClick={handleEdit}
              >
                Close
              </Button>
            </Box>
          </Fragment>
        ) : (
          <Button
            variant="contained"
            color="bright"
            size="large"
            onClick={handleEdit}
          >
            Edit Avatar
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ProfileAvatar;
