// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

// fonts and icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faImagePolaroid as iconLogo } from "@fortawesome/pro-duotone-svg-icons";
// import { faClose as iconClose } from "@fortawesome/pro-duotone-svg-icons";
import { faImageLandscape as iconImageFallback } from "@fortawesome/pro-thin-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import { ActionLoader } from "components/utils/common/CommonLoaders";
import { ActiveTag } from "components/utils/common/CommonTags";
import { timeout } from "models/Tools";

// entzy widgets and services
import { serviceObjectImageState } from "services/storage/object";

// entzy hooks
import { useMediaImage } from "hooks/media/mediaImage";

function ActionLaunchpadLogo(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  // const [showLogo, setShowLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState("");

  const handleBannerLogo = async (data) => {
    if (eventContext.state.event.manager) {
      const uploadedImage =
        data === configEntzy.EMPTY_STRING_SHORTSET
          ? { key: configEntzy.EMPTY_STRING_SHORTSET }
          : data.filter((obj) => obj.id === "event-logo")[0].value;
      if (uploadedImage && uploadedImage.key && uploadedImage.key.length) {
        let count = 0,
          ready = false;
        setLoading(true);
        if (uploadedImage.key === configEntzy.EMPTY_STRING_SHORTSET) {
          // this is a remove image request
          uploadedImage.preload = null;
          ready = true;
        } else {
          // for new image first check all resized images are available
          do {
            await timeout(count * 1000);
            ready = await serviceObjectImageState(uploadedImage.key);
            count++;
          } while (count < 10 && !ready);
        }
        // proceed when ready
        if (ready) {
          const eventUpdate = await eventContext.prepareUpdateEvent({
            key: "Logo",
            value: uploadedImage.key,
            preload: uploadedImage.preload,
          });
          setLoading(false);
          setSelection("");
          if (eventUpdate.alert) {
            return {
              alert: true,
              message: eventUpdate.message,
            };
          } else {
            eventContext.updateEvent(eventUpdate);
            return { alert: false };
          }
        } else {
          setLoading(false);
          setSelection("");
          return {
            alert: true,
            message:
              "Profile image could not be processed. Give it another go or if this problem persists try another image size or format.",
          };
        }
      } else {
        return {
          alert: true,
          message: "Profile image file is required",
        };
      }
    } else {
      return {
        alert: true,
        message: "Event runner permissions required for this update",
      };
    }
  };

  // const handleShowLogo = async () => {
  //   const value = !showLogo;
  //   setShowLogo(value);
  //   setSelection(value ? "logo" : "");
  // };

  // load page images
  const eventLogo = useMediaImage(
    eventContext.state.event.hydrated
      ? {
          user: user,
          context: mainContext,
          image: {
            id: "event:logo:" + eventContext.state.event.data.EventId,
            key: eventContext.state.event.data.Logo,
          },
        }
      : null
  );

  return (
    <Box className="box-default">
      {loading || !eventLogo ? (
        <Box
          className="box-default shadow-light"
          sx={{
            pt: configEntzy.APP_SPACING_XL,
            pb: configEntzy.APP_SPACING_XL,
          }}
        >
          <ActionLoader size="md" color="success" />
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{ mb: configEntzy.APP_SPACING_MD }}
          hidden={selection && selection !== "logo"}
        >
          {eventContext.state.event.data.Logo !==
          configEntzy.EMPTY_STRING_SHORTSET ? (
            <Box
              className="box-default bg-white-t50 shadow-light"
              sx={{ p: configEntzy.APP_SPACING_MD }}
            >
              <Box className="box-default relative">
                <Box
                  className="box-default"
                  sx={{
                    "&::before": {
                      content: `''`,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        eventLogo.hydrated && eventLogo.images.lg
                          ? "url(" + eventLogo.images.lg.data + ")"
                          : "none",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "repeat-y",
                      filter:
                        eventLogo.hydrated && eventLogo.images.lg
                          ? "blur(1px)"
                          : "none",
                      opacity: 1,
                      zIndex: -1,
                    },
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_XL,
                      pb: configEntzy.APP_SPACING_XL,
                    }}
                  >
                    <Box className="box-default">
                      <ActiveTag prefix="Background Logo" value={true} />
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <ActionButton
                        size="small"
                        text="Remove"
                        color="danger"
                        onClick={() =>
                          handleBannerLogo(configEntzy.EMPTY_STRING_SHORTSET)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="box-default">
              {/* <Box className="box-default">
                <ActionBoxButton
                  variant="contained"
                  type="button"
                  size="small"
                  bgColor="secondary.main"
                  color="black"
                  onClick={handleShowLogo}
                  text={showLogo ? "Close" : "Set Background Logo"}
                  endIcon={
                    <FontAwesomeIcon
                      icon={showLogo ? iconClose : iconLogo}
                      style={{
                        fontSize: "inherit",
                      }}
                      transform="grow-10"
                      fixedWidth
                    />
                  }
                />
              </Box> */}
              <Collapse
                in={true}
                timeout={{
                  enter: configEntzy.TRANSITIONS.default,
                  exit: configEntzy.TRANSITIONS.default,
                }}
              >
                <Box
                  className="box-default"
                  sx={{ mt: configEntzy.APP_SPACING_MD }}
                >
                  <InputForm
                    user={user}
                    navigate={props.navigate}
                    fields={[
                      {
                        id: "event-logo",
                        type: "image-picker",
                        level: "public",
                        label: "Select an image",
                        value: "",
                        image:
                          eventContext.state.event.hydrated &&
                          eventContext.state.event.data.Logo &&
                          eventContext.state.event.data.Logo !==
                            configEntzy.EMPTY_STRING_SHORTSET
                            ? eventContext.state.event.data.Logo
                            : null,
                        required: true,
                      },
                    ]}
                    submitText="All Good? Confirm Update"
                    submitType="flow"
                    openImagePicker={true}
                    fallbackImage={iconImageFallback}
                    callback={handleBannerLogo}
                    confirmation="Logo image updated successfully"
                    // cancel={handleShowCreate}
                    // cancelText="Cancel"
                    // footNotes={["Background logo for your eventuator"]}
                  />
                </Box>
              </Collapse>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadLogo;
