// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { closeAction } from "models/Tools";
import { APP_MENU_TREE } from "models/Structure";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconCloseHome } from "@fortawesome/pro-solid-svg-icons";

function MainCloseBar(props) {
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const menuItem = APP_MENU_TREE.filter(
    (obj) => obj.id === mainContext.state.menuSelected
  )[0];
  const closeTarget = props.closeTarget
    ? props.closeTarget
    : menuItem
    ? menuItem.parentId
    : "home";

  const handleCloseAction = () => {
    return closeAction(
      mainContext,
      eventContext,
      closeTarget,
      props.menuSelectById
    );
  };

  return (
    <Box className="box-default">
      <Container maxWidth="lg">
        {!["/", "/events/runner"].includes(props.page) && (
          <Box
            className="box-default text-right"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="subtitle2">
              <FontAwesomeIcon
                icon={iconCloseHome}
                size="2x"
                className="action-pointer"
                onClick={handleCloseAction}
                fixedWidth
              />
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default MainCloseBar;
