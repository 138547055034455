// react core
import { useState } from "react";

// rich text editor with html and links
import { convertToHTML } from "draft-convert";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy context and components
// import configEntzy from "components/config/ConfigEntzy";
import { ContentWarningTag } from "components/utils/common/CommonTags";
import { jsonTryParse } from "models/Tools";

function WidgetRichText(props) {
  const content = props.content;
  const contentId = props.contentId ? props.contentId : "content";

  let moderation = props.moderation;
  if (moderation && !moderation.status) {
    moderation = jsonTryParse(moderation, {
      status: "fail",
    });
  }

  const [showModerated, setShowModerated] = useState(null);

  const handleShowModerated = (id) => {
    setShowModerated(showModerated === id ? null : id);
  };

  const getConvertedContent = (contentData, moderation) => {
    let contentState,
      editorState,
      convertedContent,
      sanitizedHtml,
      linkifyOptions;
    try {
      contentState = convertFromRaw(JSON.parse(contentData));
      editorState = EditorState.createWithContent(contentState);
      convertedContent = convertToHTML(editorState.getCurrentContent());
    } catch (error) {
      convertedContent = contentData;
    }
    sanitizedHtml = DOMPurify.sanitize(convertedContent);
    sanitizedHtml = sanitizedHtml.replace(/<p><\/p>/g, "<p>&nbsp;</p>");
    linkifyOptions = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            moderation && moderation.text_link === "fail"
              ? sanitizedHtml
              : linkifyHtml(sanitizedHtml, linkifyOptions),
        }}
      />
    );
  };

  return (
    <Box className="box-default">
      <ContentWarningTag
        moderation={moderation}
        contentId={contentId}
        showModerated={showModerated}
        handleShowModerated={handleShowModerated}
      />

      <Box
        className={
          "box-default text-paragraph text-left" +
          (moderation
            ? showModerated !== contentId
              ? " content-blur"
              : ""
            : "")
        }
      >
        <Typography variant="subtitle2">
          <div>{getConvertedContent(content, moderation)}</div>
        </Typography>
      </Box>
    </Box>
  );
}

export default WidgetRichText;
