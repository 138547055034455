// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { faImage as iconImageFallback } from "@fortawesome/pro-thin-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { serviceObjectImageState } from "services/storage/object";
import { timeout } from "models/Tools";

function MemberPostMessage(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const roomArea = props.roomArea;
  const roomName = props.roomName;
  // const roomId = props.roomId;
  const memberName = props.memberName ? props.memberName.toLowerCase() : "";

  const handlePostActionCallback = async (data) => {
    const messageText = data.filter((obj) => obj.id === "message-text")[0]
      .value;
    const messageImage = data.filter((obj) => obj.id === "message-image")[0]
      .value;
    // validate message content
    const hasText = messageText && messageText.length;
    const hasImage =
      messageImage && messageImage.key && messageImage.key.length;
    if (!hasText) {
      return {
        alert: true,
        message: "Add some message text to post",
      };
    }
    if (hasImage) {
      let count = 0,
        ready = false;
      if (messageImage.key === configEntzy.EMPTY_STRING_SET) {
        // this is a remove image request
        messageImage.preload = null;
        ready = true;
      } else {
        // for new image first check all resized images are available
        do {
          await timeout(count * 1000);
          ready = await serviceObjectImageState(messageImage.key);
          count++;
        } while (count < 10 && !ready);
      }
      // proceed when ready
      if (!ready) {
        return {
          alert: true,
          message:
            "Image could not be processed. Give it another go or if this problem persists try another image size or format.",
        };
      }
    }
    const messagePayload = await mainContext.preparePostMemberMessage({
      RoomArea: roomArea,
      RoomName: roomName,
      ContentType: "message",
      ContentData: messageText,
      ContentImage: messageImage.key,
      ImagePreload: messageImage.preload,
    });
    if (!messagePayload.alert) {
      mainContext.postMemberMessage(messagePayload.data);
      props.handleCloseAction();
    }
    return {
      alert: messagePayload.alert,
      message: messagePayload.message,
    };
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        {configEntzy.RESERVED_NAMES.includes(memberName) ? (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              @{memberName} is not yet available for DMs
            </Typography>
          </Box>
        ) : (
          <InputForm
            user={user}
            navigate={props.navigate}
            fields={[
              {
                id: "message-text",
                type: "text-multiline",
                label: "Enter message",
                value: "",
                required: true,
                maxLength: 1000,
                disabled: !user.connected,
              },
              {
                id: "message-image",
                type: "image-picker",
                label: "Post with image (optional)",
                value: "",
                image: null,
                disabled: !user.connected,
              },
            ]}
            submitText="Post"
            fallbackImage={iconImageFallback}
            fallbackImageHidden={true}
            disabledSubmit={!user.connected}
            disabledTitle="Connect to continue"
            disabledText="Tap here to activate messaging"
            disabledClick={props.drawerConnectToggle}
            callback={handlePostActionCallback}
          />
        )}
      </Box>
    </Box>
  );
}

export default MemberPostMessage;
