// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";

function LegalPrivacy(props) {
  return (
    <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_LG }}>
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
        <Typography variant="h6">PRIVACY POLICY</Typography>
        <Typography variant="subtitle1">Site Privacy Policy</Typography>
      </Box>
      <Box
        className="box-default text-left bg-black-t50"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
          p: configEntzy.APP_SPACING_LG,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <Typography variant="subtitle1">
          <strong>1. INTRODUCTION</strong>
        </Typography>
        <Typography variant="subtitle1">
          1.1 This website is operated by Entzy Ltd, known as Entzy, ("Entzy",
          "our", "we", or "us") (UK company number: 08243773). We will protect
          and keep confidential information collected from you as a result of
          use of this website.
        </Typography>
        <Typography variant="subtitle1">
          1.2 Links within this site to other websites are not covered by this
          privacy policy.
        </Typography>
        <Typography variant="subtitle1">
          1.3 Please refer to the Terms and Conditions of Use of our website
          which incorporates this privacy policy.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>2. PERSONAL INFORMATION</strong>
        </Typography>
        <Typography variant="subtitle1">
          2.1 "Personal Information" is any information which relates to and
          identifies you and which you have provided to us or entered in your
          Entzy Account or elsewhere on the website, including but not limited
          to your name and address, email address, age, gender, personal
          interests, date of birth and occupation.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>3. YOUR ENTZY ACCOUNT</strong>
        </Typography>
        <Typography variant="subtitle1">
          3.1 As a Member, you have an online profile as part of your Entzy
          Account which holds your protected personal data and access to your
          linked financial data held with Stripe.
        </Typography>
        <Typography variant="subtitle1">
          3.2 Only you as the Entzy Account owner can access and edit this area,
          along with any linked accounts.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>4. SECURITY</strong>
        </Typography>
        <Typography variant="subtitle1">
          4.1 Each Entzy account on our website is secured by a Username and
          Password, which the account holder can change at any time. We
          endeavour to ensure that your Personal Information is kept
          confidential according to your preferences as selected in your profile
          settings and take reasonable steps to protect any Personal Information
          held with us.
        </Typography>
        <Typography variant="subtitle1">
          4.2 We cannot, however, guarantee the security of your Personal
          Information as it may be compromised by unauthorised access, hardware
          or software failure or other relevant factors. If you would like to
          find out more about our security measures, please contact us.
        </Typography>
        <Typography variant="subtitle1">
          4.3 We cannot guarantee the confidentiality of any Postings in an
          Interactive Area or messages (email or otherwise) transmitted between
          you, us, other Entzy account holders or members of the general public,
          as the case may be. We will not be liable to you or anyone else for
          any loss in connection with any message transmitted or Postings made
          through use of or in connection with our website.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>5. USE OF PERSONAL INFORMATION</strong>
        </Typography>
        <Typography variant="subtitle1">
          5.1 Email addresses and names are stored and used by Entzy for the
          purposes of:
        </Typography>
        <Typography variant="subtitle1">
          5.1.1 uniquely identifying you as a Member when connecting;
        </Typography>
        <Typography variant="subtitle1">
          5.1.2 alerting you of any important changes in your Entzy Account;
        </Typography>
        <Typography variant="subtitle1">
          5.1.3 alerting you of any changes to your events or tickets, and for
          other notification purposes related to the Entzy service;
        </Typography>
        <Typography variant="subtitle1">
          5.1.4 confirmation of any changes initiated by your own actions, such
          as profile setting changes;
        </Typography>
        <Typography variant="subtitle1">
          5.1.5 communication between you and event management teams via the
          contact areas;
        </Typography>
        <Typography variant="subtitle1">
          5.1.6 communication between you and the Entzy team via the contact
          support areas;
        </Typography>
        <Typography variant="subtitle1">
          5.1.7 notifying Members about our services and any related information
          or offers which we consider may be of interest;;
        </Typography>
        <Typography variant="subtitle1">
          5.1.8 providing the Entzy services.
        </Typography>
        <Typography variant="subtitle1">
          5.2 For Guest email addresses added to access lists, we do not use
          these addresses for any other purpose other than to identify a visitor
          to an event URL and grant/deny access to the event upon connection.
        </Typography>
        <Typography variant="subtitle1">
          5.3 Recipients of any invitations or emails from can be removed from
          our database either by the event owner or by contacting us.
        </Typography>
        <Typography variant="subtitle1">
          5.4 You authorise us to use any Personal Information you provide to us
          or enter in your Entzy Account or elsewhere on the website in the
          following ways:
        </Typography>
        <Typography variant="subtitle1">
          5.4.1 to use, store or otherwise process any Personal Information, to
          the extent reasonably necessary to provide the services which are
          available through our website by us, our partners, successors
          (including the purchaser of the whole or part of our business),
          associates, sub-contractors or other third parties;
        </Typography>
        <Typography variant="subtitle1">
          5.4.2 to use, store or otherwise process your Personal Information for
          market, customer, product and website analysis purposes to enable us
          to review, develop and improve our services and provide you and other
          users with relevant information about our services;
        </Typography>
        <Typography variant="subtitle1">
          5.4.3 to collect Personal Information about your buying behaviour and
          if you send us personal correspondence such as e-mails or letters then
          we may collect this information into a file specific to you;
        </Typography>
        <Typography variant="subtitle1">
          Other than for the purposes mentioned above, we will not use or
          disclose to third parties any of your Personal Information for
          advertising or marketing purposes without your prior consent.
        </Typography>
        <Typography variant="subtitle1">
          5.5 You must ensure that the Personal Information you provide is
          accurate and complete and that all registration and other details
          provided (where applicable) contain your correct name, address and
          other requested details.
        </Typography>
        <Typography variant="subtitle1">
          5.6 If you would like to review or modify any part of your Personal
          Information then you can do this in the Profile area of your account,
          or you can contact us.
        </Typography>
        <Typography variant="subtitle1">
          5.7 We may disclose some or all of the information collected from you
          if required to do so by law or other legally binding obligation.
        </Typography>
        <Typography variant="subtitle1">
          <strong>
            <em>Cookies</em>
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          5.8 We use cookies (see definition below) for essential functionality
          (such as accessing secure areas and remembering preferences) and for
          collecting user information from the website to allow us to assess the
          level of use of the site at any time and use in generating website
          statistics to enable us to review and develop our services.
        </Typography>
        <Typography variant="subtitle1">
          5.9 We may collect or store users' Internet Protocol addresses (see
          definition below), which are automatically recognised by the webserver
          (see definition below), and session information such as the duration
          of the visit and the type of browser (see definition below) used. Such
          information is only used where we use cookies and for system
          administration of the website and to compile statistics which we use
          to review use of the website.
        </Typography>
        <Typography variant="subtitle1">
          5.10 We do <strong>not</strong> use marketing or advertising cookies.
        </Typography>
        <Typography variant="subtitle1">
          <strong>
            <em>Acknowledgement</em>
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          5.11 By using our website you acknowledge that you have read the terms
          of this privacy policy and that you consent to the collection of
          information from you and use of such information as described in this
          policy.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>
            6. IDENTITY FEDERATION AND LOGIN VIA IDENTITY PROVIDERS (OAUTH)
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          6.1 When you create an account or access your account via a third
          party identity provider (for example Amazon, Apple, Google) you
          authorize Entzy to access to your name and email address held with the
          identity provider, in order for your account to function.
        </Typography>
        <Typography variant="subtitle1">
          6.2 Your data will not be used in any other way other than as
          described above in 5. USE OF PERSONAL INFORMATION.
        </Typography>
        <Typography variant="subtitle1">
          6.3 You can change your email address and name held with us by
          changing your data held with the third party identity provider and
          logging back into your account.
        </Typography>
        <Typography variant="subtitle1">
          6.4 You can remove your connection to Entzy and the use of your email
          address and name by closing your Entzy account.
        </Typography>
        <Typography variant="subtitle1">
          <strong>
            Use of Additional Scopes and Permissions for Optional Services
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          6.5 Entzy offers optional services, such as calendar integration,
          where you can request additional scopes and permissions (beyond your
          email and name) from your identity provider.
        </Typography>
        <Typography variant="subtitle1">
          6.6 Additional scopes are controlled by you and are requested in
          context via incremental auth at the time of use of each service.
          Permissions are only used to provide the additional services to you
          and can be revoked at a any time by disconnecting the service being
          used.
        </Typography>
        <Typography variant="subtitle1">
          6.7 Entzy services that currently use additional scopes are:
          <br />- Calendar Integration (view and sync events)
          <br />
          <em>
            View your calendar events inside Entzy Calendar and Optionally Sync
            your events in either direction
          </em>
        </Typography>
        <Typography variant="subtitle1">
          6.8 Entzy adheres to the terms, conditions and privacy policies of
          each provider as detailed in the agreements below:
          <br />
          <em>Google:</em>
          <br />
          The Google API Services User Data Policy, including the Limited Use
          requirements.
          <br />
          The Google Play Developer Distribution Agreement.
          <br />
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/terms/api-services-user-data-policy
          </a>
          <br />
          <a
            href="https://play.google/developer-distribution-agreement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://play.google/developer-distribution-agreement.html
          </a>
          <br />
          <em>Apple:</em>
          <br />
          The Apple Developer Agreement.
          <br />
          <a
            href="https://developer.apple.com/terms/apple-developer-agreement/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developer.apple.com/terms/apple-developer-agreement/
          </a>
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>DEFINITIONS</strong>
        </Typography>
        <Typography variant="subtitle1">
          "Cookies" are information files created when you visit a site and
          contain a unique, anonymous id. They are stored in the cookie
          directory of your browser/device and do not expire at the end of your
          session.
        </Typography>
        <Typography variant="subtitle1">
          A "Browser" is a program used to search for and display web pages;
        </Typography>
        <Typography variant="subtitle1">
          An "Internet Protocol address" is a technical standard which allows
          data to be transmitted between two devices;
        </Typography>
        <Typography variant="subtitle1">
          A "Webserver" delivers web pages to a computer, in response to a
          request from a Browser;
        </Typography>
      </Box>
    </Box>
  );
}

export default LegalPrivacy;
