// aws tools
import { Storage, Auth } from "aws-amplify";

// entzy config and components
import configAws from "components/config/ConfigAws";
import { serviceLogError } from "services/graphql/call";

const RETRY_MSG =
  "Action did not complete or unable to connect to storage service at the moment. Give it another go or contact us if this problem persists.";

export async function serviceObjectUpload(
  category,
  level,
  name,
  file,
  identity
) {
  const results = {};
  if (!["private", "protected", "public"].includes(level)) {
    level = "private";
  }
  // file uploads for queue validation and processing
  let response;
  try {
    category = ["images", "videos", "files"].includes(category)
      ? category
      : "files";
    name = name.replace(/[^A-Za-z0-9\-_:]/g, "").substr(0, 100);
    name = name + ":" + level;
    response = await Promise.all([
      uploadFile(category, name, file, false),
      uploadFile(category, name, file, true),
    ]);
    // on success capture file in memory until server side is ready
    if (response[0].key.endsWith(name)) {
      const filePreload = await readFile(file);
      const returnKey =
        level === "protected" && identity
          ? category + "/" + name + ":" + identity.replace(":", "|")
          : category + "/" + name;
      results.data = {
        key: returnKey,
        preload: filePreload.data,
      };
      results.success = true;
      // track if problem reading file locally
      if (!filePreload.success) {
        serviceLogError("serviceObjectUpload", "Unable to read file locally");
      }
    } else {
      results.success = false;
      results.message = RETRY_MSG;
      serviceLogError("serviceObjectUpload", "Response key neq queue key");
    }
  } catch (error) {
    results.success = false;
    results.message = RETRY_MSG;
    serviceLogError("serviceObjectUpload", {
      category,
      level,
      name,
      response,
      error,
    });
  }
  return results;
}

export async function serviceObjectGetUrl(key, size) {
  size = size ? size.substr(0, 2) : "md";
  key = key + "-" + size;
  const objMeta = parseKey(key);
  const results = { key, size };
  try {
    const response = await Storage.get(objMeta.readKey, {
      level: objMeta.level,
    });
    results.data = response;
    results.success = true;
  } catch (error) {
    results.success = false;
    results.message = RETRY_MSG;
    serviceLogError("serviceObjectGetUrl", error);
  }
  return results;
}

export async function serviceObjectList(level, key) {
  const results = { level, key };
  try {
    const response = await Storage.list(key, {
      level: level,
    });
    results.data = response;
    results.success = true;
  } catch (error) {
    results.success = false;
    results.message = RETRY_MSG;
    serviceLogError("serviceObjectList", error);
  }
  return results;
}

export async function serviceObjectDownload(key, size, identity) {
  size = size ? size.substr(0, 2) : "md";
  const objMeta = parseKey(key);
  const sizedKey = objMeta.readKey + "-" + size;
  const results = { sizedKey, size };
  try {
    const params = {
      level: objMeta.level,
      download: true,
    };
    if (objMeta.identity) {
      params.identityId = objMeta.identity;
    } else if (identity) {
      params.identityId = identity;
    }
    const response = await Storage.get(sizedKey, params);
    const fileLoad = await readFile(response.Body);
    if (fileLoad.success) {
      results.data = fileLoad.data;
      results.success = true;
    } else {
      results.data = null;
      results.success = false;
      results.message = RETRY_MSG;
    }
  } catch (error) {
    results.success = false;
    results.message = RETRY_MSG;
    console.error("Unable to download file content");
    // serviceLogError("serviceObjectDownload", error);
  }
  return results;
}

export async function serviceObjectRemove(key) {
  const results = {};
  const objMeta = parseKey(key);
  try {
    // always remove from primary store and replication will take care of others
    results.data = await Storage.remove(objMeta.rawKey, {
      bucket: configAws.Storage.write.bucket,
      region: configAws.Storage.write.region,
      level: objMeta.level,
    });
    results.success = true;
  } catch (error) {
    results.success = false;
    results.message = RETRY_MSG;
    serviceLogError("serviceObjectUnload", error);
  }
  return results;
}

// check if all images are resized into sm md lg
export async function serviceObjectImageState(key) {
  const objMeta = parseKey(key);
  const images = await serviceObjectList(objMeta.level, objMeta.readKey);
  if (images.data.results.length === 3) {
    return true;
  } else {
    return false;
  }
}

function parseKey(key) {
  let parts, rawKey, readKey, taggedName, name, level, identity;
  taggedName = key.split("/").slice(-1)[0];
  parts = taggedName.split(":");
  name = parts[0];
  level = parts[1];
  if (!["private", "protected", "public"].includes(level)) {
    level = "protected";
  }
  if (parts.length > 2) {
    identity = parts[2];
    rawKey = key.replace(":" + identity, "");
    identity = identity.replace("|", ":");
  } else {
    rawKey = key;
    identity = null;
  }
  readKey = level === "public" ? "anyone/" + rawKey : rawKey;
  return {
    rawKey,
    readKey,
    name,
    level,
    taggedName,
    identity,
  };
}

async function readFile(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve({
        success: true,
        data: reader.result,
      });
    };
    reader.onerror = function (error) {
      reader.abort();
      // reject(new DOMException("Problem reading file content"));
      reject({
        success: false,
        data: null,
      });
    };
    reader.readAsDataURL(file);
  });
}

// all files initially uploaded to public uploads
// area for processing into target destination
// public/uploads/category/userid/ is backend controlled
async function uploadFile(category, name, file, isRead) {
  const credentials = await Auth.currentCredentials();
  const userid = credentials.identityId;
  const uploadArea = "uploads/" + category + "/" + userid + "/";
  const fileKey = uploadArea + name;
  return await Storage.put(fileKey, file, {
    bucket: isRead
      ? configAws.Storage.read.bucket
      : configAws.Storage.write.bucket,
    region: isRead
      ? configAws.Storage.read.region
      : configAws.Storage.write.region,
    contentType: file.type,
    level: "public",
  });
}

// export async function serviceObjectChatUpload(eventid, bundle, files) {
//   let key, stored;
//   const results = {
//     files: [],
//   };
//   try {
//     // create bundle json file
//     const bundleId = bundle.id.substr(0, 100);
//     const bundleFile = JSON.stringify(bundle);
//     const bundleSrc = "chats/" + eventid + "/" + bundleId + "/";
//     // upload bundles to both storage areas
//     // TODO: move to file upload model same as images
//     key = bundleSrc + "bundle.json";
//     stored = await Storage.vault.put(key, bundleFile, {
//       bucket: configAws.Storage.read.bucket,
//       region: configAws.Storage.read.region,
//       contentType: "application/json",
//     });
//     stored = await Storage.vault.put(key, bundleFile, {
//       bucket: configAws.Storage.write.bucket,
//       region: configAws.Storage.write.region,
//       contentType: "application/json",
//     });
//     results.data = { bundleKey: stored.key };
//     // upload files
//     // push files to write region and allow to replicate
//     for (let file of files) {
//       key = bundleSrc + "files/" + file.name;
//       stored = await Storage.vault.put(key, file.source, {
//         bucket: configAws.Storage.write.bucket,
//         region: configAws.Storage.write.region,
//         contentType: file.type,
//       });
//       results.files.push(stored.key);
//     }
//     results.success = true;
//   } catch (error) {
//     results.success = false;
//     results.message = RETRY_MSG;
//     serviceLogError("serviceObjectChatUpload", error);
//   }
//   return results;
// }
