// react core
import { useContext, useState, useEffect } from "react";

// date handling
import dayjs from "dayjs";

// imported animations
// import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { HeaderTag } from "components/utils/common/CommonTags";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion as iconLaunched } from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle as iconCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as iconPending } from "@fortawesome/pro-regular-svg-icons";
// import { faChartSimpleHorizontal as iconTicking } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and services
import { EventContext } from "pages/events/EventContext";
import { RoomDatesChartItemDetail } from "./RoomDatesChart";

function RoomLaunchpadUpdates(props) {
  const eventContext = useContext(EventContext);
  // const calendarView = props.calendarView;
  // const calendarDateSelect = props.calendarDateSelect;
  const sortedLaunchedDates =
    eventContext.state.event.calendar.dates.launched.future.keys
      .slice()
      .reverse();

  const [expandDateDetail, setExpandDateDetail] = useState(null);
  const [progressTickerView, setProgressTickerView] = useState(0);

  const handleExpandDateDetail = (dateId) => {
    setExpandDateDetail(expandDateDetail === dateId ? null : dateId);
  };

  const handleDateSelect = (date) => {
    handleExpandDateDetail(date.id);
    // if (calendarView) {
    //   calendarDateSelect(dateId);
    // } else {
    //   eventContext.focusModule("dates");
    // }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressTickerView((progressTickerView + 1) % 3);
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
      }}
    >
      <Box
        className="box-default"
        sx={{
          mb: configEntzy.APP_SPACING_LG,
        }}
      >
        <Box className="box-default">
          <HeaderTag text="Dates Launched and Coming Up!" />
        </Box>
        {sortedLaunchedDates.length > 0 ? (
          <Box className="box-default">
            {sortedLaunchedDates.map((dateId) => {
              const daysToDate = dayjs(dateId, "YYYY-MM-DD").diff(
                dayjs(),
                "days"
              );
              const date =
                eventContext.state.event.calendar.dates.combined.active.find(
                  (date) => date.id === dateId
                );
              return (
                <Box
                  key={dateId}
                  className="box-inline bg-white shadow-default action-pointer"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    m: configEntzy.APP_SPACING_XS,
                    backgroundColor: "primary.main",
                    borderRadius: configEntzy.BORDER_SIZE_LG,
                  }}
                  onClick={() => handleDateSelect(date)}
                >
                  <Typography variant="subtitle2" fontFamily="monospace">
                    <FontAwesomeIcon icon={iconLaunched} fixedWidth />
                  </Typography>
                  <Typography variant="subtitle2" fontFamily="monospace">
                    <span>&nbsp;</span>
                    <span>
                      {dayjs(dateId, "YYYY-MM-DD").format("ddd DD MMM")}
                    </span>
                    <span>&nbsp;</span>
                  </Typography>
                  <Typography variant="subtitle2" fontFamily="monospace">
                    <em>
                      in {daysToDate} day{daysToDate === 1 ? "" : "s"}
                    </em>
                  </Typography>
                </Box>
              );
            })}
            {/* <Marquee speed={20} gradient={false} style={{ overflow: "hidden" }}>
            </Marquee> */}
          </Box>
        ) : (
          <Box className="box-default content-disabled">
            <Typography variant="subtitle1">
              <em>No active date launches</em>
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="box-default">
        <Box className="box-default">
          <HeaderTag text="Dates Ticking with Offer Activity" />
        </Box>

        {eventContext.state.event.calendar.dates.combined.active.length > 0 ? (
          <Box className="box-default">
            {eventContext.state.event.calendar.dates.combined.active.map(
              (date) => {
                const daysToDate = dayjs(date.id, "YYYY-MM-DD").diff(
                  dayjs(),
                  "days"
                );
                return (
                  <Box
                    key={date.id}
                    className="box-inline shadow-default action-pointer nooverflow"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                      m: configEntzy.APP_SPACING_XS,
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                      backgroundColor: date.isExpired
                        ? "primaryDisabled.main"
                        : "secondary.main",

                      color: date.isExpired ? "lightgray" : "black",
                      opacity: date.isExpired ? 0.5 : 1,
                      display:
                        eventContext.state.event.calendar.dates.launched.future.keys.includes(
                          date.id
                        )
                          ? "none"
                          : undefined,
                    }}
                    onClick={() => handleDateSelect(date)}
                  >
                    <Typography variant="subtitle2" fontFamily="monospace">
                      <strong>
                        <span>&nbsp;</span>
                        <span>
                          {dayjs(date.id, "YYYY-MM-DD").format("ddd DD MMM")}
                        </span>
                        <span>&nbsp;</span>
                      </strong>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily="monospace"
                      sx={{
                        pb: configEntzy.APP_SPACING_SM,
                        mb: configEntzy.APP_SPACING_SM,
                        borderBottom: "1px solid #555",
                      }}
                    >
                      {date.isExpired ? (
                        <span>
                          <em>Expired</em>
                        </span>
                      ) : (
                        <em>
                          in {daysToDate} day{daysToDate === 1 ? "" : "s"}
                        </em>
                      )}
                    </Typography>

                    <Slide in={progressTickerView === 1} direction="right">
                      <Typography
                        variant="body1"
                        // fontFamily="monospace"
                        sx={{
                          // height: configEntzy.FONT_SCALE_SM,
                          // lineHeight: configEntzy.FONT_SCALE_SM,
                          textAlign: "left",
                          display: progressTickerView === 1 ? "block" : "none",
                        }}
                      >
                        <span>
                          {date.isRunnerOffer ? (
                            <FontAwesomeIcon
                              icon={iconCheck}
                              color="green"
                              fixedWidth
                            />
                          ) : (
                            <FontAwesomeIcon icon={iconPending} fixedWidth />
                          )}
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span>Runners</span>
                      </Typography>
                    </Slide>

                    <Slide in={progressTickerView === 2} direction="left">
                      <Typography
                        variant="body1"
                        // fontFamily="monospace"
                        sx={{
                          // height: configEntzy.FONT_SCALE_SM,
                          // lineHeight: configEntzy.FONT_SCALE_SM,
                          textAlign: "right",
                          display: progressTickerView === 2 ? "block" : "none",
                        }}
                      >
                        <span>Riders</span>
                        <span>&nbsp;</span>
                        <span>{date.progress.trigger.quantity}%</span>
                        <span>&nbsp;&nbsp;</span>
                        <span>
                          {date.progress.trigger.quantity > 0 ? (
                            <FontAwesomeIcon
                              icon={iconCheck}
                              color="orange"
                              fixedWidth
                            />
                          ) : (
                            <FontAwesomeIcon icon={iconPending} fixedWidth />
                          )}
                        </span>
                      </Typography>
                    </Slide>

                    <Slide in={progressTickerView === 0} direction="up">
                      <Typography
                        variant="body1"
                        // fontFamily="monospace"
                        sx={{
                          // height: configEntzy.FONT_SCALE_SM,
                          // lineHeight: configEntzy.FONT_SCALE_SM,
                          // textAlign: "center",
                          display: progressTickerView === 0 ? "block" : "none",
                        }}
                      >
                        <span style={{ float: "left" }}>
                          {date.isRunnerOffer ? (
                            <FontAwesomeIcon
                              icon={iconCheck}
                              color="green"
                              fixedWidth
                            />
                          ) : (
                            <FontAwesomeIcon icon={iconPending} fixedWidth />
                          )}
                        </span>
                        <span>{date.progress.trigger.quantity}%</span>
                        <span style={{ float: "right" }}>
                          {date.progress.trigger.quantity > 0 ? (
                            <FontAwesomeIcon
                              icon={iconCheck}
                              color="orange"
                              fixedWidth
                            />
                          ) : (
                            <FontAwesomeIcon icon={iconPending} fixedWidth />
                          )}
                        </span>
                      </Typography>
                    </Slide>

                    <LinearProgress
                      variant="determinate"
                      value={date.progress.trigger.quantity}
                      color={date.isExpired ? "secondaryDisabled" : "primary"}
                      className="shadow-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_SM,
                        width: "100%",
                        height: configEntzy.AVATAR_SIZE_XS,
                        borderRadius: configEntzy.BORDER_SIZE_LG,
                      }}
                    />
                    <RoomDatesChartItemDetail
                      {...props}
                      date={date}
                      expandDateDetail={expandDateDetail}
                      handleExpandDateDetail={handleExpandDateDetail}
                    />
                  </Box>
                );
              }
            )}
            {/* <Marquee speed={30} gradient={false} style={{ overflow: "hidden" }}>
            </Marquee> */}
          </Box>
        ) : (
          <Box className="box-default content-disabled">
            <Typography variant="subtitle1">
              <em>No active date offers</em>
            </Typography>
          </Box>
        )}
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="subtitle2" color="primary">
            <span
              style={{
                padding: "0.5rem",
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
              }}
            >
              <span>Launching is at</span>
              <span>&nbsp;&nbsp;</span>
              <span className="bg-white-t25 bdp2 rounded">
                &nbsp;{eventContext.state.event.calendar.limits.notice}&nbsp;
              </span>
              <span>&nbsp;&nbsp;</span>
              <span>day</span>
              {eventContext.state.event.calendar.limits.notice === 1 ? "" : "s"}
              <span>&nbsp;notice</span>
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default RoomLaunchpadUpdates;
