// react core
import { useContext, useRef } from "react";

// material design
import Box from "@mui/material/Box";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import GooglePlace from "components/utils/google/GooglePlace";

// entzy context and services
import { EventContext } from "pages/events/EventContext";

function ActionLocationsRunner(props) {
  const eventContext = useContext(EventContext);
  const roomContainerRef = useRef();

  const handleRunnerActionCallback = async (data) => {
    const eventLocations = data;
    if (eventContext.state.event.manager) {
      const response = await eventContext.prepareUpdatePlaces({
        key: "Locations",
        value: eventLocations,
        category: "runner",
      });
      if (response.alert) {
        return {
          alert: true,
          message: response.message,
        };
      } else {
        eventContext.updatePlaces(response);
        props.handleCloseAction();
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Event runner permissions required for this update",
      };
    }
  };

  return (
    <Box
      ref={roomContainerRef}
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
      }}
    >
      <GooglePlace
        user={props.user}
        manager={eventContext.state.event.manager}
        locations={eventContext.state.places.data.combined}
        country={eventContext.state.event.data.Country}
        toggleConnect={eventContext.toggleConnect}
        editView={true}
        callback={handleRunnerActionCallback}
        roomContainerRef={roomContainerRef}
        runnerAction={true}
        riderAction={false}
      />
    </Box>
  );
}

export default ActionLocationsRunner;
