// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// entzy config and models
import configEntzy from "components/config/ConfigEntzy";
import { APP_MENU_TREE } from "models/Structure";
import { BigButton } from "components/utils/common/CommonButtons";

function MenuStart(props) {
  const menuUser = props.user;
  const menuItem = props.menuSelected;
  // const menuParentItem = menuItem.parentId
  //   ? APP_MENU_TREE.filter((obj) => obj.id === props.menuSelected.parentId)[0]
  //   : props.menuSelected;
  const menuChildren =
    menuItem.id === "home"
      ? APP_MENU_TREE.filter((obj) => obj.main === true && !obj.bookend)
      : APP_MENU_TREE.filter(
          (obj) => obj.parentId === menuItem.id && !obj.bookend
        );

  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
      }}
    >
      <Box
        className="box-default"
        sx={{ pt: configEntzy.APP_SPACING_MD, textAlign: "center" }}
        hidden={menuItem.home}
      >
        <Typography variant="h3" color="white">
          {menuItem.name}
        </Typography>
      </Box>
      {menuItem.description && (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_LG,
          }}
        >
          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "#aaa",
              },
            }}
          >
            <Chip
              variant="outlined"
              size="large"
              className="bg-black-t10"
              sx={{
                p: configEntzy.APP_SPACING_SM,
                fontWeight: "500",
                fontSize: configEntzy.FONT_SCALE_MD,
                backgroundColor: "black",
                color: "white",
              }}
              label={menuItem.description}
            />
          </Divider>
        </Box>
      )}
      {menuItem.parent && menuChildren.length > 0 && (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <Container maxWidth="lg">
            <Box className="box-default">
              {menuChildren.map(function (item, index) {
                return (
                  <Box
                    key={item.id}
                    className="box-basic"
                    sx={{
                      width: "50%",
                      display: "inline-block",
                      p: configEntzy.APP_SPACING_SM,
                    }}
                    hidden={!menuUser.connected && item.connected}
                  >
                    <BigButton
                      color="transparent"
                      icon={item.icon}
                      text={item.name}
                      onClick={() => props.menuSelect(item)}
                    />
                  </Box>
                );
              })}
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  );
}

export default MenuStart;
