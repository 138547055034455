import configAuto from "./ConfigAuto";
import configEntzy from "./ConfigEntzy";

const CONFIG_AWS = {
  Auth: {
    region: configAuto.cognito.REGION,
    userPoolId: configAuto.cognito.USER_POOL_ID,
    identityPoolId: configAuto.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: configAuto.cognito.APP_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: configEntzy.APP_COOKIE_DOMAIN,
      path: "/",
      expires: 365,
      secure: configAuto.entzy.ENV === "local" ? false : true,
    },
    redirectSignIn: configEntzy.APP_URL,
    redirectSignOut: configEntzy.APP_URL,
    oauth: {
      domain: configEntzy.APP_AUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: configEntzy.APP_URL,
      redirectSignOut: configEntzy.APP_URL,
      responseType: "code",
    },
  },
  Storage: {
    read: {
      region: configAuto.s3.REGION,
      bucket: configAuto.s3.BUCKET,
      identityPoolId: configAuto.cognito.IDENTITY_POOL_ID,
    },
    write: {
      region: configAuto.cognito.REGION,
      bucket: configAuto.s3.BUCKET.replace(
        configAuto.s3.REGION,
        configAuto.cognito.REGION
      ),
      identityPoolId: configAuto.cognito.IDENTITY_POOL_ID,
    },
  },
  API: {
    aws_appsync_region: configAuto.appsync.REGION,
    aws_appsync_graphqlEndpoint: configAuto.appsync.ENDPOINT,
    aws_appsync_authenticationType: configAuto.appsync.AUTH_TYPE,
  },
};

export default CONFIG_AWS;
