// react core
import { Component } from "react";

// react routing
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

// material design
import Box from "@mui/material/Box";

// entzy components
import configEntzy from "components/config/ConfigEntzy";
import { ActionAlert } from "components/utils/common/CommonLoaders";
import { serviceLogError } from "services/graphql/call";

const ErrorComponent = () => {
  return (
    <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_XL }}>
      <Link to="/" component={RouterLink} underline="none">
        <ActionAlert
          severity="error"
          message="Hmm something unexpected happened. Apologies leave it with us and tap here to return home."
        />
      </Link>
    </Box>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    serviceLogError("errorBoundaryCatch", {
      MSG: error.message,
      ERR: error,
      INF: info,
    });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
