// react core
import { useState } from "react";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck as IconSuccess,
  faEnvelope as IconCodeSent,
} from "@fortawesome/pro-thin-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import ImageEntzyLogo from "assets/logos/entzy-main.png";
import { ActionLoader } from "components/utils/common/CommonLoaders";

function ConnectRegister(props) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [resend, setResend] = useState(false);
  const [manualConnect, setManualConnect] = useState(false);
  const [message, setMessage] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [userConfirmCode, setUserConfirmCode] = useState("");
  const [userRegister, setUserRegister] = useState(null);

  const handleTextFieldEmail = async (event) => {
    setUserEmail(event.target.value.replace(/\s/g, ""));
  };
  const handleTextFieldPassword = async (event) => {
    setUserPassword(event.target.value);
  };
  const handleTextFieldConfirmPassword = async (event) => {
    setUserConfirmPassword(event.target.value);
  };
  const handleTextFieldConfirmCode = async (event) => {
    setUserConfirmCode(event.target.value.replace(/\s/g, ""));
  };

  // some cognito errors are a bit unfriendly so replace as required
  const formatCognitoMessage = (errorMessage) => {
    return errorMessage.includes(
      "Member must satisfy regular expression pattern"
    )
      ? "Invalid email or password. Double check your credentials and try again. Give yourself a long enough password with a good mix of characters."
      : errorMessage.includes(
          "User cannot be confirmed. Current status is CONFIRMED"
        )
      ? "This account is already active. Return to the sign in area to connect, or go to forgot password if you need to reset your credentials."
      : errorMessage;
  };

  const handleRegisterFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert(false);
    if (userEmail.length && userPassword.length) {
      if (userPassword === userConfirmPassword) {
        try {
          const user = await props.handleConnect(
            "Entzy",
            "signup",
            userEmail,
            userPassword
          );
          setUserRegister(user);
          setLoading(false);
        } catch (e) {
          console.error("ERROR", e);
          if (e.code === "UsernameExistsException") {
            try {
              const user = {
                username: userEmail,
              };
              user.resend = await props.handleConnect(
                "Entzy",
                "resend",
                userEmail
              );
              setLoading(false);
              setResend(true);
              setUserRegister(user);
            } catch (e) {
              setMessage(e.message);
              setLoading(false);
              setAlert(true);
            }
          } else {
            const cognitoMessage = formatCognitoMessage(e.message);
            setMessage(cognitoMessage);
            setLoading(false);
            setAlert(true);
          }
        }
      } else {
        setMessage("Passwords do not match");
        setLoading(false);
        setAlert(true);
      }
    } else {
      setMessage("Email and password required");
      setLoading(false);
      setAlert(true);
    }
  };

  const handleRegisterConfirmFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert(false);
    setActionMessage("");
    if (userConfirmCode.length) {
      try {
        await props.handleConnect(
          "Entzy",
          "confirm",
          userEmail,
          userConfirmCode
        );
        if (resend) {
          // cannot auto login on resend (username and pass needs to be entered again)
          // user will be activated when cognito syncs on user connect
          setManualConnect(true);
        } else {
          await props.handleConnect("Entzy", "signin", userEmail, userPassword);
        }
        setLoading(false);
      } catch (e) {
        const cognitoMessage = formatCognitoMessage(e.message);
        setMessage(cognitoMessage);
        setLoading(false);
        setAlert(true);
      }
    } else {
      setMessage("Email connection code required");
      setLoading(false);
      setAlert(true);
    }
  };

  const handleResend = async () => {
    setLoading(true);
    setAlert(false);
    try {
      await props.handleConnect("Entzy", "resend", userEmail);
      setLoading(false);
      setActionMessage("New code on its way");
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
      setAlert(true);
    }
  };

  const handleRegisterFormKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      handleRegisterFormSubmit(e);
    }
  };

  const handleRegisterConfirmFormKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      handleRegisterConfirmFormSubmit(e);
    }
  };

  function renderRegisterForm() {
    return (
      <Grid item xs={12}>
        <Box className="box-default text-left">
          <IconButton
            aria-label="back"
            onClick={() => props.handleSection(null)}
          >
            <Avatar
              alt="Entzy"
              src={ImageEntzyLogo}
              sx={{ boxShadow: "0 0 0 1px #999" }}
            />
          </IconButton>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-email"
              type="email"
              label="Email"
              autoComplete="username"
              value={userEmail}
              onKeyDown={handleRegisterFormKeyDown}
              onChange={handleTextFieldEmail}
              fullWidth
            />
          </FormControl>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-password"
              type="password"
              label="Password"
              autoComplete="new-password"
              value={userPassword}
              onKeyDown={handleRegisterFormKeyDown}
              onChange={handleTextFieldPassword}
              fullWidth
            />
          </FormControl>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-confirm-password"
              type="password"
              label="Confirm Password"
              autoComplete="new-password"
              value={userConfirmPassword}
              onKeyDown={handleRegisterFormKeyDown}
              onChange={handleTextFieldConfirmPassword}
              fullWidth
            />
          </FormControl>
        </Box>
        {loading ? (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <ActionLoader />
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              onClick={handleRegisterFormSubmit}
              fullWidth
            >
              Sign Up
            </Button>
          </Box>
        )}
        {alert && message && (
          <Box
            className="box-default text-left"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Alert severity="error">{message}</Alert>
          </Box>
        )}
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
          }}
        >
          <Typography variant="h6" noWrap={true}>
            New Connection
          </Typography>
        </Box>
      </Grid>
    );
  }

  function renderRegisterConfirm() {
    return (
      <Grid item xs={12}>
        <Box className="box-default text-left">
          <IconButton
            aria-label="back"
            onClick={() => props.handleSection(null)}
          >
            <Avatar
              alt="Entzy"
              src={ImageEntzyLogo}
              sx={{ boxShadow: "0 0 0 1px #999" }}
            />
          </IconButton>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FontAwesomeIcon
            icon={actionMessage ? IconSuccess : IconCodeSent}
            size="2x"
          />
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <Typography variant="body2">
            {actionMessage ? (
              <strong>{actionMessage}</strong>
            ) : (
              <strong>CONNECTION CODE SENT</strong>
            )}
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="body2">{userEmail}</Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-code"
              type="tel"
              label="Enter Code"
              value={userConfirmCode}
              onKeyDown={handleRegisterConfirmFormKeyDown}
              onChange={handleTextFieldConfirmCode}
              fullWidth
            />
          </FormControl>
        </Box>
        {loading ? (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <ActionLoader />
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              onClick={handleRegisterConfirmFormSubmit}
              fullWidth
            >
              Connect
            </Button>
          </Box>
        )}
        {alert && message && (
          <Box
            className="box-default text-left"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Alert severity="error">{message}</Alert>
          </Box>
        )}

        {!loading && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_XL,
            }}
          >
            <Button
              variant="outlined"
              size="large"
              type="button"
              onClick={handleResend}
            >
              Resend Code
            </Button>
          </Box>
        )}
      </Grid>
    );
  }

  function renderRegisterManualConnect() {
    return (
      <Grid item xs={12}>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FontAwesomeIcon icon={IconSuccess} size="4x" />
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="body2" noWrap={true}>
            <strong>Your new connection is ready</strong>
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Button
            variant="contained"
            size="large"
            type="button"
            onClick={() => props.handleSection("direct")}
            fullWidth
          >
            Connect Now
          </Button>
        </Box>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box>
        <Grid container>
          {userRegister === null
            ? renderRegisterForm()
            : manualConnect
            ? renderRegisterManualConnect()
            : renderRegisterConfirm()}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default ConnectRegister;
