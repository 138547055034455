// material design
import Box from "@mui/material/Box";

// entzy components
import ActionEntrySelection from "./ActionEntrySelection";

function ActionEntryRunner(props) {
  return (
    <Box className="box-default">
      <ActionEntrySelection {...props} type="runner" />
    </Box>
  );
}

export default ActionEntryRunner;
