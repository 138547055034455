// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";

function LegalTerms(props) {
  return (
    <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_LG }}>
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
        <Typography variant="h6">TERMS</Typography>
        <Typography variant="subtitle1">Site Terms of Use</Typography>
      </Box>
      <Box
        className="box-default text-left"
        sx={{ pt: configEntzy.APP_SPACING_MD2X }}
      >
        <Typography variant="subtitle1">
          <em>
            Please read these terms and conditions carefully, they contain
            important information about your rights and obligations.
          </em>
        </Typography>
      </Box>
      <Box
        className="box-default text-left bg-black-t50"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
          p: configEntzy.APP_SPACING_LG,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <Typography variant="subtitle1">
          <strong>1. INTRODUCTION</strong>
        </Typography>
        <Typography variant="subtitle1">
          1.1 Please read these terms and conditions carefully in conjunction
          with our privacy policy before using the Entzy website. Our privacy
          policy is incorporated into these terms and conditions. In particular,
          we draw your attention to clause [11] (Liability). The site is
          operated by Entzy Ltd, known as Entzy, ("Entzy", "our", "we" or "us")
          (a UK company limited by guarantee, no. 08243773).
        </Typography>
        <Typography variant="subtitle1">
          1.2 A part of our website is only accessible by entering a valid Email
          and Password ("connection details"), known as the "Member Area". To
          access the Member Area, you must be a Member who has registered for an
          Entzy account (a "Registered Member") or someone who has been
          permitted limited access to the Member Area through a referral email
          received from an Registered Member (a "Guest Member") (collectively,
          "Members").
        </Typography>
        <Typography variant="subtitle1">
          1.3 By clicking on the button marked "Connect" and entering the Member
          Area and/or by using or accessing any part of our website, you agree
          to be legally bound by these terms and conditions of use and our
          privacy policy as they may be modified and posted on our website from
          time to time at our sole discretion.
        </Typography>
        <Typography variant="subtitle1">
          1.4 If you do not wish to be bound by these terms and conditions and
          our privacy policy then you may not use our website. THESE TERMS AND
          CONDITIONS AND OUR PRIVACY POLICY APPLY TO THE USE OF EVERY PART OF
          THE WEBSITE.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>2. NATURE OF OUR WEBSITE</strong>
        </Typography>
        <Typography variant="subtitle1">
          2.1 Our website provides a web service that enables users to manage
          events, sell tickets, buy tickets, validate tickets and determine the
          best dates to run events, through a multi-functional online event
          area. Its extended features include a date marketplace, which helps
          ticket sellers and buyers to match supply with demand on preferred
          dates.
        </Typography>
        <Typography variant="subtitle1">
          2.2 The Entzy service is free to use when ticket prices are zero.
          Charges apply when tickets prices are greater than zero. See clause 4.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>3. ENTZY ACCOUNTS AND CONNECTED STRIPE ACCOUNTS</strong>
        </Typography>
        <Typography variant="subtitle1">
          <em>
            The package descriptions provided below are subject to change and
            are for guidance only. For an up-to-date list of our account options
            please see profile settings or contact us.
          </em>
        </Typography>
        <Typography variant="subtitle1">
          3.1 Customers who connect (register) with us will be provided with an
          online account (an "Entzy Account") free of charge.
        </Typography>
        <Typography variant="subtitle1">
          3.2 A basic Entzy Account gives you access to a suite of event
          management tools, including the ability to create and browse online
          events, buy and sell tickets and manage an Entzy profile.
        </Typography>
        <Typography variant="subtitle1">
          3.3 Each Entzy Account comprises the following 3 core areas:
        </Typography>
        <Typography variant="subtitle1">
          3.3.1 EVENTS, which allows Members to create their own online events,
          accept other events to manage, or save events of interest. Each event
          has its own Entzy URL for access to the event's online area and this
          URL can be opened to public access or kept private (default) for
          access only by email addresses added to the event guest list.
        </Typography>
        <Typography variant="subtitle1">
          3.3.2 TICKETS, which allows Members to track all their tickets across
          multiple events in one place. Individual tickets progress through
          several states from initiated to live (see clause 4) and the tickets
          area helps facilitate high level monitoring. This area also gives
          quick access to open any ticket, to make amendments, or when attending
          an event.
        </Typography>
        <Typography variant="subtitle1">
          3.3.3 PROFILE, which allows Members to manage personal data settings
          (such as name, email, password) and payment settings.
        </Typography>
        <Typography variant="subtitle1">
          3.4 For online payments Entzy uses services provided by Stripe (see
          stripe.com) and this process is managed via linked accounts in 2
          sub-sections of your Profile:
        </Typography>
        <Typography variant="subtitle1">
          3.4.1 Ticket Buyer Settings (payment details): Provides the ability to
          store credit/debit card information for use in buying chargeable
          tickets. This area facilitates the creation of a linked customer
          account with Stripe and card information is stored securely by Stripe.
          The linked account and card information can be updated or deleted at
          any time via Profile Payment settings.
        </Typography>
        <Typography variant="subtitle1">
          3.4.2 Ticket Seller Settings (payout details): Provides the ability to
          accept payments and create payout details, for use in running events
          and receiving chargeable ticket sales. This area facilitates the
          creation of a linked express account with Stripe and requires
          additional verification steps, which are completed directly with
          Stripe. After the payout account link is created, it can be accessed,
          updated, or deleted any time via Profile Payout settings.
        </Typography>
        <Typography variant="subtitle1">
          3.4.2.1 Any revenue received via ticket sales on the Entzy platform
          will accumulate as a balance in the linked express account with
          Stripe, less any charges for use of the Entzy service (see 'Fee
          Information' buttons for latest rates, in ticket settings or event
          manager areas). The remaining balance "Net Revenue" is what is
          available for payout to the event owner. This balance will be visible
          at any point by opening the linked account held with Stripe via
          Profile Payout settings. Customers can also control the payout
          destination, such as bank account or card details via the same
          settings.
        </Typography>
        <Typography variant="subtitle1">
          3.4.2.2 Before an event triggers, guest tickets are in a pre-order
          state and no charges have been made. Once an event date reaches its
          trigger point, set by the event management team (for example minimum
          number of guests or sales), then that date will trigger into a live
          event and all pre-ordered tickets will be charged. The Net Revenue
          from the trigger will be available to the event owner as soon as
          Stripe's own processes allow, which is usually between 1 and 7 days
          depending on country (see Stripe terms).
        </Typography>
        <Typography variant="subtitle1">
          3.4.2.3 Revenue payouts can only be made to the event owner
        </Typography>
        <Typography variant="subtitle1">
          3.4.2.4 If an individual ticket or entire event date needs to be
          cancelled then refunds can be made by the event management team, via
          the controls in the event date area. Refunds can be done for the
          entire guest list for that date, or individually for each customer as
          required. Liability for refunds, for any reason, remain the full
          responsibility of the event owner and sufficient funds must be
          available in the event owner's connected account for refunds to be
          successful. Any processing fees on the original ticket sale are
          non-refundable. Entzy as the platform that connects ticket buyer and
          seller assumes no responsibility for the event itself and therefore no
          liability for refunds should it not go ahead as expected.
        </Typography>
        <Typography variant="subtitle1">
          3.5 Entzy Accounts are provided with unlimited usage of the Member
          Area (subject to fair use as deemed fit by us);
        </Typography>
        <Typography variant="subtitle1">
          3.5 Guest access to events without an Entzy Account is possible
          through the event URL, but only when the event is set to "public" via
          its access settings. Guest access provides the visitor to the URL
          with:
        </Typography>
        <Typography variant="subtitle1">
          3.5.1 Ability to see the event details, but restricted to view-only
          and with certain areas replaced with a prompt to connect, for full
          access to the Member Area.
        </Typography>
        <Typography variant="subtitle1">
          3.5.2 Instructions on how to connect to buy tickets or to contact the
          event management team with any questions.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>4. EVENT USE</strong>
        </Typography>
        <Typography variant="subtitle1">
          4.1 Interactive areas of our website, predominantly inside individual
          events, allow Members to communicate and interact, including but not
          limited to: publishing information, advertising products such as
          experiences/tickets, discussing particular topics, posting messages
          and uploading media files ("Interactive Areas").
        </Typography>
        <Typography variant="subtitle1">
          4.2 Any Guest Member (where invited to contribute to a particular
          forum by a Registered Member) or Registered Member may contribute to
          content inside an Interactive Area, where content includes uploaded
          files as well as messages ("Postings").
        </Typography>
        <Typography variant="subtitle1">
          4.3 For your protection when you use an Interactive Area we recommend
          that you consider whether you should:
        </Typography>
        <Typography variant="subtitle1">
          4.3.1 use or disclose your name and any personal details by which you
          could be identified (including your address, telephone numbers, email
          addresses) or the name or personal details of others;
        </Typography>
        <Typography variant="subtitle1">
          4.3.2 identify yourself and address other Members other than by
          Username;
        </Typography>
        <Typography variant="subtitle1">
          4.3.3 give out any financial information (such as bank account details
          or credit card numbers) on the forum;
        </Typography>
        <Typography variant="subtitle1">
          4.3.4 alert our team by contacting us if someone attempts to send you
          their own or someone else's personal details.
        </Typography>
        <Typography variant="subtitle1">
          4.4 We cannot accept any liability for any loss or damage you may
          suffer as a result of you revealing your identity or other personal
          details when using the website.
        </Typography>
        <Typography variant="subtitle1">
          4.5 We reserve the right to review, store, copy, edit, reject, block,
          delay, suspend, remove or delete Postings or take down the Interactive
          Area at any time and in our sole discretion. You waive and agree not
          to assert any so-called moral or similar rights (whether arising under
          the Copyright Designs and Patents Act 1988 or otherwise) you may have
          in any Postings that you make. This includes waiving the rights to be
          identified as the author of and to prevent derogatory treatment of the
          Postings.
        </Typography>
        <Typography variant="subtitle1">
          <strong>
            <em>Postings</em>
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          4.6 By posting in an Interactive Area, you hereby grant to Entzy and
          its assignees a licence to publish, use, modify, publicly display,
          reproduce and distribute such Postings on the website or in any other
          media and to grant a licence to third parties to re-produce any
          Postings.
        </Typography>
        <Typography variant="subtitle1">
          4.7 You must ensure that there is nothing in your Postings which is or
          might be regarded as harassing, threatening, abusive, vulgar, obscene,
          defamatory, racist or otherwise unlawful.
        </Typography>
        <Typography variant="subtitle1">
          4.8 Any attachment uploaded onto the website, including photographs
          (and other images) and audio and video files, are Postings for the
          purposes of these terms and conditions. We permit them to be uploaded
          onto certain Interactive Areas but only subject to specified file size
          limits and to obtaining the prior consent of any person who can be
          identified from the photograph, image, audio or video file.
          Downloading of audio or video files is not permitted.
        </Typography>
        <Typography variant="subtitle1">
          4.9 Entzy assumes no responsibility for monitoring any Interactive
          Area on the website for inappropriate content or conduct. If at any
          time Entzy chooses, in its sole discretion, to monitor the Interactive
          Area, Entzy nonetheless assumes no responsibility for Postings, no
          obligation to modify or remove any inappropriate Postings, and no
          responsibility for the conduct of the user submitting any such
          Postings.
        </Typography>
        <Typography variant="subtitle1">
          4.10 You are solely responsible for the Postings that you post in any
          Interactive Area. We do not endorse and have no control over the
          Postings. Postings are not necessarily reviewed by us prior to
          publication and do not necessarily reflect our opinions or policies.
          We make no warranties, express or implied, as to the Postings or as to
          the accuracy and reliability of the Postings and expressly disclaim
          any and all liability in connection with Postings.
        </Typography>
        <Typography variant="subtitle1">
          4.11 You warrant that your Postings, or any use we may make of them
          under these terms of use, and any web page you create for publishing
          as an online event will not:{" "}
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <ul>
            <li>
              violate the rights of or harm or threaten the safety of any
              person;
            </li>
            <li>
              infringe the copyright, trademarks, rights of privacy, publicity
              or other intellectual property or other rights of any other person
              or entity;{" "}
            </li>
            <li>
              contain malicious code, such as viruses, worms, Trojan horses or
              other potentially harmful programs or material; or involve the
              transmission of junk mail, chain letters or unsolicited mass
              mailing ("spam");{" "}
            </li>
            <li>
              contain any over sized fonts, JavaScript, tables, headings or
              other advanced HTML commands. Please use only bold or coloured
              fonts to emphasise your points;
            </li>
            <li>
              be created for the purpose of damaging, or without just and
              reasonable cause be likely to damage, the reputation of any third
              party individual or business, or deceive any person;
            </li>
            <li>
              be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence;{" "}
            </li>
            <li>violate any applicable law, statute, rule or regulation;</li>
            <li>
              where a Posting is a photograph (or other image), audio or video
              file, contain nudity, or obscene, lewd, excessively violent,
              harassing, sexually explicit or otherwise objectionable subject
              matter.
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle1">
          4.12 If you become aware of any Postings which contain materials which
          breach any of these terms and conditions or are objectionable in any
          way please contact us using the contact links at the foot of our
          website.
        </Typography>
        <Typography variant="subtitle1">
          <strong>
            <em>Disclaimers</em>
          </strong>
        </Typography>
        <Typography variant="subtitle1">
          4.13 We are not responsible for any incorrect or inaccurate Postings,
          posted on our website or in connection with our website. We assume no
          responsibility for any error, omission, interruption, deletion,
          defect, delay in operation or transmission, communications line
          failure, theft or destruction or unauthorised access to, or alteration
          of, any Posting.
        </Typography>
        <Typography variant="subtitle1">
          4.14 You assume total responsibility and risk for any purchases you
          make in connection with any Products, offers of any goods or
          commercial or professional services, any requests or offers of
          financial aid or otherwise made via Postings or otherwise via our
          website. All such purchases are transactions solely between you and
          that third party business or individual. We are not a party to any
          such transaction and do not endorse or recommend any Products. We
          accept no liability whatsoever for any loss, whether direct or
          indirect, arising in connection with the transaction.
        </Typography>
        <Typography variant="subtitle1">
          4.15 During the first 3-6 months of use after Entzy has launched, the
          site and service will likely undergo more change and patches than
          under longer term operating conditions, as we respond to customer
          feedback. We will aim to mitigate this by assisting early adopters
          with as much customer service as feasible during the early stages.
          Customers should endeavour to experiment as much as possible with our
          free service and free tickets, before embarking on a chargeable event.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>5. APPLICABILITY OF ONLINE MATERIALS</strong>
        </Typography>
        <Typography variant="subtitle1">
          5.1 We have used our best endeavours to ensure that our website
          complies with UK laws. We make no representations that the materials
          on our website are appropriate or available for use in locations
          outside the UK. Those who visit our website from other locations do so
          on their own initiative and are responsible for compliance with all
          applicable laws. If use of our website and/or viewing of it, or use of
          any material or content on our website or services, or products
          offered through our website are contrary to or infringe any applicable
          law in your jurisdiction(s), you are not authorised to view or use our
          website and you must exit immediately.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>6. MODIFICATIONS TO WEBSITE</strong>
        </Typography>
        <Typography variant="subtitle1">
          6.1 We reserve the right to make changes or corrections, alter,
          suspend or discontinue any aspect of our website or the content or
          services available through it, including your access to it. Unless
          explicitly stated to the contrary, any new features including new
          content and/or the sale of new Products shall be subject to these
          terms and conditions.
        </Typography>
        <Typography variant="subtitle1">
          6.2 Please note that although we try to ensure that the content of our
          website is accurate, it may contain typographical errors or other
          inaccuracies.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>7. COPYRIGHT RESTRICTIONS </strong>
        </Typography>
        <Typography variant="subtitle1">
          7.1 The contents of our website are protected by international
          copyright laws and other intellectual property rights. The owner of
          these rights is Entzy, its affiliates or other third party licensors.
          All company names and logos mentioned in our website are the trade
          marks, service marks or trading names of their respective owners,
          including us. Commercial use or publication of all or any items
          displayed on our website is strictly prohibited without prior written
          authorisation from Entzy. You may copy documents for personal use only
          on the condition that copyright and source indications are also copied
          and no amendment or editing is made and the document is copied in
          full. Some documents have been published on this site with the
          permission of the relevant copyright owners and permission to copy
          them must be requested from the copyright owners where possible. Third
          party sources are indicated within these documents.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>8. CONFIDENTIAL INFORMATION</strong>
        </Typography>
        <Typography variant="subtitle1">
          8.1 As a Member, you must take all reasonable precautions to prevent
          the disclosure and unauthorised use of the following information and
          you must not write down or tell anyone:
        </Typography>
        <Typography variant="subtitle1">
          8.1.1 your login details or those of any other Member, should you come
          to know them;
        </Typography>
        <Typography variant="subtitle1">
          8.1.2 any information which you come to know via use of the Member
          Area of the website and which is expressly stated to be confidential.
        </Typography>
        <Typography variant="subtitle1">
          8.2 If you suspect that someone knows your login details, you should
          immediately contact us by email, or by post at 2 Leman Street, London,
          E1W 9US and change your password to prevent any misuse of your
          account. Your liability will cease in relation to actions after we
          receive notification. If unauthorised use of your login details is due
          to you acting without reasonable care, fraud, your disclosure to
          someone else or misuse, you will be liable for any loss caused.
        </Typography>
        <Typography variant="subtitle1">
          8.3 We reserve the right to suspend your access to the Member Area at
          any time at our discretion, including for the following reasons,
          namely, if:-
        </Typography>
        <Typography variant="subtitle1">
          8.3.1 incorrect login details were used to attempt to access your
          account; or
        </Typography>
        <Typography variant="subtitle1">
          8.3.2 we suspect an unauthorised person is attempting to access your
          account.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>9. LINKED SITES</strong>
        </Typography>
        <Typography variant="subtitle1">
          Entzy make no representations whatsoever about any other websites
          which you may access through our website or which may link to our
          website. When you access any other website you understand that it is
          independent from Entzy and that we have no control over the content or
          availability of that website. In addition, a link to any other website
          does not mean that Entzy endorses or accepts any responsibility for
          the content, or the use of, such a website and Entzy shall not be
          liable for any loss or damage caused or alleged to be caused by or in
          connection with use of or reliance on any content, goods or services
          available on or through any other website or resource. Any concerns
          regarding any external link should be directed to its website
          administrator or web master.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>10. AVAILABILITY OF OUR WEBSITE</strong>
        </Typography>
        <Typography variant="subtitle1">
          10.1 We will try to make our website available but cannot guarantee
          that our website will operate continuously or without interruptions or
          be error free and we can accept no liability for its unavailability.
          You must not attempt to interfere with the proper working of our
          website and, in particular, you must not attempt to circumvent
          security, tamper with, hack into, or otherwise disrupt any computer
          system, server, website, router or any other Internet connected
          device.
        </Typography>
        <Typography variant="subtitle1">
          10.2 We will try to ensure that all data relating to each Entzy
          account has appropriate backup policies but we can accept no liability
          for any loss of data or other information in or relating to your Entzy
          account caused by the unavailability of our website and/or your Entzy
          account or by any circumstance outside of our control.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>12. LIABILITY</strong>
        </Typography>
        <Typography variant="subtitle1">
          11.1 We accept no liability for any loss or damage arising out of or
          in connection with the viewing, use or performance of our website or
          its contents, whether due to inaccuracy, error, omission or any other
          cause and whether on our part or the part of our servants, agents or
          any other person or entity. For the avoidance of doubt, we expressly
          exclude any liability for any loss wherever and however arising as a
          result of use of our website, including as a result of any Posting.
        </Typography>
        <Typography variant="subtitle1">
          11.2 You are responsible for ensuring that your computer system meets
          all relevant technical specifications necessary to use our website and
          is compatible with our website. You also understand that we cannot and
          do not guarantee or warrant that any material available for
          downloading from our website will be free from infection, viruses
          and/or other code that has contaminating or destructive properties.
          You are responsible for implementing sufficient procedures and virus
          checks (including anti-virus and other security checks) to satisfy
          your particular requirements for the accuracy of data input and
          output.
        </Typography>
        <Typography variant="subtitle1">
          11.3 We exclude all express or implied terms, conditions, warranties,
          representations or endorsements whatsoever with regard to any
          Products, our website or any information or service provided through
          our website.
        </Typography>
        <Typography variant="subtitle1">
          11.4 The limitations and exclusions in this clause do not affect your
          non-excludable statutory rights and only apply to the extent permitted
          by applicable law.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>12. GENERAL</strong>
        </Typography>
        <Typography variant="subtitle1">
          12.1 We may assign, transfer, novate or subcontract any or all of our
          rights and obligations under these terms and conditions at any time.
        </Typography>
        <Typography variant="subtitle1">
          12.2 We may alter these terms and conditions from time to time and
          post the new version on our website, following which all use of our
          website will be governed by that version. You must check the terms and
          conditions on the website regularly.
        </Typography>
        <Typography variant="subtitle1">
          12.3 You acknowledge that you have not entered into this agreement in
          reliance upon any statement, warranty or representation made by Entzy
          or any other person and you irrevocably and unconditionally waive any
          rights to claim damages and/or to rescind these terms and conditions
          by reason of any misrepresentation (other than a fraudulent
          misrepresentation) that is not contained in the terms and conditions
          (including our privacy policy).
        </Typography>
        <Typography variant="subtitle1">
          12.4 If any provision or term of these terms and conditions shall
          become or be declared illegal, invalid or unenforceable for any reason
          whatsoever, such term or provision shall be divisible from the other
          terms and conditions and shall be deemed to be deleted from them.
        </Typography>
        <Typography variant="subtitle1">
          12.5 These terms and conditions and your use of our website are
          governed by English law and you submit to the non-exclusive
          jurisdiction of the English court.
        </Typography>
        <Typography variant="subtitle1">
          12.6 Except in respect of a payment obligation, neither you nor Entzy
          will be held liable for any failure to perform any obligation to the
          other due to causes beyond your or the respective reasonable control
          of Entzy.
        </Typography>
        <Typography variant="subtitle1">
          12.7 Failure or delay by either party enforcing an obligation or
          exercising a right under these terms and conditions does not
          constitute a waiver of that obligation or right.
        </Typography>
        <Typography variant="subtitle1">
          12.8 These terms and conditions do not confer any rights on any person
          or party (other than you and/or us) pursuant to the Contracts (Rights
          of Third Parties) Act 1999.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>13. NOTICES</strong>
        </Typography>
        <Typography variant="subtitle1">
          13.1 All notices shall be given:
        </Typography>
        <Typography variant="subtitle1">
          13.1.1 to us via e-mail at our contact page (link in site footer) or
          by post at 2 Leman Street, London, E1W 9US; or
        </Typography>
        <Typography variant="subtitle1">
          13.1.2 to you at either the e-mail or postal address you provide
          during any ordering process.
        </Typography>
        <Typography variant="subtitle1">
          Notice will be deemed received when an e-mail is received in full (or
          else on the next business day if it is received on a weekend or a
          public holiday in the place of receipt) or three days after the date
          of posting.
        </Typography>
        <Typography variant="subtitle1">&nbsp;</Typography>
        <Typography variant="subtitle1">
          <strong>14. REPLACEMENT</strong>
        </Typography>
        <Typography variant="subtitle1">
          These terms and conditions replace all other terms and conditions
          previously applicable to the use of our website.
        </Typography>
      </Box>
    </Box>
  );
}

export default LegalTerms;
