// react core
import { useContext, useRef } from "react";

// material design
import Box from "@mui/material/Box";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import GooglePlace from "components/utils/google/GooglePlace";
import { EventContext } from "pages/events/EventContext";

function ActionLocationsRider(props) {
  const eventContext = useContext(EventContext);
  const roomContainerRef = useRef();

  const handleActionCallback = async (data) => {
    const eventLocations = data;
    if (!eventContext.state.event.manager) {
      const response = await eventContext.prepareUpdatePlaces({
        key: "Locations",
        value: eventLocations,
      });
      if (response.alert) {
        return {
          alert: true,
          message: response.message,
        };
      } else {
        eventContext.updatePlaces(response);
        props.handleCloseAction();
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Event rider permissions required for this action.",
      };
    }
  };

  return (
    <Box
      ref={roomContainerRef}
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
      }}
    >
      <GooglePlace
        user={props.user}
        manager={eventContext.state.event.manager}
        locations={eventContext.state.places.data.riders.filter(
          (obj) => obj.UserId === props.user.identity
        )}
        country={eventContext.state.event.data.Country}
        toggleConnect={eventContext.toggleConnect}
        editView={true}
        callback={handleActionCallback}
        roomContainerRef={roomContainerRef}
        runnerAction={false}
        riderAction={true}
      />
    </Box>
  );
}

export default ActionLocationsRider;
