// react core
import { createContext } from "react";

// date handling
import dayjs from "dayjs";

// fonts and icons
import { faTicket as iconSelectorTickets } from "@fortawesome/pro-regular-svg-icons";
import { faCalendar as iconSelectorDates } from "@fortawesome/pro-regular-svg-icons";
import { faUsers as iconSelectorGroups } from "@fortawesome/pro-regular-svg-icons";

// entzy models
import newEvent, {
  EventTicketOfferStates,
  EventTicketLaunchStates,
  EventObservables,
  EVENT_CONSTANTS,
} from "models/Event";
import { currenciesGetItem } from "models/Currency";
import toolbox from "models/Tools";

// entzy services
import { serviceLogError } from "services/graphql/call";

// event state changing actions
export const eventActions = {
  FOCUS_MODULE: "FOCUS_MODULE",
  CREATE_EVENT: "CREATE_EVENT",
  JOIN_EVENT: "JOIN_EVENT",
  REQUEST_JOIN_EVENT: "REQUEST_JOIN_EVENT",
  ROUTE_ACTION: "ROUTE_ACTION",
  RELOAD_EVENT: "RELOAD_EVENT",
  TOGGLE_MODULE: "TOGGLE_MODULE",
  UPDATE_EVENT: "UPDATE_EVENT",
  UPDATE_EVENT_STAMP: "UPDATE_EVENT_STAMP",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_ALERT: "UPDATE_ALERT",
  PULL_RUNNERS: "PULL_RUNNERS",
  PULL_RIDERS: "PULL_RIDERS",
  PULL_RUNNER_INVITES: "PULL_RUNNER_INVITES",
  PULL_RIDER_INVITES: "PULL_RIDER_INVITES",
  PULL_RIDER_QUEUE: "PULL_RIDER_QUEUE",
  UPDATE_RUNNERS: "UPDATE_RUNNERS",
  UPDATE_RIDERS: "UPDATE_RIDERS",
  UPDATE_RUNNER_INVITES: "UPDATE_RUNNER_INVITES",
  UPDATE_RIDER_INVITES: "UPDATE_RIDER_INVITES",
  UPDATE_RIDER_QUEUE: "UPDATE_RIDER_QUEUE",
  PULL_LINKS: "PULL_LINKS",
  POST_LINK: "POST_LINK",
  REMOVE_LINK: "REMOVE_LINK",
  PULL_PLACES: "PULL_PLACES",
  UPDATE_PLACES: "UPDATE_PLACES",
  PULL_MESSAGES: "PULL_MESSAGES",
  PULL_MESSAGE_SETTINGS: "PULL_MESSAGE_SETTINGS",
  UPDATE_MESSAGE_SETTINGS: "UPDATE_MESSAGE_SETTINGS",
  SET_MESSAGE_ROOM: "SET_MESSAGE_ROOM",
  POST_MESSAGE: "POST_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  GENERATE_CALENDAR: "GENERATE_CALENDAR",
  GENERATE_MODULES: "GENERATE_MODULES",
  PULL_TICKS: "PULL_TICKS",
  PULL_VIEWER_TICKETS: "PULL_VIEWER_TICKETS",
  UPDATE_VIEWER_TICKETS: "UPDATE_VIEWER_TICKETS",
  ENTRY_STAGE_DATE: "ENTRY_STAGE_DATE",
  ENTRY_HANDSHAKE: "ENTRY_HANDSHAKE",
  ENTRY_RUNNER_PRESELECT: "ENTRY_RUNNER_PRESELECT",
  SET_HYDRATED: "SET_HYDRATED",
  SET_LOADING: "SET_LOADING",
  SET_CALLBACK_LOADER: "SET_CALLBACK_LOADER",
  SET_PINNED_STATUS: "SET_PINNED_STATUS",
  SET_MEMBER_DRAWER: "SET_MEMBER_DRAWER",
  SET_ACTION_DRAWER: "SET_ACTION_DRAWER",
  SET_OBSERVABLES_TRACKER: "SET_OBSERVABLES_TRACKER",
  EXIT_EVENT: "EXIT_EVENT",
};

const newAlert = function () {
  return { show: false, message: null };
};
const newMembers = function () {
  return { hydrated: false, data: { items: [] } };
};
const newLinks = function () {
  return { hydrated: false, data: { runners: [], riders: [], combined: [] } };
};
const newMessaging = function () {
  return {
    rooms: EVENT_CONSTANTS.messaging.rooms.reduce((obj, item) => {
      obj[item.name] = {
        name: item.name,
        title: item.title,
        description: item.description,
        hydrated: false,
        updated: null,
        data: { runners: [], riders: [], combined: [] },
        settingsHydrated: false,
        settingsData: {},
      };
      return obj;
    }, {}),
    activeRoom: EVENT_CONSTANTS.messaging.rooms[0].name,
  };
};
const newPlaces = function () {
  return { hydrated: false, data: { runners: [], riders: [], combined: [] } };
};
const newTicks = function () {
  return { dates: {} };
};
const newViewer = function () {
  return {
    tickets: {
      hydrated: false,
      data: [],
      offered: [],
      launched: [],
    },
    dates: {
      launched: {
        all: {
          keys: [],
        },
        future: {
          keys: [],
        },
        history: {
          keys: [],
        },
      },
      offered: {
        all: {
          keys: [],
        },
        future: {
          keys: [],
        },
        history: {
          keys: [],
        },
      },
    },
    entry: {
      hydrated: false,
      date: null,
      lists: {},
    },
  };
};
const newDrawer = function () {
  return {
    open: false,
    params: {},
  };
};

const newObservablesTracker = function () {
  return { active: false };
};

export const initialState = {
  event: newEvent(),
  runners: newMembers(),
  riders: newMembers(),
  runnerInvites: newMembers(),
  riderInvites: newMembers(),
  riderQueue: newMembers(),
  links: newLinks(),
  messaging: newMessaging(),
  places: newPlaces(),
  ticks: newTicks(),
  viewer: newViewer(),
  alert: newAlert(),
  memberDrawer: newDrawer(),
  actionDrawer: newDrawer(),
  observablesTracker: newObservablesTracker(),
  callbackLoader: false,
  callbackState: null,
  constants: EVENT_CONSTANTS,
  toolbox: toolbox(),
};

export const eventObservables = EventObservables({ eventActions });

export const eventReducer = (state, action) => {
  // state capture for ephemeral actions
  let result = {};
  // id for any context change
  state.id = Date.now();

  // attempt state change
  try {
    switch (action.type) {
      // module selections
      case eventActions.FOCUS_MODULE:
        result.hasSelected = {
          ...state.event.moduleHasSelected,
        };
        if (result.hasSelected[action.selection] === undefined) {
          result.hasSelected[action.selection] = 0;
        } else {
          result.hasSelected[action.selection]++;
        }
        return {
          ...state,
          event: {
            ...state.event,
            moduleSelected: action.selection,
            moduleHasSelected: result.hasSelected,
            moduleLanding: action.landing,
            modules: {
              ...state.event.modules,
              [action.selection]: {
                ...state.event.modules[action.selection],
                open: action.landing ? true : false,
              },
            },
          },
        };
      // initialize by creating new event
      case eventActions.CREATE_EVENT:
        return {
          ...state,
          event: {
            ...state.event,
            hydrated: true,
            expires: dayjs().add(EVENT_CONSTANTS.events.cacheExpiry, "minutes"),
            exploring: !state.user.connected,
            owner: true,
            manager: true,
            accessGranted: true,
            data: state.user.connected
              ? action.params
              : {
                  ...state.event.data,
                  Url: action.params.Name,
                  Country: action.params.Country,
                  Currency: action.params.Currency,
                },
          },
          alert: {
            ...state.alert,
            show: false,
          },
        };
      // initialize by joining existing event
      case eventActions.JOIN_EVENT:
        return {
          ...state,
          event: {
            ...state.event,
            hydrated: true,
            expires: dayjs().add(EVENT_CONSTANTS.events.cacheExpiry, "minutes"),
            owner: action.content.owner,
            manager: action.content.manager,
            accessQueued: action.content.accessQueued,
            accessGranted: action.content.accessGranted,
            data: action.content.data,
          },
          alert: {
            ...state.alert,
            show: false,
          },
        };
      // private eventuators rider join requests
      case eventActions.REQUEST_JOIN_EVENT:
        return {
          ...state,
          event: {
            ...state.event,
            accessQueued: action.request.params.remove ? false : true,
          },
        };
      // reload event after any change to data
      case eventActions.RELOAD_EVENT:
        return {
          ...state,
          event: {
            ...state.event,
            hydrated: true,
            expires: dayjs().add(EVENT_CONSTANTS.events.cacheExpiry, "minutes"),
            data: action.data,
          },
          ticks: newTicks(),
          alert: {
            ...state.alert,
            show: false,
          },
        };
      // open and close event rooms
      case eventActions.TOGGLE_MODULE:
        return {
          ...state,
          event: {
            ...state.event,
            modules: {
              ...state.event.modules,
              [action.module.id]: {
                ...state.event.modules[action.module.id],
                open: !state.event.modules[action.module.id].open,
              },
            },
          },
        };
      // update event content
      case eventActions.UPDATE_EVENT:
        return {
          ...state,
          event: {
            ...state.event,
            data: {
              ...state.event.data,
              [action.field.key]: action.field.value,
            },
            lastDataUpdate: {
              key: action.field.key,
              time: dayjs(),
            },
          },
        };
      case eventActions.UPDATE_EVENT_STAMP:
        return {
          ...state,
          event: {
            ...state.event,
            lastDataUpdate: {
              key: action.field.key,
              time: dayjs(),
            },
          },
        };
      // connected user ownership
      case eventActions.UPDATE_USER:
        return {
          ...state,
          user: action.user,
        };
      // alert messages
      case eventActions.UPDATE_ALERT:
        return {
          ...state,
          alert: action.alert,
        };
      // regenerate counters and calendar only ever
      // derived from underlying event state
      case eventActions.GENERATE_CALENDAR:
        result.calendar = generateCalendar(state);
        return {
          ...state,
          event: {
            ...state.event,
            calendar: result.calendar,
          },
        };
      case eventActions.GENERATE_MODULES:
        result.modules = generateModules(state);
        return {
          ...state,
          event: {
            ...state.event,
            modules: result.modules,
          },
        };
      // perform any list ordering after event load
      // case eventActions.SORT_LISTS:
      //   result.sortedRunnersList = state.user.connected
      //     ? state.runners.data.items.sort((a, b) => {
      //         if (a.UserId === state.event.data.UserId) {
      //           return -1; // move event owner to top
      //         } else {
      //           return 0;
      //         }
      //       })
      //     : state.runners.data.items;
      //   return {
      //     ...state,
      //     runners: {
      //       ...state.runners,
      //       data: {
      //         ...state.runners.data,
      //         items: result.sortedRunnersList,
      //       },
      //     },
      //   };
      // read and refresh members lists
      case eventActions.PULL_RUNNERS:
        return {
          ...state,
          runners: {
            ...state.runners,
            hydrated: true,
            updated: dayjs(),
            data: action.runners.data,
          },
        };
      case eventActions.PULL_RIDERS:
        return {
          ...state,
          riders: {
            ...state.riders,
            hydrated: true,
            updated: dayjs(),
            data: action.riders.data,
          },
        };
      // read and refresh invite lists
      case eventActions.PULL_RUNNER_INVITES:
        return {
          ...state,
          runnerInvites: {
            ...state.runnerInvites,
            hydrated: true,
            updated: dayjs(),
            data: action.runnerInvites.data,
          },
        };
      case eventActions.PULL_RIDER_INVITES:
        return {
          ...state,
          riderInvites: {
            ...state.riderInvites,
            hydrated: true,
            updated: dayjs(),
            data: action.riderInvites.data,
          },
        };
      case eventActions.PULL_RIDER_QUEUE:
        return {
          ...state,
          riderQueue: {
            ...state.riderQueue,
            hydrated: true,
            updated: dayjs(),
            data: action.riderQueue.data,
          },
        };
      // update members lists
      case eventActions.UPDATE_RUNNERS:
        result.updatedUser = action.user.remove ? [] : [action.user];
        result.updatedList = result.updatedUser.concat(
          state.runners.data.items.filter(
            (user) => user.UserId !== action.user.UserId
          )
        );
        result.updatedManagerStatus = action.user.remove
          ? !state.event.owner && state.event.manager
            ? false
            : state.event.manager
          : state.event.manager;
        return {
          ...state,
          runners: {
            ...state.runners,
            updated: dayjs(),
            data: {
              ...state.runners.data,
              items: result.updatedList,
              itemsCount: result.updatedList.length,
            },
          },
          event: {
            ...state.event,
            manager: result.updatedManagerStatus,
          },
        };
      case eventActions.UPDATE_RIDERS:
        result.updatedUser = action.user.remove ? [] : [action.user];
        result.updatedList = result.updatedUser.concat(
          state.riders.data.items.filter(
            (user) => user.UserId !== action.user.UserId
          )
        );
        return {
          ...state,
          riders: {
            ...state.riders,
            updated: dayjs(),
            data: {
              ...state.riders.data,
              items: result.updatedList,
              itemsCount: result.updatedList.length,
            },
          },
        };
      // update invite lists
      case eventActions.UPDATE_RUNNER_INVITES:
        result.updatedUser = action.user.remove ? [] : [action.user];
        result.updatedList = result.updatedUser.concat(
          state.runnerInvites.data.items.filter(
            (user) => user.UserId !== action.user.UserId
          )
        );
        return {
          ...state,
          runnerInvites: {
            ...state.runnerInvites,
            updated: dayjs(),
            data: {
              ...state.runnerInvites.data,
              items: result.updatedList,
              itemsCount: result.updatedList.length,
            },
          },
        };
      case eventActions.UPDATE_RIDER_INVITES:
        result.updatedUser = action.user.remove ? [] : [action.user];
        result.updatedList = result.updatedUser.concat(
          state.riderInvites.data.items.filter(
            (user) => user.UserId !== action.user.UserId
          )
        );
        return {
          ...state,
          riderInvites: {
            ...state.riderInvites,
            updated: dayjs(),
            data: {
              ...state.riderInvites.data,
              items: result.updatedList,
              itemsCount: result.updatedList.length,
            },
          },
        };
      case eventActions.UPDATE_RIDER_QUEUE:
        result.updatedUser = action.user.remove ? [] : [action.user];
        result.updatedList = result.updatedUser.concat(
          state.riderQueue.data.items.filter(
            (user) => user.UserId !== action.user.UserId
          )
        );
        return {
          ...state,
          riderQueue: {
            ...state.riderQueue,
            updated: dayjs(),
            data: {
              ...state.riderQueue.data,
              items: result.updatedList,
              itemsCount: result.updatedList.length,
            },
          },
        };
      // read and refresh links
      // handle subscription event when raw
      // action.content.items is received rather than
      // action.content.data from preparePullLinks
      case eventActions.PULL_LINKS:
        result.linksData = action.content.data
          ? action.content.data
          : action.content.items
          ? {
              combined: action.content.items,
              runners: action.content.items.filter(
                (obj) => obj.Category === "runner"
              ),
              riders: action.content.items.filter(
                (obj) => obj.Category === "rider"
              ),
              nextToken: action.content.nextToken,
            }
          : [];
        return {
          ...state,
          links: {
            ...state.links,
            hydrated: true,
            updated: dayjs(),
            data: result.linksData,
          },
        };
      // post and add new link to stack
      case eventActions.POST_LINK:
        return {
          ...state,
          links: {
            ...state.links,
            updated: dayjs(),
            data: {
              combined: [action.link, ...state.links.data.combined],
              runners:
                action.link.Category === "runner"
                  ? [action.link, ...state.links.data.runners]
                  : [...state.links.data.runners],
              riders:
                action.link.Category === "rider"
                  ? [action.link, ...state.links.data.riders]
                  : [...state.links.data.riders],
            },
          },
        };
      // read and refresh places
      // handle subscription event when raw
      // action.content.items is received rather than
      // action.content.data from preparePullLinks
      case eventActions.PULL_PLACES:
        result.linksData = action.content.data
          ? action.content.data
          : action.content.items
          ? {
              combined: action.content.items,
              runners: action.content.items.filter(
                (obj) => obj.Category === "runner"
              ),
              riders: action.content.items.filter(
                (obj) => obj.Category === "rider"
              ),
              nextToken: action.content.nextToken,
            }
          : [];
        return {
          ...state,
          places: {
            ...state.places,
            hydrated: true,
            updated: dayjs(),
            data: result.linksData,
          },
        };
      // batch update places
      case eventActions.UPDATE_PLACES:
        if (state.event.manager) {
          result.placesCombined = action.places;
        } else {
          result.placesCombined = [
            ...action.places,
            ...state.places.data.combined.filter(
              (obj) => obj.Category !== "rider"
            ),
          ];
        }
        return {
          ...state,
          places: {
            ...state.places,
            updated: dayjs(),
            data: {
              combined: result.placesCombined,
              runners: result.placesCombined.filter(
                (obj) => obj.Category === "runner"
              ),
              riders: result.placesCombined.filter(
                (obj) => obj.Category === "rider"
              ),
            },
          },
        };
      // remove existing link from stack
      case eventActions.REMOVE_LINK:
        result.linksCombined = state.links.data.combined.filter(
          (obj) => obj.LinkId !== action.link.LinkId
        );
        return {
          ...state,
          links: {
            ...state.links,
            updated: dayjs(),
            data: {
              combined: result.linksCombined,
              runners: result.linksCombined.filter(
                (obj) => obj.Category === "runner"
              ),
              riders: result.linksCombined.filter(
                (obj) => obj.Category === "rider"
              ),
            },
          },
        };
      // read and refresh messages
      case eventActions.PULL_MESSAGES:
        result.updatedRoomMessages = generateRoomMessages(
          state.event.manager,
          action.content.roomName,
          action.content.data
        );
        result.nextToken = action.content.nextToken;
        return {
          ...state,
          messaging: {
            ...state.messaging,
            rooms: {
              ...state.messaging.rooms,
              [action.content.roomName]: {
                ...state.messaging.rooms[action.content.roomName],
                hydrated: true,
                updated: dayjs(),
                data: result.updatedRoomMessages,
                nextToken: result.nextToken,
              },
            },
          },
        };
      // read and manage notifications
      case eventActions.PULL_MESSAGE_SETTINGS:
        return {
          ...state,
          messaging: {
            ...state.messaging,
            rooms: {
              ...state.messaging.rooms,
              [action.settings.roomName]: {
                ...state.messaging.rooms[action.settings.roomName],
                settingsHydrated: true,
                settingsData: action.settings.data,
              },
            },
          },
        };
      case eventActions.UPDATE_MESSAGE_SETTINGS:
        result.updatedSettings = state.messaging.rooms[action.settings.roomName]
          ? {
              ...state.messaging.rooms[action.settings.roomName].settingsData,
              ...action.settings.data,
            }
          : action.settings.data;
        return {
          ...state,
          messaging: {
            ...state.messaging,
            rooms: {
              ...state.messaging.rooms,
              [action.settings.roomName]: {
                ...state.messaging.rooms[action.settings.roomName],
                settingsHydrated: true,
                settingsData: result.updatedSettings,
              },
            },
          },
        };
      // set member message room
      case eventActions.SET_MESSAGE_ROOM:
        return {
          ...state,
          messaging: {
            ...state.messaging,
            activeRoom: action.roomName,
          },
        };
      // post and add new message to stack
      // responses with assistant are from ai and contain both request and response
      case eventActions.POST_MESSAGE:
        if (action.message.assistant) {
          result.postRequest = action.message.human;
          result.postResponse = action.message.assistant;
          result.roomName = result.postRequest.RoomName;
          result.updatedCombined = [
            result.postResponse,
            result.postRequest,
            ...state.messaging.rooms[result.roomName].data.combined.filter(
              (obj) => obj.PostId !== result.postRequest.PostId
            ),
          ];
        } else {
          result.roomName = action.message.RoomName.startsWith("desk:")
            ? "desk"
            : action.message.RoomName;
          result.updatedCombined = [
            action.message,
            ...state.messaging.rooms[result.roomName].data.combined.filter(
              (obj) => obj.PostId !== action.message.PostId
            ),
          ];
        }
        result.updatedRoomMessages = generateRoomMessages(
          state.event.manager,
          result.roomName,
          result.updatedCombined
        );
        return {
          ...state,
          messaging: {
            ...state.messaging,
            rooms: {
              ...state.messaging.rooms,
              [result.roomName]: {
                ...state.messaging.rooms[result.roomName],
                updated: dayjs(),
                data: result.updatedRoomMessages,
              },
            },
          },
        };
      // remove message set deleted flag to true on message
      case eventActions.REMOVE_MESSAGE:
        result.roomName = action.message.RoomName.startsWith("desk:")
          ? "desk"
          : action.message.RoomName;
        result.updatedCombined = state.messaging.rooms[
          result.roomName
        ].data.combined.map((item) => {
          if (item.PostId === action.message.PostId) {
            item.PostDeleted = true;
          }
          return item;
        });
        result.updatedRoomMessages = generateRoomMessages(
          state.event.manager,
          result.roomName,
          result.updatedCombined
        );
        return {
          ...state,
          messaging: {
            ...state.messaging,
            rooms: {
              ...state.messaging.rooms,
              [result.roomName]: {
                ...state.messaging.rooms[result.roomName],
                updated: dayjs(),
                data: result.updatedRoomMessages,
              },
            },
          },
        };
      // read and refresh ticks
      case eventActions.PULL_TICKS:
        result.ticks = {
          ...state.ticks,
          dates: {
            ...state.ticks.dates,
            [action.ticks.dateKey]: {
              hydrated: true,
              data: action.ticks.data,
              nextToken: action.ticks.nextToken,
              updated: dayjs(),
            },
          },
        };
        return {
          ...state,
          ticks: result.ticks,
        };
      // read and refresh viewer tickets
      case eventActions.PULL_VIEWER_TICKETS:
        result.viewerTickets = generateViewerTickets(
          action.tickets.data,
          state.constants
        );
        return {
          ...state,
          viewer: {
            ...state.viewer,
            hydrated: true,
            tickets: result.viewerTickets.tickets,
            dates: result.viewerTickets.dates,
          },
        };
      // new or updated ticket date offers
      case eventActions.UPDATE_VIEWER_TICKETS:
        action.ticket.updated = dayjs();
        result.viewerTickets = generateViewerTickets(
          [
            action.ticket,
            ...state.viewer.tickets.data.filter(
              (obj) => obj.TicketId !== action.ticket.TicketId
            ),
          ],
          state.constants
        );
        return {
          ...state,
          viewer: {
            ...state.viewer,
            tickets: result.viewerTickets.tickets,
            dates: result.viewerTickets.dates,
          },
        };
      // read and refresh viewer guest lists for runners
      case eventActions.ENTRY_STAGE_DATE:
        return {
          ...state,
          viewer: {
            ...state.viewer,
            entry: {
              hydrated: true,
              date: action.list.dateKey,
              lists: action.list.dateKey
                ? {
                    ...state.viewer.entry.lists,
                    [action.list.dateKey]: {
                      data: action.list.data,
                      nextToken: action.list.nextToken,
                      expires: dayjs().add(
                        EVENT_CONSTANTS.events.cacheExpiry,
                        "minutes"
                      ),
                    },
                  }
                : {
                    ...state.viewer.entry.lists,
                  },
            },
          },
        };
      // perform runner and rider handshake at entry
      case eventActions.ENTRY_HANDSHAKE:
        // prepare selected date and current ticket views
        result.date = state.viewer.entry.date;
        result.waiting = false;
        result.viewerTickets = {
          tickets: state.viewer.tickets,
          dates: state.viewer.dates,
        };
        result.viewerLists = state.viewer.entry.lists;
        if (!result.viewerLists[result.date]) {
          result.viewerLists[result.date] = {};
        }
        if (action.handshake.TicketAction === "hide") {
          result.viewerLists[result.date].showing = null;
          result.viewerLists[result.date].invalid = null;
        } else if (action.handshake.TicketAction.startsWith("confirm|")) {
          // runners handshake response has confirmed or denied entry
          if (state.event.manager) {
            // for runners update ticket on guest list and move it to top
            action.ticketIndex = result.viewerLists[result.date].data.findIndex(
              (obj) => obj.TicketId === action.handshake.TicketId
            );
            action.ticket =
              result.viewerLists[result.date].data[action.ticketIndex];
            action.ticket.TickedStatus =
              action.handshake.TicketAction.split("|")[1];
            result.viewerLists[result.date].data.splice(action.ticketIndex, 1);
            result.viewerLists[result.date].data.unshift(action.ticket);
          } else {
            // for riders update ticket status
            action.ticket = state.viewer.tickets.launched.filter(
              (obj) => obj.TicketId === action.handshake.TicketId
            )[0];
            action.ticket.TickedStatus =
              action.handshake.TicketAction.split("|")[1];
            action.ticket.updated = dayjs();
            result.viewerTickets = generateViewerTickets(
              [
                action.ticket,
                ...state.viewer.tickets.data.filter(
                  (obj) => obj.TicketId !== action.ticket.TicketId
                ),
              ],
              state.constants
            );
          }
          // set ticket viewing window to confirmed
          result.viewerLists[result.date].showing = null;
          result.viewerLists[result.date].invalid = null;
        } else if (action.handshake.EntryDate === state.viewer.entry.date) {
          // valid date: search for handshake ticket details from approprate viewer side
          result.showing = state.event.manager
            ? state.viewer.entry.lists[action.handshake.EntryDate].data.filter(
                (obj) => obj.TicketId === action.handshake.TicketId
              )
            : state.viewer.tickets.launched.filter(
                (obj) => obj.TicketId === action.handshake.TicketId
              );
          result.viewerLists[result.date].showing =
            result.showing.length > 0 ? result.showing[0] : null;
          result.viewerLists[result.date].invalid = null;
          result.waiting = true;
        } else {
          // invalid date: alert failed handshake
          result.viewerLists[result.date].showing = null;
          result.viewerLists[result.date].invalid = {
            handshake: action.handshake,
            message:
              "INVALID DATE: Ticket " +
              action.handshake.TicketId.split("-")[0].toUpperCase() +
              " is for " +
              action.handshake.EntryDate,
          };
        }
        // if showing activated enrich with content from handshake
        if (result.viewerLists[result.date].showing) {
          result.viewerLists[result.date].showing.Shareholders =
            action.handshake.Shareholders;
          result.viewerLists[result.date].showing.EntryUserId =
            action.handshake.EntryUserId;
          try {
            result.viewerLists[result.date].showing.EntryStatus = JSON.parse(
              action.handshake.EntryStatus
            );
          } catch (error) {
            result.viewerLists[result.date].showing.EntryStatus = {};
          }
        }
        return {
          ...state,
          loading: false,
          viewer: {
            ...state.viewer,
            entry: {
              ...state.viewer.entry,
              lists: result.viewerLists,
            },
            tickets: result.viewerTickets.tickets,
            dates: result.viewerTickets.dates,
          },
          event: {
            ...state.event,
            modules: {
              ...state.event.modules,
              entry: {
                ...state.event.modules.entry,
                counters: {
                  ...state.event.modules.entry.counters,
                  rider: {
                    ...state.event.modules.entry.counters.rider,
                    header: result.waiting
                      ? 1
                      : state.event.modules.entry.counters.rider.hidden === null
                      ? state.event.modules.entry.counters.rider.header
                      : state.event.modules.entry.counters.rider.hidden,
                    hidden: result.waiting
                      ? state.event.modules.entry.counters.rider.header
                      : null,
                  },
                },
              },
            },
          },
        };
      // manage event pinned status
      case eventActions.SET_PINNED_STATUS:
        return {
          ...state,
          event: {
            ...state.event,
            pinned: action.status,
          },
        };
      // reset hydrated status
      case eventActions.SET_HYDRATED:
        return {
          ...state,
          event: {
            ...state.event,
            hydrated: action.hydrated,
          },
        };
      // set loading for any subscriptions or cross component actions
      case eventActions.SET_LOADING:
        return {
          ...state,
          loading: action.loading,
          loadingMore: action.loadingMore,
        };
      // set observable callback loader
      case eventActions.SET_CALLBACK_LOADER:
        result.callbackState = action.callbackState
          ? action.callbackState
          : action.callbackState === null
          ? null
          : state.callbackState;
        return {
          ...state,
          callbackLoader: action.callbackLoader,
          callbackState: result.callbackState,
        };
      // set member drawer
      case eventActions.SET_MEMBER_DRAWER:
        return {
          ...state,
          memberDrawer: action.drawer,
        };
      // set action drawer
      case eventActions.SET_ACTION_DRAWER:
        return {
          ...state,
          actionDrawer: action.drawer,
        };
      // set observables
      case eventActions.SET_OBSERVABLES_TRACKER:
        return {
          ...state,
          observablesTracker: action.observablesTracker,
        };
      case eventActions.EXIT_EVENT:
        result.exitState = initialState;
        result.exitState.viewer.trail = state.viewer.trail;
        return result.exitState;
      default:
        return state;
    }
  } catch (error) {
    serviceLogError("eventContextReducer", error);
    return state;
  }
};

export const EventContext = createContext();

// derived state internal functions
const generateCalendar = (state) => {
  // currency data
  const currency = currenciesGetItem(state.event.data.Currency);
  // derive current triggers and limits in use
  const triggers = generateTriggers(
    state.event.data.Triggers,
    currency.code.long
  );
  const limits = generateLimits(
    state.event.data.Limits,
    state.event.data.TicketTypes
  );
  triggers.ratio = Math.round((triggers.default / limits.capacity) * 100);
  // derive active date items
  const dates = generateDates(
    state.event.data,
    currency,
    triggers,
    limits,
    state.constants,
    state.event.generators
  );
  // derive any ticket related data
  const tickets = {
    selectable: generateTicketsSelectable(
      state.event.data.TicketTypes,
      currency.code.long
    ),
    groups: {
      selectable: generateTicketGroupsSelectable(
        state.constants.tickets.maxGroupSize
      ),
    },
  };
  // return calendar
  const calendar = {
    triggers,
    limits,
    dates,
    tickets,
  };
  return calendar;
};

const generateLimits = (limitList, ticketTypes) => {
  // extract base settings
  const settingCapacity = limitList.find(
    (obj) => obj.active && obj.subtag === "capacity"
  );
  const settingNotice = limitList.find(
    (obj) => obj.active && obj.subtag === "notice"
  );
  // compare capacity of all ticket types to overall capacity
  let capacityTickets, capacityValue, capacityIsOverride;
  capacityTickets = 0;
  for (let tkt of ticketTypes) {
    if (tkt.active && tkt.max > 0) {
      capacityTickets += tkt.max;
    }
  }
  if (
    settingCapacity &&
    settingCapacity.max > 0 &&
    settingCapacity.max < capacityTickets
  ) {
    capacityValue = settingCapacity.max;
    capacityIsOverride = true;
  } else {
    capacityValue = capacityTickets;
    capacityIsOverride = false;
  }
  // derive the current limits
  const limits = {
    notice: settingNotice ? settingNotice.max : 7,
    capacity: capacityValue,
    capacityTickets: capacityTickets,
    capacityOverride: capacityIsOverride,
    hydrated: true,
  };
  return limits;
};

const generateTriggers = (triggerList, currencyCode) => {
  // extract base settings
  const settingTriggerDefault = triggerList.find(
    (obj) => obj.active && obj.subtag === "default"
  );
  const settingTriggerFinancial = triggerList.find(
    (obj) => obj.active && obj.subtag === "financial"
  );
  // derive the current triggers
  const triggers = {
    default: settingTriggerDefault
      ? settingTriggerDefault.min
      : toolbox().getDefaultLargeInt(),
    financial: settingTriggerFinancial
      ? settingTriggerFinancial.min
      : toolbox().getDefaultLargeInt(),
    hydrated: true,
  };
  // add a descriptive text
  triggers.display =
    triggers.default > 0 && triggers.financial > 0
      ? triggers.default +
        " or " +
        currencyCode +
        (triggers.financial / 100).toFixed(2)
      : triggers.default > 0
      ? triggers.default
      : triggers.financial > 0
      ? currencyCode + (triggers.financial / 100).toFixed(2)
      : "inactive";
  return triggers;
};

const generateDates = (
  eventData,
  currency,
  triggers,
  limits,
  constants,
  generators
) => {
  const dateList = eventData.Dates;
  // const ticketList = eventData.TicketTypes;
  const ticks = eventData.Ticking;
  // extract base settings into iterators
  const dateStateRunners = dateList.find(
    (obj) => obj.active && obj.subtag === "default"
  );
  const iteratorRunners = dateStateRunners
    ? [...new Set(dateStateRunners.add)].sort()
    : [];
  const dateStateRiders = dateList.find(
    (obj) => obj.active && obj.subtag === "riders"
  );
  const iteratorRiders = dateStateRiders
    ? [...new Set(dateStateRiders.add)].sort()
    : [];
  const iteratorCombined = [
    ...new Set(iteratorRunners.concat(iteratorRiders)),
  ].sort();
  iteratorCombined.sort((a, b) => parseInt(a) - parseInt(b));
  // derive the runners and riders dates from today onwards
  const today = dayjs().diff(dayjs(constants.dates.base, "YYYY-MM-DD"), "days");
  const todayTrailing = today - 14;
  const datesRunners = [];
  for (const add of iteratorRunners) {
    if (add >= today) {
      datesRunners.push(
        generateDateItem(eventData, add, triggers, limits, ticks, constants)
      );
    }
  }
  const datesRiders = [];
  for (const add of iteratorRiders) {
    if (add >= todayTrailing) {
      datesRiders.push(
        generateDateItem(eventData, add, triggers, limits, ticks, constants)
      );
    }
  }
  const datesCombined = [];
  for (const add of iteratorCombined) {
    if (add >= todayTrailing) {
      datesCombined.push(
        generateDateItem(
          eventData,
          add,
          triggers,
          limits,
          ticks,
          constants,
          iteratorRunners.includes(add)
        )
      );
    }
  }
  // create trending top list
  const datesTrendingRunners = generateDatesTrending(datesRunners);
  const datesTrendingRiders = generateDatesTrending(datesRiders);
  const datesTrendingCombined = generateDatesTrending(datesCombined);
  const datesLaunched = generateDatesLaunched(ticks);
  // create selectable list for data offers
  const datesSelectable = generateDatesSelectable(
    datesRunners,
    datesRiders,
    triggers,
    limits,
    constants
  );
  // create trending top list
  const ticketBreakdown = generateTicketBreakdown(
    datesTrendingCombined,
    currency.code.long
  );

  // return dates
  return {
    runners: {
      trending: datesTrendingRunners,
      active: datesRunners,
      settings: dateStateRunners
        ? dateStateRunners
        : generators.settings.date("default"),
    },
    riders: {
      trending: datesTrendingRiders,
      active: datesRiders,
      settings: dateStateRiders
        ? dateStateRiders
        : generators.settings.date("riders"),
    },
    combined: {
      trending: datesTrendingCombined,
      active: datesCombined,
      tickets: ticketBreakdown,
    },
    selectable: datesSelectable,
    launched: datesLaunched,
  };
};

const generateDatesTrending = (dates) => {
  const top = 5;
  dates = JSON.parse(JSON.stringify(dates));
  if (dates.length === 0) {
    return dates;
  } else if (dates.length < top) {
    return dates.sort((a, b) => {
      return b.ticking.Quantity - a.ticking.Quantity;
    });
  } else {
    return dates
      .sort((a, b) => {
        return b.ticking.Quantity - a.ticking.Quantity;
      })
      .slice(0, 5);
  }
};

const generateDatesSelectable = (
  datesRunners,
  datesRiders,
  triggers,
  limits,
  constants
) => {
  const selectableList = [];
  const checkRunnersSelected = (value) => {
    return datesRunners.findIndex((obj) => obj.value === value) > -1;
  };
  const checkRidersSelected = (value) => {
    return datesRiders.findIndex((obj) => obj.value === value) > -1;
  };
  let date, diff, selectable, rangeStart, rangeEnd;
  rangeStart = parseInt(limits.notice) + 1;
  rangeEnd = parseInt(limits.notice) + parseInt(constants.dates.maxDays);
  for (let i = rangeStart; i < rangeEnd; i++) {
    date = dayjs().add(i, "days");
    diff = date.diff(dayjs(constants.dates.base, "YYYY-MM-DD"), "days");
    selectable = {};
    selectable.id = date.format("YYYY-MM-DD");
    selectable.name = date.format("DD MMM YYYY");
    selectable.value = diff;
    selectable.display = date.format("DD|ddd");
    selectable.icon = iconSelectorDates;
    selectable.groupBy = date.format("MMM YYYY");
    selectable.selected = {
      runners: checkRunnersSelected(selectable.value),
      riders: checkRidersSelected(selectable.value),
    };
    selectableList.push(selectable);
  }
  return selectableList;
};

const generateTicketBreakdown = (dateList, currencyCode) => {
  const ticketMap = {};
  const ticketList = [];
  dateList.forEach(function (date) {
    date.progress.tickets.total.forEach(function (ticket) {
      if (ticketMap[ticket.id]) {
        ticketMap[ticket.id].breakdown.Amount += ticket.breakdown.Amount;
        ticketMap[ticket.id].breakdown.Quantity += ticket.breakdown.Quantity;
        ticketMap[ticket.id].breakdown.TickedCount +=
          ticket.breakdown.TickedCount;
        ticketMap[ticket.id].breakdown.DateCount += 1;
      } else {
        ticketMap[ticket.id] = ticket;
        ticketMap[ticket.id].breakdown.DateCount = 1;
        ticketMap[ticket.id].currency = currencyCode;
      }
    });
  });
  for (let key of Object.keys(ticketMap)) {
    ticketList.push(ticketMap[key]);
  }
  return {
    map: ticketMap,
    list: ticketList,
  };
};

const generateTicketsSelectable = (ticketList, currencyCode) => {
  const activeList = [];
  const inactiveList = [];
  let selectable;
  ticketList.forEach((ticket) => {
    selectable = {};
    selectable.id = ticket.id;
    selectable.name = ticket.name.toUpperCase();
    selectable.currency = currencyCode;
    selectable.price = ticket.price; // 100x no decimal price
    selectable.value = selectable.id;
    selectable.capacity = ticket.max;
    selectable.groups = ticket.min;
    selectable.groupSelectable = generateTicketGroupsSelectable(ticket.min);
    selectable.display =
      selectable.name.toUpperCase() +
      " - " +
      selectable.currency.toUpperCase() +
      " " +
      (selectable.price / 100).toFixed(2) +
      " - Groups up to " +
      selectable.groups;
    selectable.icon = iconSelectorTickets;
    selectable.active = ticket.active;
    if (ticket.active) {
      activeList.push(selectable);
    } else {
      inactiveList.push(selectable);
    }
  });
  return {
    active: activeList,
    inactive: inactiveList,
    all: activeList.concat(inactiveList),
  };
};

const generateTicketGroupsSelectable = (maxGroupSize) => {
  const selectableList = [];
  let size, selectable;
  Array.from(Array(maxGroupSize).keys()).forEach((index) => {
    selectable = {};
    size = index + 1;
    selectable.id = "ticket-group-" + size;
    selectable.name = "Group size of " + size;
    selectable.value = size;
    selectable.display = selectable.name;
    selectable.icon = iconSelectorGroups;
    selectableList.push(selectable);
  });
  return selectableList;
};

const generateDateItem = (
  eventData,
  add,
  triggers,
  limits,
  ticks,
  constants,
  runnerOffer
) => {
  // convert to date and check if ticked
  const date = dayjs(constants.dates.base, "YYYY-MM-DD").add(add, "days");
  const ticked =
    ticks && ticks.length > 0
      ? ticks.find((obj) => obj && obj.DateKey === date.format("YYYY-MM-DD"))
      : null;
  // date attributes
  const id = date.format("YYYY-MM-DD");
  const value = add;
  const day = date.format("DD");
  const dow = date.format("ddd");
  const month = date.format("MMM");
  const year = date.format("YYYY");
  const diffToday = date.diff(dayjs(), "day");
  const isExpired = diffToday < limits.notice;
  const isPast = diffToday < 0;
  const isRunnerOffer = runnerOffer ? true : false;
  const isRunnerLaunchable =
    ticked && ticked.TickStatus === "launchable" ? true : false;
  // ticking status
  const ticking = ticked
    ? ticked
    : { TickStatus: "none", Quantity: 0, Amount: 0 };
  const insights = {
    meta: ticking.Meta ? toolbox().jsonTryParse(ticking.Meta) : [],
    breakdown: ticking.Breakdown
      ? toolbox().jsonTryParse(ticking.Breakdown)
      : {},
    leading: {
      id: null,
      name: null,
      quantity: 0,
      amount: 0,
    },
  };
  // launch per ticket setting if set
  // then need to identify leading ticket
  const launchPerTicket = eventData.LaunchPerTicket;
  if (launchPerTicket) {
    for (let key of Object.keys(insights.breakdown)) {
      if (insights.breakdown[key].Quantity > insights.leading.quantity) {
        insights.leading.id = key;
        let ticketData = eventData.TicketTypes.find((obj) => obj.id === key);
        if (ticketData) {
          insights.leading.name = ticketData.name;
        } else {
          insights.leading.name = "Ticket name not found";
        }
        insights.leading.quantity = insights.breakdown[key].Quantity;
        insights.leading.amount = insights.breakdown[key].Amount;
      }
    }
  }
  const preLaunchQuantity = launchPerTicket
    ? insights.leading.quantity
    : ticking.Quantity;
  const preLaunchAmount = launchPerTicket
    ? insights.leading.amount
    : ticking.Amount;
  // initiate progress status
  const progress = {
    count: preLaunchQuantity,
    trigger: {
      quantity: null,
      amount: null,
      total: null,
    },
    capacity: {
      quantity: null,
      amount: null,
      total: null,
    },
    tickets: {
      types: insights.meta.TicketTypes || [],
      breakdown: insights.breakdown,
      leading: insights.leading,
      total: [],
    },
  };
  // launch progress status
  progress.trigger.quantity =
    preLaunchQuantity >= triggers.default
      ? 100
      : Math.round((preLaunchQuantity / triggers.default) * 100);
  progress.trigger.amount =
    preLaunchAmount >= triggers.financial
      ? 100
      : Math.round((preLaunchAmount / triggers.financial) * 100);
  progress.trigger.total =
    progress.trigger.quantity > progress.trigger.amount
      ? progress.trigger.quantity
      : progress.trigger.amount;
  progress.capacity.quantity =
    ticking.Quantity >= limits.capacity
      ? 100
      : Math.round((ticking.Quantity / limits.capacity) * 100);
  progress.capacity.total = progress.capacity.quantity;
  progress.remaining = {
    trigger: triggers.default - progress.count,
    capacity: limits.capacity - progress.count,
  };
  progress.remaining.trigger =
    progress.remaining.trigger < 0 ? 0 : progress.remaining.trigger;
  progress.remaining.capacity =
    progress.remaining.capacity < 0 ? 0 : progress.remaining.capacity;
  progress.remaining.nearlyFull =
    progress.remaining.capacity <= 10 ||
    progress.remaining.capacity <= limits.capacity * 0.1;
  // ticket breakdown progress
  progress.tickets.types.forEach(function (ticket) {
    progress.tickets.total.push({
      ...ticket,
      breakdown: progress.tickets.breakdown[ticket.id],
    });
  });
  // returned attributes
  return {
    id,
    value,
    day,
    dow,
    month,
    year,
    ticking,
    progress,
    diffToday,
    isExpired,
    isPast,
    isRunnerOffer,
    isRunnerLaunchable,
  };
};

const generateDatesLaunched = (ticks) => {
  const dateKeysAll = [];
  const dateKeysFuture = [];
  const dateKeysHistory = [];
  const dateItemsAll = [];
  const dateItemsFuture = [];
  const dateItemsHistory = [];
  const preLaunchValues = [];
  const preLaunchStates = EVENT_CONSTANTS.dates.states.filter(
    (obj) => !obj.launch
  );
  preLaunchStates.forEach(function (item) {
    preLaunchValues.push(item.value);
  });
  const datesLaunched = ticks
    ? ticks.filter((obj) => !preLaunchValues.includes(obj.TickStatus))
    : [];
  datesLaunched.forEach(function (item) {
    dateKeysAll.push(item.DateKey);
    dateItemsAll.push(item);
    if (dayjs(item.DateKey).diff(dayjs(), "days") < 0) {
      dateKeysHistory.push(item.DateKey);
      dateItemsHistory.push(item);
    } else {
      dateKeysFuture.push(item.DateKey);
      dateItemsFuture.push(item);
    }
  });
  return {
    all: {
      keys: dateKeysAll.sort().reverse(),
      items: dateItemsAll,
    },
    future: {
      keys: dateKeysFuture.sort().reverse(),
      items: dateItemsFuture,
    },
    history: {
      keys: dateKeysHistory.sort().reverse(),
      items: dateItemsHistory,
    },
  };
};

const generateViewerTickets = (tickets, constants) => {
  // first sort tickets by LastUpdated descending and create decendent lists
  tickets.sort((a, b) => (a.LastUpdated < b.LastUpdated ? 1 : -1));
  const ticketsOffered = tickets.filter((obj) =>
    EventTicketOfferStates().includes(obj.TickedStatus)
  );
  const ticketsLaunched = tickets.filter((obj) =>
    EventTicketLaunchStates().includes(obj.TickedStatus)
  );
  // date offers
  const datesOffered = [];
  const datesOfferedFuture = [];
  const datesOfferedHistory = [];
  const tickedDate = {};
  ticketsOffered.forEach(function (item) {
    item.TickedDates.forEach(function (tick) {
      // main summarizes all ticks from all holders
      if (tick.tag === "main") {
        tickedDate.js = dayjs(constants.dates.base, "YYYY-MM-DD").add(
          tick.add,
          "days"
        );
        tickedDate.key = tickedDate.js.format("YYYY-MM-DD");
        if (!datesOffered.includes(tickedDate.key)) {
          datesOffered.push(tickedDate.key);
        }
        if (tickedDate.js.diff(dayjs(), "days") < 0) {
          if (!datesOfferedHistory.includes(tickedDate.key)) {
            datesOfferedHistory.push(tickedDate.key);
          }
        } else {
          if (!datesOfferedFuture.includes(tickedDate.key)) {
            datesOfferedFuture.push(tickedDate.key);
          }
        }
      }
    });
  });
  // date launches
  const datesLaunched = [];
  const datesLaunchedFuture = [];
  const datesLaunchedHistory = [];
  ticketsLaunched.forEach(function (item) {
    if (!datesLaunched.includes(item.TickedFinal)) {
      datesLaunched.push(item.TickedFinal);
    }
    if (dayjs(item.TickedFinal, "YYYY-MM-DD").diff(dayjs(), "days") < 0) {
      if (!datesLaunchedHistory.includes(item.TickedFinal)) {
        datesLaunchedHistory.push(item.TickedFinal);
      }
    } else {
      if (!datesLaunchedFuture.includes(item.TickedFinal)) {
        datesLaunchedFuture.push(item.TickedFinal);
      }
    }
  });
  return {
    tickets: {
      data: tickets,
      offered: ticketsOffered,
      launched: ticketsLaunched,
    },
    dates: {
      launched: {
        all: {
          keys: datesLaunched.sort().reverse(),
        },
        future: {
          keys: datesLaunchedFuture.sort().reverse(),
        },
        history: {
          keys: datesLaunchedHistory.sort().reverse(),
        },
      },
      offered: {
        all: {
          keys: datesOffered.sort().reverse(),
        },
        future: {
          keys: datesOfferedFuture.sort().reverse(),
        },
        history: {
          keys: datesOfferedHistory.sort().reverse(),
        },
      },
    },
  };
};

// counters for each module headers and buttons
const generateModules = (state) => {
  const modules = state.event.modules;
  let plural, text;

  try {
    // launchpad runner text
    modules.launchpad.text.runner.title = "Eventuator Launch Settings";
    modules.launchpad.text.runner.action = "Make Date Offer";
    modules.launchpad.text.runner.description =
      "Manage settings and launch conditions";
    // launchpad rider text
    modules.launchpad.text.rider.title = "Review Criteria";
    modules.launchpad.text.rider.action = "Make Date Offer";
    modules.launchpad.text.rider.description =
      "Head to the date exchange to get started";
    // sharing runner text
    modules.sharing.text.runner.title = "Post a Flyer Link";
    modules.sharing.text.runner.action = "Post Flyer";
    modules.sharing.text.runner.description =
      "Share where you promoted the eventuator";
    // sharing rider text
    modules.sharing.text.rider.title = "Post a Flyer Link";
    modules.sharing.text.rider.action = "Post Flyer";
    modules.sharing.text.rider.description =
      "Share where you promoted the eventuator";
    // sharing runner counters
    modules.sharing.counters.runner.header = state.links.data.runners.length;
    // sharing rider counters
    modules.sharing.counters.rider.header = state.links.data.riders.length;
    // dates runner counters
    modules.dates.counters.runner.header = state.event.calendar
      ? state.event.calendar.dates.runners.active.length
      : 0;
    modules.dates.counters.runner.viewer.primary = state.event.calendar
      ? state.event.calendar.dates.runners.active.length
      : 0;
    modules.dates.counters.runner.viewer.secondary = state.event.calendar
      ? state.event.calendar.tickets.selectable.active.length
      : 0;
    // dates rider counters
    modules.dates.counters.rider.header = state.event.calendar
      ? state.event.calendar.dates.riders.active.length
      : 0;
    modules.dates.counters.rider.viewer.primary =
      state.viewer.dates.offered.future.keys.length;
    modules.dates.counters.rider.viewer.secondary =
      state.viewer.tickets.offered.length;
    // dates runner text
    modules.dates.text.runner.title = "Make Date Offer";
    modules.dates.text.runner.action = "Make Date Offer";
    text = "";
    plural = modules.dates.counters.runner.viewer.primary === 1 ? "" : "s";
    text += modules.dates.counters.runner.viewer.primary;
    text += " active date";
    text += plural;
    // plural = modules.dates.counters.runner.viewer.secondary === 1 ? "" : "s";
    // text += " with ";
    // text += modules.dates.counters.runner.viewer.secondary;
    // text += " ticket type";
    // text += plural;
    modules.dates.text.runner.description = text;
    // dates rider text
    modules.dates.text.rider.title = "Make Date Offer";
    modules.dates.text.rider.action = "Make Date Offer";
    text = "";
    plural = modules.dates.counters.rider.viewer.primary === 1 ? "" : "s";
    text += modules.dates.counters.rider.viewer.primary;
    text += " active date";
    text += plural;
    plural = modules.dates.counters.rider.viewer.secondary === 1 ? "" : "s";
    text += " across ";
    text += modules.dates.counters.rider.viewer.secondary;
    text += " ticket";
    text += plural;
    modules.dates.text.rider.description = text;
    // messaging runner counters
    modules.messaging.counters.runner.header =
      state.messaging.rooms[state.messaging.activeRoom].data.runners.length;
    // messaging rider counters
    modules.messaging.counters.rider.header =
      state.messaging.rooms[state.messaging.activeRoom].data.riders.length;
    if (
      state.messaging.activeRoom &&
      state.messaging.activeRoom.startsWith("ai")
    ) {
      // messaging runner text
      modules.messaging.text.runner.title = "AI Messaging";
      modules.messaging.text.runner.action = "Ask Question";
      modules.messaging.text.runner.description = "Group chat with AI";
      // messaging rider text
      modules.messaging.text.rider.title = "AI Messaging";
      modules.messaging.text.rider.action = "Ask Question";
      modules.messaging.text.rider.description = "Chat with with AI";
    } else {
      // messaging runner text
      modules.messaging.text.runner.title = "Group Messaging";
      modules.messaging.text.runner.action = "Post Message";
      modules.messaging.text.runner.description =
        "Chat with runners and riders";
      // messaging rider text
      modules.messaging.text.rider.title = "Group Messaging";
      modules.messaging.text.rider.action = "Post Message";
      modules.messaging.text.rider.description = "Chat with runners and riders";
    }

    // location runner counters
    modules.locations.counters.runner.header = state.places.data.runners.length;
    // location rider counters
    modules.locations.counters.rider.header = state.places.data.riders.length;
    // location runner text
    modules.locations.text.runner.title = "Manage Places";
    modules.locations.text.runner.action = "Manage Places";
    modules.locations.text.runner.description = "Locate the launch site";
    // location rider text
    modules.locations.text.rider.title = "Suggest Place";
    modules.locations.text.rider.action = "Suggest Place";
    modules.locations.text.rider.description = "Locate the launch site";

    // entry runner counters
    modules.entry.counters.runner.header = state.event.calendar
      ? state.event.calendar.dates.launched.all.keys.length
      : 0;
    modules.entry.counters.runner.viewer.primary = state.viewer.entry.date
      ? state.viewer.entry.lists[state.viewer.entry.date].data.length
      : 0;
    // entry rider counters
    modules.entry.counters.rider.header =
      state.viewer.dates.launched.all.keys.length;
    if (
      state.viewer.entry.date &&
      state.viewer.entry.lists[state.viewer.entry.date].showing
    ) {
      modules.entry.counters.rider.viewer.primary = 1;
    } else {
      modules.entry.counters.rider.viewer.primary = state.viewer.entry.date
        ? state.viewer.tickets.launched.filter(
            (obj) => obj.TickedFinal === state.viewer.entry.date
          ).length
        : state.viewer.tickets.launched.length;
    }
    // entry runner text
    modules.entry.text.runner.title = "Eventuator Entry";
    modules.entry.text.runner.action = state.viewer.entry.date
      ? dayjs(state.viewer.entry.date, "YYYY-MM-DD").format("DD MMM YYYY")
      : "Select Date";
    modules.entry.text.runner.description =
      "Select an launch date for guest list";
    // entry rider text
    modules.entry.text.rider.title = "Eventuator Entry";
    modules.entry.text.rider.action = state.viewer.entry.date
      ? dayjs(state.viewer.entry.date, "YYYY-MM-DD").format("DD MMM YYYY")
      : "Select Date";
    modules.entry.text.rider.description =
      "Select a launch date for your tickets";

    // members runner counters
    modules.members.counters.runner.header = state.runners.data.itemsCount;
    modules.members.counters.runner.viewer.primary =
      state.runners.data.itemsCount;
    // members rider counters
    modules.members.counters.rider.header = state.riders.data.itemsCount;
    modules.members.counters.rider.viewer.primary =
      state.riders.data.itemsCount;
    // members runner text
    modules.members.text.runner.title = "Invite Eventuator Members";
    modules.members.text.runner.action = "Invite Members";
    modules.members.text.runner.description =
      "Share invite links for new members";
    // members rider text
    modules.members.text.rider.title = "Invite Eventuator Members";
    modules.members.text.rider.action = "Invite Members";
    modules.members.text.rider.description =
      "Share invite links for new members";
  } catch (error) {
    serviceLogError("generateModules", error);
  }

  return modules;
};

// format and sort messages based on room
const generateRoomMessages = (isViewerRunner, roomName, messageDataItems) => {
  // first presort messages if required by room type
  if (roomName.startsWith("ai")) {
    messageDataItems.forEach((item, index, data) => {
      // update category based on room type and capture original
      if (!item.SourceCategory) {
        data[index].SourceCategory = item.Category;
      }
      // for ai rooms place all assistant responses on right hand side
      if ([item.SourceCategory, item.Category].includes("assistant")) {
        data[index].Category = isViewerRunner ? "rider" : "runner";
      }
    });
  }
  messageDataItems.forEach((item, index, data) => {
    // flag for user change in the ordering
    if (index === 0) {
      data[index].PriorUserId = messageDataItems[0].UserId;
      data[index].PriorCategory = messageDataItems[0].Category;
      data[index].PriorPostDeleted = messageDataItems[0].PostDeleted;
    } else {
      data[index].PriorUserId = messageDataItems[index - 1].UserId;
      data[index].PriorCategory = messageDataItems[index - 1].Category;
      data[index].PriorPostDeleted = messageDataItems[index - 1].PostDeleted;
      if (item.UserId !== messageDataItems[index - 1].UserId) {
        data[index].PriorUserChanged = true;
      }
    }
    if (index === data.length - 1) {
      data[index].PriorUserEnd = true;
    }
  });
  // split messages into runner and rider
  const messages = {
    combined: messageDataItems,
    runners: messageDataItems.filter((obj) => obj.Category === "runner"),
    riders: messageDataItems.filter((obj) => obj.Category === "rider"),
  };
  return messages;
};
