// react core
import { useState, useEffect } from "react";

// stripe elements
import {
  useStripe,
  useElements,
  Elements,
  // CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// material design
import Box from "@mui/material/Box";

// entzy models and services
import configEntzy from "components/config/ConfigEntzy";
import {
  serviceGraphCall,
  serviceCookieSet,
  servicePaymentConfirm,
  servicePaymentMethodSet,
} from "services/graphql/call";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionAlert,
} from "components/utils/common/CommonLoaders";

const stripePromise = loadStripe(configEntzy.APP_PAYMENTS_PUBLIC_KEY);
const constants = {
  messages: {
    ACTION_FAILED:
      "There was a problem with the settings update and it was unable to complete. Give it another go or contact us if this problem persists",
    NOT_LOADED:
      "The payment settings handler is still loading. Give it another go or try a refresh.",
  },
};

function PaymentBasicForm(props) {
  const user = props.user;
  const stripe = useStripe();
  const elements = useElements();

  const callback = props.callback;
  const handleLoader = props.handleLoader;
  const redirectPath = props.redirectPage
    ? props.redirectPage
    : props.page
    ? props.page
    : "/settings/payment/methods";

  const actionCallback = async (response) => {
    handleLoader(false);
    callback(response);
    return response;
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    handleLoader(true);

    if (!stripe || !elements) {
      return actionCallback({
        alert: true,
        message: constants.messages.NOT_LOADED,
      });
    }
    // trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return actionCallback({
        alert: true,
        message: submitError.message,
      });
    }
    // set cookie before potential redirect
    serviceCookieSet(
      configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_secret_key,
      props.clientSecret
    );
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: configEntzy.APP_URL + redirectPath,
      },
      redirect: "if_required",
    });
    if (result.error) {
      return actionCallback({
        alert: true,
        message: result.error.message,
      });
    }
    const clientSecret = result.paymentIntent
      ? result.paymentIntent.client_secret
      : null;
    if (!clientSecret) {
      return actionCallback({
        alert: true,
        message:
          "Unable to confirm payment. Give it another go or contact us if this problem persists.",
      });
    }
    // handle final setup intent response if no redirect needed
    const confirmResponse = await servicePaymentConfirm(user, {
      stripe,
      clientSecret,
      noredirect: true,
    });
    return actionCallback(confirmResponse);
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default shadow-input bg-white"
        sx={{ p: configEntzy.APP_SPACING_MD }}
      >
        <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_SM }}>
          <PaymentElement />
        </Box>
      </Box>
      <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_MD }}>
        <ActionButton
          variant="contained"
          size="medium"
          text={props.submitText}
          disabled={props.disabled}
          onClick={handlePaymentSubmit}
          fullWidth={true}
        />
      </Box>
    </Box>
  );
}

function PaymentMethodForm(props) {
  const user = props.user;
  const stripe = useStripe();
  const elements = useElements();

  const callback = props.callback;
  const handleLoader = props.handleLoader;
  const redirectPath = props.redirectPage
    ? props.redirectPage
    : props.page
    ? props.page
    : "/settings/payment/methods";

  const actionCallback = async (response) => {
    handleLoader(false);
    callback(response);
    return response;
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    handleLoader(true);
    let response;
    if (!stripe || !elements) {
      return actionCallback({
        alert: true,
        message: constants.messages.NOT_LOADED,
      });
    }
    // trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return actionCallback({
        alert: true,
        message: submitError.message,
      });
    }
    // create setup intent server side to return client secret
    response = await serviceGraphCall("mutation", "updatePayment", {
      PaymentToken: "setup",
    });
    if (!response.success) {
      return actionCallback({ alert: true, message: response.message });
    }
    // parse the returned results
    const paymentParts = (response.data.PaymentId + ":").split(":");
    // const customerId = paymentParts[0];
    const clientSecret = paymentParts[1];
    serviceCookieSet(
      configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_secret_key,
      clientSecret
    );
    // use the elements and secret to confirm the setup
    response = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: configEntzy.APP_URL + redirectPath,
      },
      redirect: "if_required",
    });
    // handle final setup intent response if no redirect needed
    const setupResponse = await servicePaymentMethodSet(user, {
      stripe,
      clientSecret,
      noredirect: true,
    });
    return actionCallback(setupResponse);
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default shadow-input bg-white"
        sx={{ p: configEntzy.APP_SPACING_MD }}
      >
        <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_SM }}>
          <PaymentElement />
        </Box>
      </Box>
      <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_MD }}>
        <ActionButton
          variant="contained"
          size="medium"
          text={props.submitText}
          disabled={props.disabled}
          onClick={handlePaymentSubmit}
          fullWidth={true}
        />
      </Box>
    </Box>
  );
}

export function PaymentBasic(props) {
  const payment = props.payment;
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const getClientSecret = async () => {
      const response = await serviceGraphCall(
        "query",
        "publicGetPaymentIntent",
        {
          Action: "create",
          Email: payment.email,
          Currency: payment.currency.code.long,
          Amount: payment.amountInt,
          Description: payment.description,
          RecipientType: payment.recipientType,
          RecipientName: payment.recipientName,
        }
      );
      const secret = response.success ? response.data : "error";
      return setClientSecret(secret);
    };
    if (!clientSecret) {
      getClientSecret();
    }
  }, [payment, clientSecret]);

  return (
    <Box className="box-default">
      {clientSecret && clientSecret === "error" ? (
        <ActionAlert
          severity="error"
          title="Unable to Create Transaction"
          message="Hmm there was a problem preparing this transaction. Give it another go and if the problem persists ask the seller to check their store settings or contact us."
        />
      ) : clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
          }}
        >
          <PaymentBasicForm {...props} clientSecret={clientSecret} />
        </Elements>
      ) : (
        <Box className="box-default">
          <ActionLoader />
        </Box>
      )}
    </Box>
  );
}

export function PaymentMethod(props) {
  const options = {
    mode: "setup",
    currency: "usd", // todo: pass in currency with pref ordering (user profile, event, transaction)
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentMethodForm {...props} />
    </Elements>
  );
}
