// react core
import { useState, useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { TextTag } from "components/utils/common/CommonTags";
import { ActionButton } from "components/utils/common/CommonButtons";
import { ActionAlert } from "components/utils/common/CommonLoaders";
import { SettingsContext } from "pages/settings/SettingsContext";

function SecurityPassword(props) {
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const context = useContext(SettingsContext);

  const handleEdit = async (value) => {
    setSuccess(false);
    setEdit(value);
  };

  const handleFormCallback = async (data) => {
    const settingsCurrentPassword = data.filter(
      (obj) => obj.id === "settings-current-password"
    )[0].value;
    const settingsNewPassword = data.filter(
      (obj) => obj.id === "settings-new-password"
    )[0].value;
    const settingsConfirmPassword = data.filter(
      (obj) => obj.id === "settings-confirm-password"
    )[0].value;
    if (
      settingsCurrentPassword.length &&
      settingsNewPassword.length &&
      settingsConfirmPassword.length
    ) {
      const settingsUpdate = await context.prepareUpdateSettings({
        key: "Password",
        value: {
          currentPassword: settingsCurrentPassword,
          newPassword: settingsNewPassword,
          confirmPassword: settingsConfirmPassword,
        },
      });
      if (settingsUpdate.alert) {
        return settingsUpdate;
      } else {
        setEdit(false);
        setSuccess(true);
        setMessage("Password updated successfully");
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Enter current and new password details",
      };
    }
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD2X,
        }}
        hidden={edit}
      >
        <Typography className="lower-case" variant="h1" noWrap={true}>
          * * * * * * * *
        </Typography>
      </Box>
      {context.state.user.provider === "internal" ? (
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
          {edit && (
            <Box className="box-default">
              <Box className="box-default">
                <Typography variant="h6" noWrap={true}>
                  Change Password
                </Typography>
                <Typography variant="subtitle1" noWrap={true}>
                  <em>Enter current and new password details</em>
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{ pt: configEntzy.APP_SPACING_LG }}
              >
                <InputForm
                  navigate={props.navigate}
                  fields={[
                    {
                      id: "settings-current-password",
                      type: "text-password",
                      label: "Enter your current password",
                      value: "",
                      maxLength: 50,
                      required: true,
                    },
                    {
                      id: "settings-new-password",
                      type: "text-password",
                      label: "Enter your new password",
                      value: "",
                      maxLength: 50,
                      required: true,
                    },
                    {
                      id: "settings-confirm-password",
                      type: "text-password",
                      label: "Confirm new password",
                      value: "",
                      maxLength: 50,
                      required: true,
                    },
                  ]}
                  submitText="Update Password"
                  callback={handleFormCallback}
                />
              </Box>
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              onClick={() => handleEdit(!edit)}
              text={edit ? "Cancel" : "Change Password"}
            />
          </Box>
          {success && message && (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
              }}
            >
              <ActionAlert severity="success" message={message} />
            </Box>
          )}
        </Box>
      ) : (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              disabled={true}
              text="Change Password"
            />
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <Typography variant="subtitle2">
              Password not editable here
            </Typography>
            <Typography variant="subtitle1">
              You are connected via external provider
            </Typography>
            <Typography
              variant="subtitle1"
              className="upper-case"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <TextTag size="lg" text={context.state.user.provider} />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SecurityPassword;
