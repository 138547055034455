// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage as iconLogo } from "@fortawesome/pro-duotone-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faCheck as iconActive } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconInactive } from "@fortawesome/pro-duotone-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";

// entzy components
import ActionLaunchpadLogo from "./ActionLaunchpadLogo";

function ActionLaunchpadRunnerBanner(props) {
  const section = "banner";
  const user = props.user;
  const editing = props.editing;
  const handleEdit = props.handleEdit;
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  return (
    <Box id={anchorTop} className="box-default">
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Image Banner
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Set a background banner logo</em>
        </Typography>
      </Box>
      {editing ? (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          {user.connected ? (
            <ActionLaunchpadLogo {...props} />
          ) : (
            <Box
              className="box-default shadow-light"
              sx={{
                pt: configEntzy.APP_SPACING_XL,
                pb: configEntzy.APP_SPACING_XL,
              }}
            >
              <TextTag text="Connect to manage logo" />
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
            }}
          >
            <ActionButton
              color="bright"
              size="small"
              text="Exit Banner Logo Editor"
              onClick={() => handleEdit()}
              endIcon={<FontAwesomeIcon icon={iconClose} fixedWidth />}
            />
          </Box>
        </Box>
      ) : (
        <Box
          className="box-default action-pointer"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
          onClick={() => handleEdit("background")}
        >
          {/* EDIT ICON */}
          {/* ----------------------------------------- */}
          <Box className="box-default zero-height text-right">
            <Typography variant="h1" color="primary">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="#ccc"
                  transform="grow-15 down-14"
                />
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="white"
                  transform="grow-14 down-14"
                />
                <FontAwesomeIcon icon={iconEdit} transform="down-14" />
              </span>
            </Typography>
          </Box>
          {/* ----------------------------------------- */}
          <Box
            className="box-default shadow-light text-left"
            sx={{
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconLogo} />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>Eventuator Logo</span>
              </Typography>
            </Box>
            <Box className="box-default">
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <em>
                  {eventContext.state.event.data.Logo !==
                  configEntzy.EMPTY_STRING_SHORTSET ? (
                    <span>
                      <span>Banner Logo is Active&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={iconActive} color="green" />
                    </span>
                  ) : (
                    <span>
                      <span>Banner Logo Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={iconInactive} color="red" />
                    </span>
                  )}
                </em>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerBanner;
