// react core
// import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus as iconInvite } from "@fortawesome/pro-thin-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
// import InputForm from "components/input/InputForm";
// import { MainContext } from "components/main/MainContext";
// import { EventContext } from "pages/events/EventContext";
import ActionMembersInvites from "./ActionMembersInvites";

function ActionMembersRider(props) {
  const user = props.user;
  // const mainContext = useContext(MainContext);
  // const eventContext = useContext(EventContext);

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Typography variant="h6">
          <FontAwesomeIcon icon={iconInvite} size="2x" fixedWidth />
        </Typography>
      </Box>
      <Box className="box-default">
        <ActionMembersInvites
          user={user}
          runnersView={false}
          ridersView={true}
          queueView={false}
        />
      </Box>
    </Box>
  );
}

export default ActionMembersRider;
