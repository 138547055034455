// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCheck as iconTerms } from "@fortawesome/pro-thin-svg-icons";
import { faFileLock as iconPrivacy } from "@fortawesome/pro-thin-svg-icons";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";

// legal pages
import LegalPrivacy from "./LegalPrivacy";
import LegalTerms from "./LegalTerms";

function LegalIndex(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Container maxWidth="lg">
        <Box className="box-default">
          {props.page === "/legal/privacy" ? (
            <LegalPrivacy {...props} />
          ) : props.page === "/legal/terms" ? (
            <LegalTerms {...props} />
          ) : (
            <Box
              className="box-default"
              sx={{ pt: configEntzy.APP_SPACING_XL2X }}
            >
              <Box
                className="box-default half-width action-pointer"
                onClick={() => props.navigate("/legal/terms")}
              >
                <Typography variant="h2">
                  <FontAwesomeIcon icon={iconTerms} size="2x" fixedWidth />
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ mt: configEntzy.APP_SPACING_LG }}
                >
                  TERMS
                </Typography>
              </Box>
              <Box
                className="box-default half-width action-pointer"
                onClick={() => props.navigate("/legal/privacy")}
              >
                <Typography variant="h2">
                  <FontAwesomeIcon icon={iconPrivacy} size="2x" fixedWidth />
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ mt: configEntzy.APP_SPACING_LG }}
                >
                  PRIVACY
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default LegalIndex;
