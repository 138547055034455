// react core
import { useState } from "react";

// material design
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";

// entzy config and models
import configEntzy from "components/config/ConfigEntzy";
import { countriesList } from "models/Country";
import { currenciesList } from "models/Currency";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBills as iconTicketCurrency } from "@fortawesome/pro-solid-svg-icons";

// content from https://flagpedia.net/
const flags = require.context("assets/flags/", true);

export function CountrySelect(props) {
  const allCountries = countriesList();
  return (
    <Autocomplete
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      variant={props.variant}
      disabled={props.disabled}
      options={allCountries}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            borderTop: option.code.short === "af" ? "1px dashed #ccc" : "none",
            "& > img": { mr: 2, flexShrink: 0 },
          }}
          {...props}
          key={option.code.long}
        >
          <img
            loading="lazy"
            width="20"
            src={flags(`./${option.code.short}.png`)}
            alt=""
          />
          {option.name} ({option.code.short.toUpperCase()})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disables autocomplete and autofill
            form: {
              autocomplete: "off",
            },
          }}
          sx={{ backgroundColor: "white" }}
          fullWidth
        />
      )}
    />
  );
}

export function CurrencySelect(props) {
  const acceptedCurrencies = currenciesList(true);
  return (
    <Autocomplete
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      variant={props.variant}
      disabled={props.disabled}
      options={acceptedCurrencies}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            borderTop: option.code.long === "aed" ? "1px dashed #ccc" : "none",
          }}
          {...props}
          key={option.code.long}
        >
          <FontAwesomeIcon
            icon={iconTicketCurrency}
            transform="shrink-2"
            fixedWidth
          />
          &nbsp;&nbsp;{option.name}&nbsp;({option.code.long.toUpperCase()})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disables autocomplete and autofill
            form: {
              autocomplete: "off",
            },
          }}
          sx={{ backgroundColor: "white" }}
          fullWidth
        />
      )}
    />
  );
}

export function BasicSelect(props) {
  const value = props.value
    ? props.options.findIndex((item) => item.id === props.value.id)
    : "";
  return (
    <NativeSelect
      id={props.id}
      value={value}
      label={props.label}
      onChange={(e) => {
        props.onChange(
          e,
          props.options[e.target.value],
          "selectOption",
          e.target.value
        );
      }}
      variant={props.variant}
      disabled={props.disabled}
      disableUnderline={props.disableUnderline}
      inputProps={{
        style: {
          padding: "5px",
        },
      }}
      sx={{
        p: configEntzy.APP_SPACING_MD,
      }}
      fullWidth={true}
    >
      {props.selectPrompt && <option value={-1}>{props.label}</option>}
      {props.options.map((option, index) => (
        <option key={option.value} value={index}>
          {option.display}
        </option>
      ))}
    </NativeSelect>
  );
}

export function StandardSelect(props) {
  return (
    <Autocomplete
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      variant={props.variant}
      options={props.options}
      autoHighlight={true}
      multiple={props.multiple}
      disableClearable={props.disableClearable}
      disabled={props.disabled}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          <FontAwesomeIcon icon={option.icon} transform="shrink-2" fixedWidth />
          &nbsp;&nbsp;{option.display}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disables autocomplete and autofill
            form: {
              autocomplete: "off",
            },
          }}
          fullWidth
        />
      )}
    />
  );
}

export function MultiSelect(props) {
  return (
    <Autocomplete
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      variant={props.variant}
      options={props.options}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      multiple={true}
      freeSolo={true}
      disabled={props.disabled}
      disableCloseOnSelect={true}
      // renderTags={(props, option) => (
      //   <Chip variant="outlined" label={option.name} />
      // )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          <FontAwesomeIcon icon={option.icon} transform="shrink-2" fixedWidth />
          &nbsp;&nbsp;{option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disables autocomplete and autofill
            form: {
              autocomplete: "off",
            },
          }}
          fullWidth
        />
      )}
    />
  );
}

export function ModalGroupedSelect(props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <Box className="box-default">
      <Button
        variant={props.variant ? props.variant : "contained"}
        type="button"
        color={props.color}
        size={props.size ? props.size : "large"}
        onClick={handleClickOpen}
        disabled={props.disabled}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        sx={{ fontSize: configEntzy.FONT_SCALE_MD }}
      >
        {props.text}
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box className="box-default">
            <FormControl fullWidth>
              <InputLabel htmlFor="date-select">{props.text}</InputLabel>
              <Select
                id="date-select"
                variant="outlined"
                // label={props.text}
                disabled={props.disabled}
                value={props.value ? props.value : ""}
                defaultValue={props.value ? props.value : ""}
                defaultOpen={true}
                onChange={(e) => {
                  props.onChange(e);
                  handleClose();
                }}
              >
                <MenuItem value="">
                  <ListItemText style={{ textAlign: "center" }}>
                    <em>Please Select</em>
                  </ListItemText>
                </MenuItem>
                {props.groupedOptions.map((group) => {
                  return [
                    <ListSubheader key={group.header}>
                      {group.header}
                    </ListSubheader>,
                    group.options.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemText style={{ textAlign: "center" }}>
                            {option.display}
                          </ListItemText>
                        </MenuItem>
                      );
                    }),
                    group.options.length === 0 && (
                      <MenuItem value="none" disabled>
                        <ListItemText style={{ textAlign: "center" }}>
                          <em>None</em>
                        </ListItemText>
                      </MenuItem>
                    ),
                  ];
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export function UnboxedGroupedSelect(props) {
  return (
    <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_MD }}>
      <Box
        className="box-default upper-case"
        hidden={!props.value || props.value === ""}
      >
        <ActionBoxButton
          size="small"
          fullWidth={true}
          onClick={() =>
            props.onChange({
              target: {
                id: "",
                name: "",
                value: "",
                display: "",
              },
            })
          }
          text="Deselect"
          color="black"
          bgColor={
            !props.value || props.value === "" ? "tertiary.main" : "white"
          }
          selected={!props.value || props.value === ""}
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          topIcon={props.topIcon}
        />
      </Box>
      {props.groupedOptions.map((group) => {
        return [
          <Box
            key={group.header}
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Divider>
              <Chip
                label={group.header + " (" + group.options.length + ")"}
                sx={{ fontSize: configEntzy.FONT_SCALE_SM }}
              />
            </Divider>
          </Box>,
          group.options.map((option) => {
            return (
              <Box
                key={group.header + option.value}
                className="box-default"
                sx={{ mt: configEntzy.APP_SPACING_MD }}
              >
                <ActionBoxButton
                  size="small"
                  fullWidth={true}
                  onClick={() =>
                    props.onChange({
                      target: {
                        id: props.id,
                        name: props.name,
                        value: option.value,
                        display: option.display,
                      },
                    })
                  }
                  text={option.display}
                  color="black"
                  bgColor={
                    props.value === option.value ? "tertiary.main" : "white"
                  }
                  selected={props.value === option.value}
                  startIcon={props.startIcon}
                  endIcon={props.endIcon}
                  topIcon={props.topIcon}
                />
              </Box>
            );
          }),
          group.options.length === 0 && (
            <Box
              key={group.header + "empty"}
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_MD }}
            >
              <ActionBoxButton
                size="small"
                fullWidth={true}
                text="No Available Options"
                startIcon={props.startIcon}
                endIcon={props.endIcon}
                topIcon={props.topIcon}
                disabled={true}
              />
            </Box>
          ),
        ];
      })}
    </Box>
  );
}
