import { useState, useEffect } from "react";

// import banner image from assets
import imageFallback from "assets/banners/crowd-t50.png";
// import imageFallback from "assets/tiles/white-t10.png";

export const useMediaImage = (params) => {
  const [imageInitiated, setImageInitiated] = useState(false);
  const [imageData, setImageData] = useState({
    ready: false,
  });
  useEffect(() => {
    let didCancel = false;
    const createInteraction = async (activeInteraction) => {
      setImageInitiated(true);
      // resuse user details if cached
      if (!didCancel) {
        if (activeInteraction) {
          setImageData(activeInteraction);
        } else {
          const imageInteraction =
            await params.context.prepareInteractWithImage({
              id: params.image.id,
              key: params.image.key,
              user: params.user,
            });
          if (imageInteraction.alert) {
            setImageData({
              ready: true,
              hydrated: false,
              error: true,
              fallback: imageFallback,
            });
          } else {
            imageInteraction.data.ready = true;
            imageInteraction.data.fallback = imageFallback;
            setImageData(imageInteraction.data);
            params.context.interactWithImage(imageInteraction);
          }
        }
      }
    };
    if (params) {
      const interaction = params.context.state.interaction.images.filter(
        (obj) => obj.id === params.image.id
      );
      const activeInteraction = interaction.length ? interaction[0] : null;
      if (!imageInitiated) {
        createInteraction(activeInteraction);
      } else {
        if (activeInteraction) {
          if (activeInteraction.key !== params.image.key) {
            createInteraction(null);
          } else {
            setImageData(activeInteraction);
          }
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [params, imageInitiated]);
  return imageData;
};

// USAGE
// const image = useMediaImage(user, context, "event-logo");
