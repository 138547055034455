// react core
import { useState, useContext } from "react";

// auth code entry
import AuthCode from "react-auth-code-input";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { ActionButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";
import {
  ActionAlert,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { SettingsContext } from "pages/settings/SettingsContext";

function SecurityEmail(props) {
  const [edit, setEdit] = useState(false);
  const [flow, setFlow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [authCode, setAuthCode] = useState();
  const context = useContext(SettingsContext);

  const handleEdit = async (value) => {
    if (!value) {
      setAlert(false);
      setFlow(0);
    } else {
      setSuccess(false);
    }
    setEdit(value);
  };

  const handleCodeChange = (code) => {
    setAuthCode(code);
  };

  const handleFormCallback0 = async (data) => {
    const settingsEmail = data.filter((obj) => obj.id === "settings-email")[0]
      .value;
    if (settingsEmail && context.state.toolbox.isEmail(settingsEmail)) {
      setEmail(settingsEmail.toLowerCase());
      setFlow(1);
      return { alert: false };
    } else {
      return {
        alert: true,
        message: "A valid email address is required",
      };
    }
  };

  const handleFormCallback1 = async () => {
    setAlert(false);
    setLoading(true);
    const settingsUpdate = await context.prepareUpdateSettings({
      key: "Email",
      value: email,
    });
    setLoading(false);
    if (settingsUpdate.alert) {
      setAlert(true);
      setMessage(settingsUpdate.message);
    } else {
      setFlow(2);
    }
  };

  const handleFormCallback2 = async (resend) => {
    setAlert(false);
    setLoading(true);
    const settingsUpdate = await context.prepareUpdateSettings({
      key: resend ? "EmailResend" : "EmailVerify",
      value: email,
      verify: authCode,
    });
    setLoading(false);
    if (settingsUpdate.alert) {
      setAlert(true);
      setMessage(settingsUpdate.message);
    } else {
      if (resend) {
        setFlow(2);
      } else {
        context.updateSettings(settingsUpdate);
        setMessage("Email address updated successfully");
        setSuccess(true);
        handleEdit(false);
      }
    }
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD2X,
        }}
        hidden={edit}
      >
        <Typography className="lower-case" variant="h5" noWrap={true}>
          {context.state.user.email}
        </Typography>
      </Box>
      {context.state.user.provider === "internal" ? (
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
          {edit && (
            <Box className="box-default">
              {loading ? (
                <Box className="box-default">
                  <ActionLoader />
                </Box>
              ) : flow === 0 ? (
                <Box className="box-default">
                  <Box className="box-default">
                    <Typography variant="h6" noWrap={true}>
                      Change Email
                    </Typography>
                    <Typography variant="subtitle1" noWrap={true}>
                      <em>Enter new address to start the process</em>
                    </Typography>
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <InputForm
                      navigate={props.navigate}
                      fields={[
                        {
                          id: "settings-email",
                          type: "text-standard",
                          label: "Enter your new email address",
                          value: context.state.user.email.toLowerCase(),
                          maxLength: 50,
                          focus: true,
                          required: true,
                        },
                      ]}
                      submitText="Initiate Update"
                      callback={handleFormCallback0}
                    />
                  </Box>
                </Box>
              ) : flow === 1 ? (
                <Box className="box-default">
                  <Box className="box-default">
                    <Typography variant="h6" noWrap={true}>
                      Check and confirm new address
                    </Typography>
                    <Typography variant="subtitle1" noWrap={true}>
                      <em>We are about to send you a verification code</em>
                    </Typography>
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <TextTag size="lg" text={email} lower={true} />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_LG,
                      pb: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <ActionButton
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleFormCallback1}
                      text="Send Verification Code"
                    />
                  </Box>
                </Box>
              ) : (
                flow === 2 && (
                  <Box className="box-default">
                    <Box className="box-default">
                      <Typography variant="h6" noWrap={true}>
                        Verification code sent
                      </Typography>
                      <Typography variant="subtitle1" noWrap={true}>
                        <em>Enter the code below to continue</em>
                      </Typography>
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <AuthCode
                        length={6}
                        containerClassName="auth-container"
                        inputClassName="auth-input"
                        allowedCharacters="numeric"
                        onChange={handleCodeChange}
                      />
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <TextTag size="lg" text={email} lower={true} />
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                        pb: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <Box
                        className="box-default half-width text-right"
                        sx={{
                          pr: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <ActionButton
                          variant="contained"
                          color="bright"
                          size="medium"
                          onClick={() => handleFormCallback2(true)}
                          text="Resend Code"
                        />
                      </Box>
                      <Box
                        className="box-default half-width text-left"
                        sx={{
                          pl: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <ActionButton
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => handleFormCallback2(false)}
                          text="Confirm Code"
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
            }}
            hidden={loading}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              onClick={() => handleEdit(!edit)}
              text={edit ? "Cancel" : "Change Email"}
            />
          </Box>
          {(alert || success) && message && (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
              }}
            >
              <ActionAlert
                severity={success ? "success" : "error"}
                message={message}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              disabled={true}
              text="Edit Email"
            />
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <Typography variant="subtitle2">
              Email address not editable here
            </Typography>
            <Typography variant="subtitle1">
              You are connected via external provider
            </Typography>
            <Typography
              variant="subtitle1"
              className="upper-case"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <TextTag size="lg" text={context.state.user.provider} />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SecurityEmail;
