// react core
import { useContext, useState, useEffect, useCallback } from "react";

// dayjs date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { MessagePost } from "components/event/lists/ListMessaging";
import { FallbackMessage } from "components/event/lists/ListFallbacks";
import { MoreButton } from "components/utils/common/CommonButtons";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { PageLoader } from "components/utils/common/CommonLoaders";

function MemberMessaging(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const memberName = props.memberName;
  const roomArea = props.roomArea;
  const roomName = props.roomName;
  const roomId = props.roomId;
  const [hydrated, setHydrated] = useState(false);
  const [markedRead, setMarkedRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageLoadingId, setMessageLoadingId] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [removing, setRemoving] = useState(null);

  const handlePullMemberMessages = async (more, reload) => {
    if (more) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    let response;
    response = await mainContext.preparePullMemberMessages({
      RoomArea: roomArea,
      RoomName: roomName,
      // DateFragment: "2000",
      Category: null,
      nextToken: mainContext.state.memberMessaging[roomId]
        ? mainContext.state.memberMessaging[roomId].data.nextToken
        : null,
      more,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.pullMemberMessages({
        roomId: roomId,
        data: response.data,
      });
    }
    if (!more) {
      response = await mainContext.preparePullMemberChatSettings({
        RoomArea: roomArea,
        RoomName: roomName,
      });
      if (response.alert) {
        mainContext.updateAlert(response);
      } else {
        mainContext.pullMemberChatSettings({
          roomId: roomId,
          data: response.data,
        });
      }
    }
    if (more) {
      setLoadingMore(false);
    } else {
      setLoading(false);
    }
    if (reload) {
      setReloading(false);
    }
  };
  const cbHandlePullMemberMessages = useCallback(handlePullMemberMessages, [
    mainContext,
    roomArea,
    roomName,
    roomId,
  ]);

  const handleRemoveMemberMessage = async (message) => {
    setMessageLoadingId(message.PostId);
    const response = await mainContext.prepareRemoveMemberMessage({
      RoomArea: roomArea,
      RoomName: roomName,
      PostId: message.PostId,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.removeMemberMessage({
        roomId: roomId,
        data: response.data,
      });
    }
    setMessageLoadingId(null);
    return true;
  };
  const handleRemovingMemberMessage = async (id) => {
    setRemoving(id);
  };

  const markAllMemberMessagesRead = async (unreadNotifications) => {
    const promises = unreadNotifications.map(async (notification) => {
      const response = await mainContext.prepareUpdateNotification({
        notification: notification,
        action: "markread",
      });
      if (!response.alert) {
        mainContext.updateNotification(response);
      }
    });
    await Promise.all(promises);
  };
  const cbMarkAllMemberMessagesRead = useCallback(markAllMemberMessagesRead, [
    mainContext,
  ]);

  // add effect to pull messages on page load if not already hydrated and on a regular basis
  useEffect(() => {
    if (!hydrated) {
      setHydrated(true);
      cbHandlePullMemberMessages(false, false);
    } else {
      if (mainContext.state.memberMessaging[roomId]) {
        const now = dayjs();
        const lastUpdated = now.diff(
          mainContext.state.memberMessaging[roomId].updated,
          "minutes"
        );
        if (!reloading && lastUpdated > configEntzy.CACHE_EXPIRY_MINUTES) {
          setReloading(true);
          cbHandlePullMemberMessages(false, true);
        }
      }
    }
  }, [
    hydrated,
    reloading,
    roomId,
    mainContext.state.memberMessaging,
    cbHandlePullMemberMessages,
  ]);

  // add effect to mark all messages read if any pending
  useEffect(() => {
    const unreadNotifications =
      mainContext.state.notifications.data.messages.filter(
        (obj) => obj.MessageUrl.startsWith("@" + memberName) && !obj.MessageRead
      );
    if (!markedRead && unreadNotifications.length > 0) {
      const timeout = setTimeout(() => {
        setMarkedRead(true);
        cbMarkAllMemberMessagesRead(unreadNotifications);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    markedRead,
    mainContext.state.notifications.data.messages,
    memberName,
    cbMarkAllMemberMessagesRead,
  ]);

  return (
    <Box className="box-default">
      {loading ? (
        <Box className="box-default">
          <PageLoader />
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
        >
          {user.connected ? (
            <Box className="box-default">
              {mainContext.state.memberMessaging[roomId] &&
              mainContext.state.memberMessaging[roomId].data.combined.length >
                0 ? (
                <Box className="box-default">
                  {mainContext.state.memberMessaging[roomId].data.combined.map(
                    (message) => {
                      return (
                        <Box
                          key={message.PostId}
                          className="box-default bg-black-t50"
                          sx={{
                            p: configEntzy.APP_SPACING_SM,
                            mb: configEntzy.APP_SPACING_MD,
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                          }}
                        >
                          <MessagePost
                            message={message}
                            user={user}
                            memberMessaging={true}
                            memberViewer={true}
                            loadingId={messageLoadingId}
                            removing={removing}
                            handleRemoving={handleRemovingMemberMessage}
                            handleRemoveMessage={handleRemoveMemberMessage}
                          />
                        </Box>
                      );
                    }
                  )}
                </Box>
              ) : (
                <FallbackMessage fallback="No direct messages" />
              )}
            </Box>
          ) : (
            <FallbackMessage fallback="Connect to access messaging" />
          )}
        </Box>
      )}

      {mainContext.state.memberMessaging[roomId] &&
        mainContext.state.memberMessaging[roomId].data.nextToken && (
          <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_LG }}>
            {user.connected ? (
              <MoreButton
                variant="contained"
                size="large"
                type="button"
                color="bright"
                text="More"
                loading={loadingMore}
                onClick={() => handlePullMemberMessages(true, false)}
              />
            ) : (
              <MoreButton
                variant="contained"
                size="large"
                type="button"
                color="bright"
                text="Connect for more messages..."
                disabled={true}
              />
            )}
          </Box>
        )}
    </Box>
  );
}

export default MemberMessaging;
