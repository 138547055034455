// entzy components
import configEntzy from "components/config/ConfigEntzy";

// material design
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as iconCancel } from "@fortawesome/pro-solid-svg-icons";
import { faCheck as iconConfirm } from "@fortawesome/pro-solid-svg-icons";
import { faMousePointer as iconTap } from "@fortawesome/pro-solid-svg-icons";
import { faPlug as iconConnecting } from "@fortawesome/pro-duotone-svg-icons";
import { faCaretCircleDown as iconPullDown } from "@fortawesome/pro-duotone-svg-icons";

export function AppLoader(props) {
  return (
    <Box className="box-default full-height">
      <Box
        className="box-inline"
        sx={{
          pt: configEntzy.APP_SPACING_HL2X,
          pb: configEntzy.APP_SPACING_HL2X,
        }}
      >
        <Box
          className="box-inline bg-black-t50"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <LinearProgress
            color="primary"
            sx={{
              height: configEntzy.APP_SPACING_MD,
              width: configEntzy.AVATAR_SIZE_LG,
              borderRadius: configEntzy.BORDER_SIZE_LG,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function PageLoader(props) {
  return (
    <Box className="box-default">
      <Box
        className="box-inline"
        sx={{
          pt: configEntzy.APP_SPACING_HL2X,
          pb: configEntzy.APP_SPACING_HL2X,
        }}
      >
        <Box
          className="box-inline bg-black-t50"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <LinearProgress
            color="primary"
            sx={{
              height: configEntzy.APP_SPACING_MD,
              width: configEntzy.AVATAR_SIZE_LG,
              borderRadius: configEntzy.BORDER_SIZE_LG,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function ConnectingLoader(props) {
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_HL2X,
        pb: configEntzy.APP_SPACING_HL2X,
        color: "secondary.main",
      }}
    >
      <FontAwesomeIcon
        icon={iconConnecting}
        size="8x"
        className="fa-beat"
        transform="rotate-90"
        style={{
          "--fa-animation-duration": "1.5s",
          "--fa-beat-scale": 1.1,
        }}
      />
    </Box>
  );
}

export function FormLoader(props) {
  return (
    <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_XL }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}

export function ButtonLoader(props) {
  return (
    <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_XS }}>
      <CircularProgress size={30} color="bright" thickness={4} />
    </Box>
  );
}

export function ActionLoader(props) {
  return (
    <Box className="box-default">
      <Box
        className="box-inline bg-black-t50"
        sx={{
          // height: configEntzy.APP_SPACING_SM,
          // width:
          //   props.size === "sm"
          //     ? configEntzy.AVATAR_SIZE_SM
          //     : props.size === "md"
          //     ? configEntzy.AVATAR_SIZE_MD
          //     : configEntzy.AVATAR_SIZE_LG,
          p: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <LinearProgress
          color={props.color ? props.color : "primary"}
          sx={{
            height: configEntzy.APP_SPACING_MD,
            width:
              props.size === "sm"
                ? configEntzy.AVATAR_SIZE_SM
                : props.size === "md"
                ? configEntzy.AVATAR_SIZE_MD
                : configEntzy.AVATAR_SIZE_LG,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
        />
      </Box>
    </Box>
  );
}

export function PullLoader(props) {
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD2X,
        pb: configEntzy.APP_SPACING_MD2X,
      }}
    >
      <Box
        className="box-inline bg-black-t50"
        sx={{
          p: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <LinearProgress
          color={props.color ? props.color : "primary"}
          sx={{
            height: configEntzy.APP_SPACING_MD,
            width:
              props.size === "sm"
                ? configEntzy.AVATAR_SIZE_SM
                : props.size === "md"
                ? configEntzy.AVATAR_SIZE_MD
                : configEntzy.AVATAR_SIZE_LG,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
        />
      </Box>
    </Box>
  );
}
export function PullPrompt(props) {
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD2X,
        pb: configEntzy.APP_SPACING_MD2X,
      }}
    >
      <Typography variant="subtitle2" className="upper-case" color="black">
        <FontAwesomeIcon icon={iconPullDown} />
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Pull to Refresh</span>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <FontAwesomeIcon icon={iconPullDown} />
      </Typography>
    </Box>
  );
}

export function ActionAlert(props) {
  const headers = {
    success: props.title ? props.title : "Done",
    error: props.title ? props.title : "Heads Up",
    warning: props.title ? props.title : "Advisory",
    info: props.title ? props.title : "Info",
  };
  return (
    <Box
      className="box-default shadow-default text-left"
      sx={{
        border: "1px solid #333",
        borderRadius: configEntzy.BORDER_SIZE_LG,
        overflow: "hidden",
      }}
      onClick={props.onClick}
    >
      <Alert
        severity={props.severity}
        sx={{
          pt: configEntzy.APP_SPACING_SM,
          pb: configEntzy.APP_SPACING_SM,
          backgroundColor: props.severity === "info" ? "dusk.main" : undefined,
          color: props.severity === "info" ? "white" : undefined,
        }}
        onClose={props.onClose}
        closeText={props.onClose ? "X" : null}
      >
        {headers[props.severity] && (
          <AlertTitle className="upper-case">
            <span
              style={{
                fontWeight: "900",
              }}
            >
              {headers[props.severity]}&nbsp;&nbsp;&nbsp;&nbsp;
              {props.onClick && (
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={iconTap}
                    style={{ color: "gray" }}
                    transform="grow-2"
                    fixedWidth
                  />
                </span>
              )}
            </span>
          </AlertTitle>
        )}
        <span style={{ fontWeight: "600" }}>{props.message}</span>
      </Alert>
    </Box>
  );
}

export function ActionCheck(props) {
  return (
    <Dialog
      open={props.dialog}
      onClose={props.cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ textAlign: "left" }}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent id="alert-dialog-description" dividers>
        <Typography variant="subtitle2">{props.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={(e) => props.cancel(e, false)}>
          Cancel&nbsp;&nbsp;
          <FontAwesomeIcon icon={iconCancel} fixedWidth />
        </Button>
        <Button
          color="success"
          onClick={(e) => props.confirm(e, true)}
          autoFocus
        >
          Confirm&nbsp;&nbsp;
          <FontAwesomeIcon icon={iconConfirm} fixedWidth />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
