// react core
import { useContext, useState } from "react";

// linkify highlighting
import Linkify from "react-linkify";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersLine as iconPrivate } from "@fortawesome/pro-thin-svg-icons";
import { faCheckSquare as iconCheckedIn } from "@fortawesome/pro-solid-svg-icons";

// entzy context and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import { ActionLoader } from "components/utils/common/CommonLoaders";

function WidgetWelcomeSplash(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [loading, setLoading] = useState(false);

  const handleRideRequest = async () => {
    setLoading(true);
    const removeRequest = eventContext.state.event.accessQueued ? true : false;
    const response = await eventContext.prepareRequestJoinEvent({
      EventId: eventContext.state.event.data.EventId,
      remove: removeRequest,
    });
    if (response.alert) {
      setLoading(false);
      eventContext.requestJoinEvent(response);
      mainContext.updateAlert(response);
      return;
    }
    eventContext.requestJoinEvent(response);
    if (!removeRequest) {
      mainContext.addEvent(
        eventContext.state.event.data,
        eventContext.state.event.owner,
        eventContext.state.event.manager
      );
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        className="box-default bg-black-t50"
        sx={{
          p: configEntzy.APP_SPACING_LG,
          borderRadius: configEntzy.BORDER_SIZE_LG,
          color: "white",
        }}
      >
        <Box className="box-default">
          <Typography variant="subtitle2">
            <FontAwesomeIcon icon={iconPrivate} size="4x" />
          </Typography>
        </Box>
        {!user.connected ? (
          <Box className="box-default">
            <Box className="box-default">
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                Private Entry Check In
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                Connect to Continue
              </Typography>
            </Box>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              <ActionButton
                variant="contained"
                color="primary"
                text={props.drawerConnect ? "Close X" : "Connect"}
                onClick={() => {
                  props.drawerConnectToggle();
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box className="box-default">
            {eventContext.state.event.accessQueued ? (
              <Box className="box-default">
                <Typography
                  variant="h6"
                  color="success.main"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  <FontAwesomeIcon icon={iconCheckedIn} fixedWidth />
                  <span>&nbsp;&nbsp;</span>
                  You are Checked In
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  Great! You are in the Run Team queue.
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  You'll be notified when entry is ready.
                </Typography>
                <Typography variant="subtitle1">
                  The eventuator is pinned to your rides.
                </Typography>
              </Box>
            ) : (
              <Box className="box-default">
                <Typography
                  variant="h6"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  Private Entry Check In
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  To join this eventuator notify
                </Typography>
                <Typography variant="subtitle1">
                  the Run Team that you've arrived
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  @{user.name}
                </Typography>
              </Box>
            )}
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              {loading ? (
                <Box className="box-default">
                  <ActionLoader bgColor="orange" />
                </Box>
              ) : (
                <Box className="box-default">
                  {eventContext.state.event.accessQueued && (
                    <Box
                      className="box-default"
                      sx={{ pb: configEntzy.APP_SPACING_HL }}
                    >
                      <ActionButton
                        variant="contained"
                        color="primary"
                        text="To my Eventuators"
                        onClick={() =>
                          eventContext.exitEvent({ mainContext, eventContext })
                        }
                      />
                    </Box>
                  )}
                  <Box className="box-default">
                    <ActionButton
                      variant="contained"
                      color={
                        eventContext.state.event.accessQueued
                          ? "danger"
                          : "primary"
                      }
                      text={
                        eventContext.state.event.accessQueued
                          ? "Leave Queue"
                          : "Send Ride Request"
                      }
                      onClick={handleRideRequest}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {eventContext.state.event.data.WelcomeDetails && (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <Typography variant="subtitle2" color="primary">
                  Message from the Run Team
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    whiteSpace: "pre-line",
                  }}
                >
                  <Linkify>
                    {eventContext.state.event.data.WelcomeDetails}
                  </Linkify>
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default WidgetWelcomeSplash;
