// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { faImage as iconImageFallback } from "@fortawesome/pro-thin-svg-icons";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";

// entzy components
import InputForm from "components/input/InputForm";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { serviceObjectImageState } from "services/storage/object";
import { timeout } from "models/Tools";

function ActionMessagingPost(props) {
  const user = props.user;
  const source = props.source;
  const inReplyTo = props.actionParams ? props.actionParams.inReplyTo : null;
  const inReplyUserId = props.actionParams
    ? props.actionParams.inReplyUserId
    : null;

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const inReplyParent = inReplyTo
    ? eventContext.state.messaging.rooms[
        eventContext.state.messaging.activeRoom
      ].data.combined.find((obj) => obj.PostId === inReplyTo)
    : null;
  const isAiRoom = eventContext.state.messaging.activeRoom.startsWith("ai");
  const isDeskRoom = eventContext.state.messaging.activeRoom === "desk";
  const deskUserId = isDeskRoom
    ? inReplyTo
      ? eventContext.state.event.manager
        ? inReplyUserId
        : user.identity
      : user.identity
    : null;
  const postRoomName = isDeskRoom
    ? eventContext.state.event.manager
      ? eventContext.state.messaging.activeRoom + ":" + deskUserId
      : eventContext.state.messaging.activeRoom
    : eventContext.state.messaging.activeRoom;
  const formFields = [
    {
      id: "message-text",
      type: "text-multiline",
      label: "Enter message",
      value: "",
      required: true,
      maxLength: 1000,
      disabled: !user.connected,
    },
  ];
  if (!isAiRoom) {
    formFields.push({
      id: "message-image",
      type: "image-picker",
      label: "Post with image (optional)",
      value: "",
      image: null,
      disabled: !user.connected,
    });
  }

  const handlePostActionCallback = async (data) => {
    const messageText = data.filter((obj) => obj.id === "message-text")[0]
      .value;
    const formHasImage = data.filter((obj) => obj.id === "message-image");
    const messageImage = formHasImage.length
      ? data.filter((obj) => obj.id === "message-image")[0].value
      : { key: null };
    // validate permissions
    if (source === "runner" && !eventContext.state.event.manager) {
      return {
        alert: true,
        message: "Event rider permissions required for this update",
      };
    }
    if (source === "rider" && eventContext.state.event.manager) {
      return {
        alert: true,
        message: "Event runner permissions required for this update",
      };
    }
    // validate message content
    const hasText = messageText && messageText.length;
    const hasImage =
      messageImage && messageImage.key && messageImage.key.length;
    if (!hasText) {
      return {
        alert: true,
        message: "Add some message text to post",
      };
    }
    if (hasImage) {
      let count = 0,
        ready = false;
      if (messageImage.key === configEntzy.EMPTY_STRING_SET) {
        // this is a remove image request
        messageImage.preload = null;
        ready = true;
      } else {
        // for new image first check all resized images are available
        do {
          await timeout(count * 1000);
          ready = await serviceObjectImageState(messageImage.key);
          count++;
        } while (count < 10 && !ready);
      }
      // proceed when ready
      if (!ready) {
        return {
          alert: true,
          message:
            "Image could not be processed. Give it another go or if this problem persists try another image size or format.",
        };
      }
    }
    // prepare and post message
    props.handleCloseAction();
    eventContext.setCallbackLoader(true);
    const messagePayload = await eventContext.preparePostMessage({
      EventId: eventContext.state.event.data.EventId,
      RoomName: postRoomName,
      ContentType: "message",
      ContentData: messageText,
      ContentImage: messageImage.key,
      ImagePreload: messageImage.preload,
      InReplyTo: inReplyTo,
    });
    eventContext.setCallbackLoader(false);
    if (messagePayload.alert) {
      // props.handleOpenAction();
      mainContext.updateAlert(messagePayload);
    } else {
      eventContext.postMessage(messagePayload.data);
    }
    return {
      alert: messagePayload.alert,
      message: messagePayload.message,
    };
  };

  return (
    <Box className="box-default">
      {inReplyParent && (
        <Box
          className="box-default bg-black-t75 text-left rounded"
          sx={{
            p: configEntzy.APP_SPACING_SM2X,
            mb: configEntzy.APP_SPACING_MD,
          }}
        >
          {/* <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_SM2X,
            }}
          >
            <Typography variant="body1" noWrap>
              <em>In Reply To:</em>
            </Typography>
          </Box> */}
          <Box
            className="box-default"
            sx={{
              borderLeft: "2px solid #888",
              pl: configEntzy.APP_SPACING_SM,
            }}
          >
            <Box className="box-default">
              <UserAvatar
                user={{ identity: inReplyParent.UserId }}
                size="sm"
                nameOnly={true}
                nameHideInfo={true}
              />
            </Box>
            <Box className="box-default">
              <Typography variant="body1" color="white" noWrap>
                &nbsp;&nbsp;{inReplyParent.ContentData}
                &nbsp;&nbsp;
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <InputForm
        user={user}
        navigate={props.navigate}
        fields={formFields}
        submitText="Post"
        fallbackImage={iconImageFallback}
        fallbackImageHidden={true}
        disabledSubmit={!user.connected}
        disabledTitle="Connect to continue"
        disabledText="Tap here to activate messaging"
        disabledClick={eventContext.toggleConnect}
        callback={handlePostActionCallback}
      />
    </Box>
  );
}

export default ActionMessagingPost;
