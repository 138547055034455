// extended dayjs for utc
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

// entzy models and services
import configEntzy from "components/config/ConfigEntzy";
import { serviceLogError } from "services/graphql/call";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff as iconActiveSwitch } from "@fortawesome/pro-duotone-svg-icons";
import { faDoNotEnter as iconPrivateSwitch } from "@fortawesome/pro-duotone-svg-icons";
import { faDotCircle as iconFallback } from "@fortawesome/pro-duotone-svg-icons";
import { faStopwatch as iconTimeMin } from "@fortawesome/pro-duotone-svg-icons";
import { faUserGroup as iconUserMin } from "@fortawesome/pro-duotone-svg-icons";
import { faUserCircle as iconUserGroup } from "@fortawesome/pro-duotone-svg-icons";
import { faUsersLine as iconUserMax } from "@fortawesome/pro-duotone-svg-icons";
import { faQrcode as iconTicketText } from "@fortawesome/pro-duotone-svg-icons";
import { faStopwatch as iconAge } from "@fortawesome/pro-regular-svg-icons";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const getAdormentIcon = function (icon) {
  let iconValue;
  switch (icon) {
    case "active-switch":
      iconValue = iconActiveSwitch;
      break;
    case "private-switch":
      iconValue = iconPrivateSwitch;
      break;
    case "ticket-text":
      iconValue = iconTicketText;
      break;
    case "time-min":
      iconValue = iconTimeMin;
      break;
    case "user-min":
      iconValue = iconUserMin;
      break;
    case "user-group":
      iconValue = iconUserGroup;
      break;
    case "user-max":
      iconValue = iconUserMax;
      break;
    default:
      iconValue = iconFallback;
      break;
  }
  return <FontAwesomeIcon icon={iconValue} fixedWidth />;
};

export const scrollSnapTop = function () {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const scrollIntoViewIfNeeded = function (target, snap) {
  // if (target.getBoundingClientRect().bottom > window.innerHeight) {
  //   target.scrollIntoView({
  //     behavior: snap ? "instant" : "smooth",
  //     block: "start",
  //   });
  // }
  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView({
      behavior: snap ? "instant" : "smooth",
      block: "start",
    });
  }
};

export async function copyToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const getDomainFromUrl = function (url) {
  let domain;
  try {
    if (!url.toLowerCase().startsWith("http")) {
      url = "https://" + url;
    }
    domain = new URL(url);
    domain = domain.hostname
      .toLowerCase()
      .replace("www.", "")
      .substring(url, 20);
  } catch (e) {
    domain = url.substring(url, 20);
  }
  return domain;
};

export const parseEntzyUrl = function (val) {
  try {
    let urlparts = ("/" + val).split("/");
    const eventUrl = urlparts[urlparts.length - 1].toLowerCase();
    return eventUrl;
  } catch (error) {
    return false;
  }
};

export const isInteger = function (val) {
  try {
    let int = parseInt(val);
    return Number.isInteger(int);
  } catch (error) {
    return false;
  }
};

export const isEmptyString = function (val) {
  return val.trim().length === 0;
};

export const isEmail = function (email) {
  // var re = /\S+@\S+\.\S+/;
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const isUrl = function (url) {
  if (url.indexOf(" ") !== -1 || url.indexOf(".") === -1) {
    return false;
  } else {
    return true;
  }
};

export const jsonTryParse = function (str, fallback) {
  try {
    return JSON.parse(str);
  } catch (error) {
    const logCapture = { str, error };
    serviceLogError("jsonTryParse", logCapture);
    return fallback ? fallback : [];
  }
};

// external link flags
export const getExternalTag = () => {
  return "external:";
};
export const isExternalLink = (location) => {
  return location.LinkTarget
    ? location.LinkTarget.startsWith(getExternalTag())
    : false;
};
export const getExternalLink = (location) => {
  return location.LinkTarget
    ? location.LinkTarget.replace(getExternalTag(), "")
    : "";
};

// javascript guid generator
// for use client side only until replaced server side
export const idGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

// id generators
export function getCombinedUserId(userId1, userId2) {
  const delimiter = configEntzy.DELIMITER_USERS;
  const sortedStrings = [userId1, userId2].sort();
  const combinedString = sortedStrings[0] + delimiter + sortedStrings[1];
  return combinedString;
}
export const getDefaultLargeInt = function () {
  return 100000000;
};

// dynamodb ttl generator
export function getDynamoDBTTL(days) {
  const currentTime = new Date().getTime();
  const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
  const expirationTime = currentTime + days * oneDay;
  return Math.floor(expirationTime / 1000); // TTL requires the timestamp to be in seconds
}

// currencies
export const getCurrencyDisplayAmount = (intValue) => {
  return (intValue / 100).toFixed(2);
};
export const getCurrencyIntAmount = (decValue) => {
  return Math.round(decValue * 100);
};

// age generator
export const getAge = (props) => {
  let ageValue = "";
  try {
    const currentTimestamp = dayjs.utc();
    const utcTimestamp = props.timestamp;
    const format = "YYYY-MM-DDTHH:mm:sssZ";
    const timestampObj = dayjs.utc(utcTimestamp, format);
    const diffInMins = currentTimestamp.diff(timestampObj, "minute");
    const diffInHours = currentTimestamp.diff(timestampObj, "hour");
    const diffInDays = currentTimestamp.diff(timestampObj, "day");
    const diffInWeeks = currentTimestamp.diff(timestampObj, "week");
    const diffInMonths = currentTimestamp.diff(timestampObj, "month");
    const diffInYears = currentTimestamp.diff(timestampObj, "year");

    if (diffInMins < 60) {
      ageValue = `${diffInMins}m`;
    } else if (diffInHours < 24) {
      ageValue = `${diffInHours}h`;
    } else if (diffInDays < 7) {
      ageValue = `${diffInDays}d`;
    } else if (diffInWeeks < 4) {
      ageValue = `${diffInWeeks}w`;
    } else if (diffInMonths < 12) {
      ageValue = `${diffInMonths}m`;
    } else {
      ageValue = `${diffInYears}y`;
    }
  } catch (error) {
    ageValue = "earlier";
  }
  return (
    <span>
      <FontAwesomeIcon icon={iconAge} />
      &nbsp;&nbsp;
      {ageValue}
    </span>
  );
};

export const timeout = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const closeAction = (
  mainContext,
  eventContext,
  closeTarget,
  menuSelectById
) => {
  const menuId =
    mainContext && mainContext.state.viewer.trail.sourcePage
      ? mainContext.state.viewer.trail.sourcePage
      : closeTarget
      ? closeTarget
      : "home";
  menuSelectById(menuId);
  if (mainContext) {
    mainContext.updateViewerTrail({
      memberTab: 0,
      launchpadTab: 0,
    });
    mainContext.selectMenu(menuId);
  }
};

// third party tools
export const googleConvertColorId = (colorId) => {
  let id, value;
  id = colorId ? colorId.toString() : "0";
  switch (id) {
    case "1":
      value = "#A4BDFC";
      break;
    case "2":
      value = "#7AE7BF";
      break;
    case "3":
      value = "#DBADFF";
      break;
    case "4":
      value = "#FF887C";
      break;
    case "5":
      value = "#FBD75B";
      break;
    case "6":
      value = "#FFB878";
      break;
    case "7":
      value = "#46D6DB";
      break;
    case "8":
      value = "#E1E1E1";
      break;
    case "9":
      value = "#5484ED";
      break;
    case "10":
      value = "#51B749";
      break;
    case "11":
      value = "#DC2127";
      break;
    default:
      value = "#000000";
      break;
  }
  return value;
};

// toolbox
const toolbox = function () {
  const tools = {};
  tools.getAdormentIcon = getAdormentIcon;
  tools.scrollSnapTop = scrollSnapTop;
  tools.scrollIntoViewIfNeeded = scrollIntoViewIfNeeded;
  tools.copyToClipboard = copyToClipboard;
  tools.getDomainFromUrl = getDomainFromUrl;
  tools.isInteger = isInteger;
  tools.isEmptyString = isEmptyString;
  tools.isEmail = isEmail;
  tools.isUrl = isUrl;
  tools.parseEntzyUrl = parseEntzyUrl;
  tools.jsonTryParse = jsonTryParse;
  tools.getExternalTag = getExternalTag;
  tools.isExternalLink = isExternalLink;
  tools.getExternalLink = getExternalLink;
  tools.idGenerator = idGenerator;
  tools.getDefaultLargeInt = getDefaultLargeInt;
  tools.getCombinedUserId = getCombinedUserId;
  tools.getDynamoDBTTL = getDynamoDBTTL;
  tools.getCurrencyDisplayAmount = getCurrencyDisplayAmount;
  tools.getCurrencyIntAmount = getCurrencyIntAmount;
  tools.getAge = getAge;
  tools.timeout = timeout;
  tools.closeAction = closeAction;
  tools.googleConvertColorId = googleConvertColorId;
  return tools;
};

export default toolbox;
