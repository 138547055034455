// react core
import { useState } from "react";

// rich text editor
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// entzy config
import configEntzy from "components/config/ConfigEntzy";

// material design
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconClose } from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle as iconHelpText } from "@fortawesome/pro-duotone-svg-icons";
import { faImage as iconImage } from "@fortawesome/pro-duotone-svg-icons";
import { faPlusCircle as iconAdd } from "@fortawesome/pro-solid-svg-icons";

// entzy components
import {
  CountrySelect,
  CurrencySelect,
  MultiSelect,
  BasicSelect,
  StandardSelect,
  ModalGroupedSelect,
} from "./InputSelectors";
import {
  StandardSlider,
  RangeStaticSlider,
  RangeShiftSlider,
} from "./InputSliders";
import { PaymentBasic, PaymentMethod } from "./InputPayments";
import { DatePicker, MemberPickerNumberRocker } from "./InputPickers";
import { serviceObjectUpload } from "services/storage/object";
import { serviceLogError } from "services/graphql/call";
import {
  ActionAlert,
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { ActionButton } from "components/utils/common/CommonButtons";

// constants
const constants = {
  files: {
    MAX_SIZE: 10000000,
  },
  alerts: {
    FILE_SELECT: "Please select a valid image file",
    FILE_SIZE: "Please select a file less than 10mb in size",
    FILE_CATCH:
      "Unable to process the provided image. Give it another go or try another format.",
    SUBMIT_CATCH:
      "Unable complete this action at the moment. Double check details and give it another go.",
  },
};

function InputForm(props) {
  const user = props.user;
  const userReload = props.userReload;
  const navigate = props.navigate;
  const page = props.page;
  const redirectPage = props.redirectPage;
  const searchParams = props.searchParams;
  const payment = props.payment;
  const fallbackImage = props.fallbackImage;
  const fallbackImageHidden = props.fallbackImageHidden;
  const openImagePicker = props.openImagePicker;
  const helpText = props.helpText;
  const submitText = props.submitText;
  const submitType = props.submitType;

  const [formFields, setFormFields] = useState(props.fields);
  const [submitHidden, setSubmitHidden] = useState(
    ["flow", "auto", "focus"].includes(submitType) ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [fileAlert, setFileAlert] = useState(false);
  const [fileImage, setFileImage] = useState(null);
  const [fileMessage, setFileMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmation] = useState(props.confirmation);
  const [footNotes] = useState(props.footNotes);
  const [hasChanges, setHasChanges] = useState(false);
  const [showImagePicker, setShowImagePicker] = useState(
    openImagePicker ? true : false
  );

  const [editorState, setEditorState] = useState();

  // component functions
  const getParamValue = (value) => {
    return value;
  };
  const getEditorState = (initialData) => {
    if (editorState) {
      return editorState;
    } else {
      let contentBlocks, contentState, initialEditorState;
      try {
        initialEditorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(initialData))
        );
      } catch (error) {
        try {
          contentBlocks = convertFromHTML(initialData);
          contentState = ContentState.createFromBlockArray(contentBlocks);
          initialEditorState = EditorState.createWithContent(contentState);
        } catch (error) {
          try {
            contentState = ContentState.createFromText(initialData);
            initialEditorState = EditorState.createWithContent(contentState);
          } catch (error) {
            initialEditorState = EditorState.createEmpty();
          }
        }
      }
      setEditorState(initialEditorState);
      return initialEditorState;
    }
  };

  const startChange = (params) => {
    setAlert(false);
    setFileAlert(false);
    setSuccess(false);
    setLoading(params && params.loader ? params.loader : false);
    setHasChanges(true);
  };
  const endChange = () => {
    setAlert(false);
    setFileAlert(false);
    setSuccess(false);
    setLoading(false);
    setHasChanges(false);
  };

  const handleLoader = (state) => {
    startChange({ loader: state });
  };

  const handleSubmitHidden = (state) => {
    setSubmitHidden(state);
  };

  const handleShowImagePicker = () => {
    setShowImagePicker(!showImagePicker);
  };

  const handleTextFieldChange = async (event) => {
    startChange();
    const newFields = formFields.map((field) => {
      if (event.target.id === field.id) {
        if (field.numeric) {
          let newValue = event.target.value.replace(/[^0-9.]/gi, "");
          // if (field.minValue !== undefined && newValue < field.minValue) {
          //   newValue = field.minValue;
          // }
          if (field.maxValue !== undefined && newValue > field.maxValue) {
            newValue = field.maxValue;
          }
          return { ...field, value: newValue };
        } else if (field.alphanumeric) {
          return {
            ...field,
            value: event.target.value
              .replace(/\s+/gi, "_")
              .replace(/[^0-9A-Z_]/gi, ""),
          };
        } else if (field.email) {
          return {
            ...field,
            value: event.target.value.replace(/\s/gi, ""),
          };
        } else {
          return { ...field, value: event.target.value };
        }
      }
      return field;
    });
    setFormFields(newFields);
  };

  const handleRichTextFieldChange = async (editor, fieldId) => {
    startChange();
    setEditorState(editor);
    const contentState = editorState.getCurrentContent();
    const rawObjectText = JSON.stringify(convertToRaw(contentState));
    const newFields = formFields.map((field) => {
      if (fieldId === field.id) {
        return { ...field, value: rawObjectText };
      }
      return field;
    });
    setFormFields(newFields);
  };

  const handleSwitchChange = async (event) => {
    startChange();
    const newFields = formFields.map((field) => {
      if (event.target.name === field.id) {
        return { ...field, value: event.target.checked };
      }
      return field;
    });
    setFormFields(newFields);
  };

  const handleSelectorChange = async (
    event,
    value,
    action,
    clicked,
    fieldId
  ) => {
    startChange();
    // on change loop through all fields in the form and update as required
    const newFields = formFields.map((field) => {
      // first adjust and return any dependent fields
      if (field.dependsOn && field.dependsOn.selection === fieldId) {
        if (field.dependsOn.submitHidden !== undefined) {
          setSubmitHidden(field.dependsOn.submitHidden);
        }
        return {
          ...field,
          hidden: value ? false : true,
          options:
            field.dependsOn.property && value
              ? value[field.dependsOn.property]
              : field.options,
          value: field.dependsOn.property ? field.emptyValue : field.value,
        };
      }
      // update field that was selected
      if (fieldId === field.id) {
        let newValue;
        if (action === "multi-select") {
          if (field.value.includes(value)) {
            newValue = field.value.filter((item) => item !== value);
          } else {
            newValue = [].concat(field.value, value);
          }
        } else {
          newValue = value;
        }
        return { ...field, value: newValue };
      }
      // all other fields return unchanged
      return field;
    });
    setFormFields(newFields);
    if (props.changeEmitter) {
      props.changeEmitter(fieldId, value, clicked, newFields);
    }
  };

  const handleSliderChange = async (value, fieldId) => {
    startChange();
    const newFields = formFields.map((field) => {
      if (fieldId === field.id) {
        return { ...field, value };
      }
      return field;
    });
    setFormFields(newFields);
  };

  const handlePaymentChange = async (value, fieldId) => {
    if (value.alert) {
      setAlert(true);
      setMessage(value.message);
    } else {
      const newFields = formFields.map((field) => {
        if (fieldId === field.id) {
          return { ...field, value };
        }
        return field;
      });
      setFormFields(newFields);
      return handleFormSubmit(null, newFields);
    }
  };

  const handleFileInputReset = async (event) => {
    startChange({ loader: true });
    const newFields = formFields.map((field) => {
      if (event.target.id === field.id) {
        return {
          ...field,
          value: {
            key: configEntzy.EMPTY_STRING_SET,
          },
        };
      }
      return field;
    });
    setFileImage(configEntzy.EMPTY_STRING_SET);
    setFormFields(newFields);
    setSubmitHidden(true);
    setLoading(false);
    return handleFormSubmit(event, newFields);
  };
  const handleFileInputChange = async (event, type, level) => {
    startChange({ loader: true });
    // extract validate and upload file
    const files = Array.from(event.target.files);
    const [file] = files;
    if (!files.length) {
      setLoading(false);
      setFileAlert(true);
      setFileMessage(constants.alerts.FILE_SELECT);
      return false;
    } else if (file.size > constants.files.MAX_SIZE) {
      setLoading(false);
      setAlert(true);
      setFileAlert(true);
      setFileMessage(constants.alerts.FILE_SIZE);
      setMessage(constants.alerts.FILE_SIZE);
      return false;
    }
    const response = await serviceObjectUpload(
      "images",
      level ? level : "protected",
      type + "-" + Date.now(),
      file,
      user.identity
    );
    if (response.success) {
      const newFields = formFields.map((field) => {
        if (event.target.id === field.id) {
          return { ...field, value: response.data };
        }
        return field;
      });
      setFileImage(response.data.preload);
      setFormFields(newFields);
      setSubmitHidden(false);
      setLoading(false);
    } else {
      setLoading(false);
      setAlert(true);
      setFileAlert(true);
      setFileMessage(constants.alerts.FILE_CATCH);
      setMessage(constants.alerts.FILE_CATCH);
      return false;
    }
  };

  const handleFormSubmit = async (event, fields) => {
    if (event) {
      event.preventDefault();
    }
    startChange({ submit: true, loader: true });
    if (!fileAlert) {
      try {
        const results = await props.callback(fields ? fields : formFields);
        setLoading(false);
        if (results.alert) {
          setAlert(true);
          setMessage(results.message);
        } else {
          endChange();
          if (confirmation) {
            setSuccess(true);
          }
        }
      } catch (error) {
        setLoading(false);
        setAlert(true);
        setMessage(constants.alerts.SUBMIT_CATCH);
        serviceLogError("handleFormSubmit", error);
      }
    } else {
      setLoading(false);
      setAlert(true);
      setMessage(
        "Fix the following file issues before submitting: " + fileMessage
      );
    }
  };

  const handleFormCancel = async (event, confirm) => {
    if (confirm) {
      setDialog(false);
      props.cancel();
    } else {
      setDialog(!dialog);
    }
  };

  return (
    <Box className="box-default">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(e);
        }}
      >
        <Box className="box-default" hidden={success && confirmation}>
          {typeof helpText === "string" && (
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL,
                mb: configEntzy.APP_SPACING_LG,
                textAlign: "left",
              }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconHelpText} />
                &nbsp;&nbsp;
                {helpText}
              </Typography>
            </Box>
          )}

          {props.disabledSubmit && (
            <Box
              className="box-default"
              sx={{
                mb: configEntzy.APP_SPACING_LG,
              }}
            >
              <ActionAlert
                severity="info"
                title={props.disabledTitle}
                message={props.disabledText}
                onClick={props.disabledClick}
              />
            </Box>
          )}

          {props.closeForm && (
            <Box
              className="box-default text-right"
              sx={{
                mb: configEntzy.APP_SPACING_LG,
              }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon
                  icon={iconClose}
                  size="2x"
                  className="action-pointer"
                  onClick={props.closeForm}
                  fixedWidth
                />
              </Typography>
            </Box>
          )}

          {props.warnOnChange && hasChanges && (
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_LG,
              }}
            >
              <ActionAlert
                severity="warning"
                message="Save your changes before exiting this page"
                title="Change in Progress"
              />
            </Box>
          )}

          {formFields.map((field) => (
            <Box
              className={
                "box-default " +
                ([
                  "payment-basic",
                  "payment-method",
                  "picker-calendar",
                  "picker-member-number-rocker",
                ].includes(field.type)
                  ? ""
                  : field.dark
                  ? "bg-black-t10"
                  : field.light
                  ? "bg-white"
                  : "bg-white")
              }
              key={field.id}
              sx={{
                mb: field.disableMargin ? 0 : configEntzy.APP_SPACING_MD2X,
                borderRadius: configEntzy.BORDER_SIZE_LG,
                opacity: props.disabledSubmit ? 0.5 : 1,
                overflow: "hidden",
                p: configEntzy.APP_SPACING_MD,
              }}
              hidden={field.hidden}
            >
              <FormControl fullWidth>
                {
                  // text fields standard
                  field.type === "text-standard" ? (
                    <TextField
                      type={
                        field.numeric
                          ? "number"
                          : field.email
                          ? "email"
                          : "text"
                      }
                      variant={field.variant ? field.variant : "outlined"}
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      placeholder={field.placeholder}
                      disabled={field.disabled}
                      required={field.required}
                      autoFocus={field.focus}
                      value={field.value}
                      pattern={field.numeric ? "[0-9]*" : null}
                      inputMode={
                        field.numeric ? "decimal" : field.email ? "email" : null
                      }
                      inputProps={{
                        min: field.minValue ? field.minValue : 0,
                        max: field.maxValue ? field.maxValue : 999999,
                        maxLength: field.maxLength ? field.maxLength : 128,
                        autoComplete: field.autoComplete ? "on" : "off",
                        style: {
                          textAlign: field.alignCenter ? "center" : "left",
                          fontWeight: 900,
                        },
                      }}
                      InputProps={{
                        startAdornment: field.startAdornment ? (
                          <InputAdornment
                            position="start"
                            sx={{
                              pl: configEntzy.APP_SPACING_SM,
                              pr: configEntzy.APP_SPACING_MD,
                              color: "orange",
                            }}
                          >
                            <strong>{field.startAdornment}</strong>
                          </InputAdornment>
                        ) : null,
                        endAdornment: field.endAdornment ? (
                          <InputAdornment
                            position="end"
                            sx={{
                              pl: configEntzy.APP_SPACING_MD,
                              pr: configEntzy.APP_SPACING_SM,
                              color: "orange",
                            }}
                          >
                            <strong>{field.endAdornment}</strong>
                          </InputAdornment>
                        ) : null,
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        "& .MuiInputBase-root": {
                          height: configEntzy.APP_SPACING_XL,
                        },
                        border: field.border ? "1px solid #ccc" : "none",
                        borderRadius: field.border ? "4px" : "0",
                        // label style
                        "& .MuiFormLabel-root": {
                          fontWeight: 500,
                          backgroundColor: "white",
                          paddingLeft: configEntzy.APP_SPACING_SM,
                          paddingRight: configEntzy.APP_SPACING_SM,
                          paddingTop: configEntzy.APP_SPACING_SM,
                        },
                      }}
                      onChange={handleTextFieldChange}
                      onFocus={
                        submitType === "focus"
                          ? () => setSubmitHidden(false)
                          : null
                      }
                      fullWidth
                    />
                  ) : // text fields password
                  field.type === "text-password" ? (
                    <TextField
                      type="password"
                      variant={field.variant ? field.variant : "outlined"}
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      disabled={field.disabled}
                      required={field.required}
                      autoFocus={field.focus}
                      value={field.value}
                      inputProps={{
                        min: field.minValue ? field.minValue : 0,
                        max: field.maxValue ? field.maxValue : 999999,
                        maxLength: field.maxLength ? field.maxLength : 128,
                        style: {
                          textAlign: field.alignCenter ? "center" : "left",
                          fontWeight: 500,
                        },
                      }}
                      InputProps={{
                        startAdornment: field.startAdornment ? (
                          <InputAdornment
                            position="start"
                            sx={{
                              pl: configEntzy.APP_SPACING_SM,
                              color: "orange",
                            }}
                          >
                            <strong>{field.startAdornment}</strong>
                          </InputAdornment>
                        ) : null,
                        endAdornment: field.endAdornment ? (
                          <InputAdornment
                            position="end"
                            sx={{
                              pr: configEntzy.APP_SPACING_SM,
                              color: "orange",
                            }}
                          >
                            <strong>{field.endAdornment}</strong>
                          </InputAdornment>
                        ) : null,
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        "& .MuiInputBase-root": {
                          height: configEntzy.APP_SPACING_XL,
                        },
                      }}
                      onChange={handleTextFieldChange}
                      onFocus={
                        submitType === "focus"
                          ? () => setSubmitHidden(false)
                          : null
                      }
                      fullWidth
                    />
                  ) : // text fields multiline
                  field.type === "text-multiline" ? (
                    <TextField
                      multiline={true}
                      rows={3}
                      // minRows={4}
                      // maxRows={12}
                      variant={field.variant ? field.variant : "outlined"}
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      placeholder={field.placeholder}
                      disabled={field.disabled}
                      required={field.required}
                      autoFocus={field.focus}
                      value={field.value}
                      inputProps={{
                        maxLength: field.maxLength ? field.maxLength : 4096,
                      }}
                      sx={{ backgroundColor: "transparent" }}
                      onChange={handleTextFieldChange}
                      fullWidth
                    />
                  ) : // text fields rich text
                  field.type === "text-rich" ? (
                    <fieldset className="fs-default">
                      <legend className="fsl-left upper-case">
                        <Typography variant="subtitle2">
                          {field.label}
                        </Typography>
                      </legend>
                      <Box
                        className="box-default"
                        sx={{
                          pl: configEntzy.APP_SPACING_MD,
                          pr: configEntzy.APP_SPACING_MD,
                          pt: configEntzy.APP_SPACING_SM,
                          pb: configEntzy.APP_SPACING_MD,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Editor
                          editorState={getEditorState(field.value)}
                          toolbarClassName="wysiwyg-toolbar"
                          wrapperClassName="wysiwyg-wrapper"
                          editorClassName="wysiwyg-editor"
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "list",
                              "emoji",
                              "history",
                            ],
                            inline: {
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "monospace",
                              ],
                            },
                            blockType: {
                              options: [
                                "Normal",
                                "H1",
                                "H2",
                                "H3",
                                "H4",
                                "H5",
                                "H6",
                                "Blockquote",
                              ],
                            },
                          }}
                          onEditorStateChange={(
                            e,
                            fieldId = getParamValue(field.id)
                          ) => handleRichTextFieldChange(e, fieldId)}
                        />
                      </Box>
                    </fieldset>
                  ) : // selector standard options passed in
                  field.type === "switch-standard" ? (
                    <Box
                      className="box-default shadow-light"
                      sx={{
                        pt: configEntzy.APP_SPACING_SM,
                        pb: configEntzy.APP_SPACING_SM,
                      }}
                    >
                      <Box
                        className="box-default half-width text-left"
                        sx={{
                          pl: configEntzy.APP_SPACING_MD2X,
                          color: "gray",
                          height: configEntzy.AVATAR_SIZE_SM,
                          lineHeight: configEntzy.AVATAR_SIZE_SM,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            height: configEntzy.AVATAR_SIZE_SM,
                            lineHeight: configEntzy.AVATAR_SIZE_SM,
                          }}
                          noWrap
                        >
                          {field.startAdornment}&nbsp;&nbsp;&nbsp;&nbsp;
                          {field.label}
                        </Typography>
                      </Box>
                      <Box
                        className="box-default half-width text-right"
                        sx={{
                          pr: configEntzy.APP_SPACING_MD2X,
                          height: configEntzy.AVATAR_SIZE_SM,
                          lineHeight: configEntzy.AVATAR_SIZE_SM,
                        }}
                      >
                        <FormControlLabel
                          control={<Switch />}
                          id={field.id}
                          name={field.id}
                          label={field.value ? "Active" : "Inactive"}
                          required={field.required}
                          checked={field.value}
                          onChange={handleSwitchChange}
                          labelPlacement="start"
                        />
                      </Box>
                    </Box>
                  ) : // selector standard options passed in
                  field.type === "selector-basic" ? (
                    <BasicSelect
                      variant={field.variant ? field.variant : "outlined"}
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      icon={field.icon}
                      options={field.options}
                      disabled={field.disabled}
                      disableUnderline={field.disableUnderline}
                      selectPrompt={field.selectPrompt}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // selector standard options passed in
                  field.type === "selector-modal" ? (
                    <ModalGroupedSelect
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      icon={field.icon}
                      options={field.options}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // selector standard options passed in
                  field.type === "selector-standard" ? (
                    <StandardSelect
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      icon={field.icon}
                      options={field.options}
                      disableClearable={field.disableClearable}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // selector standard options passed in
                  field.type === "selector-multi" ? (
                    <MultiSelect
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      icon={field.icon}
                      options={field.options}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // selector fields country
                  field.type === "selector-country" ? (
                    <CountrySelect
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // selector fields country
                  field.type === "selector-currency" ? (
                    <CurrencySelect
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // picker fields member
                  field.type === "picker-member-number-rocker" ? (
                    <MemberPickerNumberRocker
                      user={user}
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      placeholder={field.placeholder}
                      value={field.value}
                      formState={formFields}
                      icon={field.icon}
                      options={field.options}
                      disabled={field.disabled}
                      multiple={field.multiple}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // picker fields calendar
                  field.type === "picker-calendar" ? (
                    <DatePicker
                      variant="outlined"
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      placeholder={field.placeholder}
                      value={field.value}
                      icon={field.icon}
                      options={field.options}
                      optionsDecorate={field.optionsDecorate}
                      optionsDisabled={field.optionsDisabled}
                      disabled={field.disabled}
                      onChange={(
                        e,
                        value,
                        action,
                        clicked,
                        fieldId = getParamValue(field.id)
                      ) =>
                        handleSelectorChange(e, value, action, clicked, fieldId)
                      }
                    />
                  ) : // slider fields standard
                  field.type === "slider-standard" ? (
                    <StandardSlider
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      minValue={field.minValue}
                      maxValue={field.maxValue}
                      stepValue={field.stepValue}
                      disabled={field.disabled}
                      onChange={(value, fieldId = getParamValue(field.id)) =>
                        handleSliderChange(value, fieldId)
                      }
                    />
                  ) : // slider fields range static
                  field.type === "slider-range-static" ? (
                    <RangeStaticSlider
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      minValue={field.minValue}
                      maxValue={field.maxValue}
                      stepValue={field.stepValue}
                      minRange={field.minRange}
                      disabled={field.disabled}
                      onChange={(value, fieldId = getParamValue(field.id)) =>
                        handleSliderChange(value, fieldId)
                      }
                    />
                  ) : // slider fields range shift
                  field.type === "slider-range-shift" ? (
                    <RangeShiftSlider
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      value={field.value}
                      minValue={field.minValue}
                      maxValue={field.maxValue}
                      stepValue={field.stepValue}
                      minRange={field.minRange}
                      disabled={field.disabled}
                      onChange={(value, fieldId = getParamValue(field.id)) =>
                        handleSliderChange(value, fieldId)
                      }
                    />
                  ) : // payments basic input for one off transactions
                  field.type === "payment-basic" ? (
                    <Box className="box-default" hidden={loading}>
                      <PaymentBasic
                        user={user}
                        userReload={userReload}
                        navigate={navigate}
                        page={page}
                        redirectPage={redirectPage}
                        searchParams={searchParams}
                        payment={payment}
                        variant="outlined"
                        id={field.id}
                        name={field.id}
                        label={field.label}
                        value={field.value}
                        disabled={field.disabled}
                        submitText={submitText}
                        callback={(value, fieldId = getParamValue(field.id)) =>
                          handlePaymentChange(value, fieldId)
                        }
                        handleLoader={handleLoader}
                      />
                    </Box>
                  ) : // payment method input for delayed off session payments
                  field.type === "payment-method" ? (
                    <Box className="box-default" hidden={loading}>
                      <PaymentMethod
                        user={user}
                        userReload={userReload}
                        navigate={navigate}
                        page={page}
                        redirectPage={redirectPage}
                        searchParams={searchParams}
                        payment={payment}
                        variant="outlined"
                        id={field.id}
                        name={field.id}
                        label={field.label}
                        value={field.value}
                        disabled={field.disabled}
                        submitText={submitText}
                        callback={(value, fieldId = getParamValue(field.id)) =>
                          handlePaymentChange(value, fieldId)
                        }
                        handleLoader={handleLoader}
                      />
                    </Box>
                  ) : (
                    // image picker
                    field.type === "image-picker" && (
                      <Box className="box-default">
                        <Box
                          className="box-default action-pointer"
                          onClick={handleShowImagePicker}
                          sx={{
                            p: configEntzy.APP_SPACING_MD,
                            textAlign: showImagePicker ? "right" : "left",
                          }}
                          hidden={openImagePicker}
                        >
                          <Typography variant="body1">
                            {showImagePicker ? (
                              <span>Close X</span>
                            ) : (
                              <span>
                                <span className="fa-layers fa-fw">
                                  <FontAwesomeIcon
                                    icon={iconImage}
                                    transform="grow-24"
                                  />
                                  <FontAwesomeIcon
                                    icon={iconAdd}
                                    transform="right-16 up-12 grow-8"
                                  />
                                </span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                {field.label}
                              </span>
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className="box-default"
                          sx={{
                            pt: configEntzy.APP_SPACING_MD2X,
                            pb: configEntzy.APP_SPACING_MD,
                            pl: configEntzy.APP_SPACING_MD,
                            pr: configEntzy.APP_SPACING_MD,
                            borderRadius: configEntzy.BORDER_SIZE_LG,
                            // backgroundColor: "primary.light",
                          }}
                          hidden={!showImagePicker}
                        >
                          <Box className="box-default">
                            {fallbackImageHidden && !fileImage ? (
                              <Typography variant="body1">
                                {field.label}
                              </Typography>
                            ) : (
                              <Avatar
                                className="bg-black-t25 rounded"
                                variant="rounded"
                                src={
                                  fileImage
                                    ? fileImage === configEntzy.EMPTY_STRING_SET
                                      ? null
                                      : fileImage
                                    : field.image
                                    ? field.image
                                    : null
                                }
                                alt="Avatar"
                                sx={{
                                  width: configEntzy.AVATAR_SIZE_LG,
                                  height: configEntzy.AVATAR_SIZE_LG,
                                  margin: "0 auto",
                                  color: "divider",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={fallbackImage}
                                  size="5x"
                                  color="#555"
                                />
                              </Avatar>
                            )}
                          </Box>
                          <Box
                            className="box-default"
                            sx={{
                              pb: configEntzy.APP_SPACING_XS2X,
                            }}
                          >
                            <Box
                              className={
                                !field.image
                                  ? "box-basic"
                                  : "box-basic half-width"
                              }
                              sx={{
                                pr: configEntzy.APP_SPACING_XS,
                              }}
                            >
                              <Input
                                type="file"
                                id={field.id}
                                name={field.id}
                                required={field.required}
                                disabled={field.disabled}
                                accept="image/*"
                                inputProps={{
                                  accept: "image/*",
                                }}
                                onChange={(e) =>
                                  handleFileInputChange(
                                    e,
                                    field.id,
                                    field.level
                                  )
                                }
                                multiple={false}
                                fullWidth={true}
                                disableUnderline={true}
                                sx={{ visibility: "hidden" }}
                              />
                              <label htmlFor={field.id}>
                                <ActionButton
                                  size="small"
                                  variant="contained"
                                  component="span"
                                  color={submitHidden ? "primary" : "secondary"}
                                  text={
                                    !field.image
                                      ? fileImage
                                        ? "Reselect"
                                        : "Select Image"
                                      : "Replace"
                                  }
                                  fullWidth
                                />
                              </label>
                            </Box>
                            {field.image && (
                              <Box
                                className="box-basic half-width"
                                sx={{
                                  pl: configEntzy.APP_SPACING_SM,
                                }}
                              >
                                <ActionButton
                                  id={field.id}
                                  name={field.id}
                                  variant="contained"
                                  color="danger"
                                  text="Reset"
                                  onClick={handleFileInputReset}
                                  fullWidth
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )
                  )
                }
              </FormControl>
              {field.footnote && (
                <Box
                  className="box-default text-left"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Typography variant="body1">
                    <em>{field.footnote}</em>
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        {loading ? (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <ActionLoader
              color={props.loaderColor ? props.loaderColor : "primary"}
            />
          </Box>
        ) : (
          <Box
            className="box-default"
            hidden={success && confirmation}
            sx={{ pb: submitHidden ? 0 : configEntzy.APP_SPACING_MD2X }}
          >
            <Box className="box-default" hidden={submitHidden}>
              <ActionButton
                variant="contained"
                text={submitText}
                disabled={props.disabledSubmit}
                onClick={handleFormSubmit}
                fullWidth
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_LG }}
              hidden={submitType !== "focus" || submitHidden}
            >
              <ActionButton
                size="small"
                variant="contained"
                color="bright"
                text="Cancel"
                onClick={(e) => handleSubmitHidden(true)}
              />
            </Box>
            <Box
              className="box-default"
              sx={{ mt: configEntzy.APP_SPACING_LG }}
              hidden={!props.cancel}
            >
              <ActionButton
                size="small"
                variant="contained"
                color="bright"
                text={props.cancelText ? props.cancelText : "Cancel Action"}
                onClick={(e) => handleFormCancel(e, props.cancelAutoConfirm)}
              />
            </Box>
            <Box className="box-default">
              <ActionCheck
                dialog={dialog}
                title={props.cancelTitle ? props.cancelTitle : "Go back?"}
                description={
                  props.cancelDescription
                    ? props.cancelDescription
                    : "Your action will be stopped"
                }
                cancel={(e) => handleFormCancel(e, false)}
                confirm={(e) => handleFormCancel(e, true)}
              />
            </Box>
          </Box>
        )}
        {alert && message && (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <ActionAlert severity="error" message={message} />
          </Box>
        )}
        {success && confirmation && (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <ActionAlert severity="success" message={confirmation} />
          </Box>
        )}
        {footNotes && (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            {footNotes.map((note, index) => {
              return (
                <Typography
                  key={"note-" + index}
                  variant="body1"
                  color="primary"
                >
                  <em>{note}</em>
                </Typography>
              );
            })}
          </Box>
        )}
      </form>
    </Box>
  );
}

export default InputForm;
