// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage as iconLogo } from "@fortawesome/pro-duotone-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faCheck as iconActive } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconInactive } from "@fortawesome/pro-duotone-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";

// entzy components
import ActionLaunchpadFrame from "./ActionLaunchpadFrame";

function ActionLaunchpadRunnerGallery(props) {
  const section = "gallery";
  const user = props.user;
  const editing = props.editing;
  const handleEdit = props.handleEdit;
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  return (
    <Box id={anchorTop} className="box-default">
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Image Gallery
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Add images to show what's going on</em>
        </Typography>
      </Box>
      {user.connected ? (
        <Box className="box-default">
          {eventContext.hangImageFrames().map((frame) => {
            const frameName = frame.id.replace("frame", "Frame ");
            return (
              <Box id={frame.id} key={frame.id} className="box-default">
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_LG,
                  }}
                  hidden={editing !== frame.id}
                >
                  <Box
                    className="box-default shadow-light text-left action-pointer"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                      mb: configEntzy.APP_SPACING_MD,
                    }}
                    onClick={() => handleEdit(null)}
                  >
                    <Typography variant="subtitle2">
                      <FontAwesomeIcon icon={iconLogo} />
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span>{frameName}</span>
                    </Typography>
                  </Box>
                  <ActionLaunchpadFrame {...props} frame={frame} />
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_LG,
                      pb: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <ActionButton
                      color="bright"
                      size="small"
                      text={"Exit " + frameName + " Editor"}
                      onClick={() => handleEdit(null)}
                      endIcon={<FontAwesomeIcon icon={iconClose} fixedWidth />}
                    />
                  </Box>
                </Box>
                <Box
                  className="box-default action-pointer"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                  }}
                  hidden={editing === frame.id}
                  onClick={() => handleEdit(frame.id)}
                >
                  <Box
                    className="box-default shadow-light text-left"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Box className="box-default half-width text-left">
                      <Typography variant="subtitle2">
                        <FontAwesomeIcon icon={iconLogo} />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>{frameName}</span>
                      </Typography>
                    </Box>
                    <Box className="box-default half-width text-right">
                      {/* EDIT ICON */}
                      {/* ----------------------------------------- */}
                      <Box className="box-default zero-height text-left">
                        <Typography variant="h1" color="primary">
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon
                              icon={iconCircle}
                              color="#ccc"
                              transform="grow-15 up-3 left-5"
                            />
                            <FontAwesomeIcon
                              icon={iconCircle}
                              color="white"
                              transform="grow-14 up-3 left-5"
                            />
                            <FontAwesomeIcon
                              icon={iconEdit}
                              transform="up-3 left-5"
                            />
                          </span>
                        </Typography>
                      </Box>
                      {/* ----------------------------------------- */}
                      <Typography variant="subtitle2">
                        <em>
                          {frame.active ? (
                            <span>
                              <span>Active&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <FontAwesomeIcon
                                icon={iconActive}
                                color="green"
                              />
                            </span>
                          ) : (
                            <span>
                              <span>Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <FontAwesomeIcon
                                icon={iconInactive}
                                color="red"
                              />
                            </span>
                          )}
                        </em>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          className="box-default shadow-light"
          sx={{
            pt: configEntzy.APP_SPACING_XL,
            pb: configEntzy.APP_SPACING_XL,
          }}
        >
          <TextTag text="Connect to manage image frames" />
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerGallery;
