// react core
// import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
// import { MainContext } from "components/main/MainContext";
// import { EventContext } from "pages/events/EventContext";

// entzy components
import WidgetMemberViewer from "components/event/widgets/WidgetMemberViewer";

function ActionLaunchpadRunnerMembers(props) {
  const user = props.user;
  const section = "members";
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";

  // const mainContext = useContext(MainContext);
  // const eventContext = useContext(EventContext);

  return (
    <Box
      id={anchorTop}
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
        pl: configEntzy.APP_SPACING_MD,
        pr: configEntzy.APP_SPACING_MD,
        pb: configEntzy.APP_SPACING_LG,
        mb: configEntzy.APP_SPACING_XL,
      }}
    >
      {user.connected && (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <WidgetMemberViewer {...props} />
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerMembers;
