// react core
import { useContext, useState } from "react";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";

// material design
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink as iconLogin } from "@fortawesome/pro-solid-svg-icons";
import { faStripe as iconIncome } from "@fortawesome/free-brands-svg-icons";

// entzy context and services
import { SettingsContext } from "pages/settings/SettingsContext";
import { ActiveTag } from "components/utils/common/CommonTags";
import {
  ActionAlert,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

// entzy components
import TransactionList from "../transaction/TransactionList";

function IncomeBalance(props) {
  const context = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [manageUrl, setManageUrl] = useState(null);

  // component functions
  const handleAccountLogin = async () => {
    setLoading(true);
    setAlert(false);
    setSuccess(false);
    setMessage("");
    const url = await context.prepareIncomeAccountUrl();
    if (url.alert) {
      setMessage(url.message);
      setAlert(true);
    } else {
      setManageUrl(url);
    }
    setLoading(false);
  };

  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_LG,
        pb: configEntzy.APP_SPACING_LG,
      }}
    >
      <Box className="box-default" hidden={loading}>
        <Box className="box-default" hidden={manageUrl}>
          <ActiveTag
            value={context.state.user.status.payout_set}
            prefix="income account"
          />
        </Box>
        {context.state.user.status.payout_set &&
        context.state.settings.data.PayoutId &&
        context.state.settings.data.PayoutId.split(":")[0] !== "none" ? (
          <Box className="box-default">
            {manageUrl && (
              <Box className="box-default">
                <Box className="box-default">
                  <Typography variant="h6" noWrap={true}>
                    Ready
                  </Typography>
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <a
                    href={manageUrl}
                    className="image-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip label={manageUrl} />
                  </a>
                </Box>
                <Box
                  className="box-default"
                  sx={{ mt: configEntzy.APP_SPACING_LG }}
                >
                  <a
                    href={manageUrl}
                    className="image-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ActionBoxButton
                      variant="contained"
                      text="Login to your Account"
                      size="medium"
                      bgColor="primary.main"
                      endIcon={<FontAwesomeIcon icon={iconLogin} fixedWidth />}
                    />
                  </a>
                </Box>
              </Box>
            )}
            <Box
              className="box-default"
              sx={{
                mt: manageUrl
                  ? configEntzy.APP_SPACING_HL2X
                  : configEntzy.APP_SPACING_XL,
              }}
            >
              <ActionBoxButton
                variant="contained"
                color="tertiary"
                size="medium"
                onClick={handleAccountLogin}
                text={manageUrl ? "Regenerate Login Link" : "View your balance"}
                subtext={
                  manageUrl
                    ? "Link expired? Tap here to refresh it."
                    : "Tap here to generate a login link for Stripe."
                }
                topIcon={
                  <FontAwesomeIcon
                    icon={iconIncome}
                    size="3x"
                    transform="up-2"
                    fixedWidth
                  />
                }
              />
            </Box>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL,
              }}
            >
              <TransactionList incomeView={true} />
            </Box>
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_LG,
            }}
          >
            <Typography variant="subtitle2" noWrap={true}>
              No income bank account link detected
            </Typography>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
              }}
            >
              <Box className="box-inline">
                <ActionBoxButton
                  variant="contained"
                  color="black"
                  size="medium"
                  onClick={() => props.menuSelectById("settings-income-bank")}
                  text="Go to Income Activation"
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {loading && (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
            mb: configEntzy.APP_SPACING_XL,
          }}
        >
          <ActionLoader />
        </Box>
      )}
      {alert && message && (
        <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
          <ActionAlert severity="error" message={message} />
        </Box>
      )}
      {success && message && (
        <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
          <ActionAlert severity="success" message={message} />
        </Box>
      )}
    </Box>
  );
}

export default IncomeBalance;
