// react core
import { useState, useContext } from "react";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// material design
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// entzy context and services
import { SettingsContext } from "pages/settings/SettingsContext";

function ProfileName(props) {
  const [edit, setEdit] = useState(false);
  const context = useContext(SettingsContext);

  // component functions
  const handleEdit = async () => {
    setEdit(!edit);
  };

  const handleFormCallback = async (data) => {
    const settingsName = data
      .filter((obj) => obj.id === "settings-name")[0]
      .value.toLowerCase();
    if (settingsName) {
      if (!configEntzy.RESERVED_NAMES.includes(settingsName)) {
        if (
          settingsName.length >= configEntzy.MIN_MAX_PROFILE_NAME[0] &&
          settingsName.length <= configEntzy.MIN_MAX_PROFILE_NAME[1]
        ) {
          const settingsUpdate = await context.prepareUpdateSettings({
            key: "Name",
            value: settingsName,
          });
          if (settingsUpdate.alert) {
            return {
              alert: true,
              message: settingsUpdate.message,
            };
          } else {
            context.updateSettings(settingsUpdate);
            props.userReload();
            return { alert: false };
          }
        } else {
          return {
            alert: true,
            message:
              "Profile name must be between " +
              configEntzy.MIN_MAX_PROFILE_NAME[0] +
              " and " +
              configEntzy.MIN_MAX_PROFILE_NAME[1] +
              " characters",
          };
        }
      } else {
        return {
          alert: true,
          message: "This name is reserved. Give another one a try.",
        };
      }
    } else {
      return {
        alert: true,
        message: "Profile name is required",
      };
    }
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box className="box-default">
          <Typography className="lower-case" variant="h3" noWrap={true}>
            @{context.state.user.name}
          </Typography>
        </Box>
      </Box>
      {edit && (
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
          <InputForm
            navigate={props.navigate}
            fields={[
              {
                id: "settings-name",
                type: "text-standard",
                label: "Your profile name (letters numbers only)",
                value: context.state.user.name.toLowerCase(),
                maxLength: 20,
                alphanumeric: true,
                focus: true,
                required: true,
                startAdornment: "@",
              },
            ]}
            submitText="Update"
            callback={handleFormCallback}
            confirmation="Profile name updated successfully"
          />
        </Box>
      )}
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_XL,
          pb: configEntzy.APP_SPACING_XL,
        }}
      >
        {edit ? (
          <Box className="box-default">
            <Button
              variant="contained"
              color="bright"
              size="medium"
              onClick={handleEdit}
            >
              Close
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="bright"
            size="large"
            onClick={handleEdit}
          >
            Edit Name
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ProfileName;
