// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config
import configEntzy from "components/config/ConfigEntzy";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuestionCircle as iconLost } from "@fortawesome/pro-regular-svg-icons";
import { faFaceMonocle as iconLost } from "@fortawesome/pro-thin-svg-icons";

function Page404Index() {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_XL }}>
        <FontAwesomeIcon icon={iconLost} size="4x" fixedWidth />
      </Box>
      <Box
        className="box-default"
        sx={{ pt: configEntzy.APP_SPACING_LG, pb: configEntzy.APP_SPACING_XL }}
      >
        <Typography variant="h2" noWrap={true}>
          Nothing to see here
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default Page404Index;
