// react core
import { useState, useContext } from "react";

// qr code generator and auth code entry
import { QRCode } from "react-qrcode-logo";
import AuthCode from "react-auth-code-input";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import { ActionButton } from "components/utils/common/CommonButtons";
import { TextTag, ActiveTag } from "components/utils/common/CommonTags";
import {
  ActionAlert,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { SettingsContext } from "pages/settings/SettingsContext";

import { userMfaSetup } from "hooks/identity/identityState";

function SecurityMfa(props) {
  const [edit, setEdit] = useState(false);
  const [flow, setFlow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [mfaQrCode, setMfaQrCode] = useState("");
  const [authCode, setAuthCode] = useState();
  const context = useContext(SettingsContext);

  const handleEdit = async (value) => {
    if (!value) {
      setFlow(0);
      setLoading(false);
      setAlert(false);
      setMessage("");
      setMfaQrCode("");
    }
    setEdit(value);
  };

  const handleCodeChange = (code) => {
    setAuthCode(code);
  };

  const handleActivate = async () => {
    setLoading(true);
    setAlert(false);
    const response = await userMfaSetup();
    setMfaQrCode(response.qrCode);
    setFlow(1);
    setLoading(false);
  };

  const handleActivateValidate = async () => {
    if (authCode && authCode.length) {
      setLoading(true);
      setAlert(false);
      const settingsUpdate = await context.prepareUpdateSettings({
        key: "MfaActivate",
        value: authCode,
      });
      setLoading(false);
      if (settingsUpdate.alert) {
        setAlert(true);
        setMessage(settingsUpdate.message);
      } else {
        handleEdit(false);
        props.userReload();
      }
    } else {
      setAlert(true);
      setMessage(
        "Enter the code from your authenticator app to complete the setup"
      );
    }
  };

  const handleDeactivate = async () => {
    setLoading(true);
    setAlert(false);
    const settingsUpdate = await context.prepareUpdateSettings({
      key: "MfaDeactivate",
      value: null,
    });
    setLoading(false);
    if (settingsUpdate.alert) {
      setAlert(true);
      setMessage(settingsUpdate.message);
    } else {
      handleEdit(false);
      props.userReload();
    }
  };

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD2X,
        }}
        hidden={edit}
      >
        <Box className="box-default">
          <Typography className="lower-case" variant="h5" noWrap={true}>
            Multi-Factor Authentication
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <ActiveTag value={context.state.user.mfa !== "NOMFA"} prefix="mfa" />
        </Box>
        {context.state.user.mfa === "NOMFA" && (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="subtitle1" noWrap={true}>
              <em>Add an authenticator app to use an extra</em>
            </Typography>
            <Typography variant="subtitle1" noWrap={true}>
              <em>one time code with your password at login</em>
            </Typography>
          </Box>
        )}
      </Box>
      {context.state.user.provider === "internal" ? (
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_LG }}>
          {edit && (
            <Box className="box-default">
              {loading ? (
                <Box className="box-default">
                  <ActionLoader />
                </Box>
              ) : flow === 0 ? (
                <Box className="box-default">
                  <Box className="box-default">
                    <Typography variant="h6" noWrap={true}>
                      Configure Authenticator
                    </Typography>
                  </Box>
                  {context.state.user.mfa === "NOMFA" && (
                    <Box className="box-default">
                      <Box
                        className="box-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography variant="subtitle1" noWrap={true}>
                          <em>To activate MFA have your preferred</em>
                        </Typography>
                        <Typography variant="subtitle1" noWrap={true}>
                          <em>authenticator app ready to scan the QR code</em>
                        </Typography>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                          pb: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        <Box className="box-default">
                          <ActionButton
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleActivate}
                            text="Activate MFA"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {context.state.user.mfa === "SOFTWARE_TOKEN_MFA" && (
                    <Box className="box-default">
                      <Box
                        className="box-default"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography variant="subtitle1" noWrap={true}>
                          <em>MFA is currently active</em>
                        </Typography>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                          pb: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        <ActionButton
                          variant="contained"
                          color="danger"
                          size="medium"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to remove your authenticator?\nMFA helps keep your account secure."
                              )
                            )
                              handleDeactivate();
                          }}
                          text="Deactivate MFA"
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                flow === 1 && (
                  <Box className="box-default">
                    <Box className="box-default">
                      <Typography variant="h6" noWrap={true}>
                        Scan QR and Enter Code
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        noWrap={true}
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <em>Use your preferred authenticator app</em>
                      </Typography>
                      <Typography variant="subtitle1" noWrap={true}>
                        <em>and enter the code that shows when ready</em>
                      </Typography>
                    </Box>
                    {mfaQrCode && (
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        <QRCode value={mfaQrCode} />
                      </Box>
                    )}
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <AuthCode
                        length={6}
                        containerClassName="auth-container"
                        inputClassName="auth-input"
                        allowedCharacters="numeric"
                        onChange={handleCodeChange}
                      />
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <ActionButton
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleActivateValidate}
                        text="Validate Authenticator"
                      />
                    </Box>
                  </Box>
                )
              )}
              {alert && message && (
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_XL,
                  }}
                >
                  <ActionAlert severity="error" message={message} />
                </Box>
              )}
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
            hidden={loading}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              onClick={() => handleEdit(!edit)}
              text={edit ? "Cancel" : "Configure MFA"}
            />
          </Box>
        </Box>
      ) : (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <ActionButton
              variant="contained"
              color="bright"
              size="medium"
              disabled={true}
              text="Configure MFA"
            />
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <Typography variant="subtitle2">
              MFA settings not editable here
            </Typography>
            <Typography variant="subtitle1">
              You are connected via external provider
            </Typography>
            <Typography
              variant="subtitle1"
              className="upper-case"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <TextTag size="lg" text={context.state.user.provider} />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SecurityMfa;
