// animations
import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy context
import configEntzy from "components/config/ConfigEntzy";
import { EVENT_MODULES } from "models/Structure";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as iconCircleDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircleThin } from "@fortawesome/pro-thin-svg-icons";
import { faBurst as iconBurstDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faStopwatch as iconTimer } from "@fortawesome/pro-thin-svg-icons";
import { Fragment } from "react";
// import { faExplosion as iconLaunchedDuo } from "@fortawesome/pro-duotone-svg-icons";
// import { faExplosion as iconLaunchedThin } from "@fortawesome/pro-thin-svg-icons";
// import { orange } from "@mui/material/colors/";

// derive active modules
const CORE_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && !obj.secondaryNav
);

function WidgetHomeMenu(props) {
  return (
    <Box className="box-default wormhole">
      <Marquee speed={30} gradient={false}>
        {CORE_MODULES.map((module, index) => {
          return (
            <Box
              key={"nav-" + module.id}
              className="box-inline action-pointer"
              sx={{
                // width: Math.floor((100 / CORE_MODULES.length) * 2) + "%",
                // display: "inline-block",
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_LG,
                pr:
                  module.id === "entry"
                    ? configEntzy.APP_SPACING_HL2X
                    : configEntzy.APP_SPACING_HL,
                // pr:
                //   index === 2
                //     ? configEntzy.APP_SPACING_XL2X
                //     : index === 5
                //     ? configEntzy.APP_SPACING_XL2X
                //     : configEntzy.APP_SPACING_LG,
              }}
              onClick={() => props.menuSelectById("about")}
            >
              <Box className="box-default">
                <Typography
                  variant="subtitle1"
                  color="white"
                  noWrap={true}
                  sx={{ overflow: "visible" }}
                >
                  <span className="fa-layers fa-fw fa-2x">
                    {module.id === "entry" && (
                      <Fragment>
                        <FontAwesomeIcon
                          icon={iconCircleDuo}
                          transform="grow-60 down-10"
                          style={{ color: "orange", opacity: 0.5 }}
                        />
                        <FontAwesomeIcon
                          icon={iconCircleThin}
                          transform="grow-60 down-10"
                          style={{ color: "orange", opacity: 0.5 }}
                        />
                      </Fragment>
                    )}
                    <FontAwesomeIcon
                      icon={
                        module.id === "entry" ? iconBurstDuo : iconCircleDuo
                      }
                      transform={
                        module.id === "entry"
                          ? "grow-60 down-10"
                          : "grow-60 down-10"
                      }
                      color={module.id === "entry" ? "darkred" : "orange"}
                    />
                    {/* <FontAwesomeIcon
                      icon={iconCircleDuo}
                      transform="grow-60 down-10"
                    /> */}

                    <FontAwesomeIcon
                      icon={module.icon.main}
                      className="fa-beat"
                      color="white"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                    />

                    {module.id === "launchpad" && (
                      <FontAwesomeIcon
                        icon={iconTimer}
                        transform="grow-5 right-50 up-20"
                        style={{ color: "white" }}
                      />
                    )}
                  </span>
                </Typography>
              </Box>
              <Box
                className="box-default relative pop-top upper-case"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Typography
                  variant="subtitle1"
                  color={module.id === "entry" ? "white" : "orange"}
                >
                  {module.shortName}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Marquee>
    </Box>
  );
}

export default WidgetHomeMenu;
