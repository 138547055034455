// react core
import { useContext, useState } from "react";

// imported animations
import Marquee from "react-fast-marquee";

// material design
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode as iconTicket } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronRight as iconRight } from "@fortawesome/pro-regular-svg-icons";
import { faBox as iconProduct } from "@fortawesome/pro-regular-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-regular-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { TicketViewer } from "components/event/lists/ListTickets";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { TextTag } from "components/utils/common/CommonTags";

// entzy components
import WidgetMemberViewer from "components/event/widgets/WidgetMemberViewer";
import { MenuEventBar } from "components/menu/MenuEvent";

function EventTicker(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [showTickets, setShowTickets] = useState(false);

  const handleShowMembers = (landing) => {
    const landingTab = landing.runners
      ? 0
      : landing.riders
      ? 0
      : landing.queue
      ? 1
      : 0;
    mainContext.updateViewerTrail({
      memberTab: landingTab,
    });
    eventContext.focusModule("members");
    // eventContext.setMemberDrawer({
    //   open: true,
    // });
  };
  const handleCloseMembers = () => {
    eventContext.setMemberDrawer({
      open: false,
    });
  };

  const handleShowTickets = () => {
    setShowTickets(!showTickets);
  };

  const handleShowStore = async () => {
    if (user.connected) {
      mainContext.setPayDirectViewer({
        open: mainContext.state.payDirect.viewer.open ? false : true,
        recipient:
          configEntzy.URL_POINTERS.MAIN + eventContext.state.event.data.Url,
        currency: eventContext.state.event.data.Currency,
      });
    } else {
      mainContext.updateAlert({
        show: true,
        message: "You must be connected to transact",
      });
    }
  };

  const RUNNER_AVATARS_MIN = 5;
  const RUNNER_AVATARS_MAX = configEntzy.PAGE_SIZE_USERS;
  const RIDER_AVATARS_MIN = 10;
  const RIDER_AVATARS_MAX = configEntzy.PAGE_SIZE_USERS;
  const QUEUE_AVATARS_MIN = 5;
  const QUEUE_AVATARS_MAX = configEntzy.PAGE_SIZE_USERS;

  return (
    <Box className="box-default">
      {eventContext.state.event.accessGranted ? (
        <Box className="box-default">
          {/* header tickers */}
          <Box
            className="box-default bg-black-t75"
            sx={{
              pt: configEntzy.APP_SPACING_SM2X,
              // pb: configEntzy.APP_SPACING_MD,
              // borderBottom: "1px solid #555",
              whiteSpace: "nowrap",
            }}
          >
            <Container maxWidth="md" sx={{ pb: configEntzy.APP_SPACING_SM }}>
              <Box
                className="box-default half-width nooverflow"
                sx={{ pr: configEntzy.APP_SPACING_SM }}
              >
                <fieldset className="fs-mid">
                  <legend className="fsl-left">
                    <Typography variant="body2" color="white">
                      Basics
                      <span>&nbsp;</span>
                      <FontAwesomeIcon
                        icon={iconRight}
                        color="orange"
                        fixedWidth
                      />
                    </Typography>
                  </legend>
                  {/* core eventuator info marquee */}
                  <Box
                    className="box-default nooverflow"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM,
                      pb: configEntzy.APP_SPACING_SM2X,
                      pl: configEntzy.APP_SPACING_SM,
                      pr: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <Marquee
                      speed={25}
                      gradient={false}
                      pauseOnHover={true}
                      className="marquee-ticker-half nooverflow"
                    >
                      <Box
                        className="box-inline left"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="upper-case"
                          sx={{
                            height: configEntzy.AVATAR_SIZE_XS,
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          Country ({eventContext.state.event.data.Country})
                        </Typography>
                      </Box>
                      <Box
                        className="box-inline left"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD2X,
                        }}
                      >
                        <TextTag
                          flag={eventContext.state.event.data.Country}
                          bgColor="#222"
                          rounded={true}
                        />
                      </Box>

                      <Box
                        className="box-inline left action-pointover"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD,
                        }}
                        onClick={handleShowTickets}
                      >
                        <Typography
                          variant="body1"
                          className="upper-case"
                          sx={{
                            height: configEntzy.AVATAR_SIZE_XS,
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          Tickets (
                          {
                            eventContext.state.event.calendar.tickets.selectable
                              .active.length
                          }
                          )
                        </Typography>
                      </Box>
                      <Box
                        className="box-inline left action-pointover"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD2X,
                        }}
                        onClick={handleShowTickets}
                      >
                        <TextTag
                          text={Array.from(
                            Array(
                              eventContext.state.event.calendar.tickets
                                .selectable.active.length > 0
                                ? eventContext.state.event.calendar.tickets
                                    .selectable.active.length > 10
                                  ? 10
                                  : eventContext.state.event.calendar.tickets
                                      .selectable.active.length
                                : 1
                            ).keys()
                          ).map((index) => {
                            return (
                              <span key={index}>
                                <FontAwesomeIcon icon={iconTicket} fixedWidth />
                                {index + 1 <
                                  eventContext.state.event.calendar.tickets
                                    .selectable.active.length && (
                                  <span>&nbsp;|&nbsp;</span>
                                )}
                              </span>
                            );
                          })}
                          bgColor="#111"
                          color="#ccc"
                          rounded={true}
                        />
                      </Box>

                      <Box
                        className="box-inline left action-pointover"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD,
                        }}
                        onClick={handleShowStore}
                      >
                        <Typography
                          variant="body1"
                          className="upper-case"
                          sx={{
                            height: configEntzy.AVATAR_SIZE_XS,
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          Store Items (
                          {mainContext.state.productList.data.itemsCount})
                        </Typography>
                      </Box>
                      <Box
                        className="box-inline left action-pointover"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD2X,
                        }}
                        onClick={handleShowStore}
                      >
                        <TextTag
                          text={Array.from(
                            Array(
                              mainContext.state.productList.data.itemsCount
                                ? mainContext.state.productList.data
                                    .itemsCount > 0
                                  ? mainContext.state.productList.data
                                      .itemsCount > 5
                                    ? 5
                                    : mainContext.state.productList.data
                                        .itemsCount
                                  : 1
                                : 1
                            ).keys()
                          ).map((index) => {
                            return (
                              <span key={index}>
                                <FontAwesomeIcon
                                  icon={iconProduct}
                                  fixedWidth
                                />
                                {index + 1 < 5 &&
                                  index + 1 <
                                    mainContext.state.productList.data
                                      .itemsCount && <span>&nbsp;|&nbsp;</span>}
                              </span>
                            );
                          })}
                          bgColor="#111"
                          color="#ccc"
                          rounded={true}
                        />
                      </Box>

                      <Box
                        className="box-inline left"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="upper-case"
                          sx={{
                            height: configEntzy.AVATAR_SIZE_XS,
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          Currency
                        </Typography>
                      </Box>
                      <Box
                        className="box-inline left"
                        sx={{
                          height: configEntzy.AVATAR_SIZE_XS,
                          display: "flex",
                          alignItems: "center",
                          pr: configEntzy.APP_SPACING_MD2X,
                        }}
                      >
                        <TextTag
                          text={eventContext.state.event.data.Currency}
                          bgColor="#111"
                          color="#ccc"
                          rounded={true}
                        />
                      </Box>
                    </Marquee>
                  </Box>
                </fieldset>
              </Box>
              <Box
                className="box-default half-width nooverflow"
                sx={{ pl: configEntzy.APP_SPACING_SM }}
              >
                <fieldset
                  className={
                    eventContext.state.event.moduleSelected === "members"
                      ? "fs-highlight"
                      : "fs-mid"
                  }
                >
                  <legend className="fsl-left">
                    <Typography variant="body2" color="white">
                      Members
                      <span>&nbsp;</span>
                      <FontAwesomeIcon
                        icon={iconRight}
                        color="orange"
                        fixedWidth
                      />
                    </Typography>
                  </legend>
                  <Box
                    className="box-default nooverflow"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM,
                      pb: configEntzy.APP_SPACING_SM2X,
                      pl: configEntzy.APP_SPACING_SM,
                      pr: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    {/* runners and riders marquee (connected users only) */}
                    {user.connected ? (
                      <Marquee
                        speed={25}
                        gradient={false}
                        pauseOnHover={true}
                        className="marquee-ticker-half nooverflow"
                      >
                        <Box className="box-inline left">
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD,
                            }}
                            onClick={() => handleShowMembers({ runners: true })}
                          >
                            <Typography
                              variant="body1"
                              className="upper-case"
                              sx={{
                                height: configEntzy.AVATAR_SIZE_XS,
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                              }}
                            >
                              <span>Runners</span>
                              <span>&nbsp;&#40;</span>
                              <span>
                                {eventContext.state.runners.data.items.length}
                              </span>
                              {eventContext.state.runners.data.items.length >=
                                RUNNER_AVATARS_MAX && <span>+</span>}
                              <span>&#41;</span>
                            </Typography>
                          </Box>
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD2X,
                            }}
                            onClick={() => handleShowMembers({ runners: true })}
                          >
                            <AvatarGroup
                              max={RUNNER_AVATARS_MAX}
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: configEntzy.AVATAR_SIZE_XS,
                                  height: configEntzy.AVATAR_SIZE_XS,
                                  fontSize: 12,
                                  borderColor: "#222",
                                  borderWidth: "1px",
                                  backgroundColor: "#555",
                                },
                              }}
                            >
                              {eventContext.state.runners.data.items.map(
                                (manager) => {
                                  return (
                                    <UserAvatar
                                      key={manager.UserId}
                                      user={{ identity: manager.UserId }}
                                      size="xs"
                                      avatarOnly
                                    />
                                  );
                                }
                              )}
                              {Array.from(
                                Array(
                                  eventContext.state.runners.data.items.length >
                                    RUNNER_AVATARS_MIN
                                    ? 0
                                    : RUNNER_AVATARS_MIN -
                                        eventContext.state.runners.data.items
                                          .length
                                ).keys()
                              ).map((index) => {
                                return (
                                  <UserAvatar
                                    key={index}
                                    user={{ identity: null }}
                                    size="xs"
                                    avatarOnly
                                  />
                                );
                              })}
                            </AvatarGroup>
                          </Box>
                        </Box>

                        <Box className="box-inline left">
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD,
                            }}
                            onClick={() => handleShowMembers({ riders: true })}
                          >
                            <Typography
                              variant="body1"
                              className="upper-case"
                              sx={{
                                height: configEntzy.AVATAR_SIZE_XS,
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                              }}
                            >
                              <span>Riders</span>
                              <span>&nbsp;&#40;</span>
                              <span>
                                {eventContext.state.riders.data.items.length}
                              </span>
                              {eventContext.state.riders.data.items.length >=
                                RIDER_AVATARS_MAX && <span>+</span>}
                              <span>&#41;</span>
                            </Typography>
                          </Box>
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD2X,
                            }}
                            onClick={() => handleShowMembers({ riders: true })}
                          >
                            <AvatarGroup
                              max={RIDER_AVATARS_MAX}
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: configEntzy.AVATAR_SIZE_XS,
                                  height: configEntzy.AVATAR_SIZE_XS,
                                  fontSize: 12,
                                  borderColor: "#222",
                                  borderWidth: "1px",
                                  backgroundColor: "#555",
                                },
                              }}
                            >
                              {eventContext.state.riders.data.items.map(
                                (manager) => {
                                  return (
                                    <UserAvatar
                                      key={manager.UserId}
                                      user={{ identity: manager.UserId }}
                                      size="xs"
                                      avatarOnly
                                    />
                                  );
                                }
                              )}
                              {Array.from(
                                Array(
                                  eventContext.state.riders.data.items.length >
                                    RIDER_AVATARS_MIN
                                    ? 0
                                    : RIDER_AVATARS_MIN -
                                        eventContext.state.riders.data.items
                                          .length
                                ).keys()
                              ).map((index) => {
                                return (
                                  <UserAvatar
                                    key={index}
                                    user={{ identity: null }}
                                    size="xs"
                                    avatarOnly
                                  />
                                );
                              })}
                            </AvatarGroup>
                          </Box>
                        </Box>

                        {eventContext.state.event.data.Access === "private" && (
                          <Box className="box-inline left">
                            <Box
                              className="box-inline left action-pointover"
                              sx={{
                                height: configEntzy.AVATAR_SIZE_XS,
                                display: "flex",
                                alignItems: "center",
                                pr: configEntzy.APP_SPACING_MD,
                              }}
                              onClick={() => handleShowMembers({ queue: true })}
                            >
                              <Typography
                                variant="body1"
                                className="upper-case"
                                sx={{
                                  height: configEntzy.AVATAR_SIZE_XS,
                                  display: "flex",
                                  alignItems: "center",
                                  color: "white",
                                }}
                              >
                                <span>Queue</span>
                                <span>&nbsp;&#40;</span>
                                <span>
                                  {
                                    eventContext.state.riderQueue.data.items
                                      .length
                                  }
                                </span>
                                {eventContext.state.riderQueue.data.items
                                  .length >= QUEUE_AVATARS_MAX && (
                                  <span>+</span>
                                )}
                                <span>&#41;</span>
                              </Typography>
                            </Box>
                            <Box
                              className="box-inline left action-pointover"
                              sx={{
                                height: configEntzy.AVATAR_SIZE_XS,
                                display: "flex",
                                alignItems: "center",
                                pr: configEntzy.APP_SPACING_MD2X,
                              }}
                              onClick={() => handleShowMembers({ queue: true })}
                            >
                              <AvatarGroup
                                max={QUEUE_AVATARS_MAX}
                                sx={{
                                  "& .MuiAvatar-root": {
                                    width: configEntzy.AVATAR_SIZE_XS,
                                    height: configEntzy.AVATAR_SIZE_XS,
                                    fontSize: 12,
                                    borderColor: "#222",
                                    borderWidth: "1px",
                                    backgroundColor: "#555",
                                  },
                                }}
                              >
                                {eventContext.state.riderQueue.data.items.map(
                                  (manager) => {
                                    return (
                                      <UserAvatar
                                        key={manager.UserId}
                                        user={{ identity: manager.UserId }}
                                        size="xs"
                                        avatarOnly
                                      />
                                    );
                                  }
                                )}
                                {Array.from(
                                  Array(
                                    eventContext.state.riderQueue.data.items
                                      .length > QUEUE_AVATARS_MIN
                                      ? 0
                                      : QUEUE_AVATARS_MIN -
                                          eventContext.state.riderQueue.data
                                            .items.length
                                  ).keys()
                                ).map((index) => {
                                  return (
                                    <UserAvatar
                                      key={index}
                                      user={{ identity: null }}
                                      size="xs"
                                      avatarOnly
                                    />
                                  );
                                })}
                              </AvatarGroup>
                            </Box>
                          </Box>
                        )}
                      </Marquee>
                    ) : (
                      <Marquee
                        speed={25}
                        gradient={false}
                        pauseOnHover={true}
                        className="marquee-ticker-half nooverflow"
                      >
                        <Box className="box-inline left">
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD,
                            }}
                            onClick={() => handleShowMembers({ runners: true })}
                          >
                            <Typography
                              variant="body1"
                              className="upper-case"
                              sx={{
                                height: configEntzy.AVATAR_SIZE_XS,
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                              }}
                            >
                              <span>Connect to view members</span>
                            </Typography>
                          </Box>
                          <Box
                            className="box-inline left action-pointover"
                            sx={{
                              height: configEntzy.AVATAR_SIZE_XS,
                              display: "flex",
                              alignItems: "center",
                              pr: configEntzy.APP_SPACING_MD2X,
                            }}
                            onClick={() => handleShowMembers({ runners: true })}
                          >
                            <AvatarGroup
                              max={RUNNER_AVATARS_MAX}
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: configEntzy.AVATAR_SIZE_XS,
                                  height: configEntzy.AVATAR_SIZE_XS,
                                  fontSize: 12,
                                  borderColor: "#222",
                                  borderWidth: "1px",
                                  backgroundColor: "#555",
                                },
                              }}
                            >
                              {eventContext.state.runners.data.items.map(
                                (manager) => {
                                  return (
                                    <UserAvatar
                                      key={manager.UserId}
                                      user={{ identity: manager.UserId }}
                                      size="xs"
                                      avatarOnly
                                    />
                                  );
                                }
                              )}
                              {Array.from(Array(RUNNER_AVATARS_MIN).keys()).map(
                                (index) => {
                                  return (
                                    <UserAvatar
                                      key={index}
                                      user={{ identity: null }}
                                      size="xs"
                                      avatarOnly
                                    />
                                  );
                                }
                              )}
                            </AvatarGroup>
                          </Box>
                        </Box>
                      </Marquee>
                    )}
                  </Box>
                </fieldset>
              </Box>
            </Container>
          </Box>

          {/* main event menu */}
          <Box
            className="box-default bg-black-t75"
            sx={{
              pt: configEntzy.APP_SPACING_SM2X,
              pb: configEntzy.APP_SPACING_MD,
              borderBottom: "1px solid #555",
              whiteSpace: "nowrap",
            }}
          >
            <Container maxWidth="md" sx={{ pb: configEntzy.APP_SPACING_SM }}>
              <fieldset className="fs-mid">
                <legend className="fsl-left">
                  <Typography variant="body2" color="white">
                    Launching
                    <span>&nbsp;</span>
                    <FontAwesomeIcon
                      icon={iconRight}
                      color="orange"
                      fixedWidth
                    />
                  </Typography>
                </legend>
                <Box className="box-default">
                  <MenuEventBar
                    {...props}
                    filters={{
                      mini: true,
                      core: true,
                      // square: true,
                      shadow: true,
                      mainBar: true,
                    }}
                  />
                </Box>
              </fieldset>
            </Container>
          </Box>

          {/* members popup */}
          <Drawer
            anchor="bottom"
            open={eventContext.state.memberDrawer.open}
            onClose={handleCloseMembers}
          >
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD2X,
                pb: configEntzy.APP_SPACING_MD2X,
                pl: configEntzy.APP_SPACING_MD,
                pr: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pl: configEntzy.APP_SPACING_SM,
                  pr: configEntzy.APP_SPACING_SM,
                }}
              >
                <Typography variant="subtitle2" className="text-right">
                  <FontAwesomeIcon
                    icon={iconClose}
                    size="2x"
                    className="action-pointer"
                    onClick={handleCloseMembers}
                    fixedWidth
                  />
                </Typography>
                <Typography variant="h6" className="upper-case">
                  {eventContext.state.event.data.Url}
                </Typography>
                <Typography variant="subtitle2">
                  Eventuator Runners & Riders
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Container maxWidth="sm">
                  <WidgetMemberViewer {...props} eventView={true} />
                </Container>
              </Box>
            </Box>
          </Drawer>

          {/* ticket popup */}
          <Drawer
            anchor="bottom"
            open={showTickets}
            onClose={handleShowTickets}
          >
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD2X,
                pb: configEntzy.APP_SPACING_MD2X,
                pl: configEntzy.APP_SPACING_MD,
                pr: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pl: configEntzy.APP_SPACING_SM,
                  pr: configEntzy.APP_SPACING_SM,
                }}
              >
                <Typography variant="subtitle2" className="text-right">
                  <FontAwesomeIcon
                    icon={iconClose}
                    size="2x"
                    className="action-pointer"
                    onClick={handleShowTickets}
                    fixedWidth
                  />
                </Typography>
                <Typography variant="subtitle2" className="upper-case">
                  {eventContext.state.event.data.Url}
                </Typography>
                <Typography variant="subtitle1">
                  Available Ticket Types
                </Typography>
                {eventContext.state.event.calendar.tickets.selectable.active
                  .length > 0 && (
                  <Typography variant="subtitle1">
                    <em>Select by going to Date Offers</em>
                  </Typography>
                )}
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                  p: configEntzy.APP_SPACING_MD,
                }}
              >
                <Container maxWidth="sm">
                  <Box className="box-default">
                    {eventContext.state.event.calendar.tickets.selectable.active
                      .length > 0 ? (
                      <Box className="box-default">
                        {eventContext.state.event.calendar.tickets.selectable.active.map(
                          (selectable) => {
                            return (
                              <Box
                                key={selectable.id}
                                className="box-default action-pointer"
                                sx={{
                                  mt: configEntzy.APP_SPACING_SM,
                                }}
                                onClick={() =>
                                  eventContext.focusModule("dates")
                                }
                              >
                                <TicketViewer
                                  selectable={selectable}
                                  triggers={
                                    eventContext.state.event.calendar.triggers
                                  }
                                  limits={
                                    eventContext.state.event.calendar.limits
                                  }
                                  listView={true}
                                />
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    ) : (
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                          pb: configEntzy.APP_SPACING_LG2X,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          className="content-disabled"
                        >
                          <em>Currently no ticket types on offer</em>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="content-disabled"
                        >
                          <em>Check with the run team</em>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Container>
              </Box>
            </Box>
          </Drawer>
        </Box>
      ) : (
        <Box className="box-default">
          {/* private event marquee */}
          <Box
            className="box-default bg-black-t75"
            sx={{
              p: configEntzy.APP_SPACING_SM,
              borderBottom: "1px solid #555",
              overflow: "hidden",
            }}
          >
            <Marquee
              speed={25}
              gradient={false}
              pauseOnHover={true}
              className="marquee-ticker-half nooverflow"
            >
              <Box
                className="box-inline left"
                sx={{
                  height: configEntzy.AVATAR_SIZE_XS,
                  display: "flex",
                  alignItems: "center",
                  pr: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <TextTag
                  flag={eventContext.state.event.data.Country}
                  bgColor="#222"
                  rounded={true}
                />
              </Box>
              <Box
                className="box-inline left"
                sx={{
                  height: configEntzy.AVATAR_SIZE_XS,
                  display: "flex",
                  alignItems: "center",
                  pr: configEntzy.APP_SPACING_MD,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    height: configEntzy.AVATAR_SIZE_XS,
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <span>Welcome to private eventuator&nbsp;</span>
                  <span>{configEntzy.URL_POINTERS.MAIN}</span>
                  <span>{eventContext.state.event.data.Url}</span>
                </Typography>
              </Box>
            </Marquee>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EventTicker;
