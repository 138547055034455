// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook as iconContacts } from "@fortawesome/pro-duotone-svg-icons";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import ContactsList from "./ContactsList";

function ContactsIndex(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_XL }}>
        <Box className="box-default">
          <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_LG }}>
            <Typography variant="h6" noWrap={true}>
              <FontAwesomeIcon icon={iconContacts} size="4x" />
            </Typography>
          </Box>
          <Box className="box-default">
            <Typography variant="h4" noWrap={true}>
              Your Contacts
            </Typography>
          </Box>
          <ContactsList />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ContactsIndex;
