// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { TextTag } from "components/utils/common/CommonTags";

function ContactFeatures(props) {
  return (
    <Box className="box-default">
      <Box className="box-default">
        <Typography variant="h2" color="orange" noWrap={true}>
          Entzy for You
        </Typography>
        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          Feature Requests
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="h6">
            Entzy is an evolving product and it needs to entzify around you. Let
            us know what you need as an Event Runner or an Event Rider and we
            will get your request on the upcoming feature list. As our
            development pipeline evolves we will start displaying the list here
            with priorities. Drop us an email to get started.
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
            pb: configEntzy.APP_SPACING_LG2X,
          }}
        >
          <a href="mailto:features@entzy.com" className="image-link">
            <TextTag
              size="lg"
              text="features@entzy.com"
              bgColor="primary.main"
              color="black"
              lower={true}
              rounded={true}
              shadow={true}
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
}

export default ContactFeatures;
