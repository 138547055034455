// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";

// entzy context and services
import { EventContext } from "pages/events/EventContext";

function ActionDatesRunner(props) {
  const eventContext = useContext(EventContext);
  const [user] = useState(props.user);

  const anchorTop = "anchor-action-dates-runner";

  const handleActionCallback = async (data) => {
    const dateValueList = data.filter((obj) => obj.id === "event-dates")[0]
      .value;
    // replace runner date settings with new list
    const datesRunners =
      eventContext.state.event.generators.settings.date("default");
    for (let item of dateValueList) {
      datesRunners.add.push(item);
    }
    // pass through rider date settings unaltered
    const datesRiders = eventContext.state.event.calendar.dates.riders.settings;
    // rebuild event dates and post update
    const eventDates = [];
    eventDates.push(datesRunners);
    eventDates.push(datesRiders);
    if (eventContext.state.event.manager) {
      const eventUpdate = await eventContext.prepareUpdateEvent({
        key: "Dates",
        value: eventDates,
      });
      if (eventUpdate.alert) {
        return {
          alert: true,
          message: eventUpdate.message,
        };
      } else {
        eventContext.updateEvent(eventUpdate);
        props.handleCloseAction();
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Event runner permissions required for this update",
      };
    }
  };

  return (
    <Box id={anchorTop} className="box-default">
      <Box
        className="box-default"
        sx={{
          mt: configEntzy.APP_SPACING_MD2X,
          pt: configEntzy.APP_SPACING_MD,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
          borderTop: "1px solid #ccc",
        }}
      >
        <InputForm
          navigate={props.navigate}
          fields={[
            {
              id: "event-dates",
              type: "picker-calendar",
              label: "Place your Run Date offers",
              placeholder: "Select one or many possible dates",
              value: eventContext.state.event.calendar.dates.selectable
                .filter((obj) => obj.selected.runners === true)
                .reduce((acc, obj) => [...acc, obj.value], []),
              required: true,
              disabled: !user.connected,
              options: eventContext.state.event.calendar.dates.selectable,
              optionsDecorate: {
                ticking:
                  eventContext.state.event.calendar.dates.combined.active,
                launched:
                  eventContext.state.event.calendar.dates.launched.all.keys,
              },
              optionsDisabled:
                eventContext.state.event.calendar.dates.launched.all.keys,
            },
          ]}
          submitText="Update Dates"
          // disabledSubmit={!user.connected}
          // disabledTitle="Connect to continue"
          // disabledText="Tap here to activate date offering. A connection is required to save the offer details."
          // disabledClick={context.toggleConnect}
          callback={handleActionCallback}
        />
      </Box>
    </Box>
  );
}

export default ActionDatesRunner;
