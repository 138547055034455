// react core
import { useState, useEffect } from "react";

// aws
import { Auth } from "aws-amplify";

// date handling
import dayjs from "dayjs";

// entzy models and services
import newUser from "models/User";
import configEntzy from "components/config/ConfigEntzy";
import { serviceObjectDownload } from "services/storage/object";
import { serviceLogError } from "services/graphql/call";

const userConnected = async () => {
  const data = {
    connected: false,
    attributes: null,
  };
  try {
    const user = await Auth.currentAuthenticatedUser();
    const credentials = await Auth.currentCredentials();
    const attributes = (await Auth.currentSession()).getIdToken().payload;
    data.id = credentials.identityId;
    data.username = user.username;
    data.mfa = user.preferredMFA;
    data.connected = true;
    data.attributes = attributes;
  } catch (e) {
    data.alert = true;
    data.message = e;
  }
  return data;
};

export const userGet = async (key) => {
  const authData = await userConnected();
  const userData = newUser();
  if (authData.connected) {
    try {
      // set core attributes
      userData.stateid = Date.now();
      userData.connected = true;
      userData.identity = authData.id;
      userData.username = authData.username;
      userData.mfa = authData.mfa;
      userData.sub = authData.attributes.sub;
      userData.email = authData.attributes.email;
      userData.avatar = {
        key: authData.attributes["custom:entzy_avatar"],
        images: {},
        hydrated: false,
      };
      // name and custom name handling
      if (
        authData.attributes["custom:entzy_name"] &&
        authData.attributes["custom:entzy_name"].length > 0
      ) {
        userData.name = authData.attributes["custom:entzy_name"];
      } else if (authData.attributes.name && authData.attributes.name > 0) {
        userData.name = authData.attributes.name;
      } else {
        userData.name = "set your name";
      }
      userData.name = userData.name.toLowerCase();
      userData.name = userData.name.replace(/\s/g, "_");
      userData.name = userData.name.replace(/[^0-9a-z_]/g, "");
      // set status attributes
      userData.status = {};
      userData.status.reset = false;
      userData.status.verified = authData.attributes.email_verified
        ? authData.attributes.email_verified
        : false;
      userData.status.payment_set = authData.attributes[
        "custom:entzy_payment_set"
      ]
        ? String(
            authData.attributes["custom:entzy_payment_set"]
          ).toLowerCase() === "true"
        : false;
      userData.status.payout_set = authData.attributes[
        "custom:entzy_payout_set"
      ]
        ? String(
            authData.attributes["custom:entzy_payout_set"]
          ).toLowerCase() === "true"
        : false;
      userData.status.last_email_verified_timestamp = authData.attributes[
        "custom:entzy_last_email"
      ]
        ? authData.attributes["custom:entzy_last_email"]
        : null;
      userData.status.last_email_verified_address = authData.attributes[
        "custom:entzy_last_email_add"
      ]
        ? authData.attributes["custom:entzy_last_email_add"]
        : null;
      userData.status.device_synced = authData.attributes[
        "custom:entzy_device_synced"
      ]
        ? authData.attributes["custom:entzy_device_synced"]
        : configEntzy.DEVICE_SYNC_STAMP.generateStamp();
      userData.status.last_connect = authData.attributes.auth_time;
      // set notifications
      if (authData.attributes["custom:entzy_notifications"]) {
        userData.notifications =
          authData.attributes["custom:entzy_notifications"];
      } else {
        userData.notifications = JSON.stringify([
          { id: "emailMessagesDirect", muted: false },
          { id: "emailMessagesSocial", muted: false },
        ]);
      }
      // set identity provider
      userData.provider = "internal";
      if (authData.username.startsWith("LoginWithAmazon")) {
        userData.provider = "amazon";
      }
      if (authData.username.startsWith("Google")) {
        userData.provider = "google";
      }
      if (authData.username.startsWith("SignInWithApple")) {
        userData.provider = "apple";
      }
      // hydrate avatar
      if (userData.avatar.key && !userData.avatar.key.includes(":none:")) {
        const images = await Promise.all([
          serviceObjectDownload(userData.avatar.key, "sm"),
          serviceObjectDownload(userData.avatar.key, "md"),
          serviceObjectDownload(userData.avatar.key, "lg"),
        ]);
        userData.avatar.hydrated = true;
        images.forEach((item) => {
          if (item.success) {
            userData.avatar.images[item.size] = item;
          } else {
            // any failures deactivate avatar
            userData.avatar.hydrated = false;
          }
        });
      } else {
        userData.avatar.hydrated = true;
      }
    } catch (e) {
      userData.alert = true;
      userData.message = e;
    }
  }
  // validate user data for consistency
  userValidate(userData);
  userData.status.verified = true;
  // results: return filtered if requested
  if (key) {
    if (Array.isArray(key)) {
      const output = {};
      for (let k of key) {
        output[k] = userData[k];
      }
      return output;
    } else {
      return userData[key];
    }
  } else {
    return userData;
  }
};

export const userUpdate = async (attributes) => {
  const keys = Object.keys(attributes);
  const validKeys = [
    "name",
    "custom:entzy_name",
    "custom:entzy_avatar",
    "custom:entzy_payment_set",
    "custom:entzy_payout_set",
  ];
  let validUpdate = true;
  keys.map(function (key) {
    if (!validKeys.includes(key)) {
      validUpdate = false;
    }
    return validUpdate;
  });
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
    return {
      success: true,
      data: await userGet(),
    };
  } catch (error) {
    serviceLogError("userUpdate", error);
    return { success: false };
  }
};
export const userUpdateTimestamp = () => {
  return dayjs().unix();
};

export const userValidate = async (userData) => {
  // correct partial state email changes
  if (
    userData.status.last_email_verified_timestamp &&
    userData.status.last_email_verified_address !== userData.email
  ) {
    const tsDate = dayjs.unix(userData.status.last_email_verified_timestamp);
    const tsNow = dayjs();
    const tsDiff = tsNow.diff(tsDate, "minute");
    // if last email change was less than 5 minutes ago, revert to last verified email
    if (tsDiff > 5) {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = {
        email: userData.status.last_email_verified_address,
        "custom:entzy_last_email": "",
      };
      await Auth.updateUserAttributes(user, attributes);
      alert(
        "Your recent email change was unconfirmed.\n\nResetting to original:\n" +
          userData.status.last_email_verified_address +
          "\n\nManage email settings in your profile."
      );
    }
  }
};

export const userEmailVerify = async (email, code) => {
  try {
    if (code) {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
    } else {
      await Auth.verifyCurrentUserAttribute("email");
    }
    return {
      success: true,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const userPasswordUpdate = async (credentials) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(
      user,
      credentials.currentPassword,
      credentials.newPassword
    );
    return {
      success: true,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const userMfaSetup = async (credentials) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.setupTOTP(user).then((code) => {
      const qrCode =
        "otpauth://totp/" +
        user.attributes.email +
        "?secret=" +
        code +
        "&issuer=" +
        configEntzy.APP_DOMAIN;
      return {
        success: true,
        code,
        qrCode,
      };
    });
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const userMfaActivate = async (code) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.verifyTotpToken(user, code).then(() => {
      Auth.setPreferredMFA(user, "TOTP");
      return {
        success: true,
      };
    });
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const userMfaDeactivate = async (code) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    Auth.setPreferredMFA(user, "NOMFA");
    return {
      success: true,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const userExit = async () => {
  try {
    await Auth.signOut();
    return {
      success: true,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const useIdentityState = (setContextUser) => {
  const [identifying, setIdentifying] = useState(true);
  const [user, setUser] = useState(null);
  const userReload = async (userState) => {
    setIdentifying(true);
    if (!userState) {
      userState = await userGet();
    }
    setUser(userState);
    setIdentifying(false);
    return userState;
  };
  useEffect(() => {
    let cancel = false;
    const fetchUser = async () => {
      setIdentifying(true);
      const results = await userGet();
      if (!cancel) {
        setUser(results);
        if (setContextUser) {
          setContextUser(results);
        }
      }
      setIdentifying(false);
    };
    fetchUser();
    return () => {
      cancel = true;
    };
  }, [setContextUser]);
  return [identifying, user, userReload];
};

// USAGE
// // identity hook
// const [identifying, user] = useIdentityUserState();
