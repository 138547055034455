// react core
import { useContext, useState, useEffect } from "react";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripe as iconIncome } from "@fortawesome/free-brands-svg-icons";
import { faCog as iconOtherActions } from "@fortawesome/pro-thin-svg-icons";
import { faArrowRight as iconArrowRight } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and services
import { SettingsContext } from "pages/settings/SettingsContext";
import { ActiveTag, WarningTag } from "components/utils/common/CommonTags";
import {
  ActionAlert,
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

// alert messages
const ALERT_MESSAGES = {
  errors: {
    accountLogin:
      "Currently unable to generate a login connection. Give it another go or contact us if this persists.",
    accountActivateCode:
      "A valid account connection code is required (sent by Stripe on completion of account creation)",
  },
  success: {
    accountLogin:
      "Your account login url is ready. Click the link to connect to your income account.",
    accountDeactivate: "Your income account was successfully deactivated",
  },
};

function IncomeBank(props) {
  const context = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);
  const [linkInProgress, setLinkInProgress] = useState(false);
  const [activating, setActivating] = useState(false);
  const [otherActions, setOtherActions] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [createCode, setCreateCode] = useState(props.searchParams.get("code"));

  // component functions
  const handleOpenAccountActivate = async () => {
    setLoading(true);
    setLinkInProgress(true);
    props.menuSelectById("connecting:StripeConnect");
  };
  const handleRefresh = async () => {
    setLoading(true);
    window.location.reload();
  };

  const handleOtherActions = async () => {
    setOtherActions(!otherActions);
  };

  const handleAccountActivate = async () => {
    if (createCode && createCode.length) {
      const settingsUpdate = await context.prepareUpdateSettings({
        key: "PayoutId",
        value: createCode,
      });
      if (settingsUpdate.alert) {
        return {
          alert: true,
          message: settingsUpdate.message,
        };
      } else {
        context.updateSettings(settingsUpdate);
        props.userReload();
        return {
          alert: false,
          message: ALERT_MESSAGES.success.accountActivate,
        };
      }
    } else {
      return {
        alert: true,
        message: ALERT_MESSAGES.errors.accountActivateCode,
      };
    }
  };

  const handleAccountDeactivateCancel = async () => {
    setDialog(false);
  };
  const handleAccountDeactivateConfirm = async () => {
    handleAccountDeactivate(null, true);
  };

  const handleAccountDeactivate = async (event, confirm) => {
    // TODO: VALIDATE THAT NO PAID EVENTS IN PROGRESS
    // HANDLE THIS BY CHECKING EVENTS OR DO WE HAVE A FALLBACK SUSPENSE ACCOUNT?
    if (confirm) {
      setDialog(false);
      setLoading(true);
      const settingsUpdate = await context.prepareUpdateSettings({
        key: "PayoutId",
        value: "delete",
      });
      if (settingsUpdate.alert) {
        setMessage(settingsUpdate.message);
        setAlert(true);
      } else {
        context.updateSettings(settingsUpdate);
        setMessage(ALERT_MESSAGES.success.accountDeactivate);
        setSuccess(true);
        props.userReload();
      }
      setLoading(false);
    } else {
      setDialog(true);
    }
  };

  // hook: first time account activation callback
  useEffect(() => {
    const activateIncomeBank = async () => {
      setActivating(true);
      setLoading(true);
      const results = await handleAccountActivate();
      setMessage(results.message);
      setAlert(results.alert);
      setLoading(false);
      setCreateCode(null);
      setActivating(false);
      props.navigate(props.page);
    };
    if (createCode && !activating) {
      activateIncomeBank();
    }
  });

  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_LG,
        pb: configEntzy.APP_SPACING_LG,
      }}
    >
      <Container maxWidth="sm">
        <Box className="box-default" hidden={loading || linkInProgress}>
          <Box className="box-default">
            <ActiveTag
              value={context.state.user.status.payout_set}
              prefix="income account"
            />
          </Box>
          {context.state.user.status.payout_set &&
            context.state.settings.data.PayoutId && (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                {context.state.settings.data.PayoutId.split(":")[0] ===
                "none" ? (
                  <WarningTag text="Income account link could not be detected. Refreshing or reconnecting may resolve but if the problem persists try unlinking and relinking your account." />
                ) : (
                  <Box className="box-default">
                    <Typography variant="subtitle2" noWrap={true}>
                      Your income account is connected
                    </Typography>
                    <Typography variant="subtitle2" noWrap={true}>
                      You have ticket selling capability
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          <Box className="box-default">
            {context.state.user.status.payout_set ? (
              <Box className="box-default">
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <ActionBoxButton
                    variant="contained"
                    color="tertiary"
                    size="medium"
                    onClick={() =>
                      props.menuSelectById("settings-income-balance")
                    }
                    text="Go to Account Balance"
                    topIcon={
                      <FontAwesomeIcon
                        icon={iconIncome}
                        size="3x"
                        transform="up-2"
                        fixedWidth
                      />
                    }
                    endIcon={
                      <FontAwesomeIcon icon={iconArrowRight} fixedWidth />
                    }
                  />
                </Box>
                <Container maxWidth="xs">
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_HL,
                      pt: configEntzy.APP_SPACING_HL,
                    }}
                  >
                    <ActionBoxButton
                      variant="contained"
                      color="tertiary"
                      size="small"
                      onClick={handleOtherActions}
                      text={otherActions ? "Cancel X" : "Other Actions"}
                      endIcon={
                        otherActions ? null : (
                          <FontAwesomeIcon icon={iconOtherActions} fixedWidth />
                        )
                      }
                    />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                    }}
                    hidden={!otherActions}
                  >
                    <ActionBoxButton
                      variant="outlined"
                      size="small"
                      bgColor="danger.dark"
                      color="danger.contrastText"
                      onClick={handleAccountDeactivate}
                      sx={{ opacity: 0.8 }}
                      text="Unlink Account"
                      subtext="Remove the income bank link"
                    />
                  </Box>
                  <Box className="box-default">
                    <ActionCheck
                      dialog={dialog}
                      title="Confirm Unlink Income Account"
                      description="Warning! Please ensure you have no remaining balance left in your account as it will no longer be visible after you unlink it. Select yes to proceed with the disconnection."
                      cancel={handleAccountDeactivateCancel}
                      confirm={handleAccountDeactivateConfirm}
                    />
                  </Box>
                </Container>
              </Box>
            ) : (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_XL,
                }}
              >
                <Box className="box-default">
                  <ActionBoxButton
                    variant="contained"
                    color="bright"
                    size="medium"
                    onClick={handleOpenAccountActivate}
                    text="Tap here to link bank details"
                    subtext="Payment Processing with Stripe"
                    topIcon={
                      <FontAwesomeIcon
                        icon={iconIncome}
                        size="3x"
                        transform="up-2"
                        fixedWidth
                      />
                    }
                  />
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_XL,
                  }}
                >
                  <Typography variant="h6">Generating Revenue</Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ mt: configEntzy.APP_SPACING_MD }}
                  >
                    Accept payments and generate income
                  </Typography>
                  <Typography variant="subtitle1">
                    from Eventuators and Direct Pay
                  </Typography>
                  <Typography variant="subtitle1">
                    <em>
                      Income fees are {configEntzy.FEE_VARIABLE * 100}% + $
                      {configEntzy.FEE_FIXED_USD.toFixed(2)}
                    </em>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {loading && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_XL,
              mb: configEntzy.APP_SPACING_XL,
            }}
          >
            <ActionLoader />
          </Box>
        )}
        {linkInProgress && (
          <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
            <ActionAlert
              severity="info"
              title="Bank Link in Progress"
              message="Tap here to refresh when you have completed the connection with Stripe"
              onClick={handleRefresh}
            />
          </Box>
        )}
        {alert && message && (
          <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
            <ActionAlert severity="error" message={message} />
          </Box>
        )}
        {success && message && (
          <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_XL }}>
            <ActionAlert severity="success" message={message} />
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default IncomeBank;

// + '&stripe_user[business_type]=individual'
// + '&suggested_capabilities[]=transfers'
// ---------------
// settings.payouts.schedule.interval
// string
// How frequently funds will be paid out
// One of manual (payouts only created via API call), daily, weekly, or monthly.
// after account is created update settings? https://stripe.com/docs/api/accounts/update
// ---------------
