// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
// import { faClose as iconClose } from "@fortawesome/pro-duotone-svg-icons";
// import { faInfoCircle as iconInfo } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { EventContext } from "pages/events/EventContext";

function ActionCoreInfo(props) {
  const context = useContext(EventContext);
  const handleShowLaunchSettings = async () => {
    context.focusModule("launches");
  };

  return (
    <Box className="box-default">
      <Card variant="outlined">
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_LG,
          }}
        >
          {/* dates runner info */}
          {context.state.event.moduleSelected === "dates" &&
            props.runnerViewer && (
              <Box className="box-default">
                <Typography variant="subtitle2">INFO</Typography>
                <Typography variant="body2">
                  <em>Select the dates you are able to</em>
                </Typography>
                <Typography variant="body2">
                  <em>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    run the event with{" "}
                    {context.state.event.calendar.limits.notice}{" "}
                    {context.state.event.calendar.limits.notice === 1
                      ? "day"
                      : "days"}{" "}
                    notice
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <FontAwesomeIcon
                      icon={iconEdit}
                      className="action-pointer"
                      onClick={handleShowLaunchSettings}
                    />
                  </em>
                </Typography>
                <Typography variant="subtitle1">
                  <em>Add ticket types</em>
                </Typography>
              </Box>
            )}
          {/* dates rider info */}
          {context.state.event.moduleSelected === "dates" && props.riderViewer && (
            <Box className="box-default">
              <Typography variant="subtitle2">INFO</Typography>
              <Typography variant="body2">
                <em>Select a ticket and confirm that you can</em>
              </Typography>
              <Typography variant="body2">
                <em>attend on one of the following possible dates</em>
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default ActionCoreInfo;
