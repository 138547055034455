// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faThumbTack as iconPin } from "@fortawesome/pro-duotone-svg-icons";
import { faRunning as iconRunner } from "@fortawesome/pro-duotone-svg-icons";
import { faBiking as iconRider } from "@fortawesome/pro-duotone-svg-icons";

// entzy config and context
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
// import { ActiveTag } from "components/utils/common/CommonTags";

function ActionLaunchpadPin(props) {
  // const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handlePinEvent = async (event, confirm) => {
    if (!eventContext.state.event.pinned) {
      setDialog({ visible: false });
      await handleConfirmPinEvent();
    } else if (confirm === true) {
      setDialog({ visible: false });
      await handleConfirmPinEvent();
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: eventContext.state.event.owner
          ? "Confirm Remove Eventuator"
          : eventContext.state.event.manager
          ? "Confirm Remove Manager Access"
          : "Confirm Remove Ride",
        description: eventContext.state.event.owner
          ? "Are you sure you want to remove this eventuator from your runners? You are the owner so this will deactivate launching and remove all activity from your feed. You can add it back at any time."
          : eventContext.state.event.manager
          ? "Are you sure you want to remove this eventuator from your runners and revoke your manager access? You will lose run team permissions immediately and will need to contact the eventuator owner if your access needs to be restored."
          : eventContext.state.event.data.Access === "private"
          ? "Are you sure you want to remove this eventuator from your rides? You will need to request access again to rejoin."
          : "Are you sure you want to remove this eventuator from your rides? You can add it back at any time.",
        cancel: (e) => handlePinEvent(e, false),
        confirm: (e) => handlePinEvent(e, true),
      });
    }
  };

  const handleConfirmPinEvent = async () => {
    setLoading(true);
    const updatedPinned = !eventContext.state.event.pinned;
    const response = await eventContext.preparePinEvent({
      EventId: eventContext.state.event.data.EventId,
      pinned: updatedPinned,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
      setLoading(false);
      return response;
    } else {
      eventContext.pinEvent(response);
      if (updatedPinned) {
        mainContext.addEvent(
          eventContext.state.event.data,
          eventContext.state.event.owner,
          eventContext.state.event.manager
        );
      } else {
        mainContext.removeEvent(eventContext.state.event.data);
        eventContext.exitEvent({ mainContext, eventContext });
      }
      setLoading(false);
      return { alert: false };
    }
  };

  return (
    <Box className="box-default">
      <Box className="box-default">
        {loading || !eventContext.state.event.hydrated ? (
          <Box
            className="box-default"
            // sx={{
            //   pb: configEntzy.APP_SPACING_LG,
            // }}
          >
            <ActionLoader size="md" color="success" />
          </Box>
        ) : (
          <Box className="box-default">
            {eventContext.state.event.pinned ? (
              <Box className="box-default">
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_SM,
                    pb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  <Typography variant="subtitle2">
                    <FontAwesomeIcon
                      icon={
                        eventContext.state.event.manager
                          ? iconRunner
                          : iconRider
                      }
                      size="3x"
                      transform="up-4"
                      fixedWidth
                    />
                  </Typography>
                  <Typography variant="subtitle2" className="upper-case">
                    {eventContext.state.event.manager
                      ? "You have Runner Status"
                      : "You have Rider Status"}
                  </Typography>
                  <Typography variant="subtitle1">
                    {eventContext.state.event.owner ? (
                      <em>You are on the run team as owner</em>
                    ) : eventContext.state.event.manager ? (
                      <em>You are on the run team as manager</em>
                    ) : (
                      <em>Eventuator is pinned to your rides</em>
                    )}
                  </Typography>
                </Box>
                <Box
                  className="box-default"
                  sx={{ p: configEntzy.APP_SPACING_MD }}
                >
                  <ActionButton
                    variant="contained"
                    type="button"
                    size="small"
                    color="danger"
                    disableElevation={true}
                    onClick={handlePinEvent}
                    text="Remove from your feed"
                  />
                </Box>
              </Box>
            ) : (
              <Box className="box-default">
                <ActionButton
                  variant="contained"
                  type="button"
                  size="small"
                  color="secondary"
                  onClick={handlePinEvent}
                  text={
                    eventContext.state.event.owner
                      ? "Add back to your feed"
                      : eventContext.state.event.manager
                      ? "Add back to your feed"
                      : "Follow this eventuator"
                  }
                  // endIcon={
                  //   <FontAwesomeIcon
                  //     icon={iconPin}
                  //     style={{
                  //       fontSize: "inherit",
                  //     }}
                  //     transform="grow-10 rotate-45"
                  //     fixedWidth
                  //   />
                  // }
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>
    </Box>
  );
}

export default ActionLaunchpadPin;
