// react core
import { useContext, useState, useEffect } from "react";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck as iconComplete } from "@fortawesome/pro-solid-svg-icons";
import { faTimes as iconIncomplete } from "@fortawesome/pro-solid-svg-icons";
import { faWarning as iconWarning } from "@fortawesome/pro-duotone-svg-icons";
import { faListAlt as iconTransactions } from "@fortawesome/pro-duotone-svg-icons";
import { faUndo as iconRefund } from "@fortawesome/pro-solid-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { SettingsContext } from "pages/settings/SettingsContext";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { MoreButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";
import { getAge, getCurrencyDisplayAmount } from "models/Tools";

function TransactionList(props) {
  const user = props.user;
  const paymentView = props.paymentView ? true : false;
  const incomeView = props.incomeView ? true : false;
  const mainContext = useContext(MainContext);
  const settingsContext = useContext(SettingsContext);
  const [transactions, setTransactions] = useState([]);
  const [showAll, setShowAll] = useState(props.combinedView ? true : false);
  const [showButtonText, setShowButtonText] = useState("Show All");
  const [emptyText, setEmptyText] = useState("No transactions found.");
  const [headerText, setHeaderText] = useState("Recent Transactions");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleRefundConfirm = async (event, confirm, transaction) => {
    if (confirm === true) {
      setDialog({ visible: false });
      await handleRefundExecute(transaction);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Refund",
        description:
          "Are you sure you want to refund this transaction for " +
          transaction.Currency.toUpperCase() +
          " " +
          (transaction.Amount / 100).toFixed(2) +
          "? This will attempt to return the funds to the sender.",
        cancel: (e) => handleRefundConfirm(e, false),
        confirm: (e) => handleRefundConfirm(e, true, transaction),
      });
    }
  };
  const handleRefundExecute = async (transaction) => {
    setLoadingItem(transaction.TransactionId);
    const response = await settingsContext.prepareRefundTransaction({
      TransactionId: transaction.TransactionId,
      EventId: transaction.EventId,
      TicketId: transaction.TicketId,
      EntryDate: transaction.EntryDate,
    });
    if (!response.success) {
      mainContext.updateAlert({
        alert: true,
        message: response.message,
      });
    } else {
      settingsContext.refundTransaction(transaction);
    }
    setLoadingItem(null);
  };

  // component functions
  const handleShowAll = async () => {
    setShowAll(!showAll);
  };

  const handlePullMore = async () => {
    setLoadingMore(true);
    const response = await settingsContext.preparePullTransactions({
      nextToken: settingsContext.state.transactions.nextToken,
      more: true,
    });
    if (response.alert) {
      settingsContext.updateAlert(response);
    } else {
      settingsContext.pullTransactions(response);
    }
    setLoadingMore(false);
  };

  // use effect to tailor view based on props and showAll
  useEffect(() => {
    if (settingsContext.state.transactions.hydrated) {
      setLoading(true);
      if (paymentView) {
        if (showAll) {
          setTransactions(settingsContext.state.transactions.data);
          setHeaderText("Recent Transactions");
          setShowButtonText("Show Payments Only");
          setEmptyText("No income or payment transactions");
        } else {
          setTransactions(
            settingsContext.state.transactions.data.filter(
              (item) => item["TransactionType"] === "payment"
            )
          );
          setHeaderText("Recent Payment Transactions");
          setShowButtonText("Show with Income");
          setEmptyText("No payment transactions");
        }
      } else if (incomeView) {
        if (showAll) {
          setTransactions(settingsContext.state.transactions.data);
          setHeaderText("Recent Transactions");
          setShowButtonText("Show Income Only");
          setEmptyText("No payment or income transactions");
        } else {
          setTransactions(
            settingsContext.state.transactions.data.filter(
              (item) => item["TransactionType"] === "income"
            )
          );
          setHeaderText("Recent Income Transactions");
          setShowButtonText("Show with Payments");
          setEmptyText("No income transactions");
        }
      } else {
        setTransactions(settingsContext.state.transactions.data);
        setHeaderText("Recent Transactions");
        setEmptyText("No payment or income transactions");
      }
    }
    setLoading(false);
  }, [
    settingsContext.state.transactions.hydrated,
    settingsContext.state.transactions.data,
    showAll,
    paymentView,
    incomeView,
  ]);

  return (
    <Box className="box-default">
      {props.combinedView && (
        <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_LG2X }}>
          {user.status.payout_set ? (
            <Box className="box-default half-width">
              <Box className="box-default">
                <FontAwesomeIcon
                  icon={iconComplete}
                  size="4x"
                  color="green"
                  fixedWidth
                />
              </Box>
              <Box className="box-default">
                <TextTag
                  text={<span>&nbsp;Income Active&nbsp;</span>}
                  size="md"
                  color="green"
                  rounded={true}
                  margin={true}
                />
              </Box>
            </Box>
          ) : (
            <Box
              className="box-default half-width action-pointer"
              onClick={() => {
                mainContext.setMemberTransactionViewer({
                  open: false,
                });
                props.menuSelectById("settings-income-bank");
              }}
            >
              <Box className="box-default">
                <FontAwesomeIcon
                  icon={iconWarning}
                  size="4x"
                  color="orange"
                  fixedWidth
                />
              </Box>
              <Box className="box-default">
                <TextTag
                  text={<span>&nbsp;Activate Income&nbsp;</span>}
                  size="md"
                  bgColor="warning.light"
                  color="black"
                  rounded={true}
                  margin={true}
                  shadow={true}
                />
              </Box>
            </Box>
          )}
          {user.status.payment_set ? (
            <Box className="box-default half-width">
              <Box className="box-default">
                <FontAwesomeIcon
                  icon={iconComplete}
                  size="4x"
                  color="green"
                  fixedWidth
                />
              </Box>
              <Box className="box-default">
                <TextTag
                  text={<span>&nbsp;Payments Active&nbsp;</span>}
                  size="md"
                  bgColor="success.main"
                  color="green"
                  rounded={true}
                  margin={true}
                />
              </Box>
            </Box>
          ) : (
            <Box
              className="box-default half-width action-pointer"
              onClick={() => {
                mainContext.setMemberTransactionViewer({
                  open: false,
                });
                props.menuSelectById("settings-payment-methods");
              }}
            >
              <Box className="box-default">
                <FontAwesomeIcon
                  icon={iconWarning}
                  size="4x"
                  color="orange"
                  fixedWidth
                />
              </Box>
              <Box className="box-default">
                <TextTag
                  text={<span>&nbsp;Activate Payments&nbsp;</span>}
                  size="md"
                  bgColor="warning.light"
                  color="black"
                  rounded={true}
                  margin={true}
                  shadow={true}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_MD }}>
        <Typography variant="h6" component="h6" gutterBottom>
          {headerText}
        </Typography>
        {props.incomeView && (
          <Typography variant="subtitle1" noWrap={true}>
            <em>
              Income fees are {configEntzy.FEE_VARIABLE * 100}% + $
              {configEntzy.FEE_FIXED_USD.toFixed(2)}
            </em>
          </Typography>
        )}
      </Box>
      {props.combinedView ? (
        <Box className="box-default">
          {user.status.payout_set && (
            <Box
              className="box-default"
              sx={{ pb: configEntzy.APP_SPACING_LG }}
            >
              <TextTag
                text={<span>&nbsp;View Balance&nbsp;</span>}
                size="md"
                color="black"
                rounded={true}
                margin={true}
                shadow={true}
                onClick={() => {
                  mainContext.setMemberTransactionViewer({
                    open: false,
                  });
                  props.menuSelectById("settings-income-balance");
                }}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_LG }}>
          <TextTag
            text={
              <span>
                &nbsp;
                {showButtonText}
                &nbsp;
              </span>
            }
            size="md"
            color="black"
            rounded={true}
            margin={true}
            shadow={true}
            onClick={handleShowAll}
          />
        </Box>
      )}
      {loading ? (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
            mb: configEntzy.APP_SPACING_XL,
          }}
        >
          <ActionLoader />
        </Box>
      ) : transactions.length === 0 ? (
        <Box className="box-default">
          <Typography
            variant="subtitle2"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              mb: configEntzy.APP_SPACING_MD,
            }}
          >
            <FontAwesomeIcon
              icon={iconTransactions}
              size="8x"
              color={props.combinedView ? "lightgray" : "white"}
            />
          </Typography>
          <Typography variant="subtitle2">{emptyText}</Typography>
        </Box>
      ) : (
        <Box className="box-default">
          {transactions.map((transaction, index) => (
            <Box
              className="box-default"
              key={transaction.TransactionId}
              sx={{
                pt: configEntzy.APP_SPACING_SM,
                pb: configEntzy.APP_SPACING_LG,
                borderTop: "1px solid #999",
              }}
            >
              {loadingItem === transaction.TransactionId && (
                <Box className="box-default zero-height">
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <ActionLoader />
                  </Box>
                </Box>
              )}
              <Grid container>
                <Grid item xs={6} className="text-left">
                  <TextTag
                    text={
                      <span>
                        &nbsp;
                        {transaction.TransactionStatus === "complete" ? (
                          <FontAwesomeIcon icon={iconComplete} fixedWidth />
                        ) : transaction.TransactionStatus === "refunded" ? (
                          <FontAwesomeIcon icon={iconRefund} fixedWidth />
                        ) : (
                          <FontAwesomeIcon icon={iconIncomplete} fixedWidth />
                        )}
                        &nbsp;
                      </span>
                    }
                    bgColor={
                      transaction.TransactionStatus === "complete"
                        ? "success.main"
                        : "danger.light"
                    }
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                  <TextTag
                    text={
                      <span>&nbsp;{transaction.TransactionType}&nbsp;</span>
                    }
                    bgColor={
                      transaction.TransactionType === "income"
                        ? "success.main"
                        : "secondary.dark"
                    }
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                </Grid>
                <Grid item xs={6} className="text-right">
                  <TextTag
                    text={
                      <span>
                        &nbsp;{transaction.Currency}&nbsp;
                        {getCurrencyDisplayAmount(transaction.Amount)}
                        &nbsp;
                      </span>
                    }
                    bgColor={"bright.main"}
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                </Grid>
                {transaction.TransactionStatus !== "complete" && (
                  <Grid item xs={12} className="text-left">
                    <TextTag
                      text={
                        <span>
                          <span>&nbsp;Transaction&nbsp;</span>
                          {transaction.TransactionStatus}
                          <span>&nbsp;</span>
                          {/* {transaction.TransactionType !== "income" && (
                            <span>&nbsp;-&nbsp;No&nbsp;charges&nbsp;</span>
                          )} */}
                        </span>
                      }
                      bgColor="danger.light"
                      color="black"
                      textNoCasing={true}
                      rounded={true}
                      margin={true}
                    />
                  </Grid>
                )}
                {transaction.TransactionType === "income" && (
                  <Grid item xs={12} className="text-right">
                    <TextTag
                      text={
                        <span>
                          &nbsp;Fee&nbsp;{transaction.Currency}&nbsp;
                          {getCurrencyDisplayAmount(transaction.Fee)}&nbsp;
                        </span>
                      }
                      color="black"
                      rounded={true}
                      margin={true}
                    />
                  </Grid>
                )}
                {transaction.TransactionType === "income" && (
                  <Grid item xs={12} className="text-right">
                    <TextTag
                      text={
                        <span>
                          &nbsp;Net&nbsp;{transaction.Currency}&nbsp;
                          {getCurrencyDisplayAmount(
                            transaction.Amount - transaction.Fee
                          )}
                          &nbsp;
                        </span>
                      }
                      color="black"
                      rounded={true}
                      margin={true}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  className="text-left"
                  sx={{ mt: configEntzy.APP_SPACING_MD }}
                >
                  <TextTag
                    text={
                      <span>
                        &nbsp;From&nbsp;
                        {transaction.SenderType}&nbsp;
                        {transaction.SenderType === "eventuator"
                          ? configEntzy.URL_POINTERS.MAIN
                          : transaction.SenderType === "guest"
                          ? ""
                          : "@"}
                        {transaction.SenderName}&nbsp;
                      </span>
                    }
                    textNoCasing={true}
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                </Grid>
                <Grid item xs={8} className="text-left">
                  <TextTag
                    text={
                      <span>
                        &nbsp;To&nbsp;
                        {transaction.RecipientType}&nbsp;
                        {transaction.RecipientType === "eventuator"
                          ? configEntzy.URL_POINTERS.MAIN
                          : "@"}
                        {transaction.RecipientName}&nbsp;
                      </span>
                    }
                    textNoCasing={true}
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                </Grid>
                <Grid item xs={4} className="text-right">
                  <TextTag
                    text={
                      <span>
                        &nbsp;
                        {getAge({ timestamp: transaction.LastUpdated })}
                        &nbsp;
                      </span>
                    }
                    color="black"
                    rounded={true}
                    margin={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="text-left"
                  sx={{
                    p: configEntzy.APP_SPACING_SM,
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                      p: configEntzy.APP_SPACING_SM2X,
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                    }}
                  >
                    <Typography variant="body2">
                      <em>Description</em>
                    </Typography>
                    <Typography variant="body2">
                      {transaction.Description
                        ? transaction.Description
                        : "No description"}
                    </Typography>
                  </Box>
                  {/* <TextTag
                    text={
                      <span>
                        &nbsp;
                        {transaction.Description}
                        &nbsp;
                      </span>
                    }
                    textNoCasing={true}
                    color="black"
                    rounded={true}
                    margin={true}
                  /> */}
                </Grid>
              </Grid>
              {transaction.TransactionType === "income" &&
                transaction.TransactionStatus !== "refunded" && (
                  <Grid
                    item
                    xs={12}
                    className="text-right"
                    sx={{ mt: configEntzy.APP_SPACING_MD }}
                  >
                    <TextTag
                      text={
                        <span>
                          <FontAwesomeIcon
                            icon={iconRefund}
                            transform="shrink-2"
                          />
                          <span>&nbsp;&nbsp;</span>
                          <span>Issue&nbsp;Refund</span>
                          <span>&nbsp;</span>
                        </span>
                      }
                      textNoCasing={true}
                      color="white"
                      rounded={true}
                      margin={true}
                      shadow={true}
                      bgColor="dusk.dark"
                      onClick={(e) => handleRefundConfirm(e, null, transaction)}
                    />
                  </Grid>
                )}
            </Box>
          ))}
        </Box>
      )}
      {settingsContext.state.transactions.nextToken && (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <MoreButton
            variant="contained"
            size="small"
            type="button"
            color="bright"
            text="More"
            loading={loadingMore}
            onClick={handlePullMore}
          />
        </Box>
      )}
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>
    </Box>
  );
}

export default TransactionList;
