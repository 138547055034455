const CURRENCY_MODEL = {
  aed: {
    code: { short: "د.إ", long: "aed", numeric: "784" },
    name: "Emirati Dirham",
    decimal: true,
    accepted: true,
    common: false,
  },
  afn: {
    code: { short: "؋", long: "afn", numeric: "971" },
    name: "Afghan Afghani",
    decimal: true,
    accepted: true,
    common: false,
  },
  all: {
    code: { short: "L", long: "all", numeric: "008" },
    name: "Albanian Lek",
    decimal: true,
    accepted: true,
    common: false,
  },
  amd: {
    code: { short: "֏", long: "amd", numeric: "051" },
    name: "Armenian Dram",
    decimal: true,
    accepted: true,
    common: false,
  },
  ang: {
    code: { short: "ƒ", long: "ang", numeric: "532" },
    name: "Netherlands Antillean Guilder",
    decimal: true,
    accepted: true,
    common: false,
  },
  aoa: {
    code: { short: "Kz", long: "aoa", numeric: "973" },
    name: "Angolan Kwanza",
    decimal: true,
    accepted: true,
    common: false,
  },
  ars: {
    code: { short: "$", long: "ars", numeric: "032" },
    name: "Argentine Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  aud: {
    code: { short: "$", long: "aud", numeric: "036" },
    name: "Australian Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  awg: {
    code: { short: "ƒ", long: "awg", numeric: "533" },
    name: "Aruban Florin",
    decimal: true,
    accepted: true,
    common: false,
  },
  azn: {
    code: { short: "₼", long: "azn", numeric: "944" },
    name: "Azerbaijani Manat",
    decimal: true,
    accepted: true,
    common: false,
  },
  bam: {
    code: { short: "KM", long: "bam", numeric: "977" },
    name: "Bosnia and Herzegovina Convertible Mark",
    decimal: true,
    accepted: true,
    common: false,
  },
  bbd: {
    code: { short: "$", long: "bbd", numeric: "052" },
    name: "Barbados Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  bdt: {
    code: { short: "৳", long: "bdt", numeric: "050" },
    name: "Bangladeshi Taka",
    decimal: true,
    accepted: true,
    common: false,
  },
  bgn: {
    code: { short: "лв", long: "bgn", numeric: "975" },
    name: "Bulgarian Lev",
    decimal: true,
    accepted: true,
    common: false,
  },
  bmd: {
    code: { short: "BD$", long: "bmd", numeric: "060" },
    name: "Bermudian Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  bnd: {
    code: { short: "B$", long: "bnd", numeric: "096" },
    name: "Brunei Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  bob: {
    code: { short: "$b", long: "bob", numeric: "068" },
    name: "Boliviano",
    decimal: true,
    accepted: true,
    common: false,
  },
  brl: {
    code: { short: "R$", long: "brl", numeric: "986" },
    name: "Brazilian Real",
    decimal: true,
    accepted: true,
    common: false,
  },
  bsd: {
    code: { short: "B$", long: "bsd", numeric: "044" },
    name: "Bahamian Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  bwp: {
    code: { short: "P", long: "bwp", numeric: "072" },
    name: "Botswana Pula",
    decimal: true,
    accepted: true,
    common: false,
  },
  bzd: {
    code: { short: "BZ$", long: "bzd", numeric: "084" },
    name: "Belize Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  cad: {
    code: { short: "C$", long: "cad", numeric: "124" },
    name: "Canadian Dollar",
    decimal: true,
    accepted: true,
    common: true,
  },
  cdf: {
    code: { short: "CDF", long: "cdf", numeric: "976" },
    name: "Congolese Franc",
    decimal: true,
    accepted: true,
    common: false,
  },
  chf: {
    code: { short: "Fr.", long: "chf", numeric: "756" },
    name: "Swiss Franc",
    decimal: true,
    accepted: true,
    common: true,
  },
  cny: {
    code: { short: "¥", long: "cny", numeric: "156" },
    name: "Yuan Renminbi",
    decimal: true,
    accepted: true,
    common: false,
  },
  cop: {
    code: { short: "$", long: "cop", numeric: "170" },
    name: "Colombian Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  crc: {
    code: { short: "₡", long: "crc", numeric: "188" },
    name: "Costa Rican Colon",
    decimal: true,
    accepted: true,
    common: false,
  },
  cve: {
    code: { short: "Esc", long: "cve", numeric: "132" },
    name: "Cape Verdean Escudo",
    decimal: true,
    accepted: true,
    common: false,
  },
  czk: {
    code: { short: "Kč", long: "czk", numeric: "203" },
    name: "Czech Koruna",
    decimal: true,
    accepted: true,
    common: false,
  },
  dkk: {
    code: { short: "kr", long: "dkk", numeric: "208" },
    name: "Danish Krone",
    decimal: true,
    accepted: true,
    common: false,
  },
  dop: {
    code: { short: "RD$", long: "dop", numeric: "214" },
    name: "Dominican Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  dzd: {
    code: { short: "دج", long: "dzd", numeric: "012" },
    name: "Algerian Dinar",
    decimal: true,
    accepted: true,
    common: false,
  },
  egp: {
    code: { short: "ج.م", long: "egp", numeric: "818" },
    name: "Egyptian Pound",
    decimal: true,
    accepted: true,
    common: false,
  },
  etb: {
    code: { short: "Br", long: "etb", numeric: "230" },
    name: "Ethiopian Birr",
    decimal: true,
    accepted: true,
    common: false,
  },
  eur: {
    code: { short: "€", long: "eur", numeric: "978" },
    name: "Euro",
    decimal: true,
    accepted: true,
    common: true,
  },
  fjd: {
    code: { short: "FJ$", long: "fjd", numeric: "242" },
    name: "Fiji Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  fkp: {
    code: { short: "£", long: "fkp", numeric: "238" },
    name: "Falkland Islands Pound",
    decimal: true,
    accepted: true,
    common: false,
  },
  gbp: {
    code: { short: "£", long: "gbp", numeric: "826" },
    name: "Pound Sterling",
    decimal: true,
    accepted: true,
    common: true,
  },
  gel: {
    code: { short: "GEL", long: "gel", numeric: "981" },
    name: "Georgian Lari",
    decimal: true,
    accepted: true,
    common: false,
  },
  gip: {
    code: { short: "£", long: "gip", numeric: "292" },
    name: "Gibraltar Pound",
    decimal: true,
    accepted: true,
    common: false,
  },
  gmd: {
    code: { short: "D", long: "gmd", numeric: "270" },
    name: "Gambian Dalasi",
    decimal: true,
    accepted: true,
    common: false,
  },
  gtq: {
    code: { short: "Q", long: "gtq", numeric: "320" },
    name: "Guatemalan Quetzal",
    decimal: true,
    accepted: true,
    common: false,
  },
  gyd: {
    code: { short: "GY$", long: "gyd", numeric: "328" },
    name: "Guyanese Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  hkd: {
    code: { short: "HK$", long: "hkd", numeric: "344" },
    name: "Hong Kong Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  hnl: {
    code: { short: "L", long: "hnl", numeric: "340" },
    name: "Honduran Lempira",
    decimal: true,
    accepted: true,
    common: false,
  },
  hrk: {
    code: { short: "kn", long: "hrk", numeric: "191" },
    name: "Croatian Kuna",
    decimal: true,
    accepted: true,
    common: false,
  },
  htg: {
    code: { short: "HTG", long: "htg", numeric: "332" },
    name: "Haitian Gourde",
    decimal: true,
    accepted: true,
    common: false,
  },
  huf: {
    code: { short: "Ft", long: "huf", numeric: "348" },
    name: "Hungarian Forint",
    decimal: true,
    accepted: true,
    common: false,
  },
  idr: {
    code: { short: "Rp", long: "idr", numeric: "360" },
    name: "Indonesian Rupiah",
    decimal: true,
    accepted: true,
    common: false,
  },
  ils: {
    code: { short: "₪", long: "ils", numeric: "376" },
    name: "Israeli New Shekel",
    decimal: true,
    accepted: true,
    common: false,
  },
  inr: {
    code: { short: "Rs.", long: "inr", numeric: "356" },
    name: "Indian Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  isk: {
    code: { short: "kr", long: "isk", numeric: "352" },
    name: "Icelandic Króna",
    decimal: true,
    accepted: true,
    common: false,
  },
  jmd: {
    code: { short: "J$", long: "jmd", numeric: "388" },
    name: "Jamaican Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  kes: {
    code: { short: "KSh", long: "kes", numeric: "404" },
    name: "Kenyan Shilling",
    decimal: true,
    accepted: true,
    common: false,
  },
  kgs: {
    code: { short: "лв", long: "kgs", numeric: "417" },
    name: "Kyrgyzstani Som",
    decimal: true,
    accepted: true,
    common: false,
  },
  khr: {
    code: { short: "៛", long: "khr", numeric: "116" },
    name: "Cambodian Riel",
    decimal: true,
    accepted: true,
    common: false,
  },
  kyd: {
    code: { short: "$", long: "kyd", numeric: "136" },
    name: "Cayman Islands Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  kzt: {
    code: { short: "лв", long: "kzt", numeric: "398" },
    name: "Kazakhstani Tenge",
    decimal: true,
    accepted: true,
    common: false,
  },
  lak: {
    code: { short: "₭", long: "lak", numeric: "418" },
    name: "Lao Kip",
    decimal: true,
    accepted: true,
    common: false,
  },
  lbp: {
    code: { short: "£", long: "lbp", numeric: "422" },
    name: "Lebanese Pound",
    decimal: true,
    accepted: true,
    common: false,
  },
  lkr: {
    code: { short: "₨", long: "lkr", numeric: "144" },
    name: "Sri Lankan Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  lrd: {
    code: { short: "$", long: "lrd", numeric: "430" },
    name: "Liberian Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  lsl: {
    code: { short: "LSL", long: "lsl", numeric: "426" },
    name: "Lesotho Loti",
    decimal: true,
    accepted: true,
    common: false,
  },
  mad: {
    code: { short: "د.م.", long: "mad", numeric: "504" },
    name: "Moroccan Dirham",
    decimal: true,
    accepted: true,
    common: false,
  },
  mdl: {
    code: { short: "MDL", long: "mdl", numeric: "498" },
    name: "Moldovan Leu",
    decimal: true,
    accepted: true,
    common: false,
  },
  mkd: {
    code: { short: "ден", long: "mkd", numeric: "807" },
    name: "Macedonian Denar",
    decimal: true,
    accepted: true,
    common: false,
  },
  mmk: {
    code: { short: "K", long: "mmk", numeric: "104" },
    name: "Myanmar Kyat",
    decimal: true,
    accepted: true,
    common: false,
  },
  mnt: {
    code: { short: "₮", long: "mnt", numeric: "496" },
    name: "Mongolian Tögrög",
    decimal: true,
    accepted: true,
    common: false,
  },
  mop: {
    code: { short: "$", long: "mop", numeric: "446" },
    name: "Macanese Pataca",
    decimal: true,
    accepted: true,
    common: false,
  },
  mur: {
    code: { short: "₨", long: "mur", numeric: "480" },
    name: "Mauritian Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  mvr: {
    code: { short: "Rf", long: "mvr", numeric: "462" },
    name: "Maldivian Rufiyaa",
    decimal: true,
    accepted: true,
    common: false,
  },
  mwk: {
    code: { short: "MK", long: "mwk", numeric: "454" },
    name: "Malawian Kwacha",
    decimal: true,
    accepted: true,
    common: false,
  },
  mxn: {
    code: { short: "$", long: "mxn", numeric: "484" },
    name: "Mexican Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  myr: {
    code: { short: "RM", long: "myr", numeric: "458" },
    name: "Malaysian Ringgit",
    decimal: true,
    accepted: true,
    common: false,
  },
  mzn: {
    code: { short: "MT", long: "mzn", numeric: "943" },
    name: "Mozambican Metical",
    decimal: true,
    accepted: true,
    common: false,
  },
  nad: {
    code: { short: "$", long: "nad", numeric: "516" },
    name: "Namibian Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  ngn: {
    code: { short: "₦", long: "ngn", numeric: "566" },
    name: "Nigerian Naira",
    decimal: true,
    accepted: true,
    common: false,
  },
  nio: {
    code: { short: "C$", long: "nio", numeric: "558" },
    name: "Nicaraguan Córdoba",
    decimal: true,
    accepted: true,
    common: false,
  },
  nok: {
    code: { short: "kr", long: "nok", numeric: "578" },
    name: "Norwegian Krone",
    decimal: true,
    accepted: true,
    common: false,
  },
  npr: {
    code: { short: "₨", long: "npr", numeric: "524" },
    name: "Nepalese Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  nzd: {
    code: { short: "$", long: "nzd", numeric: "554" },
    name: "New Zealand Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  pab: {
    code: { short: "B/.", long: "pab", numeric: "590" },
    name: "Panamanian Balboa",
    decimal: true,
    accepted: true,
    common: false,
  },
  pen: {
    code: { short: "S/.", long: "pen", numeric: "604" },
    name: "Peruvian Sol",
    decimal: true,
    accepted: true,
    common: false,
  },
  pgk: {
    code: { short: "K", long: "pgk", numeric: "598" },
    name: "Papua New Guinean Kina",
    decimal: true,
    accepted: true,
    common: false,
  },
  php: {
    code: { short: "₱", long: "php", numeric: "608" },
    name: "Philippine Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  pkr: {
    code: { short: "₨", long: "pkr", numeric: "586" },
    name: "Pakistani Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  pln: {
    code: { short: "zł", long: "pln", numeric: "985" },
    name: "Polish Złoty",
    decimal: true,
    accepted: true,
    common: false,
  },
  qar: {
    code: { short: "﷼", long: "qar", numeric: "634" },
    name: "Qatari Riyal",
    decimal: true,
    accepted: true,
    common: false,
  },
  ron: {
    code: { short: "L", long: "ron", numeric: "946" },
    name: "Romanian Leu",
    decimal: true,
    accepted: true,
    common: false,
  },
  rsd: {
    code: { short: "Дин.", long: "rsd", numeric: "941" },
    name: "Serbian Dinar",
    decimal: true,
    accepted: true,
    common: false,
  },
  rub: {
    code: { short: "₽", long: "rub", numeric: "643" },
    name: "Russian Ruble",
    decimal: true,
    accepted: true,
    common: false,
  },
  sar: {
    code: { short: "﷼", long: "sar", numeric: "682" },
    name: "Saudi Riyal",
    decimal: true,
    accepted: true,
    common: false,
  },
  sbd: {
    code: { short: "$", long: "sbd", numeric: "090" },
    name: "Solomon Islands Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  scr: {
    code: { short: "₨", long: "scr", numeric: "690" },
    name: "Seychelles Rupee",
    decimal: true,
    accepted: true,
    common: false,
  },
  sek: {
    code: { short: "kr", long: "sek", numeric: "752" },
    name: "Swedish Krona",
    decimal: true,
    accepted: true,
    common: false,
  },
  sgd: {
    code: { short: "$", long: "sgd", numeric: "702" },
    name: "Singapore Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  shp: {
    code: { short: "£", long: "shp", numeric: "654" },
    name: "Saint Helena Pound",
    decimal: true,
    accepted: true,
    common: false,
  },
  sll: {
    code: { short: "SLL", long: "sll", numeric: "694" },
    name: "Sierra Leonean leone",
    decimal: true,
    accepted: true,
    common: false,
  },
  sos: {
    code: { short: "S", long: "sos", numeric: "706" },
    name: "Somali Shilling",
    decimal: true,
    accepted: true,
    common: false,
  },
  srd: {
    code: { short: "$", long: "srd", numeric: "968" },
    name: "Surinamese Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  szl: {
    code: { short: "SZL", long: "szl", numeric: "748" },
    name: "Swazi Lilangeni",
    decimal: true,
    accepted: true,
    common: false,
  },
  thb: {
    code: { short: "฿", long: "thb", numeric: "764" },
    name: "Thai Baht",
    decimal: true,
    accepted: true,
    common: false,
  },
  tjs: {
    code: { short: "TJS", long: "tjs", numeric: "972" },
    name: "Tajikistani Somoni",
    decimal: true,
    accepted: true,
    common: false,
  },
  top: {
    code: { short: "T$", long: "top", numeric: "776" },
    name: "Tongan Paʻanga",
    decimal: true,
    accepted: true,
    common: false,
  },
  try: {
    code: { short: "YTL", long: "try", numeric: "949" },
    name: "Turkish Lira",
    decimal: true,
    accepted: true,
    common: false,
  },
  ttd: {
    code: { short: "TT$", long: "ttd", numeric: "780" },
    name: "Trinidad and Tobago Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  twd: {
    code: { short: "NT$", long: "twd", numeric: "901" },
    name: "New Taiwan Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  tzs: {
    code: { short: "x", long: "tzs", numeric: "834" },
    name: "Tanzanian Shilling",
    decimal: true,
    accepted: true,
    common: false,
  },
  uah: {
    code: { short: "₴", long: "uah", numeric: "980" },
    name: "Ukrainian Hryvnia",
    decimal: true,
    accepted: true,
    common: false,
  },
  usd: {
    code: { short: "$", long: "usd", numeric: "840" },
    name: "United States Dollar",
    decimal: true,
    accepted: true,
    common: true,
  },
  uyu: {
    code: { short: "$U", long: "uyu", numeric: "858" },
    name: "Uruguayan Peso",
    decimal: true,
    accepted: true,
    common: false,
  },
  uzs: {
    code: { short: "лв", long: "uzs", numeric: "860" },
    name: "Uzbekistan Som",
    decimal: true,
    accepted: true,
    common: false,
  },
  wst: {
    code: { short: "WS$", long: "wst", numeric: "882" },
    name: "Samoan Tala",
    decimal: true,
    accepted: true,
    common: false,
  },
  xcd: {
    code: { short: "EC$", long: "xcd", numeric: "951" },
    name: "East Caribbean Dollar",
    decimal: true,
    accepted: true,
    common: false,
  },
  zar: {
    code: { short: "R", long: "zar", numeric: "710" },
    name: "South African Rand",
    decimal: true,
    accepted: true,
    common: false,
  },
  zmw: {
    code: { short: "ZMW", long: "zmw", numeric: "967" },
    name: "Zambian Kwacha",
    decimal: true,
    accepted: true,
    common: false,
  },
  yer: {
    code: { short: "﷼", long: "yer", numeric: "886" },
    name: "Yemeni Rial",
    decimal: true,
    accepted: true,
    common: false,
  },
  // not accepted
  bhd: {
    code: { short: ".د.ب", long: "bhd", numeric: "048" },
    name: "Bahraini Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  bif: {
    code: { short: "Fbu", long: "bif", numeric: "108" },
    name: "Burundian Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  bov: {
    code: { short: "BOV", long: "bov", numeric: "984" },
    name: "Bolivian Mvdol",
    decimal: false,
    accepted: false,
    common: false,
  },
  btn: {
    code: { short: "Nu.", long: "btn", numeric: "064" },
    name: "Bhutanese Ngultrum",
    decimal: false,
    accepted: false,
    common: false,
  },
  byn: {
    code: { short: "Br", long: "byn", numeric: "933" },
    name: "Belarusian Ruble",
    decimal: false,
    accepted: false,
    common: false,
  },
  che: {
    code: { short: "CHE", long: "che", numeric: "947" },
    name: "WIR Euro",
    decimal: false,
    accepted: false,
    common: false,
  },
  chw: {
    code: { short: "CHW", long: "chw", numeric: "948" },
    name: "WIR Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  clf: {
    code: { short: "CLF", long: "clf", numeric: "990" },
    name: "Unidad de Fomento",
    decimal: false,
    accepted: false,
    common: false,
  },
  clp: {
    code: { short: "$", long: "clp", numeric: "152" },
    name: "Chilean Peso",
    decimal: false,
    accepted: false,
    common: false,
  },
  cou: {
    code: { short: "COU", long: "cou", numeric: "970" },
    name: "Unidad de Valor Real",
    decimal: false,
    accepted: false,
    common: false,
  },
  cuc: {
    code: { short: "CUC", long: "cuc", numeric: "931" },
    name: "Cuban Convertible Peso",
    decimal: false,
    accepted: false,
    common: false,
  },
  cup: {
    code: { short: "₱", long: "cup", numeric: "192" },
    name: "Cuban Peso",
    decimal: false,
    accepted: false,
    common: false,
  },
  djf: {
    code: { short: "Fdj", long: "djf", numeric: "262" },
    name: "Djiboutian Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  ern: {
    code: { short: "ERN", long: "ern", numeric: "232" },
    name: "Eritrean Nakfa",
    decimal: false,
    accepted: false,
    common: false,
  },
  ghs: {
    code: { short: "¢", long: "ghs", numeric: "936" },
    name: "Ghanaian Cedi",
    decimal: false,
    accepted: false,
    common: false,
  },
  gnf: {
    code: { short: "FG", long: "gnf", numeric: "324" },
    name: "Guinean Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  iqd: {
    code: { short: "ع.د", long: "iqd", numeric: "368" },
    name: "Iraqi Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  irr: {
    code: { short: "﷼", long: "irr", numeric: "364" },
    name: "Iranian Rial",
    decimal: false,
    accepted: false,
    common: false,
  },
  jod: {
    code: { short: "JOD", long: "jod", numeric: "400" },
    name: "Jordanian Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  jpy: {
    code: { short: "¥", long: "jpy", numeric: "392" },
    name: "Japanese Yen",
    decimal: false,
    accepted: false,
    common: false,
  },
  kmf: {
    code: { short: "KMF", long: "kmf", numeric: "174" },
    name: "Comoro Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  kpw: {
    code: { short: "₩", long: "kpw", numeric: "408" },
    name: "North Korean Won",
    decimal: false,
    accepted: false,
    common: false,
  },
  krw: {
    code: { short: "₩", long: "krw", numeric: "410" },
    name: "South Korean Won",
    decimal: false,
    accepted: false,
    common: false,
  },
  kwd: {
    code: { short: "د.ك", long: "kwd", numeric: "414" },
    name: "Kuwaiti Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  lyd: {
    code: { short: "LYD", long: "lyd", numeric: "434" },
    name: "Libyan Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  mga: {
    code: { short: "MGA", long: "mga", numeric: "969" },
    name: "Malagasy Ariary",
    decimal: false,
    accepted: false,
    common: false,
  },
  mru: {
    code: { short: "MRU", long: "mru", numeric: "929" },
    name: "Mauritanian Ouguiya",
    decimal: false,
    accepted: false,
    common: false,
  },
  mxv: {
    code: { short: "MXV", long: "mxv", numeric: "979" },
    name: "Mexican Unidad de Inversion",
    decimal: false,
    accepted: false,
    common: false,
  },
  omr: {
    code: { short: "﷼", long: "omr", numeric: "512" },
    name: "Omani Rial",
    decimal: false,
    accepted: false,
    common: false,
  },
  pyg: {
    code: { short: "Gs", long: "pyg", numeric: "600" },
    name: "Paraguayan Guaraní",
    decimal: false,
    accepted: false,
    common: false,
  },
  rwf: {
    code: { short: "RF", long: "rwf", numeric: "646" },
    name: "Rwandan Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  sdg: {
    code: { short: "SDG", long: "sdg", numeric: "938" },
    name: "Sudanese Pound",
    decimal: false,
    accepted: false,
    common: false,
  },
  ssp: {
    code: { short: "SSP", long: "ssp", numeric: "728" },
    name: "South Sudanese Pound",
    decimal: false,
    accepted: false,
    common: false,
  },
  stn: {
    code: { short: "STN", long: "stn", numeric: "930" },
    name: "São Tomé and Príncipe Dobra",
    decimal: false,
    accepted: false,
    common: false,
  },
  svc: {
    code: { short: "$", long: "svc", numeric: "222" },
    name: "Salvadoran Colón",
    decimal: false,
    accepted: false,
    common: false,
  },
  syp: {
    code: { short: "£", long: "syp", numeric: "760" },
    name: "Syrian Pound",
    decimal: false,
    accepted: false,
    common: false,
  },
  tmt: {
    code: { short: "TMT", long: "tmt", numeric: "934" },
    name: "Turkmenistan Manat",
    decimal: false,
    accepted: false,
    common: false,
  },
  tnd: {
    code: { short: "د.ت", long: "tnd", numeric: "788" },
    name: "Tunisian Dinar",
    decimal: false,
    accepted: false,
    common: false,
  },
  ugx: {
    code: { short: "USh", long: "ugx", numeric: "800" },
    name: "Ugandan Shilling",
    decimal: false,
    accepted: false,
    common: false,
  },
  usn: {
    code: { short: "USN", long: "usn", numeric: "997" },
    name: "United States Dollar Next Day",
    decimal: false,
    accepted: false,
    common: false,
  },
  uyi: {
    code: { short: "UYI", long: "uyi", numeric: "940" },
    name: "Uruguay Peso en Unidades Indexadas",
    decimal: false,
    accepted: false,
    common: false,
  },
  uyw: {
    code: { short: "UYW", long: "uyw", numeric: "927" },
    name: "Unidad Previsional",
    decimal: false,
    accepted: false,
    common: false,
  },
  vef: {
    code: { short: "Bs", long: "vef", numeric: "937" },
    name: "Bolivar",
    decimal: false,
    accepted: false,
    common: false,
  },
  ves: {
    code: { short: "VES", long: "ves", numeric: "928" },
    name: "Venezuelan Bolívar Soberano",
    decimal: false,
    accepted: false,
    common: false,
  },
  vnd: {
    code: { short: "₫", long: "vnd", numeric: "704" },
    name: "Vietnamese Đồng",
    decimal: false,
    accepted: false,
    common: false,
  },
  vuv: {
    code: { short: "Vt", long: "vuv", numeric: "548" },
    name: "Vanuatu Vatu",
    decimal: false,
    accepted: false,
    common: false,
  },
  xaf: {
    code: { short: "BEAC", long: "xaf", numeric: "950" },
    name: "CFA Franc BEAC",
    decimal: false,
    accepted: false,
    common: false,
  },
  xag: {
    code: { short: "XAG", long: "xag", numeric: "961" },
    name: "Silver",
    decimal: false,
    accepted: false,
    common: false,
  },
  xau: {
    code: { short: "XAU", long: "xau", numeric: "959" },
    name: "Gold",
    decimal: false,
    accepted: false,
    common: false,
  },
  xba: {
    code: { short: "XBA", long: "xba", numeric: "955" },
    name: "European Composite Unit",
    decimal: false,
    accepted: false,
    common: false,
  },
  xbb: {
    code: { short: "XBB", long: "xbb", numeric: "956" },
    name: "European Monetary Unit",
    decimal: false,
    accepted: false,
    common: false,
  },
  xbc: {
    code: { short: "XBC", long: "xbc", numeric: "957" },
    name: "European Unit of Account 9",
    decimal: false,
    accepted: false,
    common: false,
  },
  xbd: {
    code: { short: "XBD", long: "xbd", numeric: "958" },
    name: "European Unit of Account 17",
    decimal: false,
    accepted: false,
    common: false,
  },
  xdr: {
    code: { short: "XDR", long: "xdr", numeric: "960" },
    name: "Special Drawing Rights",
    decimal: false,
    accepted: false,
    common: false,
  },
  xof: {
    code: { short: "BCEAO", long: "xof", numeric: "952" },
    name: "CFA Franc BCEAO",
    decimal: false,
    accepted: false,
    common: false,
  },
  xpd: {
    code: { short: "XPD", long: "xpd", numeric: "964" },
    name: "Palladium",
    decimal: false,
    accepted: false,
    common: false,
  },
  xpf: {
    code: { short: "F", long: "xpf", numeric: "953" },
    name: "CFP Franc",
    decimal: false,
    accepted: false,
    common: false,
  },
  xpt: {
    code: { short: "XPT", long: "xpt", numeric: "962" },
    name: "Platinum",
    decimal: false,
    accepted: false,
    common: false,
  },
  xsu: {
    code: { short: "XSU", long: "xsu", numeric: "994" },
    name: "SUCRE",
    decimal: false,
    accepted: false,
    common: false,
  },
  xts: {
    code: { short: "XTS", long: "xts", numeric: "963" },
    name: "Code Reserved for Testing",
    decimal: false,
    accepted: false,
    common: false,
  },
  xua: {
    code: { short: "XUA", long: "xua", numeric: "965" },
    name: "ADB Unit of Account",
    decimal: false,
    accepted: false,
    common: false,
  },
  xxx: {
    code: { short: "XXX", long: "xxx", numeric: "999" },
    name: "No Currency",
    decimal: false,
    accepted: false,
    common: false,
  },
  zwl: {
    code: { short: "ZWL", long: "zwl", numeric: "932" },
    name: "Zimbabwean Dollar",
    decimal: false,
    accepted: false,
    common: false,
  },
  // crypto accepted
  // btc: {
  //   code: { short: "BTC", long: "btc", numeric: "1001" },
  //   name: "Bitcoin (crypto)",
  //   decimal: false,
  //   accepted: true,
  //   common: true,
  // },
  // eth: {
  //   code: { short: "ETH", long: "eth", numeric: "1002" },
  //   name: "Ethereum (crypto)",
  //   decimal: false,
  //   accepted: true,
  //   common: true,
  // },
};

export const currenciesList = (accepted) => {
  const keys = Object.keys(CURRENCY_MODEL);
  const list = [];
  keys.forEach((key, index) => {
    if (accepted) {
      if (CURRENCY_MODEL[key].accepted) {
        list.push(CURRENCY_MODEL[key]);
      }
    } else {
      list.push(CURRENCY_MODEL[key]);
    }
  });
  list.sort((a, b) => b.common - a.common);
  return list;
};

export const currenciesGetItem = (currency) => {
  return CURRENCY_MODEL[currency]
    ? CURRENCY_MODEL[currency]
    : {
        code: { short: "XOXO", long: "xoxo", numeric: "0" },
        name: "Unknown Currency",
        decimal: false,
        accepted: false,
        common: false,
      };
};

export default CURRENCY_MODEL;
