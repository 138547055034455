// react core
import { Fragment, useContext, useState, useEffect, useCallback } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode as iconTicket } from "@fortawesome/pro-duotone-svg-icons";
import { faUsers as iconGroupSize } from "@fortawesome/pro-duotone-svg-icons";
import { faUserTag as iconRiderTag } from "@fortawesome/pro-solid-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt as iconRemove } from "@fortawesome/pro-duotone-svg-icons";
import { faPowerOff as iconDisable } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";
// import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird as iconSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { faExplosion as iconLaunched } from "@fortawesome/pro-duotone-svg-icons";
import { faDoorOpen as iconEntry } from "@fortawesome/pro-duotone-svg-icons";
import { faHandshake as iconHandshake } from "@fortawesome/pro-thin-svg-icons";
import { faCreditCard as iconRetryPayment } from "@fortawesome/pro-thin-svg-icons";
import { faExclamationTriangle as iconWarning } from "@fortawesome/pro-solid-svg-icons";
import { red, green, blueGrey } from "@mui/material/colors/";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { serviceLogError } from "services/graphql/call";

// utils and constants
import InputForm from "components/input/InputForm";
import {
  ActionAlert,
  ActionCheck,
  ActionLoader,
} from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { EVENT_CONSTANTS, EventTicketLaunchStates } from "models/Event";
import { currenciesGetItem } from "models/Currency";
import {
  ActiveTag,
  HeaderTag,
  TextTag,
} from "components/utils/common/CommonTags";
import { UserAvatar } from "components/utils/common/CommonAvatars";

const convertTicketStandard = (ticketData) => {
  const ticket = {
    id: ticketData.TicketId,
    shortId: ticketData.TicketId.split("-")[0],
    eventId: ticketData.EventId,
    name: ticketData.Description,
    userId: ticketData.UserId,
    userName: ticketData.UserName,
    userEmail: ticketData.UserEmail,
    currency: ticketData.Currency,
    price: ticketData.TotalAmount / 100,
    groupSize: ticketData.Quantity,
    capacity: ticketData.Quantity,
    shareholders: ticketData.Shareholders,
    entryDate: ticketData.EntryDate
      ? ticketData.EntryDate
      : ticketData.TickedFinal,
    status: EVENT_CONSTANTS.tickets.states.filter(
      (obj) => obj.value === ticketData.TickedStatus
    )[0],
    active: true,
  };
  return ticket;
};

const convertTicketSelectable = (ticketSelectable) => {
  const ticket = {
    id: ticketSelectable.id,
    shortId: ticketSelectable.id.split("-")[0],
    eventId: ticketSelectable.eventId,
    name: ticketSelectable.name,
    userId: ticketSelectable.userId,
    userName: ticketSelectable.userName,
    userEmail: ticketSelectable.userEmail,
    currency: ticketSelectable.currency,
    price: ticketSelectable.price / 100,
    groupSize: ticketSelectable.groups,
    capacity: ticketSelectable.capacity,
    shareholders: ticketSelectable.shareholders,
    entryDate: null,
    status: EVENT_CONSTANTS.tickets.activeStates.filter(
      (obj) => obj.value === (ticketSelectable.active ? "active" : "inactive")
    )[0],
    active: ticketSelectable.active,
    selectable: true,
  };
  return ticket;
};

const LayerTicketHeaderEditing = (props) => {
  const handleEdit = props.handleEdit;

  const eventContext = useContext(EventContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleTicketUpdate = async (data) => {
    // capture form state
    const fs = {
      update: true,
      ticketName: data.filter((obj) => obj.id === "ticket-name")[0].value,
      ticketPrice: data.filter((obj) => obj.id === "ticket-price")[0].value,
      ticketCapacity: data.filter((obj) => obj.id === "ticket-capacity")[0]
        .value,
      ticketGroupSize: data.filter((obj) => obj.id === "ticket-group-size")[0]
        .value,
    };
    // prepare new ticket list
    const eventTicketTypes = JSON.parse(
      JSON.stringify(eventContext.state.event.data.TicketTypes)
    );
    // validate updated ticket settings
    if (eventContext.state.toolbox.isEmptyString(fs.ticketName)) {
      return {
        alert: true,
        message: "Ticket name cannot be empty",
      };
    }
    if (!eventContext.state.toolbox.isInteger(fs.ticketCapacity)) {
      return {
        alert: true,
        message: "Ticket capacity must be a number",
      };
    }
    if (
      !eventContext.state.toolbox.isInteger(
        fs.ticketGroupSize ? fs.ticketGroupSize.value : null
      )
    ) {
      return {
        alert: true,
        message: "Ticket group size must be a number",
      };
    }
    const setting =
      eventContext.state.event.generators.settings.ticket("default");
    fs.ticketPrice = Math.round(Number(fs.ticketPrice) * 100);
    fs.ticketCapacity = Math.round(fs.ticketCapacity * 1, 0);
    fs.ticketGroupSize.value = Math.round(fs.ticketGroupSize.value * 1, 0);
    fs.ticketPrice = Math.round(fs.ticketPrice, 0);
    setting.id = props.ticket.id;
    setting.name = fs.ticketName;
    setting.price = fs.ticketPrice;
    setting.max = fs.ticketCapacity;
    setting.min = fs.ticketGroupSize.value;

    // update ticket in list
    const ticketIndex = eventTicketTypes.findIndex(
      (obj) => obj.id === props.ticket.id
    );
    eventTicketTypes[ticketIndex] = setting;

    // update event with new ticket list
    if (eventContext.state.event.manager) {
      const eventUpdate = await eventContext.prepareUpdateEvent({
        key: "TicketTypes",
        value: eventTicketTypes,
      });
      if (eventUpdate.alert) {
        return {
          alert: true,
          message: eventUpdate.message,
        };
      } else {
        eventContext.updateEvent(eventUpdate);
        eventContext.generateCalendar();
        handleEdit(null);
        const callbackState = {
          type: "actionDrawer",
          payload: {
            actionParams: "tickets",
          },
        };
        eventContext.setCallbackLoader(true, callbackState);
        return { alert: false };
      }
    } else {
      const msg = "Runner permissions required for this update";
      return {
        alert: true,
        message: msg,
      };
    }
  };

  const handleTicketRemove = async (event, confirm) => {
    if (confirm === true) {
      setLoading(true);
      await props.editAction({
        action: "remove",
        ticket: props.ticket,
      });
      setLoading(false);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Remove Ticket",
        description:
          "Are you sure you want to permanently remove the ticket type " +
          props.ticket.name +
          "? This will cancel any offers in play (for this ticket) and withdraw it from sale with immediate effect. Any already launched tickets will NOT be affected.",
        cancel: (e) => handleTicketRemove(e, false),
        confirm: (e) => handleTicketRemove(e, true),
      });
    }
  };
  const handleTicketDisable = async (event, confirm) => {
    setLoading(true);
    await props.editAction({
      action: props.ticket.active ? "disable" : "enable",
      ticket: props.ticket,
    });
    setLoading(false);
  };

  return (
    <Box className="box-default">
      {loading ? (
        <Box
          className="box-default text-center"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
        >
          <ActionLoader size="sm" />
        </Box>
      ) : success ? (
        <Box
          className="box-default text-center"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
        >
          <ActionAlert
            severity="success"
            title="Ticket Updated"
            description="Your ticket has been successfully updated"
            onClose={() => setSuccess(false)}
          />
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_SM,
          }}
        >
          <Box
            className="box-default text-center"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <ActiveTag
              prefix="Ticket "
              value={props.ticket.status.display === "active"}
            />
            {/* <Divider>
            </Divider> */}
            {/* <Typography
              variant="subtitle2"
              noWrap={true}
              sx={{ overflow: "visible" }}
            >
              <Box
                component="span"
                className="upper-case"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                  borderRadius: configEntzy.BORDER_SIZE_MD,
                  backgroundColor: props.ticket.status.color,
                  color: "white",
                }}
              >
                &nbsp;&nbsp;{props.ticket.status.display}&nbsp;&nbsp;
              </Box>
            </Typography> */}
          </Box>

          <Box
            className="box-default text-center"
            sx={{
              mb: configEntzy.APP_SPACING_LG,
              p: configEntzy.APP_SPACING_MD,
            }}
            hidden={!props.ticket.active}
          >
            <InputForm
              navigate={props.navigate}
              fields={[
                {
                  id: "ticket-name",
                  type: "text-standard",
                  label: "Ticket name",
                  value: props.ticket.name,
                  maxLength: 50,
                  required: true,
                  border: true,
                  startAdornment:
                    eventContext.state.toolbox.getAdormentIcon("ticket-text"),
                },
                {
                  id: "ticket-price",
                  type: "text-standard",
                  label: "Ticket price",
                  value: props.ticket.price,
                  numeric: true,
                  required: true,
                  border: true,
                  startAdornment: props.ticket.currency.toUpperCase(),
                },
                {
                  id: "ticket-capacity",
                  type: "text-standard",
                  label: "Ticket capacity per launch",
                  value: props.ticket.capacity,
                  numeric: true,
                  border: true,
                  required: true,
                  startAdornment:
                    eventContext.state.toolbox.getAdormentIcon("user-max"),
                },
                {
                  id: "ticket-group-size",
                  type: "selector-standard",
                  label: "Maximum group size per ticket",
                  value:
                    eventContext.state.event.calendar.tickets.groups.selectable.filter(
                      (obj) => obj.value === props.ticket.groupSize
                    )[0],
                  options:
                    eventContext.state.event.calendar.tickets.groups.selectable,
                  required: true,
                  startAdornment:
                    eventContext.state.toolbox.getAdormentIcon("user-group"),
                },
              ]}
              submitText="Update Ticket"
              callback={handleTicketUpdate}
            />
          </Box>

          <Box className="box-default text-center">
            <Box
              className="box-default half-width"
              sx={{
                pr: configEntzy.APP_SPACING_SM,
              }}
            >
              <ActionBoxButton
                size="small"
                onClick={handleTicketRemove}
                text={"Remove"}
                bgColor={red[100]}
                endIcon={
                  <FontAwesomeIcon
                    icon={iconRemove}
                    transform="shrink-2"
                    fixedWidth
                  />
                }
                fullWidth
              />
            </Box>
            <Box
              className="box-default half-width"
              sx={{
                pl: configEntzy.APP_SPACING_SM,
              }}
            >
              <ActionBoxButton
                size="small"
                onClick={handleTicketDisable}
                text={props.ticket.active ? "Disable" : "Enable"}
                bgColor={props.ticket.active ? blueGrey[100] : green[100]}
                endIcon={
                  <FontAwesomeIcon
                    icon={iconDisable}
                    transform="shrink-2"
                    fixedWidth
                  />
                }
                fullWidth
              />
            </Box>
          </Box>
          <Box className="box-default">
            <ActionCheck
              dialog={dialog.visible}
              title={dialog.title}
              description={dialog.description}
              cancel={dialog.cancel}
              confirm={dialog.confirm}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
const LayerTicketHeaderButton = (props) => {
  return (
    <Box
      className="box-default bg-black-t50 shadow-basic rounded"
      sx={{
        p: configEntzy.APP_SPACING_SM,
        textAlign: "center",
        height: "100%",
        backgroundColor: props.button.bgColor,
      }}
    >
      <ActionBoxButton
        size="medium"
        fullWidth={true}
        fullHeight={true}
        onClick={props.button.onClick}
        disabled={props.button.disabled}
        text={props.button.text}
        bgColor={props.button.bgColor}
        color={props.button.color ? props.button.color : "black"}
        topIcon={
          <FontAwesomeIcon
            icon={props.button.icon}
            transform="grow-6 up-2"
            className="fa-beat"
            style={{
              "--fa-animation-duration": "1.5s",
              "--fa-beat-scale": 1.2,
              color: props.iconColor ? props.iconColor : "black",
            }}
            fixedWidth
          />
        }
      />
    </Box>
  );
};
const LayerTicketHeaderDefault = (props) => {
  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
        height: "100%",
        backgroundColor: blueGrey[200],
      }}
    >
      <Box className="box-default zero-height text-left">
        <Typography variant="subtitle2">
          <FontAwesomeIcon icon={iconTicket} size="3x" />
        </Typography>
      </Box>
      <Box className="box-default zero-height text-right">
        {props.ticket &&
        props.user &&
        props.ticket.userId === props.user.identity ? (
          <HeaderTag text="Owner" />
        ) : (
          <HeaderTag text="Shareholder" />
        )}
      </Box>
    </Box>
  );
};
const LayerTicketAdmissionConfirm = (props) => {
  const admitHolder =
    props.ticket.shareholders &&
    props.ticket.shareholders.find((obj) => obj.UserViewer === true);
  const admitQuantity = admitHolder ? admitHolder.Quantity : 0;
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_XL,
        pb: configEntzy.APP_SPACING_LG,
      }}
      hidden={!props.ticket.status.entry}
    >
      <Typography variant="h6" noWrap>
        <FontAwesomeIcon icon={iconEntry} transform="grow-6 up-4" />
      </Typography>
      <Typography variant="h6" noWrap>
        ENTRY
      </Typography>
      <Typography
        variant="h6"
        noWrap
        sx={{
          overflow: "visible",
        }}
      >
        <span className="fa-layers">
          {/* <FontAwesomeIcon
            icon={iconCircle}
            style={{ color: "white" }}
            transform="grow-100"
          /> */}
          <FontAwesomeIcon
            icon={iconSpinner}
            color="orange"
            spin={true}
            transform="grow-120 up-25"
          />
          <span className="fa-layers-text">
            {admitQuantity === props.ticket.groupSize
              ? admitQuantity
              : admitQuantity + " of " + props.ticket.groupSize}
          </span>
        </span>
      </Typography>
    </Box>
  );
};
// const LayerTicketAdmissionEditing = (props) => {
//   return (
//     <Box
//       className="box-default"
//       sx={{
//         pt: configEntzy.APP_SPACING_MD,
//       }}
//     >
//       <Typography
//         variant="subtitle2"
//         noWrap={true}
//         sx={{
//           mt: configEntzy.APP_SPACING_MD,
//         }}
//       >
//         <FontAwesomeIcon
//           icon={iconGroupSize}
//           color="gray"
//           transform="shrink-2 left-2"
//           fixedWidth
//         />
//         <span>&nbsp;&nbsp;Max&nbsp;Groups&nbsp;&nbsp;</span>
//         {props.ticket.groupSize}
//       </Typography>
//       <Typography
//         variant="subtitle1"
//         noWrap={true}
//         sx={{
//           mt: configEntzy.APP_SPACING_MD,
//         }}
//       >
//         <span>Capacity&nbsp;</span>
//         <span>{props.ticket.capacity}</span>
//       </Typography>
//       <Typography variant="subtitle1" noWrap={true}>
//         <span>Price&nbsp;</span>
//         {currenciesGetItem(props.ticket.currency).code.short}
//         <span>&nbsp;</span>
//         {props.ticket.price.toFixed(2)}
//       </Typography>
//     </Box>
//   );
// };
const LayerTicketAdmissionList = (props) => {
  return (
    <Box
      className="box-default"
      sx={{
        pl: configEntzy.APP_SPACING_MD,
        pr: configEntzy.APP_SPACING_MD,
        pb: configEntzy.APP_SPACING_MD,
      }}
    >
      <Box className="box-default">
        <Typography variant="subtitle1" noWrap={true}>
          {currenciesGetItem(props.ticket.currency).code.long.toUpperCase()}
          <span>&nbsp;</span>
          {props.ticket.price.toFixed(2)}
          {props.ticket.status.launch && props.ticket.entryDate && (
            <span>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              {dayjs(props.ticket.entryDate, "YYYY-MM-DD").format(
                "DD MMM YYYY"
              )}
            </span>
          )}
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;Capacity&nbsp;</span>
          <span>{props.ticket.capacity}</span>
        </Typography>
      </Box>
      <Box className="box-default">
        <Typography variant="subtitle1" noWrap={true}>
          <FontAwesomeIcon
            icon={iconGroupSize}
            color="gray"
            transform="shrink-2"
            fixedWidth
          />
          <span>&nbsp;&nbsp;&nbsp;Groups up to&nbsp;</span>
          {props.ticket.groupSize}
          {!props.ticket.selectable && (
            <span>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              {props.ticket.id.split("-")[0]}
            </span>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
const LayerTicketAdmissionDefault = (props) => {
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
      }}
    >
      <Typography
        variant="h4"
        noWrap={true}
        sx={{
          mt: configEntzy.APP_SPACING_MD,
        }}
      >
        <FontAwesomeIcon
          icon={iconGroupSize}
          color="gray"
          transform="shrink-2"
          fixedWidth
        />
        <span>&nbsp;&nbsp;</span>
        {props.ticketHolding &&
        props.ticketHolding.Quantity !== props.ticket.groupSize ? (
          <span>
            {props.ticketHolding.Quantity} of {props.ticket.groupSize}
          </span>
        ) : (
          <span>{props.ticket.groupSize}</span>
        )}
      </Typography>
      <Typography
        variant="subtitle1"
        className="upper-case"
        sx={{
          mt: configEntzy.APP_SPACING_MD,
        }}
      >
        {props.ticket.status.launch && props.ticket.entryDate ? (
          dayjs(props.ticket.entryDate, "YYYY-MM-DD").format("DD MMM YYYY")
        ) : (
          <span>&nbsp;</span>
        )}
      </Typography>
      <Typography variant="subtitle1" className="upper-case" noWrap={true}>
        {!props.ticket.selectable && (
          <span>
            {props.ticket.id.split("-")[0]}
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
        )}
        {currenciesGetItem(props.ticket.currency).code.short}
        <span>&nbsp;</span>
        {props.ticketHolding &&
        props.ticketHolding.Quantity !== props.ticket.groupSize &&
        props.ticketHolding.Category === "billing" ? (
          <span>
            {(
              (props.ticketHolding.Quantity / props.ticket.groupSize) *
              props.ticket.price
            ).toFixed(2)}
          </span>
        ) : (
          <span>{props.ticket.price.toFixed(2)}</span>
        )}
      </Typography>
    </Box>
  );
};

// <FontAwesomeIcon icon={iconReport} color="gray" transform="shrink-2" fixedWidth />
// <FontAwesomeIcon icon={iconPrice} color="gray" transform="shrink-2" fixedWidth />

// {/* {props.progress ? (
//   <span>{ticketBreakdown.Quantity} offers</span>
// ) : props.viewer ? (
//   <span>
//     {ticketEntryDate}
//     <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
//     <FontAwesomeIcon
//       icon={iconGroupSize}
//       color="gray"
//       transform="shrink-2"
//       fixedWidth
//     />
//     <span>&nbsp;&nbsp;</span>
//     {ticketGroups}
//   </span>
// ) : (
//   <span>Groups up to {ticketGroups}</span>
// )}

// {props.progress ? (
//   <span>{ticketBreakdown.DateCount} dates</span>
// ) : props.viewer ? (
//   <span className="upper-case">
//     {ticketId.split("-")[0]}
//     <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
//     {props.progress ? (
//       <span>STATUS</span>
//     ) : (
//       <span>
//         <FontAwesomeIcon
//           icon={iconReport}
//           color="gray"
//           transform="shrink-2"
//           fixedWidth
//         />
//         <span>&nbsp;</span>
//         {ticketCurrency} {ticketPrice}
//       </span>
//     )}
//   </span>
// ) : (
//   <span>Capacity {ticketCapacity}</span>
// )} */}

const LayerTicketShowingRunner = (props) => {
  return (
    <Box className="box-default">
      <TicketViewer {...props} windowView={true} />
    </Box>
  );
};

const LayerTicketShowingRider = (props) => {
  return (
    <Box className="box-default">
      <Box
        className="box-default text-center"
        sx={{
          height: "0px",
          pl: configEntzy.APP_SPACING_LG,
          pr: configEntzy.APP_SPACING_LG,
        }}
      >
        <ActionLoader size="sm" />
      </Box>
      <Box
        className="box-default"
        sx={{
          height: "0px",
        }}
      >
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_XL,
            pl: configEntzy.APP_SPACING_MD,
            pr: configEntzy.APP_SPACING_MD,
          }}
        >
          <Box
            className="box-default shadow-default"
            sx={{
              p: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_LG,
              backgroundColor: "black",
              color: "white",
            }}
          >
            <Typography variant="subtitle2">VISIBLE TO RUNNER</Typography>
            <Typography variant="subtitle2">Waiting for Entry</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="box-default" sx={{ opacity: 0.5 }}>
        <TicketViewer {...props} windowView={true} />
      </Box>
    </Box>
  );
};

// ticket summary display for any input type
export const TicketViewer = (props) => {
  const user = props.user;

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const runnerView = eventContext.state.event.manager;
  const riderView = !eventContext.state.event.manager && !props.windowView;
  const ticket = props.selectable
    ? convertTicketSelectable(props.selectable)
    : convertTicketStandard(props.ticket);
  const ticketShowing = eventContext.state.viewer.entry.lists[
    eventContext.state.viewer.entry.date
  ]
    ? eventContext.state.viewer.entry.lists[
        eventContext.state.viewer.entry.date
      ].showing
    : null;
  const entryInProgress = ticketShowing && ticketShowing.TicketId === ticket.id;
  const pullTicketDetail = props.pullTicketDetail;
  const handleTicketRetryPayment = props.handleTicketRetryPayment;
  const handleTicketHandshake = props.handleTicketHandshake;

  const [detailLoading, setDetailLoading] = useState(false);
  const [detailHydrated, setDetailHydrated] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(ticket.status);
  const [ticketShareholders, setTicketShareholders] = useState(
    ticket.shareholders
  );
  const [ticketHolding, setTicketHolding] = useState(null);
  const [button, setButton] = useState(null);

  const handlePullTicketDetail = async () => {
    setDetailLoading(true);
    let response, holders, holding, holdingStatus;
    if (ticket.shareholders) {
      holders = ticket.shareholders;
    } else {
      response = await eventContext.preparePullViewerTicket({
        EventId: ticket.eventId,
        TicketId: ticket.id,
      });
      holders = response.data.Shareholders;
    }
    setTicketShareholders(holders);
    holding = holders.find((obj) => obj.UserViewer);
    if (holding) {
      // override parent ticket status with holding status
      holdingStatus = EVENT_CONSTANTS.tickets.states.filter(
        (obj) => obj.value === holding.Status
      )[0];
      setTicketStatus(holdingStatus);
      setTicketHolding(holding);
    }
    setDetailLoading(false);
    setDetailHydrated(true);
  };
  const cbHandlePullTicketDetail = useCallback(handlePullTicketDetail, [
    eventContext,
    ticket,
  ]);

  // pull ticket detail from event context if provided and pullTicketDetail is true
  useEffect(() => {
    if (pullTicketDetail && !detailLoading && !detailHydrated) {
      cbHandlePullTicketDetail();
    }
  }, [
    pullTicketDetail,
    detailLoading,
    detailHydrated,
    cbHandlePullTicketDetail,
  ]);

  // set button content based on ticket status
  useEffect(() => {
    let buttonContent;
    if (riderView && ticketStatus.paymentRetry) {
      buttonContent = {
        text: "Retry Payment",
        bgColor: red[100],
        icon: iconRetryPayment,
        paymentRetry: true,
        onClick: handleTicketRetryPayment,
      };
    } else if (riderView && ticketStatus.entry) {
      buttonContent = {
        text: entryInProgress ? "Hide" : "Show Ticket",
        bgColor: entryInProgress ? "white" : green[100],
        color: "black",
        icon: entryInProgress ? iconClose : iconHandshake,
        entry: true,
        onClick: handleTicketHandshake,
      };
    } else if (runnerView && ticketStatus.entry) {
      buttonContent = {
        text: "Confirm Entry",
        bgColor: green[100],
        icon: iconHandshake,
        iconBounce: true,
        paymentRetry: true,
        onClick: handleTicketHandshake,
      };
    } else {
      buttonContent = null;
    }
    setButton(buttonContent);
  }, [
    riderView,
    runnerView,
    ticketStatus,
    entryInProgress,
    handleTicketHandshake,
    handleTicketRetryPayment,
  ]);

  return (
    <Box className="box-default">
      <Box
        className="box-default bg-white shadow-light"
        sx={{
          // height: props.listView ? "auto" : configEntzy.AVATAR_SIZE_XL,
          textAlign: props.listView ? "left" : "center",
          borderRadius: configEntzy.BORDER_SIZE_LG,
          overflow: "hidden",
        }}
      >
        {/* ----- Header Content ----- */}
        {!props.listView && (
          <Box
            className="box-default"
            sx={{
              height: configEntzy.AVATAR_SIZE_MD,
            }}
          >
            {props.loading || detailLoading ? (
              <Box
                className="box-default text-center"
                sx={{
                  p: configEntzy.APP_SPACING_MD,
                }}
              >
                <ActionLoader size="sm" />
              </Box>
            ) : button ? (
              <LayerTicketHeaderButton
                user={user}
                ticket={ticket}
                button={button}
              />
            ) : (
              <LayerTicketHeaderDefault user={user} ticket={ticket} />
            )}
          </Box>
        )}
        {/* ----- Main Content ----- */}
        <Box
          className="box-default"
          sx={{
            pb: configEntzy.APP_SPACING_MD2X,
            opacity: !ticket.active ? 0.2 : 1,
          }}
        >
          {/* Ticket Name */}
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
              pl: configEntzy.APP_SPACING_MD,
              pr: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_SM,
            }}
          >
            <Typography
              variant="subtitle2"
              className="upper-case"
              noWrap={true}
            >
              {props.listView && (
                <span>
                  <FontAwesomeIcon
                    icon={iconTicket}
                    color="black"
                    transform="grow-4"
                    fixedWidth
                  />
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>
              )}
              {ticket.name}
            </Typography>
          </Box>
          {/* Ticket Status */}
          {!props.listView && (
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default">
                <Typography
                  variant="subtitle2"
                  noWrap={true}
                  sx={{ overflow: "visible" }}
                >
                  <Box
                    component="span"
                    className="upper-case"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                      backgroundColor: ticketStatus.color,
                      color: "white",
                    }}
                  >
                    &nbsp;&nbsp;{ticketStatus.display}&nbsp;&nbsp;
                  </Box>
                </Typography>
              </Box>
              {ticketStatus.paymentRetry && (
                <Box
                  className="box-default bg-black-t10"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    p: configEntzy.APP_SPACING_MD,
                    borderTop: configEntzy.BORDER_DASH_GMD,
                    borderBottom: configEntzy.BORDER_DASH_GMD,
                  }}
                >
                  <Typography variant="body2">
                    Payment failed on ticket collection. Check your payment
                    method in settings and hit retry to issue tickets.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {/* Ticket Admission and Entry Date */}
          {entryInProgress && runnerView ? (
            <LayerTicketAdmissionConfirm user={user} ticket={ticket} />
          ) : props.listView ? (
            <LayerTicketAdmissionList user={user} ticket={ticket} />
          ) : (
            <LayerTicketAdmissionDefault
              user={user}
              ticket={ticket}
              ticketHolding={ticketHolding}
            />
          )}
        </Box>
        {/* Ticket Shareholders */}
        {ticketShareholders && ticketShareholders.length > 0 && ticketShowing && (
          <Box className="box-default text-center">
            {ticketShareholders
              .sort((a, b) => {
                if (a.UserViewer) {
                  return -1;
                }
                if (b.UserViewer) {
                  return 1;
                }
                return 0;
              })
              .map((shareholder) => {
                let holderStatus = EVENT_CONSTANTS.tickets.states.filter(
                  (obj) => obj.value === shareholder.Status
                )[0];

                return (
                  <Box
                    key={shareholder.UserId}
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD2X,
                      pb: configEntzy.APP_SPACING_MD2X,
                      borderTop: shareholder.UserViewer
                        ? "4px dashed #000"
                        : "1px solid #ccc",
                      borderLeft: shareholder.UserViewer
                        ? "4px dashed #000"
                        : "none",
                      borderRight: shareholder.UserViewer
                        ? "4px dashed #000"
                        : "none",
                      borderBottom: shareholder.UserViewer
                        ? "4px dashed #000"
                        : "none",
                      opacity: shareholder.UserViewer ? 1 : 0.5,
                      backgroundColor: shareholder.UserViewer
                        ? "white"
                        : "gray",
                    }}
                  >
                    <Box className="box-default">
                      <Box className="box-inline">
                        <UserAvatar
                          user={{ identity: shareholder.UserId }}
                          size="md"
                        />
                      </Box>
                    </Box>
                    <Box className="box-default">
                      <Typography
                        variant="h6"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        ADMITS {shareholder.Quantity}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        noWrap={true}
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                          overflow: "visible",
                        }}
                      >
                        <Box
                          component="span"
                          className="upper-case"
                          sx={{
                            p: configEntzy.APP_SPACING_SM,
                            borderRadius: configEntzy.BORDER_SIZE_LG,
                            backgroundColor: holderStatus.color,
                            color: "white",
                          }}
                        >
                          &nbsp;&nbsp;{holderStatus.display}&nbsp;&nbsp;
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        {/* Ticket Editing */}
        {props.editing && (
          <Box
            className="box-default bg-black shadow-basic"
            sx={{
              p: configEntzy.APP_SPACING_SM,
              borderBottomLeftRadius: configEntzy.BORDER_SIZE_LG,
              borderBottomRightRadius: configEntzy.BORDER_SIZE_LG,
            }}
          >
            <LayerTicketHeaderEditing
              user={user}
              ticket={ticket}
              editAction={props.editAction}
              handleEdit={props.handleEdit}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const TickingViewer = (props) => {
  const user = props.user;
  const ticket = props.ticket;

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [dialog, setDialog] = useState({
    visible: false,
  });
  const [ticketDetails, setTicketDetails] = useState(null);
  const [ticketAccepted, setTicketAccepted] = useState(false);
  const [ticketLaunched, setTicketLaunched] = useState(false);

  const ANCHOR_TOP = props.anchorTop;
  const ANCHOR_TICKET_TOP = "anchor-ticket-top-" + ticket.TicketId;

  const handleEdit = async () => {
    if (edit) {
      setEdit(false);
    } else {
      if (ticketDetails && ticketDetails.TickedId === ticket.TicketId) {
        setEdit(true);
      } else {
        setLoading(true);
        const response = await eventContext.preparePullViewerTicket({
          EventId: eventContext.state.event.data.EventId,
          TicketId: ticket.TicketId,
        });
        if (response.alert) {
          mainContext.updateAlert(response);
        } else {
          const userHolding = response.data.Shareholders.filter(
            (obj) => obj.UserId === user.identity
          );
          if (userHolding.length) {
            setTicketAccepted(userHolding[0].Status === "accepted");
          }
          const ticketDetailsData = response.data;
          const shareholderTicksData = {
            keys: [],
            keysOthers: [],
            values: [],
            users: {},
          };
          // merge TickedDates with Shareholders
          for (let setting of ticketDetailsData.TickedDates) {
            for (let shareholder of ticketDetailsData.Shareholders) {
              if (setting.active && setting.subtag === shareholder.UserId) {
                if (!shareholderTicksData.users[shareholder.UserId]) {
                  shareholderTicksData.users[shareholder.UserId] = [];
                }
                for (let settingAdd of setting.add) {
                  let dateKey = dayjs(EVENT_CONSTANTS.dates.base, "YYYY-MM-DD")
                    .add(settingAdd, "days")
                    .format("YYYY-MM-DD");
                  if (!shareholderTicksData.keys.includes(dateKey)) {
                    shareholderTicksData.keys.push(dateKey);
                  }
                  if (
                    shareholder.UserId !== user.identity &&
                    !shareholderTicksData.keysOthers.includes(dateKey)
                  ) {
                    shareholderTicksData.keysOthers.push(dateKey);
                  }
                  shareholderTicksData.values.push({
                    UserId: shareholder.UserId,
                    Status: shareholder.Status,
                    DateKey: dateKey,
                  });
                  shareholderTicksData.users[shareholder.UserId].push(dateKey);
                }
                shareholderTicksData.keys.sort();
              }
            }
          }
          ticketDetailsData.ShareholderTicks = shareholderTicksData;
          // check if ticket is launched and not refreshed yet
          setTicketLaunched(
            EventTicketLaunchStates().includes(ticketDetailsData.TickedStatus)
          );
          // set ticket details
          setTicketDetails(ticketDetailsData);
          setEdit(true);
        }
        setLoading(false);
      }
    }
  };

  const handleTickEdit = async (data) => {
    const dates = data.filter((obj) => obj.id === "ride-dates")[0].value;
    const days = [];
    for (let date of dates) {
      days.push(date);
    }
    setEdit(false);
    await handleTickUpdate(days);
    return { alert: false };
  };

  const handleTickRemove = async (event, day, confirm) => {
    if (confirm === true) {
      const days = [];
      for (let setting of ticket.TickedDates) {
        if (setting.active && setting.subtag === user.identity) {
          for (let add of setting.add) {
            if (add !== day) {
              days.push(add);
            }
          }
        }
      }
      handleTickUpdate(days);
    } else if (confirm === false) {
      setDialog({ visible: false });
      setAlert(false);
    } else {
      setDialog({
        visible: true,
        title: "Confirm Withdraw Offer",
        description:
          "Are you sure you want to remove your offer for date " +
          dayjs(EVENT_CONSTANTS.dates.base, "YYYY-MM-DD")
            .add(day, "days")
            .format("ddd DD MMM YYYY") +
          "? This will withdraw the date with immediate effect but you can still use this ticket for other offers.",
        cancel: (e) => handleTickRemove(e, day, false),
        confirm: (e) => handleTickRemove(e, day, true),
      });
      setAlert(false);
    }
  };

  const handleTicketRemove = async (event, confirm) => {
    if (confirm === true) {
      handleTicketWithdraw();
    } else if (confirm === false) {
      setDialog({ visible: false });
      setAlert(false);
    } else {
      setDialog({
        visible: true,
        title: "Confirm Ticket Removal",
        description: "Are you sure you want to permanently delete this ticket?",
        cancel: (e) => handleTicketRemove(e, false),
        confirm: (e) => handleTicketRemove(e, true),
      });
      setAlert(false);
    }
  };

  const stageTicketForUpdate = () => {
    const ticketUpdate = JSON.parse(JSON.stringify(ticket));
    // check for user ticking setting and create an empty one if new
    if (
      ticketUpdate.TickedDates.filter(
        (setting) => setting.subtag === user.identity
      ).length === 0
    ) {
      ticketUpdate.TickedDates.push({
        id: user.identity,
        order: ticketUpdate.TickedDates.length + 1,
        active: true,
        tag: user.identity === ticketUpdate.UserId ? "owner" : "holder",
        subtag: user.identity,
        base: EVENT_CONSTANTS.dates.base,
        add: [],
      });
    }
    return ticketUpdate;
  };

  const handleTickUpdate = async (days) => {
    setLoading(true);
    setDialog({ visible: false });
    const ticketUpdate = stageTicketForUpdate();
    const dateSettings = [];
    for (let setting of ticketUpdate.TickedDates) {
      if (setting.active && setting.subtag === user.identity) {
        setting.add = days;
      }
      dateSettings.push(setting);
    }
    if (!eventContext.state.event.manager) {
      const ticketUpdated = await eventContext.prepareTickDates(ticketUpdate);
      if (ticketUpdated.alert) {
        setLoading(false);
        setAlert(true);
        setMessage(ticketUpdated.message);
        return ticketUpdated;
      } else {
        eventContext.updateViewerTickets(ticketUpdated);
        eventContext.generateCalendar();
        setLoading(false);
        setAlert(false);
        const element = document.getElementById(ANCHOR_TOP);
        if (element) {
          eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
        }
        eventContext.setCallbackLoader(true);
        return { alert: false };
      }
    } else {
      setLoading(false);
      setAlert(true);
      setMessage("Event rider permissions required to tick dates");
      return { alert: true, message: "Event rider permissions required" };
    }
  };

  const handleTicketWithdraw = async () => {
    setLoading(true);
    setDialog({ visible: false });
    const ticketUpdate = stageTicketForUpdate();
    ticketUpdate.TickedStatus = "withdrawn";
    if (!eventContext.state.event.manager) {
      const ticketUpdated = await eventContext.prepareTicketWithdraw(
        ticketUpdate
      );
      if (ticketUpdated.alert) {
        setLoading(false);
        setAlert(true);
        setMessage(ticketUpdated.message);
      } else {
        eventContext.updateViewerTickets(ticketUpdated);
        eventContext.generateCalendar();
        setLoading(false);
        setAlert(false);
        const element = document.getElementById(ANCHOR_TOP);
        if (element) {
          eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
        }
        eventContext.setCallbackLoader(true);
      }
    } else {
      setLoading(false);
      setAlert(true);
      setMessage("Event rider permissions required to tick dates");
    }
  };

  const handleTicketUpdateHolding = async (accept) => {
    let element;
    setLoading(true);
    setDialog({ visible: false });
    element = document.getElementById(ANCHOR_TICKET_TOP);
    if (element) {
      eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
    }
    // remove all ticks if rejecting
    if (!accept) {
      const tickClear = await handleTickUpdate([]);
      if (tickClear.alert) {
        setLoading(false);
        setAlert(true);
        setMessage(tickClear.message);
        return;
      }
    }
    // accept or reject holding
    const ticketUpdate = stageTicketForUpdate();
    const ticketUpdated = await eventContext.prepareTicketUpdateHolding(
      ticketUpdate,
      accept
    );
    if (ticketUpdated.alert) {
      setLoading(false);
      setAlert(true);
      setMessage(ticketUpdated.message);
    } else {
      eventContext.updateViewerTickets(ticketUpdated);
      eventContext.generateCalendar();
      setLoading(false);
      setAlert(false);
      element = document.getElementById(ANCHOR_TOP);
      if (element) {
        eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
      }
      eventContext.setCallbackLoader(accept ? false : true);
    }
  };
  const handleTicketRejectHolding = async (event, confirm) => {
    if (confirm === true) {
      handleTicketUpdateHolding(false);
    } else if (confirm === false) {
      setDialog({ visible: false });
      setAlert(false);
    } else {
      setDialog({
        visible: true,
        title: "Confirm Ticket Holding Removal",
        description:
          "Are you sure you want to remove yourself from this ticket? This action cannot be undone.",
        cancel: (e) => handleTicketRejectHolding(e, false),
        confirm: (e) => handleTicketRejectHolding(e, true),
      });
      setAlert(false);
    }
  };

  const userDates = ticket.TickedDates.filter(
    (setting) => setting.subtag === user.identity && setting.active
  );
  const datesInPlay = userDates.length ? userDates[0].add.length : 0;
  const lastUpdatedMins = dayjs().diff(ticket.LastUpdated, "minutes");

  // calculate dates where no more room for this ticket
  const optionsDisabled = [];
  const datesSoldOut = [];
  try {
    const ticketTypeId = ticket.TypeMeta.split(":")[0];
    const ticketQuantity = ticket.Quantity;
    const tickingDatesFull =
      eventContext.state.event.calendar.dates.combined.active.filter(
        (date) =>
          eventContext.state.event.calendar.limits.capacity -
            (date.progress.tickets.breakdown[ticketTypeId]
              ? date.progress.tickets.breakdown[ticketTypeId].Quantity
              : 0) <
          ticketQuantity
      );
    if (tickingDatesFull.length > 0) {
      const tickingDatesFullKeys = tickingDatesFull.map((date) => date.id);
      optionsDisabled.push(...tickingDatesFullKeys);
      datesSoldOut.push(...tickingDatesFullKeys);
    }
  } catch (error) {
    serviceLogError("InputPickers DatePicker SoldOut Checks", error);
  }

  return (
    <Box id={ANCHOR_TICKET_TOP} className="box-default">
      <Box
        className="box-default bg-blgry shadow-light"
        sx={{
          p: configEntzy.APP_SPACING_SM,
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        {loading ? (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL2X,
              pb: configEntzy.APP_SPACING_XL2X,
            }}
          >
            <ActionLoader size="sm" />
          </Box>
        ) : edit ? (
          <Box className="box-default">
            <Box
              className="box-default text-right"
              sx={{
                pt: configEntzy.APP_SPACING_SM2X,
                pr: configEntzy.APP_SPACING_SM,
              }}
            >
              <FontAwesomeIcon
                icon={iconClose}
                size="2x"
                fixedWidth
                onClick={handleEdit}
              />
            </Box>
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <FontAwesomeIcon icon={iconEdit} size="3x" fixedWidth />
            </Box>
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD,
              }}
            >
              <Typography variant="h6" noWrap={true}>
                Ticket {ticket.TicketId.split("-")[0].toUpperCase()}
              </Typography>
              <Typography
                variant="subtitle2"
                className="upper-case"
                noWrap={true}
              >
                {ticket.Description}
              </Typography>
              <Typography variant="subtitle2" noWrap={true}>
                <span>Group of {ticket.Quantity}</span>
                <span>&nbsp;&nbsp;</span>
                <span>({currenciesGetItem(ticket.Currency).code.short}</span>
                <span>&nbsp;</span>
                <span>{ticket.TotalAmount / 100})</span>
              </Typography>
            </Box>
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_MD2X,
              }}
            >
              {ticket.UserId === user.identity ? (
                <HeaderTag text="You are Owner" />
              ) : (
                <HeaderTag text="You are a Shareholder" />
              )}
            </Box>
            <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default">
                <Typography variant="subtitle2" noWrap={true}>
                  Ticket Holders ({ticketDetails.Shareholders.length})
                </Typography>
              </Box>
              <Box className="box-default" hidden={ticketLaunched}>
                {ticketDetails.Shareholders.length > 1 ? (
                  <Fragment>
                    <Typography variant="subtitle1">
                      <em>
                        All holdings must be accepted before offers are visible
                      </em>
                    </Typography>
                    <Typography variant="subtitle1">
                      <em>
                        Ticket will be offered on dates where all holders match
                      </em>
                    </Typography>
                    <Typography variant="subtitle1">
                      <em>Green ticks are yours and white are other holders</em>
                    </Typography>
                  </Fragment>
                ) : (
                  <Typography variant="subtitle1">
                    <em>
                      You are the main holder for all riders on this ticket
                    </em>
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              className="box-default bg-white-t50"
              sx={{
                height: configEntzy.AVATAR_CONTAINER_LG,
                pt: configEntzy.APP_SPACING_MD2X,
                overflowY: "hidden",
                overflowX: "auto",
                scrollBehavior: "smooth",
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                whiteSpace: "nowrap",
              }}
            >
              {ticketDetails &&
                ticketDetails.Shareholders.map((shareholder) => {
                  return (
                    <Box
                      className="box-inline"
                      key={shareholder.UserId}
                      sx={{
                        width: configEntzy.AVATAR_CONTAINER_MD,
                      }}
                    >
                      <Box className="box-default">
                        <Box className="box-inline">
                          <UserAvatar
                            user={{ identity: shareholder.UserId }}
                            size="sm"
                          />
                        </Box>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <TextTag
                          text={
                            ticketLaunched
                              ? "Issued " + shareholder.Quantity
                              : shareholder.Status === "accepted"
                              ? "Holding " + shareholder.Quantity
                              : "Pending " + shareholder.PendingQuantity
                          }
                          size="sm"
                          textNoCasing={true}
                        />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <TextTag
                          text={
                            ticketLaunched ? "launched" : shareholder.Status
                          }
                          size="sm"
                          bgColor={
                            shareholder.Status === "accepted"
                              ? "success.main"
                              : "highlight.main"
                          }
                          color="white"
                          rounded={true}
                        />
                      </Box>
                      <Box
                        className="box-default"
                        hidden={ticket.TotalAmount === 0}
                        sx={{
                          pt: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <TextTag
                          text={shareholder.Category.replace(
                            "standard",
                            "non-billing"
                          )}
                          size="sm"
                          bgColor={
                            shareholder.Category === "billing"
                              ? "success.light"
                              : "secondary.light"
                          }
                          color="white"
                          rounded={true}
                        />
                      </Box>
                      {/* <Box className="box-default">
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.UserOwner ? "Owner" : "Holder"} |{" "}
                          {shareholder.UserViewer ? "Viewer" : "Non-Viewer"}
                        </Typography>
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.Status}
                        </Typography>
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.Category}
                        </Typography>
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.Quantity}
                        </Typography>
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.PendingQuantity}
                        </Typography>
                        <Typography variant="subtitle2" noWrap={true}>
                          {shareholder.BillingQuantity}
                        </Typography>
                      </Box> */}
                    </Box>
                  );
                })}
            </Box>
            <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
              hidden={ticketLaunched || ticketAccepted}
            >
              <Box className="box-default">
                <Typography variant="subtitle2">
                  <FontAwesomeIcon icon={iconRiderTag} size="2x" fixedWidth />
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_SM,
                  }}
                >
                  You have been tagged on this ticket
                </Typography>
                <Typography variant="subtitle1">
                  <span>Would you like to accept your shareholding of</span>
                  <span>&nbsp;</span>
                  <span>
                    {
                      ticketDetails.Shareholders.filter(
                        (obj) => obj.UserId === user.identity
                      )[0].PendingQuantity
                    }
                  </span>
                  <span>?</span>
                </Typography>
                {ticketDetails.Shareholders.filter(
                  (obj) => obj.UserId === user.identity
                )[0].Category === "billing" && (
                  <Typography variant="subtitle1">
                    <span>If this ticket launches you will be billed at</span>
                    <span>&nbsp;</span>
                    <span>
                      {currenciesGetItem(ticket.Currency).code.short}
                      {(
                        (ticket.UnitAmount *
                          ticketDetails.Shareholders.filter(
                            (obj) => obj.UserId === user.identity
                          )[0].PendingQuantity) /
                        100
                      ).toFixed(2)}
                    </span>
                  </Typography>
                )}
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <ActionBoxButton
                  size="small"
                  bgColor="success.main"
                  color="white"
                  text={
                    "Accept " +
                    ticketDetails.Shareholders.filter(
                      (obj) => obj.UserId === user.identity
                    )[0].PendingQuantity +
                    " @ " +
                    currenciesGetItem(ticket.Currency).code.short +
                    (ticketDetails.Shareholders.filter(
                      (obj) => obj.UserId === user.identity
                    )[0].Category === "billing"
                      ? (
                          (ticket.UnitAmount *
                            ticketDetails.Shareholders.filter(
                              (obj) => obj.UserId === user.identity
                            )[0].PendingQuantity) /
                          100
                        ).toFixed(2)
                      : 0)
                  }
                  fullWidth={true}
                  disableElevation={true}
                  onClick={() => handleTicketUpdateHolding(true)}
                />
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <ActionBoxButton
                  size="small"
                  bgColor="danger.main"
                  color="white"
                  text={"Remove"}
                  fullWidth={true}
                  disableElevation={true}
                  onClick={handleTicketRejectHolding}
                />
              </Box>
            </Box>
            <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
              hidden={ticketLaunched || !ticketAccepted}
            >
              <Box className="box-default">
                <InputForm
                  navigate={props.navigate}
                  fields={[
                    {
                      id: "ride-dates",
                      type: "picker-calendar",
                      label: "Manage your Date Offers (" + datesInPlay + ")",
                      placeholder: "Select dates to offer this ticket",
                      value:
                        datesInPlay > 0
                          ? eventContext.state.event.calendar.dates.selectable
                              .filter((obj) =>
                                userDates[0].add.includes(obj.value)
                              )
                              .reduce((acc, obj) => [...acc, obj.value], [])
                          : [],
                      required: true,
                      options:
                        eventContext.state.event.calendar.dates.selectable,
                      optionsDecorate: {
                        ticking:
                          eventContext.state.event.calendar.dates.combined
                            .active,
                        launched:
                          eventContext.state.event.calendar.dates.launched.all
                            .keys,
                        otherHolderTicks:
                          ticketDetails.ShareholderTicks.keysOthers,
                        soldOut: datesSoldOut,
                      },
                      optionsDisabled: optionsDisabled,
                    },
                  ]}
                  submitText="Update Dates"
                  callback={handleTickEdit}
                  cancel={handleEdit}
                  cancelText="Cancel"
                  cancelAutoConfirm={true}
                  footNotes={["To revoke all offers submit an empty date list"]}
                />
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
                hidden={ticket.UserId === user.identity}
              >
                <ActionBoxButton
                  size="small"
                  bgColor="danger.main"
                  color="white"
                  text="Remove my Holding"
                  fullWidth={true}
                  disableElevation={true}
                  onClick={handleTicketRejectHolding}
                />
              </Box>
            </Box>
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_LG,
              }}
              hidden={!ticketLaunched}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconLaunched} size="2x" fixedWidth />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                This ticket has launched!
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                It has moved to eventuated in matched dates
              </Typography>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Box
                className="box-default"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                }}
              >
                <TicketViewer
                  user={user}
                  viewer={true}
                  ticket={ticket}
                  loading={loading}
                  showing={props.showing}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="box-default"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                }}
              >
                <Box
                  className="box-default"
                  // sx={{
                  //   p: configEntzy.APP_SPACING_XS,
                  // }}
                >
                  {/* ----- Header Content ----- */}
                  <Box className="box-default">
                    <Box className="box-default">
                      <ActionBoxButton
                        size="small"
                        bgColor="secondary.light"
                        text={edit ? "Close" : "Manage Ticket"}
                        fullWidth={true}
                        disableElevation={true}
                        onClick={handleEdit}
                        endIcon={
                          <FontAwesomeIcon
                            icon={edit ? iconClose : iconEdit}
                            transform="right-10"
                            fixedWidth
                          />
                        }
                      />
                    </Box>
                    <Box
                      className="box-default"
                      hidden={edit}
                      sx={{
                        pt: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      {lastUpdatedMins < 10 && (
                        <TextTag
                          size="sm"
                          lower={true}
                          color="primary"
                          text={"Updated " + lastUpdatedMins + " mins ago"}
                        />
                      )}
                      <Typography variant="subtitle2" noWrap={true}>
                        DATES IN PLAY
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        noWrap={true}
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                          overflow: "visible",
                        }}
                      >
                        <span
                          className="bg-white-t50 shadow-light"
                          style={{ padding: "4px" }}
                        >
                          <span>&nbsp;&nbsp;</span>

                          {datesInPlay}
                          <span>&nbsp;&nbsp;</span>
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  {/* Dates Ticking List Content */}
                  <Box
                    className="box-default"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                      pt: configEntzy.APP_SPACING_SM,
                      mb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Fragment>
                      {ticket.TickedDates.map((setting) => {
                        return (
                          <Fragment key={setting.id}>
                            {setting.active &&
                              setting.subtag === user.identity &&
                              setting.add.sort().map((day) => {
                                return (
                                  <Box
                                    className="box-default"
                                    key={"day" + day}
                                  >
                                    <Box
                                      className="box-default"
                                      sx={{
                                        pt: configEntzy.APP_SPACING_SM,
                                        pb: configEntzy.APP_SPACING_SM,
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        noWrap={true}
                                      >
                                        <span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        <span
                                          style={
                                            dayjs(
                                              EVENT_CONSTANTS.dates.base,
                                              "YYYY-MM-DD"
                                            )
                                              .add(
                                                day -
                                                  eventContext.state.event
                                                    .calendar.limits.notice,
                                                "days"
                                              )
                                              .diff(dayjs(), "days") < 0
                                              ? {
                                                  textDecoration:
                                                    "line-through",
                                                  opacity: 0.5,
                                                }
                                              : {}
                                          }
                                        >
                                          {dayjs(
                                            EVENT_CONSTANTS.dates.base,
                                            "YYYY-MM-DD"
                                          )
                                            .add(day, "days")
                                            .format("ddd DD MMM YYYY")}
                                        </span>
                                        <span>&nbsp;&nbsp;</span>
                                        <FontAwesomeIcon
                                          icon={iconRemove}
                                          className="fa-pull-right action-pointer"
                                          transform="down-4"
                                          color="red"
                                          fixedWidth
                                          onClick={(e) =>
                                            handleTickRemove(e, day)
                                          }
                                        />
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}
                          </Fragment>
                        );
                      })}
                      {(ticket.TickedDates.filter(
                        (setting) => setting.subtag === user.identity
                      ).length === 0 ||
                        ticket.TickedDates.filter(
                          (setting) =>
                            setting.subtag === user.identity &&
                            setting.add.length === 0
                        ).length > 0) && (
                        <Fragment key="empty-main">
                          <Box
                            className="box-default"
                            sx={{
                              pt: configEntzy.APP_SPACING_SM,
                            }}
                          >
                            <Typography variant="body2" noWrap={true}>
                              <em>No active offer dates</em>
                            </Typography>
                          </Box>
                          <Box
                            className="box-default"
                            sx={{
                              pt: configEntzy.APP_SPACING_LG,
                            }}
                            hidden={ticket.UserId !== user.identity}
                          >
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              onClick={(e) => handleTicketRemove(e)}
                            >
                              Delete Ticket
                            </Button>
                          </Box>
                        </Fragment>
                      )}
                    </Fragment>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>
      {alert && message && (
        <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_MD }}>
          <ActionAlert severity="error" message={message} />
        </Box>
      )}
    </Box>
  );
};

export const TicketWindow = (props) => {
  // const user = props.user;

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const runnerView = eventContext.state.event.manager;

  const ticketShowing = eventContext.state.viewer.entry.lists[
    eventContext.state.viewer.entry.date
  ]
    ? eventContext.state.viewer.entry.lists[
        eventContext.state.viewer.entry.date
      ].showing
    : null;
  const ticketInvalid = eventContext.state.viewer.entry.lists[
    eventContext.state.viewer.entry.date
  ]
    ? eventContext.state.viewer.entry.lists[
        eventContext.state.viewer.entry.date
      ].invalid
    : null;

  return (
    <Box className="box-default">
      {ticketShowing ? (
        <Box className="box-default text-center">
          {runnerView ? (
            <LayerTicketShowingRunner {...props} />
          ) : (
            <LayerTicketShowingRider {...props} />
          )}
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{
            backgroundColor: props.invalid ? "#C00" : "#000",
            opacity: "0.5",
            height: configEntzy.AVATAR_SIZE_XL,
            p: configEntzy.BORDER_SIZE_LG,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
        >
          <Box
            className="box-default"
            sx={{
              height: "100%",
              border: configEntzy.BORDER_DASH_WMD,
              borderRadius: configEntzy.BORDER_SIZE_LG,
            }}
          >
            <Box
              className="box-default text-center"
              sx={{
                p: configEntzy.APP_SPACING_LG,
                color: "white",
              }}
            >
              {ticketInvalid ? (
                <Typography variant="h6">{ticketInvalid.message}</Typography>
              ) : (
                <Typography variant="h6">{props.text}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const TicketGuestRow = (props) => {
  // const user = props.user;

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const ticket = props.selectable
    ? convertTicketSelectable(props.selectable)
    : convertTicketStandard(props.ticket);

  const [loading, setLoading] = useState(false);
  const [shareholders, setShareholders] = useState([]);

  const ANCHOR_TOP = "anchor-entry-header";

  const handlePullTicket = async (ticket) => {
    if (shareholders.length > 0) {
      setShareholders([]);
    } else {
      setLoading(true);
      const response = await eventContext.preparePullViewerTicket({
        EventId: ticket.eventId,
        TicketId: ticket.id,
      });
      setLoading(false);
      if (response.alert) {
        mainContext.updateAlert(response);
      } else {
        setShareholders(response.data.Shareholders);
      }
    }
  };

  const handleManualHandshake = async (ticket, holders, entryHolder) => {
    setLoading(true);
    for (let holder of holders) {
      if (holder.UserId === entryHolder.UserId) {
        holder.UserViewer = true;
      }
    }
    const response = await eventContext.entryHandshake({
      EventId: ticket.eventId,
      TicketId: ticket.id,
      TicketAction: "show",
      Shareholders: holders,
      EntryDate: ticket.entryDate,
      EntryUserId: entryHolder.UserId,
    });
    setLoading(false);
    if (response && response.alert) {
      mainContext.updateAlert(response);
    }
    const element = document.getElementById(ANCHOR_TOP);
    if (element) {
      eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
    }
  };

  // onClick={
  //   eventContext.state.constants.tickets.states.filter(
  //     (obj) =>
  //       obj.value === ticket.TickedStatus
  //   )[0].paymentRetry
  //     ? () => handleShowPaymentIssue(ticket)
  //     : () => handlePullTicket(ticket)
  // }

  return (
    <Box
      className="box-default shadow-default action-pointer"
      sx={{
        p: configEntzy.APP_SPACING_SM2X,
        backgroundColor: "dusk.main",
        borderRadius: configEntzy.BORDER_SIZE_LG,
        color: "white",
        opacity: 0.9,
      }}
      onClick={() => handlePullTicket(ticket)}
    >
      {loading && (
        <Box className="box-default text-center zero-height">
          <ActionLoader size="sm" />
        </Box>
      )}
      <Box className="box-default upper-case">
        <Box
          className="box-inline left text-center bg-white-t50 rounded"
          sx={{
            p: configEntzy.APP_SPACING_SM,
            backgroundColor: ticket.status.color,
          }}
        >
          <Typography variant="body2" color="black">
            <span>&nbsp;&nbsp;</span>
            <FontAwesomeIcon
              icon={iconGroupSize}
              transform="shrink-2"
              fixedWidth
            />
            <span>&nbsp;&nbsp;</span>
            {ticket.groupSize}
            <span>&nbsp;&nbsp;</span>
          </Typography>
        </Box>
        <Box
          className="box-inline right text-center bg-white-t50 rounded"
          sx={{
            p: configEntzy.APP_SPACING_SM,
            backgroundColor: ticket.status.color,
          }}
        >
          <Typography variant="body2" color="black">
            <span>&nbsp;&nbsp;</span>
            {ticket.status.display}
            <span>&nbsp;&nbsp;</span>
          </Typography>
        </Box>
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
        }}
      >
        <Typography variant="body2" className="upper-case" noWrap>
          <span>{ticket.shortId}</span>
          <span>&nbsp;&nbsp;</span>
          {currenciesGetItem(ticket.currency).code.short}{" "}
          {ticket.price.toFixed(2)}
        </Typography>
        <Typography variant="body2" className="upper-case" noWrap>
          {ticket.name}
        </Typography>
        <Typography variant="body2" noWrap>
          @{ticket.userName}
        </Typography>
        {ticket.status.paymentRetry && (
          <Typography
            variant="body2"
            color="orange"
            sx={{
              mt: configEntzy.APP_SPACING_SM2X,
            }}
          >
            <FontAwesomeIcon icon={iconWarning} />
            <span>&nbsp;&nbsp;</span>
            One or more rider payments did not clear. Tap to view.
          </Typography>
        )}
      </Box>
      {/* <UserAvatar size="sm" user={{ identity: props.ticket.UserId }} /> */}
      {/* <Typography variant="body2">{props.ticket.UserEmail}</Typography> */}
      {/* Ticket Shareholders */}
      {shareholders && shareholders.length > 0 && (
        <Box className="box-default text-center">
          {shareholders
            .sort((a, b) => {
              if (a.UserViewer) {
                return -1;
              }
              if (b.UserViewer) {
                return 1;
              }
              return 0;
            })
            .map((shareholder) => {
              let holderStatus = EVENT_CONSTANTS.tickets.states.filter(
                (obj) => obj.value === shareholder.Status
              )[0];

              return (
                <Box
                  key={shareholder.UserId}
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_SM2X,
                    pt: configEntzy.APP_SPACING_MD2X,
                    borderTop: shareholder.UserViewer
                      ? "4px dashed #000"
                      : "1px solid #ccc",
                    borderLeft: shareholder.UserViewer
                      ? "4px dashed #000"
                      : "none",
                    borderRight: shareholder.UserViewer
                      ? "4px dashed #000"
                      : "none",
                    borderBottom: shareholder.UserViewer
                      ? "4px dashed #000"
                      : "none",
                  }}
                >
                  <Box className="box-default">
                    <Box className="box-inline">
                      <UserAvatar
                        user={{ identity: shareholder.UserId }}
                        size="md"
                        light={true}
                      />
                    </Box>
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pb: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      ADMITS {shareholder.Quantity}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      noWrap={true}
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                        overflow: "visible",
                      }}
                    >
                      <Box
                        component="span"
                        className="upper-case"
                        sx={{
                          p: configEntzy.APP_SPACING_SM,
                          borderRadius: configEntzy.BORDER_SIZE_LG,
                          backgroundColor: holderStatus.color,
                          color: "white",
                        }}
                      >
                        &nbsp;&nbsp;{holderStatus.display}&nbsp;&nbsp;
                      </Box>
                    </Typography>
                    {holderStatus.paymentRetry && (
                      <Fragment>
                        <Typography
                          variant="subtitle2"
                          color="orange"
                          sx={{
                            mt: configEntzy.APP_SPACING_SM2X,
                          }}
                        >
                          Rider needs to retry
                        </Typography>
                        <Typography variant="subtitle2" color="orange">
                          payment for entry
                        </Typography>
                      </Fragment>
                    )}
                  </Box>
                  <Box className="box-default" hidden={!holderStatus.entry}>
                    <ActionBoxButton
                      size="small"
                      bgColor={
                        holderStatus.display === "entered"
                          ? "success.dark"
                          : "primary.main"
                      }
                      color="white"
                      text={
                        holderStatus.display === "entered"
                          ? "Readmit"
                          : "Stage for Entry"
                      }
                      subtext="(manual admission)"
                      fullWidth={true}
                      disableElevation={true}
                      onClick={() =>
                        handleManualHandshake(ticket, shareholders, shareholder)
                      }
                    />
                  </Box>
                </Box>
              );
            })}
        </Box>
      )}
    </Box>
  );
};
