// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { TextTag } from "components/utils/common/CommonTags";

function ContactSupport(props) {
  return (
    <Box className="box-default">
      <Box className="box-default">
        <Typography variant="h2" color="orange" noWrap={true}>
          Entzy Support
        </Typography>
        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          Get in touch
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="h6">
            Contact us with any query or issue.
          </Typography>
          <Typography variant="h6">We will get right back to you.</Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
            pb: configEntzy.APP_SPACING_LG2X,
          }}
        >
          <a href="mailto:support@entzy.com" className="image-link">
            <TextTag
              size="lg"
              text="support@entzy.com"
              bgColor="primary.main"
              color="black"
              lower={true}
              rounded={true}
              shadow={true}
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
}

export default ContactSupport;
