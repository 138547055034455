// react core
import { createContext } from "react";

// entzy models
import newSettings, { SETTINGS_CONSTANTS } from "models/Settings";
import newUser from "models/User";
import toolbox from "models/Tools";

const newAlert = function () {
  return { show: false, message: null };
};
const newTransactions = function () {
  return { hydrated: false, data: [] };
};

const getPaymentState = function (paymentSet, paymentId) {
  const paymentState = {};
  const alertMessage =
    "Unable to validate payment settings. Please update your payment method to revalidate.";
  if (paymentSet && paymentId) {
    try {
      let paymentParts = paymentId.split(":");
      paymentState.cardSnippet = paymentParts[2];
      paymentState.cardDelete = SETTINGS_CONSTANTS.emptyStringSet.replace(
        "none",
        paymentParts[0]
      );
      if (paymentState.cardSnippet !== "none") {
        let cardParts = paymentState.cardSnippet.split("-");
        paymentState.cardType = cardParts[0];
        paymentState.lastDigits = cardParts[1];
        paymentState.expiryMonth = cardParts[2];
        paymentState.expiryYear = cardParts[3];
        paymentState.alert = false;
      } else {
        paymentState.alert = true;
        paymentState.message = alertMessage;
      }
    } catch (error) {
      paymentState.alert = true;
      paymentState.message = alertMessage;
    }
  } else {
    paymentState.alert = false;
    paymentState.inactive = true;
  }
  return paymentState;
};

export const initialState = {
  settings: newSettings(),
  transactions: newTransactions(),
  user: newUser(),
  alert: newAlert(),
  constants: SETTINGS_CONSTANTS,
  toolbox: toolbox(),
};

export const settingsActions = {
  PULL_SETTINGS: "PULL_SETTINGS",
  PULL_TRANSACTIONS: "PULL_TRANSACTIONS",
  REFUND_TRANSACTION: "REFUND_TRANSACTION",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_ALERT: "UPDATE_ALERT",
};

export const settingsReducer = (state, action) => {
  // state capture for ephemeral actions
  let result = {};
  switch (action.type) {
    // initialize by pulling settings down
    case settingsActions.PULL_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          hydrated: true,
          data: action.identity.settings.data,
          activePaymentMethod: getPaymentState(
            action.identity.user.status.payment_set,
            action.identity.settings.data.PaymentId
          ),
        },
        user: action.identity.user,
        alert: {
          ...state.alert,
          show: false,
        },
      };
    // initialize by pulling transactions down
    case settingsActions.PULL_TRANSACTIONS:
      result.updatedData = action.transactions.more
        ? state.transactions.data.concat(action.transactions.data)
        : action.transactions.transactionType
        ? state.transactions.data.filter(
            (item) =>
              item["TransactionType"] === action.transactions.transactionType
          )
        : action.transactions.data;
      return {
        ...state,
        transactions: {
          ...state.transactions,
          hydrated: true,
          data: result.updatedData,
          nextToken: action.transactions.nextToken,
        },
        alert: {
          ...state.alert,
          show: false,
        },
      };
    // reverse transaction
    case settingsActions.REFUND_TRANSACTION:
      result.updatedData = state.transactions.data.map((item) => {
        if (item["TransactionId"] === action.transaction.TransactionId) {
          item["TransactionStatus"] = "refunded";
        }
        return item;
      });
      return {
        ...state,
        transactions: {
          ...state.transactions,
          hydrated: true,
          data: result.updatedData,
        },
        alert: {
          ...state.alert,
          show: false,
        },
      };
    // update settings name
    case settingsActions.UPDATE_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          data: {
            ...state.settings.data,
            [action.field.key]: action.field.value,
          },
          activePaymentMethod: getPaymentState(
            action.field.user.status.payment_set,
            action.field.key === "PaymentId"
              ? action.field.value
              : state.settings.data.PaymentId
          ),
        },
        user: action.field.user,
        alert: {
          ...state.alert,
          show: false,
        },
      };
    // connected user ownership
    case settingsActions.UPDATE_USER:
      return {
        ...state,
        user: action.user,
        alert: {
          ...state.alert,
          show: false,
        },
      };
    // alert messages
    case settingsActions.UPDATE_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
};

export const SettingsContext = createContext();
