// react core
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn as iconConnect } from "@fortawesome/pro-thin-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faUser as iconUser } from "@fortawesome/pro-thin-svg-icons";
import { faCommentAltDots as iconMessaging } from "@fortawesome/pro-thin-svg-icons";

// entzy pages
import MemberContacts from "./MemberContacts";
import MemberProfile from "./MemberProfile";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { BigButton } from "components/utils/common/CommonButtons";
import { HeaderTag } from "components/utils/common/CommonTags";

function MemberIndexPublic(props) {
  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
        }}
      >
        <Box
          className="box-inline"
          sx={{
            width: configEntzy.AVATAR_SIZE_LG,
          }}
        >
          <BigButton
            color="transparent"
            icon={props.drawerConnect ? iconClose : iconConnect}
            text={
              props.drawerConnect
                ? "Close"
                : "Connect to Access Direct Messaging"
            }
            onClick={props.drawerConnectToggle}
          />
        </Box>
      </Box>
    </Box>
  );
}

function MemberIndexMenu(props) {
  const user = props.user;

  const handlePostActionCallback = async (data) => {
    const memberName = data.filter((obj) => obj.id === "member-name")[0].value;
    if (memberName.length === 0) {
      return {
        alert: true,
        message: "Please enter a member name",
      };
    }
    props.setMemberName(memberName);
    return {
      alert: false,
    };
  };

  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_MD,
      }}
    >
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
        }}
      >
        <InputForm
          navigate={props.navigate}
          fields={[
            {
              id: "member-name",
              type: "text-standard",
              label: "Enter member name",
              value: "",
              required: true,
              alphanumeric: true,
              maxLength: 100,
              disabled: !user.connected,
              startAdornment: "@",
            },
          ]}
          submitText="Open New Chat"
          disabledSubmit={!user.connected}
          disabledTitle="Connect to continue"
          disabledText="Tap here to activate messaging"
          disabledClick={props.drawerConnectToggle}
          callback={handlePostActionCallback}
        />
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
        }}
      >
        <HeaderTag text="Contacts and Recent" />
      </Box>
      <MemberContacts {...props} />
    </Box>
  );
}

function MemberIndexConnected(props) {
  return (
    <Box className="box-default">
      <MemberProfile {...props} />
    </Box>
  );
}

function MemberIndex(props) {
  const [memberName, setMemberName] = useState(null);
  const [notesToSelf, setNotesToSelf] = useState(false);

  let userConnected = props.user.connected;
  let userName = props.user.name;
  let entryPage = props.page;

  let { member } = useParams();
  let memberOverride = props.member;

  useEffect(() => {
    if (userConnected) {
      if (entryPage === "/members/me") {
        setMemberName(userName);
      } else if (memberOverride) {
        setMemberName(memberOverride);
      } else if (member) {
        setMemberName(member.replace(/@/g, ""));
      }
    }
  }, [userConnected, userName, entryPage, member, memberOverride]);

  useEffect(() => {
    if (userName === memberName) {
      setNotesToSelf(true);
    } else {
      setNotesToSelf(false);
    }
  }, [userName, memberName]);

  // useEffect(() => {
  //   const matchBodyClass = () => {
  //     document.getElementById("anchor-member-area").className =
  //       "box-default " + document.body.className;
  //   };
  //   matchBodyClass();
  // }, []);

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box id="anchor-member-area" className="box-default">
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            {!memberName && (
              <FontAwesomeIcon icon={iconUser} transform="left-10" fixedWidth />
            )}
            <FontAwesomeIcon icon={iconMessaging} size="2x" fixedWidth />
            {!memberName && (
              <FontAwesomeIcon
                icon={iconUser}
                transform="right-10"
                fixedWidth
              />
            )}
          </Typography>
          <Typography
            variant="h2"
            noWrap={true}
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            {notesToSelf ? "Selfie DMs" : "Direct Messages"}
          </Typography>
        </Box>
        <Box className="box-default">
          <Container maxWidth="md">
            {props.user.connected ? (
              memberName ? (
                <MemberIndexConnected
                  {...props}
                  key={memberName}
                  memberName={memberName}
                  setMemberName={setMemberName}
                />
              ) : (
                <MemberIndexMenu {...props} setMemberName={setMemberName} />
              )
            ) : (
              <MemberIndexPublic {...props} />
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MemberIndex;
