// react core
import { useContext, useState, useEffect, useCallback } from "react";

// material design
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode as iconActiveOffers } from "@fortawesome/pro-duotone-svg-icons";
import { faHandPointer as iconPointer } from "@fortawesome/pro-thin-svg-icons";
// import { faPersonWalkingArrowLoopLeft as iconBack } from "@fortawesome/pro-solid-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { TickingViewer } from "components/event/lists/ListTickets";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionAlert } from "components/utils/common/CommonLoaders";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  serviceCookieGet,
  serviceCookieSet,
  serviceCookieClear,
} from "services/graphql/call";

function ActionDatesRider(props) {
  const user = props.user;
  const userReload = props.userReload;
  const navigate = props.navigate;
  const handleCloseAction = props.handleCloseAction;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [actionReload, setActionReload] = useState("none");
  const [selectionState, setSelectionState] = useState(null);
  const [newOffer, setNewOffer] = useState(false);
  const [offerSuccess, setOfferSuccess] = useState(false);
  const [offerAlert, setOfferAlert] = useState(false);
  const [offerMessage, setOfferMessage] = useState("");

  const anchorTop = "anchor-action-dates-rider";

  const steps = [
    "Select a ticket",
    "Set your group size",
    "Tick possible dates",
  ];

  // cookie constants
  const selectionStateCookieName = "payment_selection_state";

  const handleNewOffer = () => {
    setNewOffer(!newOffer);
  };

  const handlePinEvent = async () => {
    if (!eventContext.state.event.pinned) {
      const response = await eventContext.preparePinEvent({
        EventId: eventContext.state.event.data.EventId,
        pinned: true,
      });
      if (response.alert) {
        mainContext.updateAlert(response);
      } else {
        eventContext.pinEvent(response);
        mainContext.addEvent(
          eventContext.state.event.data,
          eventContext.state.event.owner,
          eventContext.state.event.manager
        );
      }
    }
  };
  const cbHandlePinEvent = useCallback(handlePinEvent, [
    eventContext,
    mainContext,
  ]);

  const handlePaymentFormCancel = async () => {
    setShowPaymentForm(false);
    setActionReload("payments-" + Date.now());
  };

  const placeOffer = async (offer) => {
    const ticket = await eventContext.prepareTicketOffer({
      EventId: eventContext.state.event.data.EventId,
      TypeId: offer.ticketTypeId,
      Quantity: offer.ticketQuantity,
      DateList: offer.ticketDateList,
      Holdings: offer.ticketHoldings,
    });
    if (ticket.alert) {
      return {
        alert: true,
        message: ticket.message,
      };
    } else {
      eventContext.updateViewerTickets(ticket);
      eventContext.updateEventStamp({
        key: "Dates",
      });
      setOfferSuccess(true);
      // move top and reset
      const element = document.getElementById(anchorTop);
      if (element) {
        eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
      }
      eventContext.setCallbackLoader(true);
      setNewOffer(false);
      setActionReload("done-" + Date.now());
      // pin event if not already pinned
      await cbHandlePinEvent();
      return { alert: false };
    }
  };
  const cbPlaceOffer = useCallback(placeOffer, [
    eventContext,
    cbHandlePinEvent,
  ]);

  const handleActionCallback = async (data) => {
    setOfferSuccess(false);
    const selection = {
      typeData: data.filter((obj) => obj.id === "ticket-type")[0].value,
      groupData: data.filter((obj) => obj.id === "ticket-group")[0].value,
      datesData: data.filter((obj) => obj.id === "ticket-dates")[0].value,
      membersData: data.filter((obj) => obj.id === "ticket-members")[0].value,
    };
    // build new ticket offer with dates
    if (!eventContext.state.event.manager) {
      if (selection.typeData && selection.groupData) {
        const offer = {
          ticketTypeId: selection.typeData.value,
          ticketQuantity: selection.groupData.value,
          ticketDateList: selection.datesData,
          ticketHoldings: selection.membersData.value,
        };
        if (
          !eventContext.state.user.status.payment_set &&
          selection.typeData.price > 0
        ) {
          offer.paymentUpdate = true;
          setSelectionState(offer);
          serviceCookieSet(selectionStateCookieName, JSON.stringify(offer));
          setShowPaymentForm(true);
          return { alert: false };
        } else {
          const offerResponse = await placeOffer(offer);
          // if (!offerResponse.alert) {
          //   setTimeout(() => {
          //     handleCloseAction();
          //   }, 5000);
          // }
          return offerResponse;
        }
      } else {
        return {
          alert: true,
          message: "Ticket selection and group size are required",
        };
      }
    } else {
      return {
        alert: true,
        message: "Event rider permissions required for ticket offers",
      };
    }
  };

  const handlePaymentActionCallback = async (data) => {
    const paymentSettings = data.filter(
      (obj) => obj.id === "payment-primary"
    )[0].value;
    if (
      paymentSettings &&
      paymentSettings.paymentId &&
      paymentSettings.paymentId.length
    ) {
      const response = await eventContext.prepareUpdatePaymentMethod({
        key: "PaymentId",
        value: paymentSettings.paymentId,
      });
      if (response.alert) {
        return response;
      } else {
        const offerResponse = await placeOffer(selectionState);
        serviceCookieClear(
          configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_payment_set
        );
        serviceCookieClear(selectionStateCookieName);
        setSelectionState(null);
        setTimeout(() => {
          handleCloseAction();
          userReload();
        }, 5000);
        return offerResponse;
      }
    } else {
      return {
        alert: true,
        message: "A valid payment method required",
      };
    }
  };

  // effect to check if payment cookie is set to trigger place offer
  useEffect(() => {
    const handlePaymentSetCookieCallback = async () => {
      const paymentCookie = serviceCookieGet(
        configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_payment_set
      );
      const paymentSelectionState = serviceCookieGet(selectionStateCookieName);
      if (paymentCookie && paymentSelectionState) {
        serviceCookieClear(
          configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_payment_set
        );
        serviceCookieClear(selectionStateCookieName);
        const offerResponse = await cbPlaceOffer(
          JSON.parse(paymentSelectionState)
        );
        if (offerResponse.alert) {
          setOfferAlert(true);
          setOfferMessage(offerResponse.message);
        }
        setTimeout(() => {
          handleCloseAction();
          navigate(
            configEntzy.URL_POINTERS.MAIN + eventContext.state.event.data.Url
          );
        }, 5000);
      }
    };
    handlePaymentSetCookieCallback();
  }, [
    cbPlaceOffer,
    handleCloseAction,
    navigate,
    userReload,
    eventContext.state.event.data.Url,
  ]);

  return (
    <Box id={anchorTop} className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
          pb: configEntzy.APP_SPACING_LG,
        }}
      >
        <Stepper alternativeLabel>
          {steps.map((label) => (
            <Step key={label} active={true}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
          pb: configEntzy.APP_SPACING_LG,
        }}
      >
        <ActionButton
          text={newOffer ? "Cancel" : "New Offer"}
          color={newOffer ? "bright" : "dusk"}
          onClick={handleNewOffer}
          startIcon={
            <FontAwesomeIcon
              icon={newOffer ? iconClose : iconActiveOffers}
              transform="right-4"
              fixedWidth
            />
          }
        />
      </Box>

      <Box className="box-default" hidden={!newOffer}>
        <Box className="box-default">
          <Container maxWidth="md" disableGutters>
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_LG2X,
              }}
            >
              <Box className="box-default" hidden={showPaymentForm}>
                <InputForm
                  user={user}
                  key={actionReload}
                  navigate={props.navigate}
                  fields={[
                    {
                      id: "ticket-type",
                      type: "selector-basic",
                      label: "Select a ticket type",
                      value: null,
                      emptyValue: "",
                      required: true,
                      selectPrompt: true,
                      disabled: !eventContext.state.user.connected,
                      options:
                        eventContext.state.event.calendar.tickets.selectable
                          .active,
                    },
                    {
                      id: "ticket-group",
                      type: "selector-basic",
                      label: "Select how many people on this ticket",
                      value: null,
                      emptyValue: "",
                      required: true,
                      hidden: true,
                      selectPrompt: true,
                      dependsOn: {
                        selection: "ticket-type",
                        property: "groupSelectable",
                        submitHidden: true,
                      },
                      disabled: !eventContext.state.user.connected,
                      options:
                        eventContext.state.event.calendar.tickets.groups
                          .selectable,
                    },
                    {
                      id: "ticket-members",
                      type: "picker-member-number-rocker",
                      label: "Set Named Ticket Holders (optional)",
                      placeholder: "Your holding must be at least 1",
                      value: [],
                      required: true,
                      multiple: true,
                      hidden: true,
                      dependsOn: {
                        selection: "ticket-group",
                        submitHidden: true,
                      },
                      disabled: !eventContext.state.user.connected,
                      options: mainContext.state.interaction.users.filter(
                        (iuser) =>
                          iuser.identity !== null &&
                          iuser.identity !== user.identity
                      ),
                    },
                    {
                      id: "ticket-dates",
                      type: "picker-calendar",
                      label: "Place Some Date offers (optional)",
                      placeholder:
                        "Select any initial dates to offer this ticket",
                      value: [],
                      required: true,
                      multiple: true,
                      hidden: true,
                      dependsOn: {
                        selection: "ticket-members",
                        submitHidden: false,
                      },
                      disabled: !eventContext.state.user.connected,
                      options:
                        eventContext.state.event.calendar.dates.selectable,
                      optionsDecorate: {
                        ticking:
                          eventContext.state.event.calendar.dates.combined
                            .active,
                        launched:
                          eventContext.state.event.calendar.dates.launched.all
                            .keys,
                      },
                    },
                  ]}
                  submitText="Create Ticket Offer"
                  submitType="flow"
                  disabledSubmit={!eventContext.state.user.connected}
                  disabledTitle="Connect to continue"
                  disabledText="Tap here to activate date offering. A connection is required to save the offer details."
                  disabledClick={eventContext.toggleConnect}
                  // changeEmitter={handleActionEmitter}
                  callback={handleActionCallback}
                />
              </Box>
              {/* PAYMENT FORM */}
              <Box
                className="box-default"
                hidden={!showPaymentForm}
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Box
                  className="box-default"
                  hidden={eventContext.state.user.status.payment_set}
                  sx={{
                    p: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <Typography variant="subtitle2">PAYMENT DETAILS</Typography>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    Add billing information for chargeable ticket selections.
                    You will only be charged if one of your offers triggers with
                    an event launch
                  </Typography>
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <InputForm
                    user={user}
                    userReload={userReload}
                    navigate={props.navigate}
                    page={props.page}
                    redirectPage={props.redirectPage}
                    searchParams={props.searchParams}
                    fields={[
                      {
                        id: "payment-primary",
                        type: "payment-method",
                        label: "Primary payment method",
                        value: "",
                        required: true,
                      },
                    ]}
                    submitType="auto"
                    submitText="Add Payment Details"
                    callback={handlePaymentActionCallback}
                    cancel={handlePaymentFormCancel}
                    cancelText="Cancel"
                    confirmation="Payment details added"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_XL,
              }}
              hidden={showPaymentForm}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                Set conditions under which your group can attend
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                Make offers with all your group on the same ticket
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                This ensures you all launch on the same date
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box className="box-default" hidden={newOffer}>
        <Box
          className="box-default"
          sx={{
            pb: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="h6">
            <span>Your tickets in play</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={iconActiveOffers} transform="shrink-4" />
            <span>&nbsp;</span>
            <span>{eventContext.state.viewer.tickets.offered.length}</span>
          </Typography>
        </Box>

        {user.connected ? (
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <Container maxWidth="md">
              {offerSuccess && (
                <Box
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <Box className="box-default">
                    <ActionAlert
                      severity="success"
                      title="Offer Created"
                      message="Offer in play and can be managed below in your ticking tickets. If it eventuates you'll be notified and it will move from here to your matched dates."
                      onClose={() => setOfferSuccess(false)}
                    />
                  </Box>
                </Box>
              )}
              {offerAlert && (
                <Box
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <ActionAlert
                    severity="error"
                    title="Offer Could Not Be Placed"
                    message={offerMessage}
                    onClose={() => setOfferAlert(false)}
                  />
                </Box>
              )}
            </Container>
            {eventContext.state.viewer.tickets.offered.length === 0 ? (
              <Box className="box-default">
                <Box
                  className="box-default action-pointer"
                  hidden={offerSuccess}
                  onClick={handleNewOffer}
                >
                  <Typography variant="subtitle2">
                    <Chip
                      label={
                        <span>
                          <span>Get started with a new offer</span>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <FontAwesomeIcon
                            icon={iconPointer}
                            transform="grow-4"
                          />
                          <span>&nbsp;</span>
                        </span>
                      }
                      sx={{ fontWeight: "bold" }}
                    />
                  </Typography>
                </Box>
                <Box className="box-default">
                  <Typography
                    variant="subtitle1"
                    color="lightgray"
                    sx={{
                      mt: configEntzy.APP_SPACING_HL,
                    }}
                  >
                    <em>You have no tickets in play</em>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="lightgray"
                    sx={{
                      mt: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <em>Any prior offers have eventuated</em>
                  </Typography>
                </Box>
                {/* <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_HL3X,
                        pb: configEntzy.APP_SPACING_XL2X,
                      }}
                    >
                      <Box
                        className="box-default"
                        sx={{
                          p: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <Box
                          className="box-inline"
                          sx={{
                            width: "80%",
                          }}
                        >
                          <ActionBoxButton
                            size="small"
                            text="Back to Dates Ticking"
                            color="dusk"
                            fullWidth={true}
                            onClick={() => {
                              props.handleCloseAction();
                              eventContext.focusModule("dates");
                            }}
                            startIcon={
                              <FontAwesomeIcon
                                icon={iconBack}
                                transform="right-4"
                                fixedWidth
                              />
                            }
                          />
                        </Box>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          p: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <Box
                          className="box-inline"
                          sx={{
                            width: "80%",
                          }}
                        >
                          <ActionBoxButton
                            size="small"
                            text="Back to Matched Dates"
                            color="dusk"
                            fullWidth={true}
                            onClick={() => {
                              props.handleCloseAction();
                              eventContext.focusModule("entry");
                            }}
                            startIcon={
                              <FontAwesomeIcon
                                icon={iconBack}
                                transform="right-4"
                                fixedWidth
                              />
                            }
                          />
                        </Box>
                      </Box>
                    </Box> */}
              </Box>
            ) : (
              <Box key={eventContext.state.id} className="box-default">
                {eventContext.state.viewer.tickets.offered.map((ticket) => {
                  return (
                    <Box
                      key={ticket.TicketId}
                      className="box-default"
                      sx={{
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <TickingViewer
                        user={eventContext.state.user}
                        mainContext={mainContext}
                        eventContext={eventContext}
                        ticket={ticket}
                        anchorTop={anchorTop}
                      />
                    </Box>
                  );
                })}
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="tertiary"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                    }}
                    hidden={
                      eventContext.state.viewer.tickets.offered.length === 0
                    }
                  >
                    <em>Edit or revoke offers any time before launch.</em>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="tertiary"
                    hidden={
                      eventContext.state.viewer.tickets.offered.length === 0
                    }
                  >
                    <em>Successful offers will move to eventuated.</em>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              pb: configEntzy.APP_SPACING_LG3X,
            }}
          >
            <Typography variant="subtitle2">
              Connect to place date offers
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ActionDatesRider;
