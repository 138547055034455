// react core
import { useState } from "react";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import ImageEntzyLogo from "assets/logos/entzy-main.png";
import { ActionLoader } from "components/utils/common/CommonLoaders";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck as IconSuccess,
  faEnvelope as IconCodeSent,
} from "@fortawesome/pro-thin-svg-icons";

function ConnectForgot(props) {
  // component states
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [userConfirmCode, setUserConfirmCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  // component functions
  const handleTextFieldEmail = async (event) => {
    setUserEmail(event.target.value.replace(/\s/g, ""));
  };
  const handleTextFieldPassword = async (event) => {
    setUserPassword(event.target.value);
  };
  const handleTextFieldConfirmPassword = async (event) => {
    setUserConfirmPassword(event.target.value);
  };
  const handleTextFieldConfirmCode = async (event) => {
    setUserConfirmCode(event.target.value.replace(/\s/g, ""));
  };

  const handleCodeRequestFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert(false);
    if (userEmail.length) {
      try {
        await props.handleConnect("Entzy", "forgot", userEmail);
        setCodeSent(true);
        setLoading(false);
      } catch (e) {
        console.error("ERROR", e);
        setMessage(e.message);
        setLoading(false);
        setAlert(true);
      }
    } else {
      setMessage("Email required");
      setLoading(false);
      setAlert(true);
    }
  };

  const handleCodeConfirmFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert(false);
    setActionMessage("");
    if (userEmail.length && userPassword.length && userConfirmCode.length) {
      if (userPassword === userConfirmPassword) {
        try {
          await props.handleConnect(
            "Entzy",
            "reset",
            userEmail,
            userConfirmCode,
            userPassword
          );
          setResetSuccess(true);
          setLoading(false);
        } catch (e) {
          setMessage(e.message);
          setLoading(false);
          setAlert(true);
        }
      } else {
        setMessage("Passwords do not match");
        setLoading(false);
        setAlert(true);
      }
    } else {
      setMessage("Email, password and reset code required");
      setLoading(false);
      setAlert(true);
    }
  };

  const handleCodeRequestFormKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      handleCodeRequestFormSubmit(e);
    }
  };

  const handleCodeConfirmFormKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      handleCodeConfirmFormSubmit(e);
    }
  };

  function renderCodeRequestForm() {
    return (
      <Grid item xs={12}>
        <Box className="box-default text-left">
          <IconButton
            aria-label="back"
            onClick={() => props.handleSection(null)}
          >
            <Avatar
              alt="Entzy"
              src={ImageEntzyLogo}
              sx={{ boxShadow: "0 0 0 1px #999" }}
            />
          </IconButton>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-email"
              type="email"
              label="Enter Email"
              autoComplete="username"
              value={userEmail}
              onKeyDown={handleCodeRequestFormKeyDown}
              onChange={handleTextFieldEmail}
              fullWidth
            />
          </FormControl>
        </Box>
        {loading ? (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <ActionLoader />
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              onClick={handleCodeRequestFormSubmit}
              fullWidth
            >
              Send Reset Code
            </Button>
          </Box>
        )}
        {alert && message && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Alert severity="error">{message}</Alert>
          </Box>
        )}
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
          }}
        >
          <Typography variant="h6" noWrap={true}>
            Forgot Password
          </Typography>
        </Box>
      </Grid>
    );
  }

  function renderCodeConfirmForm() {
    return (
      <Grid item xs={12}>
        <Box className="box-default text-left">
          <IconButton
            aria-label="back"
            onClick={() => props.handleSection(null)}
          >
            <Avatar
              alt="Entzy"
              src={ImageEntzyLogo}
              sx={{ boxShadow: "0 0 0 1px #999" }}
            />
          </IconButton>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FontAwesomeIcon
            icon={actionMessage ? IconSuccess : IconCodeSent}
            size="2x"
          />
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <Typography variant="body2">
            <strong>RESET CODE SENT</strong>
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="body2">{userEmail}</Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="body2">
            <em>Enter your new password with reset code</em>
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-password"
              type="password"
              label="Password"
              autoComplete="new-password"
              value={userPassword}
              onKeyDown={handleCodeConfirmFormKeyDown}
              onChange={handleTextFieldPassword}
              fullWidth
            />
          </FormControl>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-confirm-password"
              type="password"
              label="Confirm Password"
              autoComplete="new-password"
              value={userConfirmPassword}
              onKeyDown={handleCodeConfirmFormKeyDown}
              onChange={handleTextFieldConfirmPassword}
              fullWidth
            />
          </FormControl>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="user-code"
              type="tel"
              label="Enter Code"
              value={userConfirmCode}
              onKeyDown={handleCodeConfirmFormKeyDown}
              onChange={handleTextFieldConfirmCode}
              fullWidth
            />
          </FormControl>
        </Box>
        {loading ? (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <ActionLoader />
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              onClick={handleCodeConfirmFormSubmit}
              fullWidth
            >
              Connect
            </Button>
          </Box>
        )}
        {alert && message && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Alert severity="error">{message}</Alert>
          </Box>
        )}
      </Grid>
    );
  }

  function renderSuccessMessage() {
    return (
      <Grid item xs={12}>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <FontAwesomeIcon icon={IconSuccess} size="4x" />
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="body2" noWrap={true}>
            <strong>Your password has been reset</strong>
          </Typography>
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_XL,
          }}
        >
          <Button
            variant="contained"
            size="large"
            type="button"
            onClick={() => props.handleSection("direct")}
            fullWidth
          >
            Connect Now
          </Button>
        </Box>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box>
        <Grid container>
          {resetSuccess
            ? renderSuccessMessage()
            : codeSent
            ? renderCodeConfirmForm()
            : renderCodeRequestForm()}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default ConnectForgot;
