const COUNTRY_MODEL = {
  af: {
    code: { short: "af", long: "afg", numeric: "004" },
    name: "Afghanistan",
    currency: "afn",
    common: false,
  },
  ax: {
    code: { short: "ax", long: "ala", numeric: "248" },
    name: "Åland Islands",
    currency: "eur",
    common: false,
  },
  al: {
    code: { short: "al", long: "alb", numeric: "008" },
    name: "Albania",
    currency: "all",
    common: false,
  },
  dz: {
    code: { short: "dz", long: "dza", numeric: "012" },
    name: "Algeria",
    currency: "dzd",
    common: false,
  },
  as: {
    code: { short: "as", long: "asm", numeric: "016" },
    name: "American Samoa",
    currency: "usd",
    common: false,
  },
  ad: {
    code: { short: "ad", long: "and", numeric: "020" },
    name: "Andorra",
    currency: "eur",
    common: false,
  },
  ao: {
    code: { short: "ao", long: "ago", numeric: "024" },
    name: "Angola",
    currency: "aoa",
    common: false,
  },
  ai: {
    code: { short: "ai", long: "aia", numeric: "660" },
    name: "Anguilla",
    currency: "xcd",
    common: false,
  },
  aq: {
    code: { short: "aq", long: "ata", numeric: "010" },
    name: "Antarctica",
    currency: "usd",
    common: false,
  },
  ag: {
    code: { short: "ag", long: "atg", numeric: "028" },
    name: "Antigua and Barbuda",
    currency: "xcd",
    common: false,
  },
  ar: {
    code: { short: "ar", long: "arg", numeric: "032" },
    name: "Argentina",
    currency: "ars",
    common: false,
  },
  am: {
    code: { short: "am", long: "arm", numeric: "051" },
    name: "Armenia",
    currency: "amd",
    common: false,
  },
  aw: {
    code: { short: "aw", long: "abw", numeric: "533" },
    name: "Aruba",
    currency: "awg",
    common: false,
  },
  au: {
    code: { short: "au", long: "aus", numeric: "036" },
    name: "Australia",
    currency: "aud",
    common: false,
  },
  at: {
    code: { short: "at", long: "aut", numeric: "040" },
    name: "Austria",
    currency: "eur",
    common: true,
  },
  az: {
    code: { short: "az", long: "aze", numeric: "031" },
    name: "Azerbaijan",
    currency: "azn",
    common: false,
  },
  bs: {
    code: { short: "bs", long: "bhs", numeric: "044" },
    name: "Bahamas",
    currency: "bsd",
    common: false,
  },
  bh: {
    code: { short: "bh", long: "bhr", numeric: "048" },
    name: "Bahrain",
    currency: "bhd",
    common: false,
  },
  bd: {
    code: { short: "bd", long: "bgd", numeric: "050" },
    name: "Bangladesh",
    currency: "bdt",
    common: false,
  },
  bb: {
    code: { short: "bb", long: "brb", numeric: "052" },
    name: "Barbados",
    currency: "bbd",
    common: false,
  },
  by: {
    code: { short: "by", long: "blr", numeric: "112" },
    name: "Belarus",
    currency: "byn",
    common: false,
  },
  be: {
    code: { short: "be", long: "bel", numeric: "056" },
    name: "Belgium",
    currency: "eur",
    common: true,
  },
  bz: {
    code: { short: "bz", long: "blz", numeric: "084" },
    name: "Belize",
    currency: "bzd",
    common: false,
  },
  bj: {
    code: { short: "bj", long: "ben", numeric: "204" },
    name: "Benin",
    currency: "xof",
    common: false,
  },
  bm: {
    code: { short: "bm", long: "bmu", numeric: "060" },
    name: "Bermuda",
    currency: "bmd",
    common: false,
  },
  bt: {
    code: { short: "bt", long: "btn", numeric: "064" },
    name: "Bhutan",
    currency: "inr",
    common: false,
  },
  bo: {
    code: { short: "bo", long: "bol", numeric: "068" },
    name: "Bolivia",
    currency: "bob",
    common: false,
  },
  bq: {
    code: { short: "bq", long: "bes", numeric: "535" },
    name: "Bonaire, Sint Eustatius and Saba",
    currency: "eur",
    common: false,
  },
  ba: {
    code: { short: "ba", long: "bih", numeric: "070" },
    name: "Bosnia and Herzegovina",
    currency: "bam",
    common: false,
  },
  bw: {
    code: { short: "bw", long: "bwa", numeric: "072" },
    name: "Botswana",
    currency: "bwp",
    common: false,
  },
  bv: {
    code: { short: "bv", long: "bvt", numeric: "074" },
    name: "Bouvet Island",
    currency: "nok",
    common: false,
  },
  br: {
    code: { short: "br", long: "bra", numeric: "076" },
    name: "Brazil",
    currency: "brl",
    common: true,
  },
  vg: {
    code: { short: "vg", long: "vgb", numeric: "092" },
    name: "British Virgin Islands",
    currency: "usd",
    common: false,
  },
  io: {
    code: { short: "io", long: "iot", numeric: "086" },
    name: "British Indian Ocean Territory",
    currency: "usd",
    common: false,
  },
  bn: {
    code: { short: "bn", long: "brn", numeric: "096" },
    name: "Brunei Darussalam",
    currency: "bnd",
    common: false,
  },
  bg: {
    code: { short: "bg", long: "bgr", numeric: "100" },
    name: "Bulgaria",
    currency: "bgn",
    common: false,
  },
  bf: {
    code: { short: "bf", long: "bfa", numeric: "854" },
    name: "Burkina Faso",
    currency: "xof",
    common: false,
  },
  bi: {
    code: { short: "bi", long: "bdi", numeric: "108" },
    name: "Burundi",
    currency: "bif",
    common: false,
  },
  kh: {
    code: { short: "kh", long: "khm", numeric: "116" },
    name: "Cambodia",
    currency: "khr",
    common: false,
  },
  cm: {
    code: { short: "cm", long: "cmr", numeric: "120" },
    name: "Cameroon",
    currency: "xaf",
    common: false,
  },
  ca: {
    code: { short: "ca", long: "can", numeric: "124" },
    name: "Canada",
    currency: "cad",
    common: true,
  },
  cv: {
    code: { short: "cv", long: "cpv", numeric: "132" },
    name: "Cape Verde",
    currency: "cve",
    common: false,
  },
  ky: {
    code: { short: "ky", long: "cym", numeric: "136" },
    name: "Cayman Islands",
    currency: "kyd",
    common: false,
  },
  cf: {
    code: { short: "cf", long: "caf", numeric: "140" },
    name: "Central African Republic",
    currency: "xaf",
    common: false,
  },
  td: {
    code: { short: "td", long: "tcd", numeric: "148" },
    name: "Chad",
    currency: "xaf",
    common: false,
  },
  cl: {
    code: { short: "cl", long: "chl", numeric: "152" },
    name: "Chile",
    currency: "clp",
    common: false,
  },
  cn: {
    code: { short: "cn", long: "chn", numeric: "156" },
    name: "China",
    currency: "cny",
    common: false,
  },
  hk: {
    code: { short: "hk", long: "hkg", numeric: "344" },
    name: "Hong Kong, SAR China",
    currency: "hkd",
    common: false,
  },
  mo: {
    code: { short: "mo", long: "mac", numeric: "446" },
    name: "Macao, SAR China",
    currency: "mop",
    common: false,
  },
  cx: {
    code: { short: "cx", long: "cxr", numeric: "162" },
    name: "Christmas Island",
    currency: "aud",
    common: false,
  },
  cc: {
    code: { short: "cc", long: "cck", numeric: "166" },
    name: "Cocos (Keeling) Islands",
    currency: "aud",
    common: false,
  },
  co: {
    code: { short: "co", long: "col", numeric: "170" },
    name: "Colombia",
    currency: "cop",
    common: true,
  },
  km: {
    code: { short: "km", long: "com", numeric: "174" },
    name: "Comoros",
    currency: "kmf",
    common: false,
  },
  cg: {
    code: { short: "cg", long: "cog", numeric: "178" },
    name: "Congo (Brazzaville)",
    currency: "xaf",
    common: false,
  },
  cd: {
    code: { short: "cd", long: "cod", numeric: "180" },
    name: "Congo, (Kinshasa)",
    currency: "cdf",
    common: false,
  },
  ck: {
    code: { short: "ck", long: "cok", numeric: "184" },
    name: "Cook Islands",
    currency: "nzd",
    common: false,
  },
  cr: {
    code: { short: "cr", long: "cri", numeric: "188" },
    name: "Costa Rica",
    currency: "crc",
    common: false,
  },
  ci: {
    code: { short: "ci", long: "civ", numeric: "384" },
    name: "Côte d'Ivoire",
    currency: "xof",
    common: false,
  },
  hr: {
    code: { short: "hr", long: "hrv", numeric: "191" },
    name: "Croatia",
    currency: "hrk",
    common: false,
  },
  cu: {
    code: { short: "cu", long: "cub", numeric: "192" },
    name: "Cuba",
    currency: "cup",
    common: false,
  },
  cw: {
    code: { short: "cw", long: "cuw", numeric: "531" },
    name: "Curaçao",
    currency: "ang",
    common: false,
  },
  cy: {
    code: { short: "cy", long: "cyp", numeric: "196" },
    name: "Cyprus",
    currency: "eur",
    common: false,
  },
  cz: {
    code: { short: "cz", long: "cze", numeric: "203" },
    name: "Czech Republic",
    currency: "czk",
    common: false,
  },
  dk: {
    code: { short: "dk", long: "dnk", numeric: "208" },
    name: "Denmark",
    currency: "dkk",
    common: false,
  },
  dj: {
    code: { short: "dj", long: "dji", numeric: "262" },
    name: "Djibouti",
    currency: "djf",
    common: false,
  },
  dm: {
    code: { short: "dm", long: "dma", numeric: "212" },
    name: "Dominica",
    currency: "xcd",
    common: false,
  },
  do: {
    code: { short: "do", long: "dom", numeric: "214" },
    name: "Dominican Republic",
    currency: "dop",
    common: false,
  },
  ec: {
    code: { short: "ec", long: "ecu", numeric: "218" },
    name: "Ecuador",
    currency: "usd",
    common: false,
  },
  eg: {
    code: { short: "eg", long: "egy", numeric: "818" },
    name: "Egypt",
    currency: "egp",
    common: false,
  },
  sv: {
    code: { short: "sv", long: "slv", numeric: "222" },
    name: "El Salvador",
    currency: "svc",
    common: false,
  },
  gq: {
    code: { short: "gq", long: "gnq", numeric: "226" },
    name: "Equatorial Guinea",
    currency: "xaf",
    common: false,
  },
  er: {
    code: { short: "er", long: "eri", numeric: "232" },
    name: "Eritrea",
    currency: "ern",
    common: false,
  },
  ee: {
    code: { short: "ee", long: "est", numeric: "233" },
    name: "Estonia",
    currency: "eur",
    common: false,
  },
  et: {
    code: { short: "et", long: "eth", numeric: "231" },
    name: "Ethiopia",
    currency: "etb",
    common: false,
  },
  fk: {
    code: { short: "fk", long: "flk", numeric: "238" },
    name: "Falkland Islands (Malvinas)",
    currency: "fkp",
    common: false,
  },
  fo: {
    code: { short: "fo", long: "fro", numeric: "234" },
    name: "Faroe Islands",
    currency: "dkk",
    common: false,
  },
  fj: {
    code: { short: "fj", long: "fji", numeric: "242" },
    name: "Fiji",
    currency: "fjd",
    common: false,
  },
  fi: {
    code: { short: "fi", long: "fin", numeric: "246" },
    name: "Finland",
    currency: "eur",
    common: false,
  },
  fr: {
    code: { short: "fr", long: "fra", numeric: "250" },
    name: "France",
    currency: "eur",
    common: true,
  },
  gf: {
    code: { short: "gf", long: "guf", numeric: "254" },
    name: "French Guiana",
    currency: "eur",
    common: false,
  },
  pf: {
    code: { short: "pf", long: "pyf", numeric: "258" },
    name: "French Polynesia",
    currency: "xpf",
    common: false,
  },
  tf: {
    code: { short: "tf", long: "atf", numeric: "260" },
    name: "French Southern Territories",
    currency: "eur",
    common: false,
  },
  ga: {
    code: { short: "ga", long: "gab", numeric: "266" },
    name: "Gabon",
    currency: "xaf",
    common: false,
  },
  gm: {
    code: { short: "gm", long: "gmb", numeric: "270" },
    name: "Gambia",
    currency: "gmd",
    common: false,
  },
  ge: {
    code: { short: "ge", long: "geo", numeric: "268" },
    name: "Georgia",
    currency: "gel",
    common: false,
  },
  de: {
    code: { short: "de", long: "deu", numeric: "276" },
    name: "Germany",
    currency: "eur",
    common: true,
  },
  gh: {
    code: { short: "gh", long: "gha", numeric: "288" },
    name: "Ghana",
    currency: "ghs",
    common: false,
  },
  gi: {
    code: { short: "gi", long: "gib", numeric: "292" },
    name: "Gibraltar",
    currency: "gip",
    common: false,
  },
  gr: {
    code: { short: "gr", long: "grc", numeric: "300" },
    name: "Greece",
    currency: "eur",
    common: false,
  },
  gl: {
    code: { short: "gl", long: "grl", numeric: "304" },
    name: "Greenland",
    currency: "dkk",
    common: false,
  },
  gd: {
    code: { short: "gd", long: "grd", numeric: "308" },
    name: "Grenada",
    currency: "xcd",
    common: false,
  },
  gp: {
    code: { short: "gp", long: "glp", numeric: "312" },
    name: "Guadeloupe",
    currency: "eur",
    common: false,
  },
  gu: {
    code: { short: "gu", long: "gum", numeric: "316" },
    name: "Guam",
    currency: "usd",
    common: false,
  },
  gt: {
    code: { short: "gt", long: "gtm", numeric: "320" },
    name: "Guatemala",
    currency: "gtq",
    common: false,
  },
  gg: {
    code: { short: "gg", long: "ggy", numeric: "831" },
    name: "Guernsey",
    currency: "gbp",
    common: false,
  },
  gn: {
    code: { short: "gn", long: "gin", numeric: "324" },
    name: "Guinea",
    currency: "gnf",
    common: false,
  },
  gw: {
    code: { short: "gw", long: "gnb", numeric: "624" },
    name: "Guinea-Bissau",
    currency: "xof",
    common: false,
  },
  gy: {
    code: { short: "gy", long: "guy", numeric: "328" },
    name: "Guyana",
    currency: "gyd",
    common: false,
  },
  ht: {
    code: { short: "ht", long: "hti", numeric: "332" },
    name: "Haiti",
    currency: "htg",
    common: false,
  },
  hm: {
    code: { short: "hm", long: "hmd", numeric: "334" },
    name: "Heard and Mcdonald Islands",
    currency: "aud",
    common: false,
  },
  va: {
    code: { short: "va", long: "vat", numeric: "336" },
    name: "Holy See (Vatican City State)",
    currency: "eur",
    common: false,
  },
  hn: {
    code: { short: "hn", long: "hnd", numeric: "340" },
    name: "Honduras",
    currency: "hnl",
    common: false,
  },
  hu: {
    code: { short: "hu", long: "hun", numeric: "348" },
    name: "Hungary",
    currency: "huf",
    common: false,
  },
  is: {
    code: { short: "is", long: "isl", numeric: "352" },
    name: "Iceland",
    currency: "isk",
    common: false,
  },
  in: {
    code: { short: "in", long: "ind", numeric: "356" },
    name: "India",
    currency: "inr",
    common: false,
  },
  id: {
    code: { short: "id", long: "idn", numeric: "360" },
    name: "Indonesia",
    currency: "idr",
    common: false,
  },
  ir: {
    code: { short: "ir", long: "irn", numeric: "364" },
    name: "Iran, Islamic Republic of",
    currency: "irr",
    common: false,
  },
  iq: {
    code: { short: "iq", long: "irq", numeric: "368" },
    name: "Iraq",
    currency: "iqd",
    common: false,
  },
  ie: {
    code: { short: "ie", long: "irl", numeric: "372" },
    name: "Ireland",
    currency: "eur",
    common: true,
  },
  im: {
    code: { short: "im", long: "imn", numeric: "833" },
    name: "Isle of Man",
    currency: "gbp",
    common: false,
  },
  il: {
    code: { short: "il", long: "isr", numeric: "376" },
    name: "Israel",
    currency: "ils",
    common: false,
  },
  it: {
    code: { short: "it", long: "ita", numeric: "380" },
    name: "Italy",
    currency: "eur",
    common: true,
  },
  jm: {
    code: { short: "jm", long: "jam", numeric: "388" },
    name: "Jamaica",
    currency: "jmd",
    common: false,
  },
  jp: {
    code: { short: "jp", long: "jpn", numeric: "392" },
    name: "Japan",
    currency: "jpy",
    common: false,
  },
  je: {
    code: { short: "je", long: "jey", numeric: "832" },
    name: "Jersey",
    currency: "gbp",
    common: false,
  },
  jo: {
    code: { short: "jo", long: "jor", numeric: "400" },
    name: "Jordan",
    currency: "jod",
    common: false,
  },
  kz: {
    code: { short: "kz", long: "kaz", numeric: "398" },
    name: "Kazakhstan",
    currency: "kzt",
    common: false,
  },
  ke: {
    code: { short: "ke", long: "ken", numeric: "404" },
    name: "Kenya",
    currency: "kes",
    common: false,
  },
  ki: {
    code: { short: "ki", long: "kir", numeric: "296" },
    name: "Kiribati",
    currency: "aud",
    common: false,
  },
  kp: {
    code: { short: "kp", long: "prk", numeric: "408" },
    name: "Korea (North)",
    currency: "kpw",
    common: false,
  },
  kr: {
    code: { short: "kr", long: "kor", numeric: "410" },
    name: "Korea (South)",
    currency: "krw",
    common: false,
  },
  kw: {
    code: { short: "kw", long: "kwt", numeric: "414" },
    name: "Kuwait",
    currency: "kwd",
    common: false,
  },
  kg: {
    code: { short: "kg", long: "kgz", numeric: "417" },
    name: "Kyrgyzstan",
    currency: "kgs",
    common: false,
  },
  la: {
    code: { short: "la", long: "lao", numeric: "418" },
    name: "Lao PDR",
    currency: "lak",
    common: false,
  },
  lv: {
    code: { short: "lv", long: "lva", numeric: "428" },
    name: "Latvia",
    currency: "eur",
    common: false,
  },
  lb: {
    code: { short: "lb", long: "lbn", numeric: "422" },
    name: "Lebanon",
    currency: "lbp",
    common: false,
  },
  ls: {
    code: { short: "ls", long: "lso", numeric: "426" },
    name: "Lesotho",
    currency: "zar",
    common: false,
  },
  lr: {
    code: { short: "lr", long: "lbr", numeric: "430" },
    name: "Liberia",
    currency: "lrd",
    common: false,
  },
  ly: {
    code: { short: "ly", long: "lby", numeric: "434" },
    name: "Libya",
    currency: "lyd",
    common: false,
  },
  li: {
    code: { short: "li", long: "lie", numeric: "438" },
    name: "Liechtenstein",
    currency: "chf",
    common: false,
  },
  lt: {
    code: { short: "lt", long: "ltu", numeric: "440" },
    name: "Lithuania",
    currency: "eur",
    common: false,
  },
  lu: {
    code: { short: "lu", long: "lux", numeric: "442" },
    name: "Luxembourg",
    currency: "eur",
    common: false,
  },
  mk: {
    code: { short: "mk", long: "mkd", numeric: "807" },
    name: "Macedonia, Republic of",
    currency: "mkd",
    common: false,
  },
  mg: {
    code: { short: "mg", long: "mdg", numeric: "450" },
    name: "Madagascar",
    currency: "mga",
    common: false,
  },
  mw: {
    code: { short: "mw", long: "mwi", numeric: "454" },
    name: "Malawi",
    currency: "mwk",
    common: false,
  },
  my: {
    code: { short: "my", long: "mys", numeric: "458" },
    name: "Malaysia",
    currency: "myr",
    common: false,
  },
  mv: {
    code: { short: "mv", long: "mdv", numeric: "462" },
    name: "Maldives",
    currency: "mvr",
    common: false,
  },
  ml: {
    code: { short: "ml", long: "mli", numeric: "466" },
    name: "Mali",
    currency: "xof",
    common: false,
  },
  mt: {
    code: { short: "mt", long: "mlt", numeric: "470" },
    name: "Malta",
    currency: "eur",
    common: false,
  },
  mh: {
    code: { short: "mh", long: "mhl", numeric: "584" },
    name: "Marshall Islands",
    currency: "usd",
    common: false,
  },
  mq: {
    code: { short: "mq", long: "mtq", numeric: "474" },
    name: "Martinique",
    currency: "eur",
    common: false,
  },
  mr: {
    code: { short: "mr", long: "mrt", numeric: "478" },
    name: "Mauritania",
    currency: "mru",
    common: false,
  },
  mu: {
    code: { short: "mu", long: "mus", numeric: "480" },
    name: "Mauritius",
    currency: "mur",
    common: false,
  },
  yt: {
    code: { short: "yt", long: "myt", numeric: "175" },
    name: "Mayotte",
    currency: "eur",
    common: false,
  },
  mx: {
    code: { short: "mx", long: "mex", numeric: "484" },
    name: "Mexico",
    currency: "mxn",
    common: false,
  },
  fm: {
    code: { short: "fm", long: "fsm", numeric: "583" },
    name: "Micronesia, Federated States of",
    currency: "usd",
    common: false,
  },
  md: {
    code: { short: "md", long: "mda", numeric: "498" },
    name: "Moldova",
    currency: "mdl",
    common: false,
  },
  mc: {
    code: { short: "mc", long: "mco", numeric: "492" },
    name: "Monaco",
    currency: "eur",
    common: false,
  },
  mn: {
    code: { short: "mn", long: "mng", numeric: "496" },
    name: "Mongolia",
    currency: "mnt",
    common: false,
  },
  me: {
    code: { short: "me", long: "mne", numeric: "499" },
    name: "Montenegro",
    currency: "eur",
    common: false,
  },
  ms: {
    code: { short: "ms", long: "msr", numeric: "500" },
    name: "Montserrat",
    currency: "xcd",
    common: false,
  },
  ma: {
    code: { short: "ma", long: "mar", numeric: "504" },
    name: "Morocco",
    currency: "mad",
    common: false,
  },
  mz: {
    code: { short: "mz", long: "moz", numeric: "508" },
    name: "Mozambique",
    currency: "mzn",
    common: false,
  },
  mm: {
    code: { short: "mm", long: "mmr", numeric: "104" },
    name: "Myanmar",
    currency: "mmk",
    common: false,
  },
  na: {
    code: { short: "na", long: "nam", numeric: "516" },
    name: "Namibia",
    currency: "zar",
    common: false,
  },
  nr: {
    code: { short: "nr", long: "nru", numeric: "520" },
    name: "Nauru",
    currency: "aud",
    common: false,
  },
  np: {
    code: { short: "np", long: "npl", numeric: "524" },
    name: "Nepal",
    currency: "npr",
    common: false,
  },
  nl: {
    code: { short: "nl", long: "nld", numeric: "528" },
    name: "Netherlands",
    currency: "eur",
    common: true,
  },
  nc: {
    code: { short: "nc", long: "ncl", numeric: "540" },
    name: "New Caledonia",
    currency: "xpf",
    common: false,
  },
  nz: {
    code: { short: "nz", long: "nzl", numeric: "554" },
    name: "New Zealand",
    currency: "nzd",
    common: false,
  },
  ni: {
    code: { short: "ni", long: "nic", numeric: "558" },
    name: "Nicaragua",
    currency: "nio",
    common: false,
  },
  ne: {
    code: { short: "ne", long: "ner", numeric: "562" },
    name: "Niger",
    currency: "xof",
    common: false,
  },
  ng: {
    code: { short: "ng", long: "nga", numeric: "566" },
    name: "Nigeria",
    currency: "ngn",
    common: false,
  },
  nu: {
    code: { short: "nu", long: "niu", numeric: "570" },
    name: "Niue",
    currency: "nzd",
    common: false,
  },
  nf: {
    code: { short: "nf", long: "nfk", numeric: "574" },
    name: "Norfolk Island",
    currency: "aud",
    common: false,
  },
  mp: {
    code: { short: "mp", long: "mnp", numeric: "580" },
    name: "Northern Mariana Islands",
    currency: "usd",
    common: false,
  },
  no: {
    code: { short: "no", long: "nor", numeric: "578" },
    name: "Norway",
    currency: "nok",
    common: false,
  },
  om: {
    code: { short: "om", long: "omn", numeric: "512" },
    name: "Oman",
    currency: "omr",
    common: false,
  },
  pk: {
    code: { short: "pk", long: "pak", numeric: "586" },
    name: "Pakistan",
    currency: "pkr",
    common: false,
  },
  pw: {
    code: { short: "pw", long: "plw", numeric: "585" },
    name: "Palau",
    currency: "usd",
    common: false,
  },
  ps: {
    code: { short: "ps", long: "pse", numeric: "275" },
    name: "Palestinian Territory",
    currency: "ils",
    common: false,
  },
  pa: {
    code: { short: "pa", long: "pan", numeric: "591" },
    name: "Panama",
    currency: "pab",
    common: false,
  },
  pg: {
    code: { short: "pg", long: "png", numeric: "598" },
    name: "Papua New Guinea",
    currency: "pgk",
    common: false,
  },
  py: {
    code: { short: "py", long: "pry", numeric: "600" },
    name: "Paraguay",
    currency: "pyg",
    common: false,
  },
  pe: {
    code: { short: "pe", long: "per", numeric: "604" },
    name: "Peru",
    currency: "pen",
    common: false,
  },
  ph: {
    code: { short: "ph", long: "phl", numeric: "608" },
    name: "Philippines",
    currency: "php",
    common: false,
  },
  pn: {
    code: { short: "pn", long: "pcn", numeric: "612" },
    name: "Pitcairn",
    currency: "nzd",
    common: false,
  },
  pl: {
    code: { short: "pl", long: "pol", numeric: "616" },
    name: "Poland",
    currency: "pln",
    common: false,
  },
  pt: {
    code: { short: "pt", long: "prt", numeric: "620" },
    name: "Portugal",
    currency: "eur",
    common: false,
  },
  pr: {
    code: { short: "pr", long: "pri", numeric: "630" },
    name: "Puerto Rico",
    currency: "usd",
    common: false,
  },
  qa: {
    code: { short: "qa", long: "qat", numeric: "634" },
    name: "Qatar",
    currency: "qar",
    common: false,
  },
  re: {
    code: { short: "re", long: "reu", numeric: "638" },
    name: "Réunion",
    currency: "eur",
    common: false,
  },
  ro: {
    code: { short: "ro", long: "rou", numeric: "642" },
    name: "Romania",
    currency: "ron",
    common: false,
  },
  ru: {
    code: { short: "ru", long: "rus", numeric: "643" },
    name: "Russia",
    currency: "rub",
    common: false,
  },
  rw: {
    code: { short: "rw", long: "rwa", numeric: "646" },
    name: "Rwanda",
    currency: "rwf",
    common: false,
  },
  bl: {
    code: { short: "bl", long: "blm", numeric: "652" },
    name: "Saint-Barthélemy",
    currency: "eur",
    common: false,
  },
  sh: {
    code: { short: "sh", long: "shn", numeric: "654" },
    name: "Saint Helena",
    currency: "shp",
    common: false,
  },
  kn: {
    code: { short: "kn", long: "kna", numeric: "659" },
    name: "Saint Kitts and Nevis",
    currency: "xcd",
    common: false,
  },
  lc: {
    code: { short: "lc", long: "lca", numeric: "662" },
    name: "Saint Lucia",
    currency: "xcd",
    common: false,
  },
  mf: {
    code: { short: "mf", long: "maf", numeric: "663" },
    name: "Saint-Martin (French part)",
    currency: "eur",
    common: false,
  },
  pm: {
    code: { short: "pm", long: "spm", numeric: "666" },
    name: "Saint Pierre and Miquelon",
    currency: "eur",
    common: false,
  },
  vc: {
    code: { short: "vc", long: "vct", numeric: "670" },
    name: "Saint Vincent and Grenadines",
    currency: "xcd",
    common: false,
  },
  ws: {
    code: { short: "ws", long: "wsm", numeric: "882" },
    name: "Samoa",
    currency: "wst",
    common: false,
  },
  sm: {
    code: { short: "sm", long: "smr", numeric: "674" },
    name: "San Marino",
    currency: "eur",
    common: false,
  },
  st: {
    code: { short: "st", long: "stp", numeric: "678" },
    name: "Sao Tome and Principe",
    currency: "stn",
    common: false,
  },
  sa: {
    code: { short: "sa", long: "sau", numeric: "682" },
    name: "Saudi Arabia",
    currency: "sar",
    common: false,
  },
  sn: {
    code: { short: "sn", long: "sen", numeric: "686" },
    name: "Senegal",
    currency: "xof",
    common: false,
  },
  rs: {
    code: { short: "rs", long: "srb", numeric: "688" },
    name: "Serbia",
    currency: "rsd",
    common: false,
  },
  sc: {
    code: { short: "sc", long: "syc", numeric: "690" },
    name: "Seychelles",
    currency: "scr",
    common: false,
  },
  sl: {
    code: { short: "sl", long: "sle", numeric: "694" },
    name: "Sierra Leone",
    currency: "sll",
    common: false,
  },
  sg: {
    code: { short: "sg", long: "sgp", numeric: "702" },
    name: "Singapore",
    currency: "sgd",
    common: true,
  },
  sx: {
    code: { short: "sx", long: "sxm", numeric: "534" },
    name: "Sint Maarten (Dutch part)",
    currency: "ang",
    common: false,
  },
  sk: {
    code: { short: "sk", long: "svk", numeric: "703" },
    name: "Slovakia",
    currency: "eur",
    common: false,
  },
  si: {
    code: { short: "si", long: "svn", numeric: "705" },
    name: "Slovenia",
    currency: "eur",
    common: false,
  },
  sb: {
    code: { short: "sb", long: "slb", numeric: "090" },
    name: "Solomon Islands",
    currency: "sbd",
    common: false,
  },
  so: {
    code: { short: "so", long: "som", numeric: "706" },
    name: "Somalia",
    currency: "sos",
    common: false,
  },
  za: {
    code: { short: "za", long: "zaf", numeric: "710" },
    name: "South Africa",
    currency: "zar",
    common: true,
  },
  gs: {
    code: { short: "gs", long: "sgs", numeric: "239" },
    name: "South Georgia and the South Sandwich Islands",
    currency: "gbp",
    common: false,
  },
  ss: {
    code: { short: "ss", long: "ssd", numeric: "728" },
    name: "South Sudan",
    currency: "ssp",
    common: false,
  },
  es: {
    code: { short: "es", long: "esp", numeric: "724" },
    name: "Spain",
    currency: "eur",
    common: true,
  },
  lk: {
    code: { short: "lk", long: "lka", numeric: "144" },
    name: "Sri Lanka",
    currency: "lkr",
    common: false,
  },
  sd: {
    code: { short: "sd", long: "sdn", numeric: "736" },
    name: "Sudan",
    currency: "sdg",
    common: false,
  },
  sr: {
    code: { short: "sr", long: "sur", numeric: "740" },
    name: "Suriname",
    currency: "srd",
    common: false,
  },
  sj: {
    code: { short: "sj", long: "sjm", numeric: "744" },
    name: "Svalbard and Jan Mayen Islands",
    currency: "nok",
    common: false,
  },
  sz: {
    code: { short: "sz", long: "swz", numeric: "748" },
    name: "Swaziland",
    currency: "szl",
    common: false,
  },
  se: {
    code: { short: "se", long: "swe", numeric: "752" },
    name: "Sweden",
    currency: "sek",
    common: false,
  },
  ch: {
    code: { short: "ch", long: "che", numeric: "756" },
    name: "Switzerland",
    currency: "chf",
    common: true,
  },
  sy: {
    code: { short: "sy", long: "syr", numeric: "760" },
    name: "Syrian Arab Republic (Syria)",
    currency: "syp",
    common: false,
  },
  tw: {
    code: { short: "tw", long: "twn", numeric: "158" },
    name: "Taiwan, Republic of China",
    currency: "twd",
    common: false,
  },
  tj: {
    code: { short: "tj", long: "tjk", numeric: "762" },
    name: "Tajikistan",
    currency: "tjs",
    common: false,
  },
  tz: {
    code: { short: "tz", long: "tza", numeric: "834" },
    name: "Tanzania, United Republic of",
    currency: "tzs",
    common: false,
  },
  th: {
    code: { short: "th", long: "tha", numeric: "764" },
    name: "Thailand",
    currency: "thb",
    common: false,
  },
  tl: {
    code: { short: "tl", long: "tls", numeric: "626" },
    name: "Timor-Leste",
    currency: "usd",
    common: false,
  },
  tg: {
    code: { short: "tg", long: "tgo", numeric: "768" },
    name: "Togo",
    currency: "xof",
    common: false,
  },
  tk: {
    code: { short: "tk", long: "tkl", numeric: "772" },
    name: "Tokelau",
    currency: "nzd",
    common: false,
  },
  to: {
    code: { short: "to", long: "ton", numeric: "776" },
    name: "Tonga",
    currency: "top",
    common: false,
  },
  tt: {
    code: { short: "tt", long: "tto", numeric: "780" },
    name: "Trinidad and Tobago",
    currency: "ttd",
    common: false,
  },
  tn: {
    code: { short: "tn", long: "tun", numeric: "788" },
    name: "Tunisia",
    currency: "tnd",
    common: false,
  },
  tr: {
    code: { short: "tr", long: "tur", numeric: "792" },
    name: "Turkey",
    currency: "try",
    common: false,
  },
  tm: {
    code: { short: "tm", long: "tkm", numeric: "795" },
    name: "Turkmenistan",
    currency: "tmt",
    common: false,
  },
  tc: {
    code: { short: "tc", long: "tca", numeric: "796" },
    name: "Turks and Caicos Islands",
    currency: "usd",
    common: false,
  },
  tv: {
    code: { short: "tv", long: "tuv", numeric: "798" },
    name: "Tuvalu",
    currency: "aud",
    common: false,
  },
  ug: {
    code: { short: "ug", long: "uga", numeric: "800" },
    name: "Uganda",
    currency: "ugx",
    common: false,
  },
  ua: {
    code: { short: "ua", long: "ukr", numeric: "804" },
    name: "Ukraine",
    currency: "uah",
    common: false,
  },
  ae: {
    code: { short: "ae", long: "are", numeric: "784" },
    name: "United Arab Emirates",
    currency: "aed",
    common: false,
  },
  gb: {
    code: { short: "gb", long: "gbr", numeric: "826" },
    name: "United Kingdom",
    currency: "gbp",
    common: true,
  },
  us: {
    code: { short: "us", long: "usa", numeric: "840" },
    name: "United States",
    currency: "usd",
    common: true,
  },
  um: {
    code: { short: "um", long: "umi", numeric: "581" },
    name: "US Minor Outlying Islands",
    currency: "usd",
    common: false,
  },
  uy: {
    code: { short: "uy", long: "ury", numeric: "858" },
    name: "Uruguay",
    currency: "uyu",
    common: false,
  },
  uz: {
    code: { short: "uz", long: "uzb", numeric: "860" },
    name: "Uzbekistan",
    currency: "uzs",
    common: false,
  },
  vu: {
    code: { short: "vu", long: "vut", numeric: "548" },
    name: "Vanuatu",
    currency: "vuv",
    common: false,
  },
  ve: {
    code: { short: "ve", long: "ven", numeric: "862" },
    name: "Venezuela (Bolivarian Republic)",
    currency: "vef",
    common: false,
  },
  vn: {
    code: { short: "vn", long: "vnm", numeric: "704" },
    name: "Viet Nam",
    currency: "vnd",
    common: false,
  },
  vi: {
    code: { short: "vi", long: "vir", numeric: "850" },
    name: "Virgin Islands, US",
    currency: "usd",
    common: false,
  },
  wf: {
    code: { short: "wf", long: "wlf", numeric: "876" },
    name: "Wallis and Futuna Islands",
    currency: "xpf",
    common: false,
  },
  eh: {
    code: { short: "eh", long: "esh", numeric: "732" },
    name: "Western Sahara",
    currency: "mad",
    common: false,
  },
  ye: {
    code: { short: "ye", long: "yem", numeric: "887" },
    name: "Yemen",
    currency: "yer",
    common: false,
  },
  zm: {
    code: { short: "zm", long: "zmb", numeric: "894" },
    name: "Zambia",
    currency: "zmw",
    common: false,
  },
  zw: {
    code: { short: "zw", long: "zwe", numeric: "716" },
    name: "Zimbabwe",
    currency: "zwl",
    common: false,
  },
  ww: {
    code: { short: "ww", long: "wwx", numeric: "999" },
    name: "Worldwide",
    currency: "usd",
    common: false,
  },
  wv: {
    code: { short: "wv", long: "wmv", numeric: "000" },
    name: "Metaverse",
    currency: "eth",
    common: false,
  },
};

export const countriesList = (common) => {
  const keys = Object.keys(COUNTRY_MODEL);
  const list = [];
  keys.forEach((key, index) => {
    if (common) {
      if (COUNTRY_MODEL[key].common) {
        list.push(COUNTRY_MODEL[key]);
      }
    } else {
      list.push(COUNTRY_MODEL[key]);
    }
  });
  list.sort((a, b) => b.common - a.common);
  return list;
};

export const countriesGetItem = (country) => {
  return COUNTRY_MODEL[country]
    ? COUNTRY_MODEL[country]
    : {
        code: { short: "ww", long: "wwz", numeric: "999" },
        name: "Worldwide",
        currency: "usd",
        common: false,
      };
};

export default COUNTRY_MODEL;

// todo: add dial code

// const countries = [
//   { code: "AD", label: "Andorra", phone: "376" },
//   {
//     code: "AE",
//     label: "United Arab Emirates",
//     phone: "971",
//   },
//   { code: "AF", label: "Afghanistan", phone: "93" },
//   {
//     code: "AG",
//     label: "Antigua and Barbuda",
//     phone: "1-268",
//   },
//   { code: "AI", label: "Anguilla", phone: "1-264" },
//   { code: "AL", label: "Albania", phone: "355" },
//   { code: "AM", label: "Armenia", phone: "374" },
//   { code: "AO", label: "Angola", phone: "244" },
//   { code: "AQ", label: "Antarctica", phone: "672" },
//   { code: "AR", label: "Argentina", phone: "54" },
//   { code: "AS", label: "American Samoa", phone: "1-684" },
//   { code: "AT", label: "Austria", phone: "43" },
//   {
//     code: "AU",
//     label: "Australia",
//     phone: "61",
//     suggested: true,
//   },
//   { code: "AW", label: "Aruba", phone: "297" },
//   { code: "AX", label: "Alland Islands", phone: "358" },
//   { code: "AZ", label: "Azerbaijan", phone: "994" },
//   {
//     code: "BA",
//     label: "Bosnia and Herzegovina",
//     phone: "387",
//   },
//   { code: "BB", label: "Barbados", phone: "1-246" },
//   { code: "BD", label: "Bangladesh", phone: "880" },
//   { code: "BE", label: "Belgium", phone: "32" },
//   { code: "BF", label: "Burkina Faso", phone: "226" },
//   { code: "BG", label: "Bulgaria", phone: "359" },
//   { code: "BH", label: "Bahrain", phone: "973" },
//   { code: "BI", label: "Burundi", phone: "257" },
//   { code: "BJ", label: "Benin", phone: "229" },
//   { code: "BL", label: "Saint Barthelemy", phone: "590" },
//   { code: "BM", label: "Bermuda", phone: "1-441" },
//   { code: "BN", label: "Brunei Darussalam", phone: "673" },
//   { code: "BO", label: "Bolivia", phone: "591" },
//   { code: "BR", label: "Brazil", phone: "55" },
//   { code: "BS", label: "Bahamas", phone: "1-242" },
//   { code: "BT", label: "Bhutan", phone: "975" },
//   { code: "BV", label: "Bouvet Island", phone: "47" },
//   { code: "BW", label: "Botswana", phone: "267" },
//   { code: "BY", label: "Belarus", phone: "375" },
//   { code: "BZ", label: "Belize", phone: "501" },
//   {
//     code: "CA",
//     label: "Canada",
//     phone: "1",
//     suggested: true,
//   },
//   {
//     code: "CC",
//     label: "Cocos (Keeling) Islands",
//     phone: "61",
//   },
//   {
//     code: "CD",
//     label: "Congo, Democratic Republic of the",
//     phone: "243",
//   },
//   {
//     code: "CF",
//     label: "Central African Republic",
//     phone: "236",
//   },
//   {
//     code: "CG",
//     label: "Congo, Republic of the",
//     phone: "242",
//   },
//   { code: "CH", label: "Switzerland", phone: "41" },
//   { code: "CI", label: "Cote d'Ivoire", phone: "225" },
//   { code: "CK", label: "Cook Islands", phone: "682" },
//   { code: "CL", label: "Chile", phone: "56" },
//   { code: "CM", label: "Cameroon", phone: "237" },
//   { code: "CN", label: "China", phone: "86" },
//   { code: "CO", label: "Colombia", phone: "57" },
//   { code: "CR", label: "Costa Rica", phone: "506" },
//   { code: "CU", label: "Cuba", phone: "53" },
//   { code: "CV", label: "Cape Verde", phone: "238" },
//   { code: "CW", label: "Curacao", phone: "599" },
//   { code: "CX", label: "Christmas Island", phone: "61" },
//   { code: "CY", label: "Cyprus", phone: "357" },
//   { code: "CZ", label: "Czech Republic", phone: "420" },
//   {
//     code: "DE",
//     label: "Germany",
//     phone: "49",
//     suggested: true,
//   },
//   { code: "DJ", label: "Djibouti", phone: "253" },
//   { code: "DK", label: "Denmark", phone: "45" },
//   { code: "DM", label: "Dominica", phone: "1-767" },
//   {
//     code: "DO",
//     label: "Dominican Republic",
//     phone: "1-809",
//   },
//   { code: "DZ", label: "Algeria", phone: "213" },
//   { code: "EC", label: "Ecuador", phone: "593" },
//   { code: "EE", label: "Estonia", phone: "372" },
//   { code: "EG", label: "Egypt", phone: "20" },
//   { code: "EH", label: "Western Sahara", phone: "212" },
//   { code: "ER", label: "Eritrea", phone: "291" },
//   { code: "ES", label: "Spain", phone: "34" },
//   { code: "ET", label: "Ethiopia", phone: "251" },
//   { code: "FI", label: "Finland", phone: "358" },
//   { code: "FJ", label: "Fiji", phone: "679" },
//   {
//     code: "FK",
//     label: "Falkland Islands (Malvinas)",
//     phone: "500",
//   },
//   {
//     code: "FM",
//     label: "Micronesia, Federated States of",
//     phone: "691",
//   },
//   { code: "FO", label: "Faroe Islands", phone: "298" },
//   {
//     code: "FR",
//     label: "France",
//     phone: "33",
//     suggested: true,
//   },
//   { code: "GA", label: "Gabon", phone: "241" },
//   { code: "GB", label: "United Kingdom", phone: "44" },
//   { code: "GD", label: "Grenada", phone: "1-473" },
//   { code: "GE", label: "Georgia", phone: "995" },
//   { code: "GF", label: "French Guiana", phone: "594" },
//   { code: "GG", label: "Guernsey", phone: "44" },
//   { code: "GH", label: "Ghana", phone: "233" },
//   { code: "GI", label: "Gibraltar", phone: "350" },
//   { code: "GL", label: "Greenland", phone: "299" },
//   { code: "GM", label: "Gambia", phone: "220" },
//   { code: "GN", label: "Guinea", phone: "224" },
//   { code: "GP", label: "Guadeloupe", phone: "590" },
//   { code: "GQ", label: "Equatorial Guinea", phone: "240" },
//   { code: "GR", label: "Greece", phone: "30" },
//   {
//     code: "GS",
//     label: "South Georgia and the South Sandwich Islands",
//     phone: "500",
//   },
//   { code: "GT", label: "Guatemala", phone: "502" },
//   { code: "GU", label: "Guam", phone: "1-671" },
//   { code: "GW", label: "Guinea-Bissau", phone: "245" },
//   { code: "GY", label: "Guyana", phone: "592" },
//   { code: "HK", label: "Hong Kong", phone: "852" },
//   {
//     code: "HM",
//     label: "Heard Island and McDonald Islands",
//     phone: "672",
//   },
//   { code: "HN", label: "Honduras", phone: "504" },
//   { code: "HR", label: "Croatia", phone: "385" },
//   { code: "HT", label: "Haiti", phone: "509" },
//   { code: "HU", label: "Hungary", phone: "36" },
//   { code: "ID", label: "Indonesia", phone: "62" },
//   { code: "IE", label: "Ireland", phone: "353" },
//   { code: "IL", label: "Israel", phone: "972" },
//   { code: "IM", label: "Isle of Man", phone: "44" },
//   { code: "IN", label: "India", phone: "91" },
//   {
//     code: "IO",
//     label: "British Indian Ocean Territory",
//     phone: "246",
//   },
//   { code: "IQ", label: "Iraq", phone: "964" },
//   {
//     code: "IR",
//     label: "Iran, Islamic Republic of",
//     phone: "98",
//   },
//   { code: "IS", label: "Iceland", phone: "354" },
//   { code: "IT", label: "Italy", phone: "39" },
//   { code: "JE", label: "Jersey", phone: "44" },
//   { code: "JM", label: "Jamaica", phone: "1-876" },
//   { code: "JO", label: "Jordan", phone: "962" },
//   {
//     code: "JP",
//     label: "Japan",
//     phone: "81",
//     suggested: true,
//   },
//   { code: "KE", label: "Kenya", phone: "254" },
//   { code: "KG", label: "Kyrgyzstan", phone: "996" },
//   { code: "KH", label: "Cambodia", phone: "855" },
//   { code: "KI", label: "Kiribati", phone: "686" },
//   { code: "KM", label: "Comoros", phone: "269" },
//   {
//     code: "KN",
//     label: "Saint Kitts and Nevis",
//     phone: "1-869",
//   },
//   {
//     code: "KP",
//     label: "Korea, Democratic People's Republic of",
//     phone: "850",
//   },
//   { code: "KR", label: "Korea, Republic of", phone: "82" },
//   { code: "KW", label: "Kuwait", phone: "965" },
//   { code: "KY", label: "Cayman Islands", phone: "1-345" },
//   { code: "KZ", label: "Kazakhstan", phone: "7" },
//   {
//     code: "LA",
//     label: "Lao People's Democratic Republic",
//     phone: "856",
//   },
//   { code: "LB", label: "Lebanon", phone: "961" },
//   { code: "LC", label: "Saint Lucia", phone: "1-758" },
//   { code: "LI", label: "Liechtenstein", phone: "423" },
//   { code: "LK", label: "Sri Lanka", phone: "94" },
//   { code: "LR", label: "Liberia", phone: "231" },
//   { code: "LS", label: "Lesotho", phone: "266" },
//   { code: "LT", label: "Lithuania", phone: "370" },
//   { code: "LU", label: "Luxembourg", phone: "352" },
//   { code: "LV", label: "Latvia", phone: "371" },
//   { code: "LY", label: "Libya", phone: "218" },
//   { code: "MA", label: "Morocco", phone: "212" },
//   { code: "MC", label: "Monaco", phone: "377" },
//   {
//     code: "MD",
//     label: "Moldova, Republic of",
//     phone: "373",
//   },
//   { code: "ME", label: "Montenegro", phone: "382" },
//   {
//     code: "MF",
//     label: "Saint Martin (French part)",
//     phone: "590",
//   },
//   { code: "MG", label: "Madagascar", phone: "261" },
//   { code: "MH", label: "Marshall Islands", phone: "692" },
//   {
//     code: "MK",
//     label: "Macedonia, the Former Yugoslav Republic of",
//     phone: "389",
//   },
//   { code: "ML", label: "Mali", phone: "223" },
//   { code: "MM", label: "Myanmar", phone: "95" },
//   { code: "MN", label: "Mongolia", phone: "976" },
//   { code: "MO", label: "Macao", phone: "853" },
//   {
//     code: "MP",
//     label: "Northern Mariana Islands",
//     phone: "1-670",
//   },
//   { code: "MQ", label: "Martinique", phone: "596" },
//   { code: "MR", label: "Mauritania", phone: "222" },
//   { code: "MS", label: "Montserrat", phone: "1-664" },
//   { code: "MT", label: "Malta", phone: "356" },
//   { code: "MU", label: "Mauritius", phone: "230" },
//   { code: "MV", label: "Maldives", phone: "960" },
//   { code: "MW", label: "Malawi", phone: "265" },
//   { code: "MX", label: "Mexico", phone: "52" },
//   { code: "MY", label: "Malaysia", phone: "60" },
//   { code: "MZ", label: "Mozambique", phone: "258" },
//   { code: "NA", label: "Namibia", phone: "264" },
//   { code: "NC", label: "New Caledonia", phone: "687" },
//   { code: "NE", label: "Niger", phone: "227" },
//   { code: "NF", label: "Norfolk Island", phone: "672" },
//   { code: "NG", label: "Nigeria", phone: "234" },
//   { code: "NI", label: "Nicaragua", phone: "505" },
//   { code: "NL", label: "Netherlands", phone: "31" },
//   { code: "NO", label: "Norway", phone: "47" },
//   { code: "NP", label: "Nepal", phone: "977" },
//   { code: "NR", label: "Nauru", phone: "674" },
//   { code: "NU", label: "Niue", phone: "683" },
//   { code: "NZ", label: "New Zealand", phone: "64" },
//   { code: "OM", label: "Oman", phone: "968" },
//   { code: "PA", label: "Panama", phone: "507" },
//   { code: "PE", label: "Peru", phone: "51" },
//   { code: "PF", label: "French Polynesia", phone: "689" },
//   { code: "PG", label: "Papua New Guinea", phone: "675" },
//   { code: "PH", label: "Philippines", phone: "63" },
//   { code: "PK", label: "Pakistan", phone: "92" },
//   { code: "PL", label: "Poland", phone: "48" },
//   {
//     code: "PM",
//     label: "Saint Pierre and Miquelon",
//     phone: "508",
//   },
//   { code: "PN", label: "Pitcairn", phone: "870" },
//   { code: "PR", label: "Puerto Rico", phone: "1" },
//   {
//     code: "PS",
//     label: "Palestine, State of",
//     phone: "970",
//   },
//   { code: "PT", label: "Portugal", phone: "351" },
//   { code: "PW", label: "Palau", phone: "680" },
//   { code: "PY", label: "Paraguay", phone: "595" },
//   { code: "QA", label: "Qatar", phone: "974" },
//   { code: "RE", label: "Reunion", phone: "262" },
//   { code: "RO", label: "Romania", phone: "40" },
//   { code: "RS", label: "Serbia", phone: "381" },
//   { code: "RU", label: "Russian Federation", phone: "7" },
//   { code: "RW", label: "Rwanda", phone: "250" },
//   { code: "SA", label: "Saudi Arabia", phone: "966" },
//   { code: "SB", label: "Solomon Islands", phone: "677" },
//   { code: "SC", label: "Seychelles", phone: "248" },
//   { code: "SD", label: "Sudan", phone: "249" },
//   { code: "SE", label: "Sweden", phone: "46" },
//   { code: "SG", label: "Singapore", phone: "65" },
//   { code: "SH", label: "Saint Helena", phone: "290" },
//   { code: "SI", label: "Slovenia", phone: "386" },
//   {
//     code: "SJ",
//     label: "Svalbard and Jan Mayen",
//     phone: "47",
//   },
//   { code: "SK", label: "Slovakia", phone: "421" },
//   { code: "SL", label: "Sierra Leone", phone: "232" },
//   { code: "SM", label: "San Marino", phone: "378" },
//   { code: "SN", label: "Senegal", phone: "221" },
//   { code: "SO", label: "Somalia", phone: "252" },
//   { code: "SR", label: "Suriname", phone: "597" },
//   { code: "SS", label: "South Sudan", phone: "211" },
//   {
//     code: "ST",
//     label: "Sao Tome and Principe",
//     phone: "239",
//   },
//   { code: "SV", label: "El Salvador", phone: "503" },
//   {
//     code: "SX",
//     label: "Sint Maarten (Dutch part)",
//     phone: "1-721",
//   },
//   {
//     code: "SY",
//     label: "Syrian Arab Republic",
//     phone: "963",
//   },
//   { code: "SZ", label: "Swaziland", phone: "268" },
//   {
//     code: "TC",
//     label: "Turks and Caicos Islands",
//     phone: "1-649",
//   },
//   { code: "TD", label: "Chad", phone: "235" },
//   {
//     code: "TF",
//     label: "French Southern Territories",
//     phone: "262",
//   },
//   { code: "TG", label: "Togo", phone: "228" },
//   { code: "TH", label: "Thailand", phone: "66" },
//   { code: "TJ", label: "Tajikistan", phone: "992" },
//   { code: "TK", label: "Tokelau", phone: "690" },
//   { code: "TL", label: "Timor-Leste", phone: "670" },
//   { code: "TM", label: "Turkmenistan", phone: "993" },
//   { code: "TN", label: "Tunisia", phone: "216" },
//   { code: "TO", label: "Tonga", phone: "676" },
//   { code: "TR", label: "Turkey", phone: "90" },
//   {
//     code: "TT",
//     label: "Trinidad and Tobago",
//     phone: "1-868",
//   },
//   { code: "TV", label: "Tuvalu", phone: "688" },
//   {
//     code: "TW",
//     label: "Taiwan, Province of China",
//     phone: "886",
//   },
//   {
//     code: "TZ",
//     label: "United Republic of Tanzania",
//     phone: "255",
//   },
//   { code: "UA", label: "Ukraine", phone: "380" },
//   { code: "UG", label: "Uganda", phone: "256" },
//   {
//     code: "US",
//     label: "United States",
//     phone: "1",
//     suggested: true,
//   },
//   { code: "UY", label: "Uruguay", phone: "598" },
//   { code: "UZ", label: "Uzbekistan", phone: "998" },
//   {
//     code: "VA",
//     label: "Holy See (Vatican City State)",
//     phone: "379",
//   },
//   {
//     code: "VC",
//     label: "Saint Vincent and the Grenadines",
//     phone: "1-784",
//   },
//   { code: "VE", label: "Venezuela", phone: "58" },
//   {
//     code: "VG",
//     label: "British Virgin Islands",
//     phone: "1-284",
//   },
//   {
//     code: "VI",
//     label: "US Virgin Islands",
//     phone: "1-340",
//   },
//   { code: "VN", label: "Vietnam", phone: "84" },
//   { code: "VU", label: "Vanuatu", phone: "678" },
//   { code: "WF", label: "Wallis and Futuna", phone: "681" },
//   { code: "WS", label: "Samoa", phone: "685" },
//   { code: "XK", label: "Kosovo", phone: "383" },
//   { code: "YE", label: "Yemen", phone: "967" },
//   { code: "YT", label: "Mayotte", phone: "262" },
//   { code: "ZA", label: "South Africa", phone: "27" },
//   { code: "ZM", label: "Zambia", phone: "260" },
//   { code: "ZW", label: "Zimbabwe", phone: "263" },
// ];
