// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";

// entzy context and services
import { EventContext } from "pages/events/EventContext";
import ActionMessagingIdeas from "./ActionMessagingIdeas";
import ActionMessagingPost from "./ActionMessagingPost";

function ActionMessagingRunner(props) {
  // const user = props.user;
  const eventContext = useContext(EventContext);
  const isReplying =
    props.actionParams && props.actionParams.inReplyTo ? true : false;

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        {eventContext.state.messaging.activeRoom.startsWith("ai") && (
          <Box className="box-default">
            <ActionMessagingIdeas {...props} source="runner" />
          </Box>
        )}
        {eventContext.state.messaging.activeRoom === "desk" && !isReplying ? (
          <Box className="box-default">
            <Typography variant="subtitle2" color="primary">
              Customer Front Desk
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: configEntzy.APP_SPACING_MD2X,
              }}
            >
              This room is reply-only for runners and
            </Typography>
            <Typography variant="subtitle1">
              handles your incoming rider private inquiries.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              When messages arrive tap underneath to reply
            </Typography>
            <Typography variant="subtitle1">
              and only that rider will receive your response.
            </Typography>
          </Box>
        ) : (
          <Box className="box-default">
            <ActionMessagingPost {...props} source="runner" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ActionMessagingRunner;
