// react core
import { useState } from "react";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import ImageEntzyLogo from "assets/logos/entzy-main.png";
import { ActionLoader } from "components/utils/common/CommonLoaders";

function ConnectDirect(props) {
  // component states
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [challenge, setChallenge] = useState(false);
  const [message, setMessage] = useState("");
  const [mfaUser, setMfaUser] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  // component functions
  const handleTextFieldEmail = async (event) => {
    setUserEmail(event.target.value.replace(/\s/g, ""));
  };
  const handleTextFieldPassword = async (event) => {
    setUserPassword(event.target.value);
  };
  const handleTextFieldMfaCode = async (event) => {
    setMfaCode(event.target.value.replace(/[^0-9]/gi, ""));
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlert(false);
    if (userEmail.length && userPassword.length) {
      try {
        if (mfaCode) {
          await props.handleConnect(
            "Entzy",
            "mfasignin",
            userEmail,
            userPassword,
            {
              user: mfaUser,
              code: mfaCode,
            }
          );
        } else {
          const user = await props.handleConnect(
            "Entzy",
            "signin",
            userEmail,
            userPassword
          );
          if (
            user.challengeName === "SMS_MFA" ||
            user.challengeName === "SOFTWARE_TOKEN_MFA"
          ) {
            setChallenge(true);
            setMfaUser(user);
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
        setAlert(true);
        if (e.code === "UserNotConfirmedException") {
          setMessage(
            "The original connection for this email is incomplete. Open signup to finish the email confirmation."
          );
        } else {
          setMessage(e.message);
        }
      }
    } else {
      setLoading(false);
      setAlert(true);
      setMessage("Email and password required");
    }
  };

  const handleFormKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      handleFormSubmit(e);
    }
  };

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box className="box-default">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="box-default text-left">
              <IconButton
                aria-label="back"
                onClick={() => props.handleSection(null)}
              >
                <Avatar
                  alt="Entzy"
                  src={ImageEntzyLogo}
                  sx={{ boxShadow: "0 0 0 1px #999" }}
                />
              </IconButton>
            </Box>

            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    id="user-email"
                    type="email"
                    label="Email"
                    autoComplete="username"
                    value={userEmail}
                    required={true}
                    autoFocus={true}
                    onKeyDown={handleFormKeyDown}
                    onChange={handleTextFieldEmail}
                    fullWidth
                  />
                </FormControl>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    id="user-password"
                    type="password"
                    label="Password"
                    autoComplete="current-password"
                    value={userPassword}
                    required={true}
                    onKeyDown={handleFormKeyDown}
                    onChange={handleTextFieldPassword}
                    fullWidth
                  />
                </FormControl>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
                hidden={!challenge}
              >
                <FormControl fullWidth>
                  <TextField
                    id="mfa-code"
                    type="text"
                    label="Enter your MFA Code"
                    value={mfaCode}
                    required={true}
                    onKeyDown={handleFormKeyDown}
                    onChange={handleTextFieldMfaCode}
                    fullWidth
                  />
                </FormControl>
              </Box>
              {loading ? (
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                    p: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <ActionLoader />
                </Box>
              ) : (
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={handleFormSubmit}
                    fullWidth
                  >
                    {challenge ? "Connect with MFA" : "Connect"}
                  </Button>
                </Box>
              )}
              {alert && message && (
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Alert severity="error">{message}</Alert>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL,
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_MD,
                pl: configEntzy.APP_SPACING_MD,
              }}
            >
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={() => props.handleSection("register")}
                disabled={loading}
                fullWidth
              >
                Sign Up
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL,
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_MD,
                pr: configEntzy.APP_SPACING_MD,
              }}
            >
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={() => props.handleSection("forgot")}
                disabled={loading}
                fullWidth
              >
                Forgot
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default ConnectDirect;
