// react core
import React from "react";

// material design
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import LoginAmazon from "assets/logos/amazon-connect.png";
import LoginApple from "assets/logos/apple-connect.png";
import LoginGoogle from "assets/logos/google-connect.png";
import LoginEntzy from "assets/logos/entzy-connect.png";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";

function ConnectProviders(props) {
  const images = [
    {
      url: LoginGoogle,
      title: "Google",
      width: "100%",
      click: () => props.handleIdentityProvider("Google"),
    },
    {
      url: LoginApple,
      title: "Apple",
      width: "100%",
      click: () => props.handleIdentityProvider("SignInWithApple"),
    },
    {
      url: LoginAmazon,
      title: "Amazon",
      width: "100%",
      click: () => props.handleIdentityProvider("LoginWithAmazon"),
    },
    {
      url: LoginEntzy,
      title: "Entzy",
      width: "100%",
      click: () => props.handleIdentityProvider("Entzy"),
    },
  ];

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 150,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        border: "1px solid currentColor",
        backgroundColor: "#000",
        borderRadius: "4px",
        opacity: 0.8,
      },
    },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  });

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create("opacity"),
  }));

  // const ImageMarked = styled("span")(({ theme }) => ({
  //   height: 3,
  //   width: 18,
  //   backgroundColor: theme.palette.common.white,
  //   position: "absolute",
  //   bottom: -2,
  //   left: "calc(50% - 9px)",
  //   transition: theme.transitions.create("opacity"),
  // }));

  return (
    <Box className="box-default">
      <Box
        className="box-inline"
        sx={{
          width: configEntzy.AVATAR_CONTAINER_MD,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          {images.map((image) => (
            <ImageButton
              focusRipple
              key={image.title}
              style={{
                width: image.width,
              }}
              onClick={image.click}
            >
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="inherit"
                  sx={{
                    position: "relative",
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  {image.title}
                  {/* <ImageMarked className="MuiImageMarked-root" /> */}
                </Typography>
              </Image>
            </ImageButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default ConnectProviders;
