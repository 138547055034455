// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";

// entzy context and services
import { EventContext } from "pages/events/EventContext";
import ActionMessagingIdeas from "./ActionMessagingIdeas";
import ActionMessagingPost from "./ActionMessagingPost";

function ActionMessagingRider(props) {
  // const user = props.user;
  const eventContext = useContext(EventContext);

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        {eventContext.state.messaging.activeRoom.startsWith("ai") && (
          <Box className="box-default">
            <ActionMessagingIdeas {...props} source="rider" />
          </Box>
        )}
        <Box className="box-default">
          <ActionMessagingPost {...props} source="rider" />
        </Box>
      </Box>
    </Box>
  );
}

export default ActionMessagingRider;
