// entzy config
import configEntzy from "components/config/ConfigEntzy";

// material theme
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars as iconMenu } from "@fortawesome/pro-solid-svg-icons";
import { faSignIn as iconConnect } from "@fortawesome/pro-solid-svg-icons";

// fonts and icons
import IconButton from "@mui/material/IconButton";
// import IconLogin from "@mui/icons-material/Login";
// import IconMenu from "@mui/icons-material/Menu";

// assets
import ImageEntzyLogo from "assets/logos/entzy-main.png";

function MainHeader(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box className="box-default bg-black-t50">
        <AppBar
          position="static"
          color="transparent"
          sx={{
            mb: configEntzy.APP_SPACING_XS,
          }}
        >
          <Container maxWidth="xl" disableGutters>
            <Box
              className="box-default quarter-width"
              sx={{ textAlign: "left", p: configEntzy.APP_SPACING_SM }}
            >
              <IconButton
                size="small"
                onClick={() => props.menuSelectById("menu")}
              >
                <Avatar
                  variant="rounded"
                  alt="Connect"
                  sx={{
                    height: configEntzy.AVATAR_SIZE_SM,
                    width: configEntzy.AVATAR_SIZE_SM,
                    margin: "0 auto",
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography variant="h3" component="div">
                    <FontAwesomeIcon icon={iconMenu} color="white" fixedWidth />
                  </Typography>
                </Avatar>
              </IconButton>
            </Box>
            <Box
              className="box-default half-width"
              sx={{
                p: configEntzy.APP_SPACING_SM,
              }}
              onClick={() => props.menuSelectById("home")}
            >
              <IconButton size="small">
                <Avatar
                  variant="circle"
                  alt="Entzy"
                  src={ImageEntzyLogo}
                  className="shadow-default"
                  sx={{
                    height: configEntzy.AVATAR_SIZE_SM,
                    width: configEntzy.AVATAR_SIZE_SM,
                    margin: "0 auto",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              className="box-default quarter-width"
              sx={{ textAlign: "right", p: configEntzy.APP_SPACING_SM }}
            >
              <IconButton
                size="small"
                onClick={() => props.menuSelectById("connect")}
              >
                <Avatar
                  variant="rounded"
                  alt="Connect"
                  sx={{
                    height: configEntzy.AVATAR_SIZE_SM,
                    width: configEntzy.AVATAR_SIZE_SM,
                    margin: "0 auto",
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography variant="h3" component="div">
                    <FontAwesomeIcon
                      icon={iconConnect}
                      color="white"
                      fixedWidth
                    />
                  </Typography>
                </Avatar>
              </IconButton>
            </Box>
          </Container>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default MainHeader;

//     {identifying ? (
//       <Box
//         className="box-default"
//         sx={{ height: configEntzy.AVATAR_SIZE_SM }}
//       ></Box>
//     ) : user.connected ? (
//       <Box
//         className="box-default"
//         sx={{
//           pt: configEntzy.APP_SPACING_SM,
//           pb: configEntzy.APP_SPACING_SM,
//         }}
//       >
//         <Container maxWidth="xl">
//           <MainTicker {...props} user={user} />
//         </Container>
//       </Box>
//     ) : (
//       <Box className="box-default">
//         <Button
//           variant="contained"
//           color="secondary"
//           size="small"
//           onClick={props.drawerConnectToggle}
//           disableElevation
//         >
//           <IconLogin />
//         </Button>
//       </Box>
//     )}
//   </Box>
