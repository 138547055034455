// react core
import { useContext, useState, useCallback, useEffect } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator as iconNumeric } from "@fortawesome/pro-duotone-svg-icons";
import { faCommentDollar as iconDescription } from "@fortawesome/pro-duotone-svg-icons";
// import { faBasketShopping as iconProduct } from "@fortawesome/pro-duotone-svg-icons";
import { faShoppingBag as iconStage } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus as iconIncrement } from "@fortawesome/pro-duotone-svg-icons";
import { faMinus as iconDecrement } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronSquareRight as iconCheckout } from "@fortawesome/pro-solid-svg-icons";
// import { faTimes as iconClose } from "@fortawesome/pro-solid-svg-icons";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import {
  ActionButton,
  MoreButton,
} from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { currenciesGetItem } from "models/Currency";
import InputForm from "components/input/InputForm";
import { TextTag } from "components/utils/common/CommonTags";

function PayProducts(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const recipientType = props.recipientType;
  const recipientName = props.recipientName;
  const defaultCurrency = props.defaultCurrency;
  const defaultAmount = props.defaultAmount;
  const defaultDescription = props.defaultDescription;
  const isEventuator = recipientType === "eventuator";
  const productOrder = props.productOrder;
  const startProductOrder = props.startProductOrder;
  // const clearProductOrder = props.clearProductOrder;
  const handleHidePayForm = props.handleHidePayForm;
  const handleCheckout = props.handleCheckout;
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hydrated, setHydrated] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [productCounts, setProductCounts] = useState({});
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const productsActive = user.connected ? user.status.payment_set : true;
  const productsEditingActive = user.connected && user.status.payout_set;

  // const getProductCart = () => {
  //   const productCart = [];
  //   for (const [productId, productCount] of Object.entries(productCounts)) {
  //     if (productCount > 0) {
  //       productCart.push({
  //         ProductId: productId,
  //         ProductDescription: mainContext.state.productList.data.items.filter(
  //           (obj) => obj.ProductId === productId
  //         )[0].Description,
  //         ProductCount: productCount,
  //       });
  //     }
  //   }
  //   return productCart;
  // };
  const getProductCartTotal = () => {
    let productCartTotal = 0;
    for (const [productId, productCount] of Object.entries(productCounts)) {
      if (productCount > 0) {
        const productAmount = mainContext.state.productList.data.items.filter(
          (obj) => obj.ProductId === productId
        )[0].Amount;
        productCartTotal += productAmount * productCount;
      }
    }
    return productCartTotal / 100;
  };
  const getProductCartCurrency = () => {
    let productCartCurrency = null;
    for (const [productId, productCount] of Object.entries(productCounts)) {
      if (productCount > 0) {
        productCartCurrency = mainContext.state.productList.data.items.filter(
          (obj) => obj.ProductId === productId
        )[0].Currency;
        break;
      }
    }
    return productCartCurrency;
  };
  const getProductCartDescription = () => {
    let productCartDescription = "";
    for (const [productId, productCount] of Object.entries(productCounts)) {
      if (productCount > 0) {
        productCartDescription += `${productCount} x ${
          mainContext.state.productList.data.items.filter(
            (obj) => obj.ProductId === productId
          )[0].Description
        }. `;
      }
    }
    return productCartDescription;
  };
  const getProductCount = (productId) => {
    if (productCounts[productId]) {
      return productCounts[productId];
    } else {
      return 0;
    }
  };

  const handleIncrementProductCount = (productId) => {
    // check product is the same currency as the cart
    const cartCurrency = getProductCartCurrency();
    const productCurrency = mainContext.state.productList.data.items.filter(
      (obj) => obj.ProductId === productId
    )[0].Currency;
    if (cartCurrency && cartCurrency !== productCurrency) {
      mainContext.updateAlert({
        alert: true,
        message: "Products added to each checkout must be the same currency",
      });
      return;
    }
    // add to cart
    const productCountsCopy = { ...productCounts };
    if (productCountsCopy[productId]) {
      productCountsCopy[productId] += 1;
    } else {
      productCountsCopy[productId] = 1;
    }
    setProductCounts(productCountsCopy);
  };
  const handleDecrementProductCount = (productId) => {
    const productCountsCopy = { ...productCounts };
    if (productCountsCopy[productId]) {
      productCountsCopy[productId] -= 1;
    } else {
      productCountsCopy[productId] = 0;
    }
    setProductCounts(productCountsCopy);
  };
  // const handleResetProductCount = (productId) => {
  //   const productCountsCopy = { ...productCounts };
  //   productCountsCopy[productId] = 0;
  //   setProductCounts(productCountsCopy);
  // };
  const handleShowProductForm = () => {
    handleHidePayForm(!showProductForm);
    setShowProductForm(!showProductForm);
  };

  const handleActionCallback = async (data) => {
    const productCurrency = data.filter(
      (obj) => obj.id === "product-currency"
    )[0].value;
    const productAmount = data.filter((obj) => obj.id === "product-amount")[0]
      .value;
    const productDescription = data.filter(
      (obj) => obj.id === "product-description"
    )[0].value;
    if (user.connected) {
      if (
        productCurrency &&
        productAmount &&
        !isNaN(productAmount) &&
        productAmount > 0 &&
        productDescription.length > 0
      ) {
        const productAmountInt = Math.round(productAmount * 100);
        const response = await mainContext.prepareCreateProduct({
          Url: isEventuator ? recipientName : null,
          Name: isEventuator ? null : recipientName,
          List: configEntzy.PRODUCT_LIST_DEFAULT,
          Category: configEntzy.PRODUCT_CATEGORY_DEFAULT,
          Currency: productCurrency.code.long,
          Amount: productAmountInt,
          Description: productDescription,
        });
        if (response.alert) {
          return response;
        }
        mainContext.createProduct(response.data);
        return {};
      } else {
        return {
          alert: true,
          message: "Select a currency with a valid amount and description",
        };
      }
    } else {
      return {
        alert: true,
        message: "Connect to manage product lists",
      };
    }
  };

  const handlePullProductList = async (more) => {
    if (more) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    let response;
    response = await mainContext.preparePullProductList({
      Url: isEventuator ? recipientName : null, // send url if eventuator
      Name: isEventuator ? null : recipientName, // send user name if not eventuator
      List: configEntzy.PRODUCT_LIST_DEFAULT,
      Category: configEntzy.PRODUCT_CATEGORY_DEFAULT,
      nextToken: mainContext.state.productList.data.nextToken,
      more,
    });
    setLoading(false);
    setLoadingMore(false);
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.pullProductList({
        data: response.data,
        more,
      });
    }
  };
  const cbHandlePullProductList = useCallback(handlePullProductList, [
    mainContext,
    isEventuator,
    recipientName,
  ]);

  const handleConfirmDeleteProduct = async (event, confirm, productId) => {
    if (confirm === true) {
      setDialog({ visible: false });
      handleDeleteProduct(productId);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: "Confirm Delete Product",
        description: "Are you sure you want to delete this item?",
        cancel: (e) => handleConfirmDeleteProduct(e, false, productId),
        confirm: (e) => handleConfirmDeleteProduct(e, true, productId),
      });
    }
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    const response = await mainContext.prepareDeleteProduct({
      Url: isEventuator ? recipientName : null,
      Name: isEventuator ? null : recipientName,
      ProductId: productId,
    });
    setLoading(false);
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.deleteProduct({
        data: response.data,
      });
    }
  };

  // pull messages on page load
  // on fresh hydrate or recipient change
  useEffect(() => {
    if (
      !hydrated &&
      !mainContext.state.productList.hydrated &&
      !mainContext.state.alert.show
    ) {
      setHydrated(true);
      cbHandlePullProductList(false);
    }
    if (
      mainContext.state.productList.hydrated &&
      !mainContext.state.productList.listId.startsWith(
        recipientType + ":" + recipientName
      ) &&
      !mainContext.state.alert.show
    ) {
      setHydrated(true);
      cbHandlePullProductList(false);
    }
  }, [
    hydrated,
    mainContext.state.productList.hydrated,
    mainContext.state.productList.listId,
    mainContext.state.alert.show,
    recipientName,
    recipientType,
    cbHandlePullProductList,
  ]);

  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_XL,
      }}
    >
      <Box className="box-default">
        <Typography variant="h4" className="text-default">
          Product Menu
        </Typography>
      </Box>
      {mainContext.state.productList.hydrated &&
        mainContext.state.productList.data.itemsCount > 0 && (
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_MD2X,
            }}
          >
            {showProductForm ? (
              <Box className="box-default">
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  Edit your product list
                </Typography>
              </Box>
            ) : productOrder ? (
              <Box className="box-default">
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  {productsActive
                    ? "Add products & checkout when ready"
                    : "Add payment details to activate menu"}
                </Typography>
              </Box>
            ) : (
              <Box className="box-default">
                <Box className="box-default">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    Products on sale by {recipientType} {recipientName}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      {mainContext.state.productList.hydrated &&
        mainContext.state.productList.data.DataManager && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD2X,
              mb: configEntzy.APP_SPACING_LG,
            }}
            hidden={productOrder}
          >
            <ActionButton
              size="small"
              text={showProductForm ? "Hide X" : "Manage Products"}
              variant="contained"
              color="dusk"
              onClick={handleShowProductForm}
            />
          </Box>
        )}
      {showProductForm && (
        <Box
          className="box-default"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            mb: configEntzy.APP_SPACING_LG,
          }}
        >
          <InputForm
            navigate={props.navigate}
            fields={[
              {
                id: "product-currency",
                type: "selector-currency",
                label: "Product currency",
                value: defaultCurrency
                  ? currenciesGetItem(defaultCurrency)
                  : null,
                required: true,
              },
              {
                id: "product-amount",
                type: "text-standard",
                label: "Product cost",
                placeholder: "0.00",
                value: defaultAmount ? defaultAmount : "",
                numeric: true,
                required: true,
                startAdornment: (
                  <FontAwesomeIcon
                    icon={iconNumeric}
                    transform="grow-10"
                    fixedWidth
                  />
                ),
              },
              {
                id: "product-description",
                type: "text-standard",
                label: "Product description",
                placeholder: "Description",
                value: defaultDescription ? defaultDescription : "",
                required: false,
                maxLength: 50,
                startAdornment: (
                  <FontAwesomeIcon
                    icon={iconDescription}
                    transform="grow-10"
                    fixedWidth
                  />
                ),
              },
            ]}
            submitText="Add Product to List"
            disabledSubmit={!productsEditingActive}
            disabledTitle="Add Income Account"
            disabledText="Tap here to activate your income account and you can start selling products"
            disabledClick={
              !user.connected
                ? props.drawerConnectToggle
                : () => {
                    props.menuSelectById("settings-income-bank");
                    mainContext.setPayDirectViewer({
                      open: false,
                    });
                  }
            }
            callback={handleActionCallback}
          />
        </Box>
      )}
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        {loading || !mainContext.state.productList.hydrated ? (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
            }}
          >
            <ActionLoader />
          </Box>
        ) : mainContext.state.productList.data.itemsCount > 0 ? (
          <Box className="box-default">
            {/* <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD2X,
              }}
            >
              <Typography variant="h6" className="upper-case">
                <span>Total:</span>
                <span>&nbsp;&nbsp;</span>
                {getProductCartCurrency()}
                <span>&nbsp;</span>
                {getProductCartTotal().toFixed(2)}
              </Typography>
            </Box> */}
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_LG,
              }}
              hidden={!productsActive || !productOrder}
            >
              <Box className="box-default">
                <ActionButton
                  variant="contained"
                  color="primary"
                  text={
                    <span>
                      <span>&nbsp;</span>
                      <span>Checkout</span>
                      <span>&nbsp;</span>
                      <span>
                        (
                        {getProductCartCurrency() && (
                          <span>{getProductCartCurrency()}&nbsp;</span>
                        )}
                        {getProductCartTotal().toFixed(2)})
                      </span>
                      <span>&nbsp;</span>
                    </span>
                  }
                  disabled={!productsActive || getProductCartTotal() === 0}
                  onClick={
                    productsActive
                      ? () => {
                          handleCheckout({
                            currencyCode: getProductCartCurrency(),
                            amount: getProductCartTotal(),
                            description: getProductCartDescription(),
                          });
                        }
                      : undefined
                  }
                  endIcon={<FontAwesomeIcon icon={iconCheckout} fixedWidth />}
                />
              </Box>
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG,
                }}
              >
                <TextTag
                  size="md"
                  text={
                    <span>
                      <span>&nbsp;</span>
                      <span>Reset Cart</span>
                      <span>&nbsp;</span>
                    </span>
                  }
                  rounded={true}
                  onClick={() => {
                    setProductCounts({});
                    // clearProductOrder();
                  }}
                />
              </Box>
              {/* <TextTag
                  text={
                    <span>
                      <span>&nbsp;</span>
                      <span>Checkout</span>
                      <span>&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={iconCheckout} />
                      <span>&nbsp;</span>
                    </span>
                  }
                  size="lg"
                  bgColor={productsActive ? "primary.dark" : "lightgray"}
                  color={productsActive ? "black" : "gray"}
                  rounded={true}
                  shadow={productsActive}
                  onClick={
                    productsActive
                      ? () => {
                          handleStagePayment(
                            getProductCartCurrency(),
                            getProductCartTotal(),
                            getProductCartDescription()
                          );
                        }
                      : undefined
                  }
                /> */}
            </Box>
            {/* {getProductCart().map((item, index) => {
              return (
                <Box
                  key={item.ProductId}
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Typography variant="body1" className="text-default">
                    {item.ProductDescription} {item.ProductCount}
                  </Typography>
                </Box>
              );
            })} */}
            {mainContext.state.productList.data.items.map((item, index) => {
              const productCurrency = item.Currency;
              const productCount = getProductCount(item.ProductId);
              const productPrice = item.Amount / 100;
              const productAmount = (item.Amount * productCount) / 100;
              const productDescription = item.Description;
              return (
                <Box
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_MD,
                  }}
                  key={item.ProductId}
                >
                  <Box
                    className="box-default bg-black-t50 shadow-default"
                    sx={{
                      p: configEntzy.APP_SPACING_SM2X,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                    onClick={() => {
                      if (productsActive && !productOrder) {
                        startProductOrder();
                      }
                    }}
                  >
                    <Box
                      className="box-default text-left"
                      sx={{
                        p: configEntzy.APP_SPACING_XS3X,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="white"
                        className="upper-case"
                      >
                        {productDescription}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="white"
                        sx={{
                          mt: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        <span>
                          <FontAwesomeIcon icon={iconStage} />
                          <span>&nbsp;&nbsp;</span>
                          <span>{productCurrency.toUpperCase()}</span>
                          <span>&nbsp;</span>
                          <span>{productPrice.toFixed(2)}</span>
                        </span>
                      </Typography>
                      {productCount > 0 && (
                        <Typography variant="subtitle2" color="darkorange">
                          <span>
                            <span>Subtotal: </span>
                            <span>&nbsp;&nbsp;</span>
                            <span>{productCurrency.toUpperCase()}</span>
                            <span>&nbsp;</span>
                            <span>{productAmount.toFixed(2)}</span>
                          </span>
                        </Typography>
                      )}
                    </Box>
                    <Box className="box-default">
                      {productsActive && productOrder && (
                        <Box
                          className="box-inline left"
                          sx={{
                            pt: configEntzy.APP_SPACING_MD2X,
                          }}
                        >
                          <TextTag
                            text={
                              <span>
                                <FontAwesomeIcon
                                  icon={iconDecrement}
                                  fixedWidth
                                />
                              </span>
                            }
                            size="lg"
                            bgColor="black"
                            color="white"
                            shadow={true}
                            rounded={true}
                            onClick={() => {
                              handleDecrementProductCount(item.ProductId);
                            }}
                          />
                          <TextTag
                            text={
                              <span>
                                <span>&nbsp;&nbsp;</span>
                                {productCount}
                                <span>&nbsp;&nbsp;</span>
                              </span>
                            }
                            size="lg"
                            bgColor="black"
                            color="white"
                            shadow={true}
                            rounded={true}
                          />
                          <TextTag
                            text={
                              <span>
                                <FontAwesomeIcon
                                  icon={iconIncrement}
                                  fixedWidth
                                />
                              </span>
                            }
                            size="lg"
                            bgColor="black"
                            color="white"
                            shadow={true}
                            rounded={true}
                            onClick={() => {
                              handleIncrementProductCount(item.ProductId);
                            }}
                          />
                        </Box>
                      )}
                      {showProductForm && (
                        <Box
                          className="box-inline left"
                          sx={{
                            pt: configEntzy.APP_SPACING_MD2X,
                          }}
                        >
                          {mainContext.state.productList.data.DataManager ? (
                            <TextTag
                              text={
                                <span>
                                  <span>Remove</span>
                                  <span>&nbsp;&nbsp;</span>
                                  <FontAwesomeIcon
                                    icon={iconDelete}
                                    fixedWidth
                                  />
                                </span>
                              }
                              size="lg"
                              onClick={() => {
                                handleConfirmDeleteProduct(
                                  null,
                                  null,
                                  item.ProductId
                                );
                              }}
                              bgColor="danger.light"
                              color="black"
                              rounded={true}
                              shadow={true}
                            />
                          ) : (
                            <> </>
                            // <TextTag
                            //   text={
                            //     <span>
                            //       <span>&nbsp;&nbsp;</span>
                            //       <FontAwesomeIcon
                            //         icon={iconProduct}
                            //         fixedWidth
                            //       />
                            //       <span>&nbsp;&nbsp;</span>
                            //     </span>
                            //   }
                            //   size="lg"
                            //   rounded={true}
                            //   shadow={productsActive}
                            //   onClick={
                            //     productsActive
                            //       ? () => {
                            //           handleProductCart(item.ProductId);
                            //         }
                            //       : undefined
                            //   }
                            // />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {productOrder && !productsActive && (
                    <Box
                      className="box-default text-right"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Typography variant="body1">
                        <em>Add payment details to activate</em>
                      </Typography>
                    </Box>
                  )}
                  <Box className="box-default">
                    <ActionCheck
                      dialog={dialog.visible}
                      title={dialog.title}
                      description={dialog.description}
                      cancel={dialog.cancel}
                      confirm={dialog.confirm}
                    />
                  </Box>
                </Box>
              );
            })}

            {mainContext.state.productList.data.nextToken && (
              <Box
                className="box-default"
                sx={{ mt: configEntzy.APP_SPACING_LG }}
              >
                {user.connected ? (
                  <MoreButton
                    variant="contained"
                    size="large"
                    type="button"
                    color="bright"
                    text="More"
                    loading={loadingMore}
                    onClick={() => handlePullProductList(true)}
                  />
                ) : (
                  <MoreButton
                    variant="contained"
                    size="large"
                    type="button"
                    color="bright"
                    text="Connect for more messages..."
                    disabled={true}
                  />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <Typography variant="subtitle2">
              This {recipientType} has no active products
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              Direct payment sales can be made
            </Typography>
            <Typography variant="subtitle1">
              via custom payments above
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PayProducts;
