// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodePullRequest as iconFeatures } from "@fortawesome/pro-duotone-svg-icons";
import { faMessage as iconSupport } from "@fortawesome/pro-thin-svg-icons";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import ContactFeatures from "./ContactFeatures";
import ContactSupport from "./ContactSupport";

function ContactIndex(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box
        className="box-default"
        sx={{
          mb: configEntzy.APP_SPACING_HL2X,
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box
          className="box-default bg-black-t75"
          sx={{
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          {props.page === "/contact/features" ? (
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG,
                  pb: configEntzy.APP_SPACING_LG,
                }}
              >
                <FontAwesomeIcon icon={iconFeatures} size="3x" />
              </Box>
              <ContactFeatures />
            </Box>
          ) : props.page === "/contact/support" ? (
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_LG,
                  pb: configEntzy.APP_SPACING_LG,
                }}
              >
                <FontAwesomeIcon icon={iconSupport} size="3x" />
              </Box>
              <ContactSupport />
            </Box>
          ) : (
            <> </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ContactIndex;
