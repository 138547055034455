// react core
import { useContext, useState } from "react";

// material tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus as iconInvite } from "@fortawesome/pro-thin-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
// import { EventContext } from "pages/events/EventContext";
import ActionMembersInvites from "./ActionMembersInvites";

function ActionMembersRunner(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  // const eventContext = useContext(EventContext);

  const [tab, setTab] = useState(
    // props.runners ? 0 : props.riders ? 1 : props.queue ? 2 : 0
    mainContext.state.viewer.trail.memberTab
  );
  // tab handling
  const handleTabs = (event, newTab) => {
    setTab(newTab);
    mainContext.updateViewerTrail({
      memberTab: newTab,
    });
  };
  function TabPanel(props) {
    const { children, value: tab, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {tab === index && (
          <Box className="box-default tab-height">{children}</Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function tabProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function tabLabel(index, count) {
    return (
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_SM,
          pb: configEntzy.APP_SPACING_SM,
        }}
      >
        <Typography variant="subtitle2" color="white">
          {index === 0 && "Invite Runners"}
          {index === 1 && "Invite Riders"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
      >
        <Typography variant="h6">
          <FontAwesomeIcon icon={iconInvite} size="2x" fixedWidth />
        </Typography>
      </Box>
      <Box className="box-default">
        <Tabs
          value={tab}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="Notfications"
        >
          <Tab
            label={tabLabel(0, -1)}
            {...tabProps(0)}
            wrapped={true}
            sx={{
              fontSize: configEntzy.FONT_SCALE_LG,
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_MD,
            }}
          />
          <Tab
            label={tabLabel(1, -1)}
            {...tabProps(1)}
            sx={{
              fontSize: configEntzy.FONT_SCALE_LG,
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_MD,
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <ActionMembersInvites
          user={user}
          runnersView={true}
          ridersView={false}
          queueView={false}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ActionMembersInvites
          user={user}
          runnersView={false}
          ridersView={true}
          queueView={false}
        />
      </TabPanel>
    </Box>
  );
}

export default ActionMembersRunner;
