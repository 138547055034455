// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

// entzy context and components
import configEntzy from "components/config/ConfigEntzy";
import { EventContext } from "pages/events/EventContext";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion as iconLaunchedDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faExplosion as iconLaunchedThin } from "@fortawesome/pro-thin-svg-icons";
import { faCircle as iconCircleDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircleReg } from "@fortawesome/pro-solid-svg-icons";
import { orange } from "@mui/material/colors/";

function WidgetLaunchCounter(props) {
  const user = props.user;
  const counterLaunches = props.counterLaunches;

  const eventContext = useContext(EventContext);

  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          fontSize: configEntzy.FONT_SCALE_MD,
        }}
      >
        <Box
          component="span"
          className={
            "fa-layers fa-fw fa-6x" + (user.connected ? " action-pointer" : "")
          }
          onClick={
            user.connected ? () => eventContext.focusModule("entry") : undefined
          }
        >
          <FontAwesomeIcon
            icon={iconLaunchedDuo}
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconLaunchedThin}
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconCircleDuo}
            transform="shrink-3"
            style={{ color: orange[500] }}
          />
          <FontAwesomeIcon
            icon={iconCircleDuo}
            transform="shrink-6"
            style={{ color: orange[700] }}
          />
          <FontAwesomeIcon
            icon={iconCircleReg}
            transform="shrink-9"
            style={{ color: orange[200] }}
          />
          <Box component="span" className="fa-layers-text">
            <Typography variant="h4" color="black">
              {eventContext.state.event.calendar.dates.launched.all.keys.length}
            </Typography>
          </Box>
          {counterLaunches && (
            <span
              className="fa-layers-counter fa-layers-top-right"
              style={{
                backgroundColor: counterLaunches > 0 ? "red" : "white",
                color: counterLaunches > 0 ? "white" : "black",
                border:
                  counterLaunches > 0 ? "1px solid red" : "1px solid black",
              }}
            >
              {counterLaunches}
            </span>
          )}
        </Box>
      </Box>
      <Box
        className="box-default relative pop-up"
        sx={{
          mt: configEntzy.APP_SPACING_NSM,
        }}
      >
        <Chip
          variant="outlined"
          size="large"
          className="bg-black-t10"
          sx={{
            p: configEntzy.APP_SPACING_SM,
            fontWeight: "500",
            fontSize: configEntzy.FONT_SCALE_SM,
            backgroundColor: "orange",
            color: "white",
            borderColor: "black",
            opacity: "0.8",
          }}
          label={<span>Dates Launched</span>}
        />
      </Box>
    </Box>
  );
}

export default WidgetLaunchCounter;
