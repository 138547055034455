// react core
import { Fragment, useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy context and component
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { UserAvatar } from "components/utils/common/CommonAvatars";

function WidgetMembers(props) {
  const user = props.user;
  const runnersView = props.runners;
  const ridersView = props.riders;
  const queueView = props.queue;

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const membersList = runnersView
    ? eventContext.state.runners.data.items
    : ridersView
    ? queueView
      ? eventContext.state.riderQueue.data.items
      : eventContext.state.riders.data.items
    : [];

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleRefresh = async () => {
    let response;
    setLoading(true);
    response = await Promise.all([
      eventContext.preparePullRunners({
        EventId: eventContext.state.event.data.EventId,
      }),
      eventContext.preparePullRiders({
        EventId: eventContext.state.event.data.EventId,
      }),
      eventContext.preparePullRiderQueue({
        EventId: eventContext.state.event.data.EventId,
      }),
    ]);
    if (response.filter((item) => item.alert).length > 0) {
      mainContext.updateAlert({
        alert: true,
        message:
          "Hmm unable to refresh members on that try. Give it another go or contact us if this problem persists.",
      });
    } else {
      eventContext.pullRunners(response[0]);
      eventContext.pullRiders(response[1]);
      eventContext.pullRiderQueue(response[2]);
    }
    setLoading(false);
  };

  const handleAccept = async (event, userid) => {
    setLoading(true);
    const response = await eventContext.preparePinEvent({
      EventId: eventContext.state.event.data.EventId,
      pinned: true,
      userAccept: {
        id: userid,
      },
    });
    if (response.alert) {
      mainContext.updateAlert(response);
      setLoading(false);
    } else {
      await handleRefresh();
      setLoading(false);
    }
  };

  const handleRemove = async (event, userid, confirm) => {
    const isInvite = userid.startsWith("invite");
    if (confirm === true) {
      setDialog({ visible: false });
      await handleRemoveMember(userid);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title:
          "Confirm Remove " +
          (runnersView ? "Runner" : "Rider") +
          (isInvite ? " Invite" : queueView ? " from the queue" : ""),
        description:
          "Are you sure you want to remove this " +
          (runnersView ? "runner" : "rider") +
          (isInvite ? " invite" : queueView ? " from the queue" : "") +
          "?",
        cancel: (e) => handleRemove(e, userid, false),
        confirm: (e) => handleRemove(e, userid, true),
      });
    }
  };

  const handleRemoveMember = async (userid) => {
    setLoading(true);
    const response = await eventContext.preparePinEvent({
      EventId: eventContext.state.event.data.EventId,
      userRemoval: {
        id: userid,
      },
    });
    if (response.alert) {
      mainContext.updateAlert(response);
      setLoading(false);
    } else {
      await handleRefresh();
      setLoading(false);
    }
  };

  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_HL,
      }}
    >
      {/* member content */}
      <Box className="box-default">
        {/* member list */}
        <Box className="box-default">
          {membersList.length ? (
            membersList.map((member, index) => {
              return (
                <Box
                  key={member.UserId}
                  className="box-inline"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    // maxWidth: "50%",
                  }}
                >
                  <Box className="box-default">
                    <Box className="box-inline">
                      <UserAvatar
                        user={{
                          identity: member.UserId,
                        }}
                        size="md"
                      />
                    </Box>
                  </Box>
                  {eventContext.state.event.manager && (
                    <Box
                      className="box-default"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        visibility: loading ? "hidden" : "visible",
                      }}
                    >
                      {member.UserId ===
                      eventContext.state.event.data.UserId ? (
                        <Box className="box-inline">
                          <ActionButton
                            variant="outlined"
                            color="primaryDisabled"
                            size="small"
                            text="Owner"
                            fullWidth={true}
                          />
                        </Box>
                      ) : queueView ? (
                        <Box className="box-inline">
                          <Box
                            className="box-default"
                            sx={{
                              pb: configEntzy.APP_SPACING_MD,
                            }}
                          >
                            <Typography variant="body1" color="primary">
                              Accept Ride Request?
                            </Typography>
                          </Box>
                          <Box className="box-default">
                            <ActionButton
                              variant="contained"
                              color="success"
                              size="small"
                              text="Yes"
                              onClick={(e) => handleAccept(e, member.UserId)}
                            />
                            <ActionButton
                              variant="contained"
                              color="danger"
                              size="small"
                              text="No"
                              onClick={(e) => handleRemove(e, member.UserId)}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box className="box-inline">
                          <ActionButton
                            variant="contained"
                            color="danger"
                            size="small"
                            text="Remove"
                            onClick={(e) => handleRemove(e, member.UserId)}
                            fullWidth={true}
                          />
                        </Box>
                      )}
                      {/* <Box className="box-inline">
                      <TextTag
                        text={
                          member.UserId ===
                          eventContext.state.event.data.UserId ? (
                            <span className="content-disabled">
                              &nbsp;Owner&nbsp;
                            </span>
                          ) : (
                            <span>
                              <span>&nbsp;</span>
                              <span>Remove</span>
                              <span>&nbsp;&nbsp;</span>
                              <FontAwesomeIcon
                                icon={iconRemove}
                                style={{ color: "white" }}
                              />
                              <span>&nbsp;</span>
                            </span>
                          )
                        }
                        size="sm"
                        bgColor={
                          member.UserId === eventContext.state.event.data.UserId
                            ? "divider"
                            : "danger.light"
                        }
                        color="black"
                        rounded={true}
                        // shadow={true}
                        fullwidth={true}
                        onClick={
                          member.UserId === eventContext.state.event.data.UserId
                            ? undefined
                            : (e) => handleRemove(e, member.UserId)
                        }
                      />
                    </Box> */}
                    </Box>
                  )}
                </Box>
              );
            })
          ) : (
            <Box className="box-default">
              {[1].map((item, index) => {
                return (
                  <Box
                    key={item}
                    className="box-inline"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                      // maxWidth: "50%",
                    }}
                  >
                    <Box className="box-default">
                      <Box className="box-inline">
                        <UserAvatar
                          user={{
                            identity: null,
                          }}
                          avatarOnly={true}
                          size="md"
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                {user.connected ? (
                  <Typography variant="subtitle2">
                    {runnersView
                      ? "No Runners"
                      : ridersView
                      ? queueView
                        ? "No Riders in the Queue"
                        : "No Riders Yet"
                      : "No Members"}
                  </Typography>
                ) : (
                  <Fragment>
                    <Typography variant="subtitle2">Connect to view</Typography>
                    <Typography variant="subtitle2">
                      {runnersView
                        ? "Runners"
                        : ridersView
                        ? queueView
                          ? "Rider Queue"
                          : "Riders"
                        : "Members"}
                    </Typography>
                  </Fragment>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* dialog popup */}
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>

      {/* loading popup */}
      {loading && (
        <Box className="box-fixed bottom-left full-width">
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_HL2X,
            }}
          >
            <ActionLoader size="md" color="highlight" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default WidgetMembers;
