// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionAlert } from "components/utils/common/CommonLoaders";
import { scrollIntoViewIfNeeded } from "models/Tools";

// entzy components
import ActionLaunchpadRunnerCriteria from "./ActionLaunchpadRunnerCriteria";
import ActionLaunchpadRunnerDetails from "./ActionLaunchpadRunnerDetails";
import ActionLaunchpadRunnerBanner from "./ActionLaunchpadRunnerBanner";
import ActionLaunchpadRunnerGallery from "./ActionLaunchpadRunnerGallery";
import ActionLaunchpadRunnerTickets from "./ActionLaunchpadRunnerTickets";
import ActionLaunchpadRunnerMembers from "./ActionLaunchpadRunnerMembers";

// constants
const newFormTicket = () => ({
  ticketName: "",
  ticketPrice: "0.00",
  ticketCapacity: "100",
  ticketGroupSize: null,
});

function ActionLaunchpadRunner(props) {
  // const user = props.user;
  const section = props.section;
  // const handleOpenAction = props.handleOpenAction;
  // const handleCloseAction = props.handleCloseAction;

  const anchorTop = "achor-event-actions";

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [showAddTicket, setShowAddTicket] = useState(false);
  const [addFormTicket, setFormAddTicket] = useState(newFormTicket());

  const [editing, setEditing] = useState(null);

  const handleEdit = (type) => {
    const element = document.getElementById(anchorTop);
    if (element) {
      scrollIntoViewIfNeeded(element);
    }
    setEditing(type ? type : null);
  };

  // const [editing, setEditing] = useState(false);
  // const [editCriteria, setEditCriteria] = useState(false);
  // const [editBackground, setEditBackground] = useState(false);
  // const [editFrame, setEditFrame] = useState(null);
  // const [editDetails, setEditDetails] = useState(false);
  // const [editTickets, setEditTickets] = useState(false);
  // const [editWarning, setEditWarning] = useState(false);

  // const resetEdit = () => {
  //   setEditCriteria(false);
  //   setEditBackground(false);
  //   setEditFrame(null);
  //   setEditDetails(false);
  //   setEditTickets(false);
  //   setEditWarning(false);
  //   setEditing(false);
  // };
  // const handleEdit = (type) => {
  //   resetEdit();
  //   setEditing(type ? true : false);
  //   if (!type) {
  //     const element = document.getElementById(anchorTop);
  //     if (element) {
  //       eventContext.state.toolbox.scrollIntoViewIfNeeded(element);
  //     }
  //   } else {
  //     if (type === "criteria") {
  //       setEditCriteria(true);
  //     }
  //     if (type === "background") {
  //       setEditBackground(true);
  //     }
  //     if (type.startsWith("frame")) {
  //       setEditFrame(type);
  //     }
  //     if (type === "details") {
  //       setEditDetails(true);
  //     }
  //     if (type === "tickets") {
  //       setEditTickets(true);
  //     }
  //   }
  // };

  const handleRunnerActionCallback = async (data) => {
    setSuccess(false);
    let eventActive = data.filter((obj) => obj.id === "event-active").length
      ? data.filter((obj) => obj.id === "event-active")[0].value
      : null;
    let eventPrivate = data.filter((obj) => obj.id === "event-private").length
      ? data.filter((obj) => obj.id === "event-private")[0].value
      : null;
    let eventLaunchPerTicket = data.filter(
      (obj) => obj.id === "event-launch-per-ticket"
    ).length
      ? data.filter((obj) => obj.id === "event-launch-per-ticket")[0].value
      : null;
    let eventTagline = data.filter((obj) => obj.id === "event-tagline").length
      ? data.filter((obj) => obj.id === "event-tagline")[0].value
      : null;
    let eventDetails = data.filter((obj) => obj.id === "event-details").length
      ? data.filter((obj) => obj.id === "event-details")[0].value
      : null;
    let settingNotice = data.filter((obj) => obj.id === "setting-notice").length
      ? data.filter((obj) => obj.id === "setting-notice")[0].value
      : null;
    let settingTrigger = data.filter((obj) => obj.id === "setting-trigger")
      .length
      ? data.filter((obj) => obj.id === "setting-trigger")[0].value
      : null;
    let settingCapacity = data.filter((obj) => obj.id === "setting-capacity")
      .length
      ? data.filter((obj) => obj.id === "setting-capacity")[0].value
      : null;
    // transform event access setting
    if (eventPrivate !== null) {
      if (eventPrivate) {
        eventPrivate = "private";
      } else {
        eventPrivate = "public";
      }
    }
    // construct limits
    let settingLimits = null;
    if (settingNotice !== null && settingCapacity !== null) {
      if (!eventContext.state.toolbox.isInteger(settingNotice)) {
        settingNotice = eventContext.state.event.calendar.limits.notice;
      }
      if (!eventContext.state.toolbox.isInteger(settingCapacity)) {
        settingCapacity = eventContext.state.event.calendar.limits.capacity;
      }
      settingLimits = [
        eventContext.state.event.generators.settings.limit(
          "notice",
          settingNotice,
          true
        ),
        eventContext.state.event.generators.settings.limit(
          "capacity",
          settingCapacity,
          true
        ),
      ];
    }
    // construct triggers
    let settingTriggers = null;
    if (settingTrigger !== null) {
      if (!eventContext.state.toolbox.isInteger(settingTrigger)) {
        settingTrigger = eventContext.state.event.calendar.triggers.default;
      }
      settingTriggers = [
        eventContext.state.event.generators.settings.trigger(
          "default",
          settingTrigger,
          true
        ),
        eventContext.state.event.generators.settings.trigger("financial"), // TODO: add financial trigger and make it configurable (active/inactive)
      ];
    }
    // send update
    if (eventContext.state.event.manager) {
      const keysets = [];
      if (settingLimits !== null) {
        keysets.push({ key: "Limits", value: settingLimits });
      }
      if (settingTriggers !== null) {
        keysets.push({ key: "Triggers", value: settingTriggers });
      }
      if (eventTagline !== null) {
        keysets.push({ key: "Tagline", value: eventTagline });
      }
      if (eventDetails !== null) {
        keysets.push({ key: "Details", value: eventDetails });
      }
      if (eventActive !== null) {
        keysets.push({ key: "Active", value: eventActive });
      }
      if (eventPrivate !== null) {
        keysets.push({ key: "Access", value: eventPrivate });
      }
      if (eventLaunchPerTicket !== null) {
        keysets.push({ key: "LaunchPerTicket", value: eventLaunchPerTicket });
      }
      // any blocking validations
      if (
        eventDetails !== null &&
        eventDetails.length > configEntzy.MAX_EVENT_DETAILS
      ) {
        return {
          alert: true,
          message:
            "Launch details exceed the maximum size. Reduce the text a bit to continue.",
        };
      }
      // execute update
      const eventUpdate = await eventContext.prepareUpdateEvent({
        multiple: true,
        keysets: keysets,
      });
      if (eventUpdate.alert) {
        return {
          alert: true,
          message: eventUpdate.message,
        };
      } else {
        eventContext.updateEvent(eventUpdate);
        setSuccess(true);
        handleEdit(null);
        const element = document.getElementById(anchorTop);
        if (element) {
          scrollIntoViewIfNeeded(element);
        }
        // handleCloseAction();
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Event runner permissions required for this update",
      };
    }
  };

  const handleShowAddTicket = async () => {
    setShowAddTicket(!showAddTicket);
  };

  const handleTicketActionCallback = async (data) => {
    setAlert(false);
    // temporarily fixed until decide whether useful to make dynamic per ticket
    // todo: add a setting first to configure at event level
    const selectionState =
      data.action && data.action.length
        ? {
            remove: data.action === "remove",
            disable: data.action === "disable",
            enable: data.action === "enable",
            ticketId: data.ticket.id,
          }
        : {
            add: true,
            ticketName: data.filter((obj) => obj.id === "ticket-name")[0].value,
            ticketPrice: data.filter((obj) => obj.id === "ticket-price")[0]
              .value,
            ticketCapacity: data.filter(
              (obj) => obj.id === "ticket-capacity"
            )[0].value,
            ticketGroupSize: data.filter(
              (obj) => obj.id === "ticket-group-size"
            )[0].value,
          };
    // prepare new ticket list
    const eventTicketTypes = [];
    // existing tickets and removals
    eventContext.state.event.data.TicketTypes.forEach((ticket) => {
      if (!(selectionState.remove && ticket.id === selectionState.ticketId)) {
        if (selectionState.disable && ticket.id === selectionState.ticketId) {
          ticket.active = false;
        }
        if (selectionState.enable && ticket.id === selectionState.ticketId) {
          ticket.active = true;
        }
        eventTicketTypes.push(ticket);
      }
    });
    // new tickets
    if (selectionState.add) {
      if (eventContext.state.toolbox.isEmptyString(selectionState.ticketName)) {
        return {
          alert: true,
          message: "Ticket name cannot be empty",
        };
      }
      if (
        !eventContext.state.toolbox.isInteger(selectionState.ticketCapacity)
      ) {
        return {
          alert: true,
          message: "Ticket capacity must be a number",
        };
      }
      if (
        !eventContext.state.toolbox.isInteger(
          selectionState.ticketGroupSize
            ? selectionState.ticketGroupSize.value
            : null
        )
      ) {
        return {
          alert: true,
          message: "Ticket group size must be a number",
        };
      }
      const setting =
        eventContext.state.event.generators.settings.ticket("default");
      selectionState.ticketPrice = Math.round(
        Number(selectionState.ticketPrice) * 100
      );
      selectionState.ticketCapacity = Math.round(
        selectionState.ticketCapacity * 1,
        0
      );
      selectionState.ticketGroupSize.value = Math.round(
        selectionState.ticketGroupSize.value * 1,
        0
      );
      selectionState.ticketPrice = Math.round(selectionState.ticketPrice, 0);
      setting.name = selectionState.ticketName;
      setting.price = selectionState.ticketPrice;
      setting.max = selectionState.ticketCapacity;
      setting.min = selectionState.ticketGroupSize.value;
      eventTicketTypes.push(setting);
    }
    if (eventContext.state.event.manager) {
      const eventUpdate = await eventContext.prepareUpdateEvent({
        key: "TicketTypes",
        value: eventTicketTypes,
      });
      if (eventUpdate.alert) {
        setAlert(true);
        setMessage(eventUpdate.message);
        // retain form values
        if (selectionState.add) {
          setFormAddTicket({
            ticketName: selectionState.ticketName,
            ticketPrice: selectionState.ticketPrice,
            ticketCapacity: selectionState.ticketCapacity,
            ticketGroupSize: selectionState.ticketGroupSize,
          });
        }
        return {
          alert: true,
          message: eventUpdate.message,
        };
      } else {
        eventContext.updateEvent(eventUpdate);
        eventContext.generateCalendar();
        // reset form values
        setShowAddTicket(false);
        setFormAddTicket(newFormTicket());
        handleEdit(null);
        const element = document.getElementById(anchorTop);
        if (element) {
          scrollIntoViewIfNeeded(element);
        }
        // set for callback loader
        const callbackState = {
          type: "actionDrawer",
          payload: {
            actionParams: "tickets",
          },
        };
        eventContext.setCallbackLoader(true, callbackState);
        return { alert: false };
      }
    } else {
      const msg = "Event runner permissions required for this update";
      setAlert(true);
      setMessage(msg);
      return {
        alert: true,
        message: msg,
      };
    }
  };

  return (
    <Box
      // id={anchorTop}
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
        pl: configEntzy.APP_SPACING_MD,
        pr: configEntzy.APP_SPACING_MD,
        pb: configEntzy.APP_SPACING_LG,
        mb: configEntzy.APP_SPACING_XL,
      }}
    >
      {alert && message && (
        <Box
          className="box-default"
          sx={{
            pb: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <ActionAlert severity="error" message={message} />
        </Box>
      )}
      {success && (
        <Box
          className="box-default"
          sx={{
            pb: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <ActionAlert severity="success" message="Changes Saved" />
        </Box>
      )}
      {/* {editWarning && (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <ActionAlert
            severity="warning"
            title="Cancel Editing?"
            message="You have unsaved changes. Are you sure you want to exit the editor for this section? Tap here to confirm."
            onClick={() => handleEdit()}
          />
        </Box>
      )} */}
      {/* ---------------------------- section rendering ---------------------------- */}
      <Box className="box-default" hidden={section !== "criteria"}>
        <ActionLaunchpadRunnerCriteria
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
        />
      </Box>
      <Box className="box-default" hidden={section !== "details"}>
        <ActionLaunchpadRunnerDetails
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
        />
      </Box>
      <Box className="box-default" hidden={section !== "banner"}>
        <ActionLaunchpadRunnerBanner
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
        />
      </Box>
      <Box className="box-default" hidden={section !== "gallery"}>
        <ActionLaunchpadRunnerGallery
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
        />
      </Box>
      <Box className="box-default" hidden={section !== "tickets"}>
        <ActionLaunchpadRunnerTickets
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
          handleTicketActionCallback={handleTicketActionCallback}
          addFormTicket={addFormTicket}
          showAddTicket={showAddTicket}
          handleShowAddTicket={handleShowAddTicket}
        />
      </Box>
      <Box className="box-default" hidden={section !== "members"}>
        <ActionLaunchpadRunnerMembers
          {...props}
          editing={editing}
          handleEdit={handleEdit}
          handleRunnerActionCallback={handleRunnerActionCallback}
        />
      </Box>
    </Box>
  );
}

export default ActionLaunchpadRunner;
