const UserMain = function () {
  return {
    identity: "guest:user",
    username: null,
    sub: null,
    email: null,
    name: "guest",
    avatar: null,
    notifications: null,
    public: false,
    error: false,
    status: {
      verified: null,
      reset: null,
      payment_set: null,
      payout_set: null,
      last_email_verified: null,
    },
  };
};

export default UserMain;
