// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageCaptions as iconMessages } from "@fortawesome/pro-regular-svg-icons";
import { faRocketLaunch as iconActvity } from "@fortawesome/pro-regular-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-regular-svg-icons";

// entzy context and component
import configEntzy from "components/config/ConfigEntzy";
import MainCallbackLoader from "components/main/MainCallbackLoader";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { ActionAlert } from "components/utils/common/CommonLoaders";

// entzy pages
import MemberIndex from "pages/members/MemberIndex";
import PayIndex from "pages/pay/PayIndex";
import SettingsIndex from "pages/settings/SettingsIndex";
import ContactsIndex from "pages/contacts/ContactsIndex";

function MainNotifications(props) {
  // const user = props.user;
  const mainContext = useContext(MainContext);

  const handleGoToNotifications = () => {
    props.menuSelectById("home");
  };

  return (
    <Box
      className="box-default"
      sx={{
        height: "0px",
      }}
    >
      {props.user.connected &&
        mainContext.state.menuSelected !== "home" &&
        props.page !== "/" && (
          <Box
            className="box-inline action-pointer"
            sx={{
              mt: configEntzy.APP_SPACING_NXL,
              pt: configEntzy.APP_SPACING_SM,
            }}
            onClick={handleGoToNotifications}
          >
            <Typography variant="h6">
              <span
                className={
                  (mainContext.state.notifications.data.messagesUnreadCount > 0
                    ? "bg-unread"
                    : "bg-white") + " rounded shadow-default"
                }
              >
                <span>&nbsp;</span>
                {mainContext.state.notifications.data.messagesUnreadCount}
                <span>&nbsp;</span>
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <FontAwesomeIcon
                icon={iconMessages}
                transform="down-8"
                style={{ color: "orange" }}
                fixedWidth
              />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <FontAwesomeIcon
                icon={iconActvity}
                transform="down-8"
                style={{ color: "orange" }}
                fixedWidth
              />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span
                className={
                  (mainContext.state.notifications.data.activityUnreadCount > 0
                    ? "bg-unread"
                    : "bg-white") + " rounded shadow-default"
                }
              >
                <span>&nbsp;</span>
                {mainContext.state.notifications.data.activityUnreadCount}
                <span>&nbsp;</span>
              </span>
            </Typography>
          </Box>
        )}
      {mainContext.state.alert && mainContext.state.alert.show && (
        <Box
          className="box-fixed bottom full-width"
          sx={{
            pb: configEntzy.APP_SPACING_XL,
            zIndex: 9999999999,
          }}
        >
          <Container maxWidth="sm">
            <ActionAlert
              severity={
                mainContext.state.alert.severity
                  ? mainContext.state.alert.severity
                  : "error"
              }
              title={mainContext.state.alert.title}
              message={mainContext.state.alert.message}
              onClose={() => mainContext.updateAlert({ show: false })}
            />
          </Container>
        </Box>
      )}
      {props.user.connected &&
        (mainContext.state.memberContact.viewer.open ||
          mainContext.state.memberMessaging.viewer.open ||
          mainContext.state.memberTransactions.viewer.open ||
          mainContext.state.payDirect.viewer.open) && (
          <Box
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Drawer
              anchor="left"
              open={
                mainContext.state.memberContact.viewer.open ||
                mainContext.state.memberMessaging.viewer.open ||
                mainContext.state.memberTransactions.viewer.open ||
                mainContext.state.payDirect.viewer.open
              }
              onClose={() => {
                mainContext.setMemberContactViewer({
                  open: false,
                });
                mainContext.setMemberMessageViewer({
                  open: false,
                });
                mainContext.setMemberTransactionViewer({
                  open: false,
                });
                mainContext.setPayDirectViewer({
                  open: false,
                });
              }}
              keepMounted
            >
              <Slide
                in={
                  mainContext.state.memberContact.viewer.open ||
                  mainContext.state.memberMessaging.viewer.open ||
                  mainContext.state.memberTransactions.viewer.open ||
                  mainContext.state.payDirect.viewer.open
                }
                direction="left"
              >
                <Box className="box-default">
                  <Box
                    className="box-default text-right"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD2X,
                      pr: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <Typography variant="subtitle2">
                      <FontAwesomeIcon
                        icon={iconClose}
                        size="2x"
                        color="orange"
                        fixedWidth
                        onClick={() => {
                          mainContext.setMemberContactViewer({
                            open: false,
                          });
                          mainContext.setMemberMessageViewer({
                            open: false,
                          });
                          mainContext.setMemberTransactionViewer({
                            open: false,
                          });
                          mainContext.setPayDirectViewer({
                            open: false,
                          });
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box className="box-default">
                    {mainContext.state.memberContact.viewer.open && (
                      <ContactsIndex
                        {...props}
                        user={mainContext.state.memberContact.viewer.user}
                      />
                    )}
                    {mainContext.state.memberMessaging.viewer.open && (
                      <MemberIndex
                        {...props}
                        member={
                          mainContext.state.memberMessaging.viewer.user
                            ? mainContext.state.memberMessaging.viewer.user.name
                            : null
                        }
                      />
                    )}
                    {mainContext.state.memberTransactions.viewer.open && (
                      <SettingsIndex
                        {...props}
                        page="/settings/transaction/history"
                        hideMenu={true}
                      />
                    )}
                    {mainContext.state.payDirect.viewer.open && (
                      <PayIndex
                        {...props}
                        recipient={mainContext.state.payDirect.viewer.recipient}
                        currency={mainContext.state.payDirect.viewer.currency}
                      />
                    )}
                  </Box>
                </Box>
              </Slide>
            </Drawer>
          </Box>
        )}
      <MainCallbackLoader />
    </Box>
  );
}

export default MainNotifications;
