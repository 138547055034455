// react core
import { useContext, useState, useEffect, useCallback } from "react";

// pull to refresh
import PullToRefresh from "react-simple-pull-to-refresh";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { PayTag, RefreshTag } from "components/utils/common/CommonTags";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  ActionAlert,
  ActionLoader,
  PullLoader,
  PullPrompt,
} from "components/utils/common/CommonLoaders";
import { getCombinedUserId } from "models/Tools";

// entzy direct messaging components
import MemberMessaging from "components/member/MemberMessaging";
import MemberMessagingSelector from "components/member/MemberMessagingSelector";
import MemberPostMessage from "components/member/MemberPostMessage";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt as iconUserChat } from "@fortawesome/pro-thin-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faPlus as iconAdd } from "@fortawesome/pro-regular-svg-icons";

function MemberProfile(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const memberName = props.memberName;
  const [roomArea, setRoomArea] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [showPost, setShowPost] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpenAction = () => {
    setShowPost(true);
  };
  const handleCloseAction = () => {
    setShowPost(false);
  };
  const activateRoom = (roomArea, roomName, roomId) => {
    mainContext.openSubscription("memberMessaging", {
      RoomArea: roomArea,
      RoomName: roomName,
    });
  };
  const cbActivateRoom = useCallback(activateRoom, [mainContext]);

  const handlePayDirect = async () => {
    if (user.connected) {
      mainContext.setPayDirectViewer({
        open: mainContext.state.payDirect.viewer.open ? false : true,
        recipient: "@" + memberName,
        // currency: "usd",
      });
      mainContext.setMemberMessageViewer({
        open: false,
      });
    } else {
      return true;
    }
  };

  const handleRefresh = async () => {
    if (user.connected) {
      if (roomId) {
        const response = await Promise.all([
          mainContext.preparePullNotifications({
            Category: null,
          }),
          mainContext.preparePullMemberMessages({
            RoomArea: roomArea,
            RoomName: roomName,
          }),
        ]);
        if (response.filter((item) => item.alert).length > 0) {
          mainContext.updateAlert({
            alert: true,
            message:
              "Hmm unable to refresh direct messages on that try. Give it another go or contact us if this problem persists.",
          });
        } else {
          mainContext.pullNotifications(response[0]);
          mainContext.pullMemberMessages({
            roomId: roomId,
            data: response[1].data,
          });
        }
        const responseNext = await Promise.all([
          mainContext.preparePullMemberChatSettings({
            RoomArea: roomArea,
            RoomName: roomName,
          }),
        ]);
        if (responseNext.filter((item) => item.alert).length > 0) {
          mainContext.updateAlert({
            alert: true,
            message:
              "Hmm unable to refresh direct message settings on that try. Give it another go or contact us if this problem persists.",
          });
        } else {
          mainContext.pullMemberChatSettings({
            roomId: roomId,
            data: responseNext[0].data,
          });
        }
      } else {
        mainContext.updateAlert({
          alert: true,
          message: "Unable to refresh direct messages. Member not found.",
        });
      }
    }
    return true;
  };

  // add effect to load direct messaging data when user interaction is created
  useEffect(() => {
    // set a timeout to deactivate loading if no user interaction found
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    // wait for interaction to be set by avatar loading
    const userInteraction = mainContext.state.interaction.users.filter(
      (obj) => obj.name === memberName
    );
    if (userInteraction.length > 0) {
      const area = getCombinedUserId(
        user.identity,
        userInteraction[0].identity
      );
      const name = "main";
      const id = area + configEntzy.DELIMITER_DEFAULT + name;
      setRoomArea(area);
      setRoomName(name);
      setRoomId(id);
      setLoading(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [mainContext.state.interaction.users, memberName, user.identity]);

  // add effect to open direct messaging subscription when room id is set
  useEffect(() => {
    if (roomArea && roomName && roomId) {
      cbActivateRoom(roomArea, roomName, roomId);
    }
  }, [cbActivateRoom, roomArea, roomName, roomId]);

  return (
    <Box className="box-default">
      <PullToRefresh
        onRefresh={handleRefresh}
        refreshingContent={<PullLoader />}
        pullingContent={<PullPrompt />}
      >
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
            height: configEntzy.AVATAR_SIZE_LG,
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Box className="box-inline right">
                <PayTag onClick={handlePayDirect} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="box-inline right"
                sx={{ pr: configEntzy.APP_SPACING_SM }}
              >
                <UserAvatar user={user} size="md" avatarOnly={false} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="box-inline left"
                sx={{ pl: configEntzy.APP_SPACING_SM }}
              >
                <UserAvatar
                  user={{ name: memberName }}
                  size="md"
                  avatarOnly={false}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className="box-inline left">
                <RefreshTag />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PullToRefresh>
      {roomId ? (
        <Box
          className="box-default"
          sx={{
            pb: configEntzy.APP_SPACING_HL3X,
          }}
        >
          <Box
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            {user.connected && (
              <MemberMessagingSelector
                {...props}
                roomArea={roomArea}
                roomName={roomName}
                roomId={roomId}
                memberName={memberName}
                setMemberName={props.setMemberName}
              />
            )}
          </Box>
          <Box
            className="box-fixed bottom-left full-width pop-up"
            sx={{
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <ActionButton
              variant="contained"
              size="small"
              color={showPost ? "bright" : "dusk"}
              fullWidth={props.fullWidth}
              borderGlow={true}
              text={
                <span>
                  <span>{showPost ? "Close X" : "Post Message"}</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span>
                    <FontAwesomeIcon
                      icon={iconUserChat}
                      transform="grow-4"
                      fixedWidth
                    />
                    <FontAwesomeIcon
                      icon={showPost ? iconClose : iconAdd}
                      transform="shrink-4"
                      fixedWidth
                    />
                  </span>
                </span>
              }
              onClick={showPost ? handleCloseAction : handleOpenAction}
            />

            {/* <ActionButton
                text={showPost ? "Close X" : "Post Message"}
                size="sm"
                fullWidth={true}
                onClick={handleOpenAction}
              /> */}
          </Box>

          <Box className="box-default">
            <MemberMessaging
              {...props}
              roomArea={roomArea}
              roomName={roomName}
              roomId={roomId}
              memberName={memberName}
            />
          </Box>
          <Drawer anchor="bottom" open={showPost} onClose={handleCloseAction}>
            <Box className="box-default">
              <MemberPostMessage
                {...props}
                roomArea={roomArea}
                roomName={roomName}
                roomId={roomId}
                memberName={memberName}
                handleCloseAction={handleCloseAction}
              />
            </Box>
          </Drawer>
          {/* Observables idle alert */}
          <Box
            className="box-fixed bottom-left full-width"
            sx={{
              p: configEntzy.APP_SPACING_LG,
              textAlign: "left",
            }}
            hidden={
              !user.connected || mainContext.state.observablesTracker.active
            }
          >
            <ActionAlert
              severity="info"
              title="Sleeping"
              message={
                "Move around to wake things up. Sleep mode entered after " +
                configEntzy.IDLE_TIMEOUT_MINS +
                " minutes of inactivity"
              }
            />
          </Box>
        </Box>
      ) : (
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
        >
          {loading ? (
            <Box className="box-default">
              <ActionLoader />
            </Box>
          ) : (
            <Box className="box-default">
              <Typography variant="h5">Member not found</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MemberProfile;
