// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator as iconNumeric } from "@fortawesome/pro-duotone-svg-icons";
import { faCommentDollar as iconDescription } from "@fortawesome/pro-duotone-svg-icons";
import { faEnvelope as iconEmail } from "@fortawesome/pro-duotone-svg-icons";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { ActionButton } from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionAlert,
} from "components/utils/common/CommonLoaders";
import { currenciesGetItem } from "models/Currency";
import {
  serviceCookieGet,
  serviceCookieSet,
  serviceCookieClear,
} from "services/graphql/call";

// entzy pages
import PayProducts from "./PayProducts";
import InputForm from "components/input/InputForm";

function PaySend(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const userReload = props.userReload;
  const recipientType = props.recipientType;
  const recipientName = props.recipientName;
  const defaultCurrency = props.defaultCurrency;
  const defaultAmount = props.defaultAmount;
  const defaultDescription = props.defaultDescription;

  const [payment, setPayment] = useState(null);
  const [ordering, setOrdering] = useState(false);
  const [productOrder, setProductOrder] = useState(false);
  const [customOrder, setCustomOrder] = useState(false);
  const [hidePayForm, setHidePayForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

  // override page redirects in case viewing in modal
  const redirectPage =
    "/pay" + (recipientType === "eventuator" ? "/e/" : "/@/") + recipientName;

  // capture any completed payments from stripe redirects
  const paymentCompleteCookie = serviceCookieGet(
    configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_payment_complete
  );
  const guestEmailCookie = serviceCookieGet(
    configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_guest_email
  );
  const [paymentComplete, setPaymentComplete] = useState(
    paymentCompleteCookie ? true : false
  );
  const clearPaymentComplete = () => {
    serviceCookieClear(
      configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_payment_complete
    );
    setPaymentComplete(false);
  };

  const handleProductOrder = (value) => {
    setProductOrder(value ? value : value === false ? false : !productOrder);
  };
  // const handleCustomOrder = (value) => {
  //   setCustomOrder(value ? value : value === false ? false : !customOrder);
  // };
  const handleHidePayForm = (value) => {
    setHidePayForm(value ? value : value === false ? false : !hidePayForm);
  };

  const startProductOrder = () => {
    setOrdering(true);
    setCustomOrder(false);
    setProductOrder(true);
    handleHidePayForm(true);
  };
  const clearProductOrder = () => {
    setProductOrder(false);
    setOrdering(false);
    handleHidePayForm(false);
  };
  const startCustomOrder = () => {
    setOrdering(true);
    setProductOrder(false);
    setCustomOrder(true);
    handleHidePayForm(false);
  };
  const clearCustomOrder = () => {
    setCustomOrder(false);
    setOrdering(false);
    handleHidePayForm(false);
  };

  const handleActionCallback = async (data, stage) => {
    let payCurrency, payAmount, payDescription;
    if (stage) {
      payCurrency = {
        code: {
          long: stage.currencyCode,
        },
      };
      payAmount = stage.amount;
      payDescription = stage.description;
    } else {
      payCurrency = data.filter((obj) => obj.id === "pay-currency")[0].value;
      payAmount = data.filter((obj) => obj.id === "pay-amount")[0].value;
      payDescription = data.filter((obj) => obj.id === "pay-description")[0]
        .value;
    }
    if (payCurrency && payAmount && !isNaN(payAmount) && payAmount > 0) {
      const payAmountInt = Math.round(payAmount * 100);
      setLoading(true);
      setTimeout(() => {
        setPayment({
          currency: payCurrency,
          amount: payAmount * 1,
          amountInt: payAmountInt,
          description: payDescription,
          recipientType: recipientType,
          recipientName: recipientName,
        });
        setLoading(false);
      }, 200);
      return {};
    } else {
      return {
        alert: true,
        message: "Select a currency and a valid amount",
      };
    }
  };

  const handleSendPayment = async () => {
    if (user.connected && payment) {
      setLoading(true);
      setSuccess(false);
      setAlert(false);
      const response = await mainContext.prepareSendPayment({
        RecipientType: recipientType,
        RecipientName: recipientName,
        Currency: payment.currency.code.long,
        Amount: payment.amountInt,
        Description: payment.description,
      });
      if (response.alert) {
        // mainContext.updateAlert(response);
        setAlert(true);
        setMessage(response.message);
      } else {
        mainContext.sendPayment(response.data);
        setPayment(null);
        setSuccess(true);
        setMessage("Payment sent successfully");
      }
      setLoading(false);
      return response;
    }
  };

  const handlePaymentMethodCallback = async (data) => {
    if (user.connected) {
      const paymentSettings = data.filter(
        (obj) => obj.id === "payment-primary"
      )[0].value;
      if (
        paymentSettings &&
        paymentSettings.paymentId &&
        paymentSettings.paymentId.length
      ) {
        const response = await mainContext.prepareUpdatePaymentMethod({
          key: "PaymentId",
          value: paymentSettings.paymentId,
        });
        if (response.alert) {
          return response;
        } else {
          userReload();
          // mainContext.updatePaymentMethod(response);
          // if (paymentSettings.redirect) {
          //   navigate(paymentSettings.redirect);
          //   userReload();
          // } else {
          //   userReload();
          // }
          return { alert: false };
        }
      } else {
        return {
          alert: true,
          message: "A valid payment method required",
        };
      }
    } else {
      return {
        alert: true,
        message: "Connect to save payment methods",
      };
    }
  };

  const handlePaymentBasicCallback = async (data) => {
    const response = data.filter((obj) => obj.id === "payment-direct")[0].value;
    if (response) {
      if (response.alert) {
        return response;
      } else {
        setPayment(null);
        setSuccess(true);
        setMessage("Payment sent successfully");
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "A valid payment method required",
      };
    }
  };

  const handleCaptureEmailCallback = async (data) => {
    const email = data.filter((obj) => obj.id === "pay-email")[0].value;
    if (email) {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) {
        return {
          alert: true,
          message: "A valid email address is required. Double check format.",
        };
      }
      serviceCookieSet(
        configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_guest_email,
        email
      );
      setPayment({
        ...payment,
        email,
      });
    } else {
      return {
        alert: true,
        message: "A valid email address is required",
      };
    }
  };

  const handleCancelPayment = async () => {
    setPayment(null);
    setCustomOrder(false);
    setHidePayForm(false);
    setSuccess(false);
    setAlert(false);
    setMessage("");
  };
  const handleResetPayment = async () => {
    setPayment(null);
    setCustomOrder(false);
    setHidePayForm(false);
    setSuccess(false);
    setAlert(false);
    setMessage("");
  };

  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_LG,
      }}
    >
      {recipientType && recipientName && (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Box
              className="box-default text-left shadow-light"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box className="box-default half-width">
                <Typography variant="subtitle2">Recipient Type</Typography>
              </Box>
              <Box className="box-default half-width upper-case">
                <Typography variant="subtitle1">{recipientType}</Typography>
              </Box>
              <Box className="box-default half-width">
                <Typography variant="subtitle2">Recipient Name</Typography>
              </Box>
              <Box className="box-default half-width upper-case">
                <Typography variant="subtitle1">{recipientName}</Typography>
              </Box>
            </Box>
          </Box>
          {paymentComplete && (
            <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD,
              }}
            >
              <ActionAlert
                severity="success"
                title="Payment Complete"
                message={
                  <span>
                    Your payment to {recipientType} {recipientName} was
                    successful
                  </span>
                }
                onClose={clearPaymentComplete}
              />
            </Box>
          )}
          <Box
            className="box-default"
            sx={{
              pl: configEntzy.APP_SPACING_MD,
              pr: configEntzy.APP_SPACING_MD,
            }}
          >
            {loading ? (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                  pb: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <ActionLoader />
              </Box>
            ) : success ? (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                  pb: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <ActionButton
                  text="Pay Again"
                  type="button"
                  size="medium"
                  color="secondary"
                  fullWidth={true}
                  onClick={handleResetPayment}
                />
              </Box>
            ) : payment ? (
              <Box className="box-default">
                <Box
                  className="box-default text-left shadow-light"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{
                      pb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Box className="box-default half-width">
                      <Typography variant="h6">Amount</Typography>
                    </Box>
                    <Box className="box-default half-width upper-case">
                      <Typography variant="h6">
                        {payment.currency.code.long} {payment.amount.toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                  {payment.description && (
                    <Box className="box-default">
                      <Box className="box-default half-width">
                        <Typography variant="subtitle2">Description</Typography>
                      </Box>
                      <Box className="box-default half-width">
                        <Typography variant="subtitle1">
                          {payment.description}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {!user.connected && payment.email && (
                    <Box className="box-default">
                      <Box className="box-default half-width">
                        <Typography variant="subtitle2">
                          Confirmation Email
                        </Typography>
                      </Box>
                      <Box className="box-default half-width">
                        <Typography variant="subtitle1">
                          {payment.email}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {user.connected ? (
                    <Box className="box-default">
                      <Box
                        className="box-default text-center"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD3X,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography variant="subtitle1">
                          <em>Review payment and confirm send</em>
                        </Typography>
                      </Box>
                      <Box className="box-default">
                        <ActionButton
                          text="Send Payment"
                          type="button"
                          size="medium"
                          fullWidth={true}
                          onClick={handleSendPayment}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box className="box-default">
                      <Box
                        className="box-default text-center"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        <Typography variant="subtitle2">
                          GUEST CHECKOUT
                        </Typography>
                        <Typography variant="subtitle2" color="primary">
                          {payment.email
                            ? "Enter payment details to continue"
                            : "Enter email address to continue"}
                        </Typography>
                      </Box>
                      <Box
                        key={payment.email ? "payment-direct" : "pay-email"}
                        className="box-default text-center"
                        sx={{
                          pt: configEntzy.APP_SPACING_LG,
                        }}
                      >
                        {payment.email ? (
                          <Box className="box-default">
                            <InputForm
                              user={user}
                              userReload={userReload}
                              navigate={props.navigate}
                              page={props.page}
                              redirectPage={redirectPage}
                              searchParams={props.searchParams}
                              payment={payment}
                              fields={[
                                {
                                  id: "payment-direct",
                                  type: "payment-basic",
                                  label: "Guest direct payment",
                                  value: "",
                                  required: true,
                                },
                              ]}
                              submitType="auto"
                              submitText="Confirm Payment"
                              callback={handlePaymentBasicCallback}
                              // cancelText="Cancel"
                              // confirmation="Payment successful"
                            />
                          </Box>
                        ) : (
                          <Box className="box-default">
                            <InputForm
                              navigate={props.navigate}
                              fields={[
                                {
                                  id: "pay-email",
                                  type: "text-standard",
                                  label: "Enter your email address",
                                  placeholder: "Your email",
                                  value: payment.email
                                    ? payment.email
                                    : guestEmailCookie
                                    ? guestEmailCookie
                                    : "",
                                  email: true,
                                  required: true,
                                  startAdornment: (
                                    <FontAwesomeIcon
                                      icon={iconEmail}
                                      transform="grow-10"
                                      fixedWidth
                                    />
                                  ),
                                },
                              ]}
                              submitText="Confirm Email"
                              callback={handleCaptureEmailCallback}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_HL,
                  }}
                >
                  <ActionButton
                    text="Cancel"
                    type="button"
                    size="small"
                    color="bright"
                    onClick={handleCancelPayment}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="box-default">
                <Box className="box-default">
                  <Box
                    className="box-default half-width"
                    sx={{
                      p: configEntzy.APP_SPACING_XS,
                    }}
                  >
                    <ActionButton
                      variant="contained"
                      color={productOrder ? "bright" : "primary"}
                      text={
                        <span>
                          <span>&nbsp;</span>
                          {productOrder ? (
                            <span>Close X</span>
                          ) : (
                            <span>Buy from Menu</span>
                          )}
                          <span>&nbsp;</span>
                        </span>
                      }
                      fullWidth={true}
                      disabled={
                        mainContext.state.productList.hydrated &&
                        mainContext.state.productList.data.itemsCount === 0
                      }
                      onClick={
                        productOrder ? clearProductOrder : startProductOrder
                      }
                    />
                  </Box>
                  <Box
                    className="box-default half-width"
                    sx={{
                      p: configEntzy.APP_SPACING_XS,
                    }}
                  >
                    <ActionButton
                      variant="contained"
                      color={customOrder ? "bright" : "primary"}
                      text={
                        <span>
                          <span>&nbsp;</span>
                          {customOrder ? (
                            <span>Close X</span>
                          ) : (
                            <span>Custom Payment</span>
                          )}
                          <span>&nbsp;</span>
                        </span>
                      }
                      fullWidth={true}
                      onClick={
                        customOrder ? clearCustomOrder : startCustomOrder
                      }
                    />
                  </Box>
                </Box>
                {(!user.connected ||
                  (user.connected && user.status.payment_set)) && (
                  <Box className="box-default">
                    <Box
                      className="box-default"
                      hidden={hidePayForm || !customOrder}
                      sx={{
                        pt: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <InputForm
                        navigate={props.navigate}
                        fields={[
                          {
                            id: "pay-currency",
                            type: "selector-currency",
                            label: "Currency of payment",
                            value: defaultCurrency
                              ? currenciesGetItem(defaultCurrency)
                              : null,
                            required: true,
                          },
                          {
                            id: "pay-amount",
                            type: "text-standard",
                            label: "Enter amount",
                            placeholder: "0.00",
                            value: defaultAmount ? defaultAmount : "",
                            numeric: true,
                            required: true,
                            startAdornment: (
                              <FontAwesomeIcon
                                icon={iconNumeric}
                                transform="grow-10"
                                fixedWidth
                              />
                            ),
                          },
                          {
                            id: "pay-description",
                            type: "text-standard",
                            label: "Enter description (optional)",
                            placeholder: "Description",
                            value: defaultDescription ? defaultDescription : "",
                            required: false,
                            maxLength: 1000,
                            startAdornment: (
                              <FontAwesomeIcon
                                icon={iconDescription}
                                transform="grow-10"
                                fixedWidth
                              />
                            ),
                          },
                        ]}
                        submitText="Review & Checkout"
                        callback={handleActionCallback}
                      />
                    </Box>
                  </Box>
                )}
                {ordering && user.connected && !user.status.payment_set && (
                  <Box className="box-default">
                    <Box
                      className="box-default"
                      sx={{
                        p: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <Typography variant="subtitle2">
                        PAYMENT DETAILS
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          mt: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        Add billing information to use Direct Pay
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          mt: configEntzy.APP_SPACING_SM,
                        }}
                      >
                        You only need to do this once then you're set
                      </Typography>
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        pb: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <InputForm
                        user={user}
                        userReload={userReload}
                        navigate={props.navigate}
                        page={props.page}
                        redirectPage={redirectPage}
                        searchParams={props.searchParams}
                        fields={[
                          {
                            id: "payment-primary",
                            type: "payment-method",
                            label: "Primary payment method",
                            value: "",
                            required: true,
                          },
                        ]}
                        submitType="auto"
                        submitText="Add Payment Details"
                        callback={handlePaymentMethodCallback}
                        cancelText="Cancel"
                        confirmation="Payment details added"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {(alert || success) && (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                  pb: configEntzy.APP_SPACING_MD2X,
                }}
              >
                <ActionAlert
                  severity={alert ? "error" : success ? "success" : "info"}
                  title={
                    alert ? "Transaction Issue" : success ? "Done" : "Info"
                  }
                  message={message}
                />
              </Box>
            )}
          </Box>
          {!payment && !success && !customOrder && (
            <PayProducts
              {...props}
              productOrder={productOrder}
              startProductOrder={startProductOrder}
              clearProductOrder={clearProductOrder}
              handleHidePayForm={handleHidePayForm}
              handleProductOrder={handleProductOrder}
              handleCheckout={(stage) => handleActionCallback(null, stage)}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default PaySend;
