import configEntzy from "components/config/ConfigEntzy";
import { faUserAstronaut as iconImageFallback } from "@fortawesome/pro-thin-svg-icons";

const SettingsMain = function () {
  return {
    data: {
      UserId: "guest:user",
      Name: "guest",
      Email: null,
      IdentityProvider: null,
      FirstCreated: null,
      LastConnect: null,
      LastUpdated: null,
      Avatar: configEntzy.EMPTY_STRING_SET,
      PaymentId: configEntzy.EMPTY_STRING_SET,
      PayoutId: configEntzy.EMPTY_STRING_SET,
    },
    activePaymentMethod: null,
    hydrated: false,
  };
};

export const SETTINGS_CONSTANTS = {
  emptyStringSet: configEntzy.EMPTY_STRING_SET,
  iconImageFallback: iconImageFallback,
  styles: {
    xsSpacing: configEntzy.APP_SPACING_XS,
    smSpacing: configEntzy.APP_SPACING_SM,
    mdSpacing: configEntzy.APP_SPACING_MD,
    lgSpacing: configEntzy.APP_SPACING_LG,
    xlSpacing: configEntzy.APP_SPACING_XL,
  },
};

export default SettingsMain;
