// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import { PayTagSlot } from "components/utils/common/CommonTags";

// entzy pages
import TransactionList from "pages/settings/transaction/TransactionList";

function TransactionHistory(props) {
  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box id="anchor-finance-area" className="box-default">
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <PayTagSlot />
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="h2" noWrap={true}>
              Your Finances
            </Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2" noWrap={true}>
              Track your financial transactions
            </Typography>
            <Typography variant="subtitle2" noWrap={true}>
              from eventuators and direct pay
            </Typography>
          </Box>
        </Box>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <Container maxWidth="md">
            <TransactionList {...props} combinedView={true} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default TransactionHistory;
