// react core
import { useState, useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import { SettingsContext } from "pages/settings/SettingsContext";
import { ActionButton } from "components/utils/common/CommonButtons";

function SecurityShutdown(props) {
  const [edit, setEdit] = useState(false);
  const context = useContext(SettingsContext);

  const handleEdit = async (value) => {
    setEdit(value);
    console.log("edit", edit);
  };

  const handleFormCallback = async (data) => {
    const settingsFeedback = data.filter(
      (obj) => obj.id === "settings-feedback"
    )[0].value;
    const response = await context.prepareCloseAccount({
      feedback: settingsFeedback.length ? settingsFeedback : "No feedback",
    });
    if (response.alert) {
      return response;
    } else {
      context.state.toolbox.scrollSnapTop();
      props.navigate("/");
      props.userReload();
      return { alert: false };
    }
  };

  return (
    <Box
      className="box-default"
      sx={{ mt: configEntzy.APP_SPACING_MD, mb: configEntzy.APP_SPACING_LG }}
    >
      <Box className="box-default" onClick={handleEdit}>
        <Typography variant="h6" noWrap={true}>
          Warning! Destructive Action
        </Typography>
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_SM,
        }}
      >
        <Typography variant="subtitle1" noWrap={true}>
          <em>Use this area to permanently disconnect</em>
        </Typography>
      </Box>
      <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_MD }}>
        {edit && (
          <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_MD }}>
            <Box
              className="box-default bg-white-t50 shadow-light"
              sx={{
                p: configEntzy.APP_SPACING_MD2X,
              }}
            >
              <Container maxWidth="xs">
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  CONFIRMATION
                </Typography>
                <Typography variant="subtitle1">
                  This action will permanently close your account and cannot be
                  undone. It will immediately disconnect you and trigger the
                  following closure activity:
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    mb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  FOR EVENT RUNNERS
                </Typography>
                <Typography variant="subtitle1">
                  Any events you own will be archived and any bank payout
                  details you setup with us will be disconnected. Please make
                  sure your account balance is zero in your income bank account.
                  Account closure can only proceed if all triggered dates are
                  either completed or cancelled and beyond their cooling period.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    mb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  FOR EVENT RIDERS
                </Typography>
                <Typography variant="subtitle1">
                  Any payment details in your profile will be removed and any
                  in-progress ticking or active tickets will be cancelled.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    mb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  ACCOUNT CLOSURE
                </Typography>
                <Typography variant="subtitle1">
                  Your account will be closed and permanently deactivated. Any
                  connection or permission granted via a 3rd party identity
                  provider will be removed. If you later connect with the same
                  email a brand new account will be created for you.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD2X,
                    mb: configEntzy.APP_SPACING_SM,
                  }}
                >
                  FEEDBACK
                </Typography>
                <Typography variant="subtitle1">
                  If you are leaving for any reason you'd like to tell us about,
                  do let us know in the optional comments box. All feedback is
                  extremely useful.
                </Typography>
              </Container>
            </Box>
            <Box
              className="box-default"
              sx={{ pt: configEntzy.APP_SPACING_MD }}
            >
              <InputForm
                navigate={props.navigate}
                fields={[
                  {
                    id: "settings-feedback",
                    type: "text-standard",
                    label: "Close Account Feedback",
                    value: "",
                    maxLength: 500,
                  },
                ]}
                submitText="Confirm Close Account"
                callback={handleFormCallback}
              />
            </Box>
          </Box>
        )}
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_XL,
          }}
        >
          <ActionButton
            variant="contained"
            color={edit ? "bright" : "danger"}
            size="medium"
            onClick={() => handleEdit(!edit)}
            text={edit ? "Cancel" : "Initiate Account Closure"}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SecurityShutdown;

// 	handleSubmit = async event => {
// 		event.preventDefault();
// 		this.setState({
// 			loading: true,
// 			alert: false,
// 			message: ""
// 		});
// 		try {
// 			await API.graphql(graphqlOperation(mutations.closeAccount, {Comments: this.state.comments}));
// 			this.setState({ loading: false });
// 			this.props.handleLogout();
// 		} catch (e) {
// 			const msg = e.errors ? e.errors[0].message : 'EMSG:' + process.env.REACT_APP_ERROR_CATCH;
// 			this.setState({ loading: false, alert: true, message: msg });
// 		}
// 	};

// 				</div>
// 				<div className="EntzyFloat">
// 					<p>&nbsp;</p>
// 					<p>&nbsp;</p>
// 				</div>
// 				<div className="EntzyFloat">
// 					<Form onSubmit={this.handleSubmit}>
// 						<div className="EntzyFloat">
// 							<Form.Group controlId="comments">
// 								<Form.Control
// 									type="comments"
// 									placeholder="Optional Comments"
// 									value={this.state.comments}
// 									onChange={this.handleChange}
// 									className="EntzyM text-center rounded"
// 								/>
// 							</Form.Group>
// 						</div>
// 						<div className="EntzyFloat">
// 							<p>&nbsp;</p>
// 						</div>
// 						<div className="EntzyFloat">
// 							{
// 								this.state.loading
// 								? <FontAwesomeIcon icon={['fal', 'compact-disc']} className="EntzyDusk" size="3x" spin />
// 								:	<Button variant="danger" className="EntzyS EntzyUpper EntzyBold p-3" onClick={this.handleSubmit} block={true}>
// 										Confirm Close Account
// 									</Button>
// 							}
// 						</div>
// 						<div className="EntzyFloat">
// 							<p>&nbsp;</p>
// 						</div>
// 						<div className="EntzyFloat">
// 							<LoaderAlert
// 								alertType="danger"
// 								alertText={this.state.message}
// 								dismissState={this.state.alert}
// 								dismissAction={e => { this.setState({ alert: false, message: "" });	}}
// 							/>
// 						</div>
// 					</Form>
// 				</div>
// 			</div>
// 		);
// 	}
// }
