// react core
import { useContext, useState } from "react";

// material tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers as iconRiders } from "@fortawesome/pro-duotone-svg-icons";
import { faUsersLine as iconQueue } from "@fortawesome/pro-duotone-svg-icons";

// entzy context and component
import configEntzy from "components/config/ConfigEntzy";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";

// entzy components
import WidgetMembers from "./WidgetMembers";

function WidgetMemberViewer(props) {
  const user = props.user;
  // const eventView = props.eventView;
  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [tab, setTab] = useState(0);
  // eventContext.state.event.data.Access === "private"
  //   ? mainContext.state.viewer.trail.memberTab === 1
  //     ? 1
  //     : 0
  //   : 0

  // tab handling
  const handleTabs = (event, newTab) => {
    setTab(newTab);

    // tab tracking disabled for now as state change closes the modal
    // mainContext.updateViewerTrail({
    //   memberTab: newTab,
    // });
  };

  function TabPanel(props) {
    const { children, value: tab, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {tab === index && (
          <Box className="box-default tab-height">{children}</Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function tabProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function tabLabel(index, count, icon) {
    return (
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_SM,
          pb: configEntzy.APP_SPACING_SM,
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap={true}
          sx={{
            overflow: "visible",
          }}
        >
          <span>
            <span className="fa-layers fa-fw fa-3x">
              <FontAwesomeIcon icon={icon} transform="shrink-6" />
              <span
                className={
                  "fa-layers-counter fa-layers-top-" +
                  (index === 2 ? "right" : "left")
                }
                style={{
                  backgroundColor:
                    count > 0 ? (index === 2 ? "red" : "black") : "white",
                  color: count > 0 ? "white" : "black",
                  border: count > 0 ? "1px solid red" : "1px solid black",
                }}
              >
                {count}
              </span>
            </span>
          </span>
        </Typography>
        <Typography variant="subtitle2">
          {index === 0 && "Riders"}
          {index === 1 && "Queue"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="box-default">
      <Box
        className="box-default half-width"
        sx={{
          borderRight: "1px solid #ccc",
          pr: configEntzy.APP_SPACING_MD,
        }}
      >
        <WidgetMembers user={user} runners={true} />
      </Box>
      <Box
        className="box-default half-width"
        sx={{
          pl: configEntzy.APP_SPACING_MD,
        }}
      >
        <Box
          className="box-default"
          hidden={eventContext.state.event.data.Access !== "private"}
          sx={{
            pb: configEntzy.APP_SPACING_MD2X,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabs}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Notfications"
          >
            <Tab
              label={tabLabel(
                0,
                eventContext.state.riders.data.items.length,
                iconRiders
              )}
              {...tabProps(0)}
              wrapped={true}
              sx={{
                fontSize: configEntzy.FONT_SCALE_LG,
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_MD,
              }}
            />
            <Tab
              label={tabLabel(
                1,
                eventContext.state.riderQueue.data.items.length,
                iconQueue
              )}
              {...tabProps(1)}
              sx={{
                fontSize: configEntzy.FONT_SCALE_LG,
                pt: configEntzy.APP_SPACING_MD,
                pb: configEntzy.APP_SPACING_MD,
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <WidgetMembers user={user} riders={true} />
        </TabPanel>
        {eventContext.state.event.data.Access === "private" && (
          <TabPanel value={tab} index={1}>
            <WidgetMembers user={user} riders={true} queue={true} />
          </TabPanel>
        )}
      </Box>
    </Box>
  );
}

export default WidgetMemberViewer;
