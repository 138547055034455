// react core
import { useContext, useState } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { UnboxedGroupedSelect } from "components/input/InputSelectors";
import {
  ActionAlert,
  ActionLoader,
} from "components/utils/common/CommonLoaders";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCircleUser as iconAction } from "@fortawesome/pro-duotone-svg-icons";

function ActionEntrySelection(props) {
  // const user = props.user;
  const type = props.type;
  const mianContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [loading, setLoading] = useState(false);

  const handleDateSelect = async (event) => {
    const dateKey = event.target.value;
    setLoading(true);
    const element = document.getElementById("anchor-main-content");
    if (element) {
      eventContext.state.toolbox.scrollIntoViewIfNeeded(element, true);
    }
    const response = await eventContext.prepareEntryStageDate({
      dateKey,
    });
    if (response.alert) {
      mianContext.updateAlert(response);
      setLoading(false);
      return;
    }
    eventContext.entryStageDate(response);
    setLoading(false);
    props.handleCloseAction();
  };

  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_LG,
      }}
    >
      {((type === "runner" &&
        eventContext.state.event.calendar.dates.launched.all.keys.length ===
          0) ||
        (type === "rider" &&
          eventContext.state.viewer.dates.launched.all.keys.length === 0)) && (
        <Box
          className="box-default"
          sx={{
            pl: configEntzy.APP_SPACING_MD,
            pr: configEntzy.APP_SPACING_MD,
            pb: configEntzy.APP_SPACING_LG,
          }}
        >
          <ActionAlert
            severity="info"
            title="No Date Launches Yet"
            message={
              type === "runner"
                ? "When run dates trigger they will appear here for selection. This is where you will be able to review guest lists and check tickets on entry."
                : "When your ride dates trigger your tickets will appear here for selection. This is where you will be able to display your tickets to runners on entry."
            }
          />
        </Box>
      )}
      {loading ? (
        <Box className="box-default">
          <ActionLoader />
        </Box>
      ) : (
        <Box className="box-default">
          <UnboxedGroupedSelect
            size="small"
            title="Entry Date"
            text={
              !eventContext.state.viewer.entry.date
                ? "Select"
                : dayjs(
                    eventContext.state.viewer.entry.date,
                    "YYYY-MM-DD"
                  ).format("ddd DD MMM YYYY")
            }
            disabled={
              (type === "runner" && !eventContext.state.event.manager) ||
              (type === "rider" && eventContext.state.event.manager)
            }
            groupedOptions={[
              {
                header: "Coming Up",
                options: (type === "runner" && eventContext.state.event.manager
                  ? eventContext.state.event.calendar.dates.launched.future.keys
                  : eventContext.state.viewer.dates.launched.future.keys
                ).map((dateKey) => {
                  return {
                    value: dateKey,
                    display: dayjs(dateKey, "YYYY-MM-DD").format(
                      "ddd DD MMM YYYY"
                    ),
                  };
                }),
              },
              {
                header: "Past Dates",
                options: (type === "runner" && eventContext.state.event.manager
                  ? eventContext.state.event.calendar.dates.launched.history
                      .keys
                  : eventContext.state.viewer.dates.launched.history.keys
                ).map((dateKey) => {
                  return {
                    value: dateKey,
                    display: dayjs(dateKey, "YYYY-MM-DD").format(
                      "ddd DD MMM YYYY"
                    ),
                  };
                }),
              },
            ]}
            startIcon={
              type === "rider" && (
                <FontAwesomeIcon
                  icon={iconAction}
                  style={{
                    fontSize: "inherit",
                    color:
                      type === "runner" && eventContext.state.event.manager
                        ? "white"
                        : "disabled",
                  }}
                  fixedWidth
                />
              )
            }
            endIcon={
              type === "runner" && (
                <FontAwesomeIcon
                  icon={iconAction}
                  style={{
                    fontSize: "inherit",
                    color:
                      type === "rider" && !eventContext.state.event.manager
                        ? "white"
                        : "disabled",
                  }}
                  fixedWidth
                />
              )
            }
            value={eventContext.state.viewer.entry.date}
            onChange={handleDateSelect}
          />
        </Box>
      )}
    </Box>
  );
}

export default ActionEntrySelection;
