import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { EventContext } from "pages/events/EventContext";
import { serviceActionCapture } from "services/graphql/call";

export const useMainRedirect = (props) => {
  const user = props.user;
  const location = useLocation();
  const eventContext = useContext(EventContext);

  const [redirect, setRedirect] = useState(null);

  // if not connected capture url for redirect on connect
  useEffect(() => {
    const captureRedirect = async () => {
      await serviceActionCapture({
        type: "redirect",
        path: location.pathname,
      });
      setRedirect(location.pathname);
    };
    if (!user.connected) {
      if (eventContext) {
        if (
          eventContext.state.event.hydrated &&
          !eventContext.state.event.exploring
        ) {
          captureRedirect();
        }
      } else {
        captureRedirect();
      }
    }
  }, [user.connected, location.pathname, eventContext]);

  return redirect;
};

// USAGE
// useMainRedirect(props);
// const redirect = useMainRedirect(props);
