// react core
import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSignIn as iconConnect } from "@fortawesome/pro-thin-svg-icons";
// import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";

// entzy pages
import PaySend from "./PaySend";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
// import { BigButton } from "components/utils/common/CommonButtons";
import { PayTagSlot } from "components/utils/common/CommonTags";
import { scrollIntoViewIfNeeded } from "models/Tools";

// function PayIndexPublic(props) {
//   return (
//     <Box className="box-default">
//       <Box
//         className="box-default"
//         sx={{
//           mt: configEntzy.APP_SPACING_LG,
//         }}
//       >
//         <Box
//           className="box-inline"
//           sx={{
//             width: configEntzy.AVATAR_SIZE_LG,
//           }}
//         >
//           <BigButton
//             color="transparent"
//             icon={props.drawerConnect ? iconClose : iconConnect}
//             text={
//               props.drawerConnect
//                 ? "Close"
//                 : "Connect to Access Direct Payments"
//             }
//             onClick={props.drawerConnectToggle}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// }

function PayIndexMenu(props) {
  return (
    <Box className="box-default">
      <Box
        className="box-default"
        sx={{
          mt: configEntzy.APP_SPACING_LG,
        }}
      >
        <Typography variant="h6">
          Initiate direct payments from the pay icon
        </Typography>
        <Typography variant="h6">
          inside eventuators or from member profiles
        </Typography>
      </Box>
    </Box>
  );
}

function PayIndexConnected(props) {
  return (
    <Box className="box-default">
      <PaySend key={props.reloadKey} {...props} />
    </Box>
  );
}

function PayIndex(props) {
  const [recipientType, setRecipientType] = useState(null);
  const [recipientName, setRecipientName] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState(null);
  const [description, setDescription] = useState(null);
  const [reloadKey, setReloadKey] = useState(Math.random());

  // viewer connected state
  // let userConnected = props.user.connected;

  // page entry point and path value
  let entryPage = props.page;
  let { recipient } = useParams();
  let [searchParams] = useSearchParams();

  // context value override
  let recipientOverride = props.recipient;

  useEffect(() => {
    let type, name;
    name = recipientOverride ? recipientOverride : recipient ? recipient : "";
    if (entryPage === "/pay/m") {
      type = "member";
    } else if (entryPage === "/pay/e") {
      type = "eventuator";
    } else if (name.startsWith("@")) {
      type = "member";
      name = name.replace("@", "");
    } else if (name.startsWith(configEntzy.URL_POINTERS.MAIN)) {
      type = "eventuator";
      name = name.replace(configEntzy.URL_POINTERS.MAIN, "");
    } else {
      type = null;
    }
    setRecipientName(name);
    setRecipientType(type);
    if (searchParams.has("currency")) {
      setCurrency(searchParams.get("currency").toLowerCase());
    }
    if (searchParams.has("amount")) {
      setAmount(searchParams.get("amount").replace(/[^0-9.]/g, ""));
    }
    if (searchParams.has("description")) {
      setDescription(searchParams.get("description").substring(0, 100));
    }
    // if (userConnected) {
    // }
  }, [entryPage, recipient, recipientOverride, searchParams]);

  // update reload if currency, amount or description change
  useEffect(() => {
    setReloadKey(Math.random());
  }, [currency, amount, description]);

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      <Box id="anchor-pay-area" className="box-default">
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
            pb: configEntzy.APP_SPACING_MD,
          }}
        >
          <PayTagSlot color="orange" />
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="h2" noWrap={true}>
              Direct Pay
            </Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2" noWrap={true}>
              Eventuator in-play payments
            </Typography>
            <Typography variant="subtitle2" noWrap={true}>
              Member to member payments
            </Typography>
          </Box>
        </Box>
        <Box className="box-default">
          <Container maxWidth="md">
            {recipientName ? (
              <PayIndexConnected
                {...props}
                key={recipientName}
                reloadKey={reloadKey}
                recipientType={recipientType}
                recipientName={recipientName}
                defaultCurrency={
                  props.currency ? props.currency : currency ? currency : null
                }
                defaultAmount={
                  props.amount ? props.amount : amount ? amount : null
                }
                defaultDescription={
                  props.description
                    ? props.description
                    : description
                    ? description
                    : null
                }
                handleStagePayment={(currency, amount, description) => {
                  const element = document.getElementById("anchor-pay-area");
                  if (element) {
                    scrollIntoViewIfNeeded(element);
                  }
                  setCurrency(currency);
                  setAmount(amount);
                  setDescription(description);
                }}
              />
            ) : (
              <PayIndexMenu {...props} />
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PayIndex;
