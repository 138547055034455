// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose as iconClose } from "@fortawesome/pro-regular-svg-icons";

// entzy context and component
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { AppLoader } from "components/utils/common/CommonLoaders";

function MainCallbackLoader() {
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const open =
    mainContext && mainContext.state.callbackLoader
      ? true
      : eventContext && eventContext.state.callbackLoader
      ? true
      : false;

  return (
    <Box
      className="box-fixed top full-width"
      sx={{
        zIndex: 9999999999,
      }}
    >
      <Modal open={open}>
        <Box className="box-default">
          <Box
            className="box-default text-right"
            sx={{
              p: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="h6">
              <FontAwesomeIcon
                icon={iconClose}
                size="2x"
                fixedWidth
                onClick={() => window.location.reload()}
              />
            </Typography>
          </Box>
          <AppLoader />
        </Box>
      </Modal>
    </Box>
  );
}

export default MainCallbackLoader;
