// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt as iconRemove } from "@fortawesome/pro-duotone-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import {
  ActionBoxButton,
  CopyButton,
} from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";

function ActionMembersInvites(props) {
  // const user = props.user;
  const runnersView = props.runnersView;
  const ridersView = props.ridersView;
  const queueView = props.queueView;

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const invitesActive =
    (eventContext.state.event.owner && runnersView) ||
    (eventContext.state.event.owner &&
      eventContext.state.event.data.Access === "private" &&
      ridersView) ||
    (eventContext.state.event.owner &&
      eventContext.state.event.data.Access === "private" &&
      queueView);
  const invitesList = runnersView
    ? eventContext.state.runnerInvites.data.items
    : eventContext.state.riderInvites.data.items;

  const [loading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleRefresh = async () => {
    let response;
    setLoading(true);
    response = await Promise.all([
      eventContext.preparePullRunnerInvites({
        EventId: eventContext.state.event.data.EventId,
      }),
      eventContext.preparePullRiderInvites({
        EventId: eventContext.state.event.data.EventId,
      }),
    ]);
    if (response.filter((item) => item.alert).length > 0) {
      mainContext.updateAlert({
        alert: true,
        message:
          "Hmm unable to refresh members on that try. Give it another go or contact us if this problem persists.",
      });
    } else {
      eventContext.pullRunnerInvites(response[0]);
      eventContext.pullRiderInvites(response[1]);
    }
    setLoading(false);
  };

  const handleGenerateInviteLink = async () => {
    if (eventContext.state.event.owner) {
      setInviteLoading(true);
      const response = await eventContext.prepareInviteLink({
        EventId: eventContext.state.event.data.EventId,
        Category: runnersView ? "runner" : "rider",
      });
      if (response.alert) {
        mainContext.updateAlert(response);
      }
      const updateInvites = runnersView
        ? eventContext.updateRunnerInvites
        : eventContext.updateRiderInvites;
      updateInvites({
        UserId: response.data,
        EventId: eventContext.state.event.data.EventId,
        Category: runnersView ? "runner" : "rider",
        Link: eventContext.prepareFormatInviteLink({
          Token: response.data,
          EventId: eventContext.state.event.data.EventId,
        }),
      });
      setInviteLoading(false);
    } else {
      return {
        alert: true,
        message: "Owner permissions required for this update",
      };
    }
  };

  const handleRemove = async (event, userid, confirm) => {
    const isInvite = userid.startsWith("invite");
    if (confirm === true) {
      setDialog({ visible: false });
      await handleRemoveMember(userid);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title:
          "Confirm Remove " +
          (runnersView ? "Runner" : "Rider") +
          (isInvite ? " Invite" : queueView ? " from the queue" : ""),
        description:
          "Are you sure you want to remove this " +
          (runnersView ? "runner" : "rider") +
          (isInvite ? " invite" : queueView ? " from the queue" : "") +
          "?",
        cancel: (e) => handleRemove(e, userid, false),
        confirm: (e) => handleRemove(e, userid, true),
      });
    }
  };

  const handleRemoveMember = async (userid) => {
    setLoading(true);
    const response = await eventContext.preparePinEvent({
      EventId: eventContext.state.event.data.EventId,
      userRemoval: {
        id: userid,
      },
    });
    if (response.alert) {
      mainContext.updateAlert(response);
      setLoading(false);
    } else {
      await handleRefresh();
      setLoading(false);
    }
  };

  // const handleShowInvites = () => {
  //   mainContext.updateViewerTrail({
  //     memberInvites: !mainContext.state.viewer.trail.memberInvites,
  //   });
  // };

  return (
    <Box
      className="box-default"
      sx={{
        p: configEntzy.APP_SPACING_SM,
      }}
    >
      <Box
        className="box-default"
        hidden={
          runnersView &&
          eventContext.state.event.manager &&
          !eventContext.state.event.owner
            ? false
            : true
        }
      >
        <Box
          className="box-default content-disabled"
          sx={{
            pt: configEntzy.APP_SPACING_HL,
            pb: configEntzy.APP_SPACING_MD,
            pl: configEntzy.APP_SPACING_SM,
            pr: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="subtitle2">Runner Invites</Typography>
          <Typography
            variant="subtitle1"
            sx={{
              mt: configEntzy.APP_SPACING_SM,
            }}
          >
            Run team changes are made by the eventuator owner.
          </Typography>
          <Typography variant="subtitle1">
            Send a message to the owner to request a change.
          </Typography>
        </Box>
      </Box>
      <Box className="box-default" hidden={runnersView}>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD2X,
            pb: configEntzy.APP_SPACING_MD,
            pl: configEntzy.APP_SPACING_SM,
            pr: configEntzy.APP_SPACING_SM,
          }}
        >
          <Typography variant="subtitle2">How to Invite Riders</Typography>
          <Typography
            variant="subtitle1"
            sx={{
              mt: configEntzy.APP_SPACING_SM,
            }}
          >
            To invite new riders simply share the eventuator link.
          </Typography>
        </Box>
        <Box
          className="box-default bg-black-t75"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
            mb: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
        >
          <CopyButton
            text={
              configEntzy.APP_URL +
              configEntzy.URL_POINTERS.MAIN +
              eventContext.state.event.data.Url
            }
            color="white"
          />
        </Box>
      </Box>
      <Box
        className="box-default"
        // hidden={!invitesActive || !mainContext.state.viewer.trail.memberInvites}
        hidden={!invitesActive}
      >
        <Box className="box-default">
          <Box
            className="box-default text-left"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pb: configEntzy.APP_SPACING_MD,
              pl: configEntzy.APP_SPACING_SM,
              pr: configEntzy.APP_SPACING_SM,
            }}
          >
            <Typography variant="subtitle2">
              Active {runnersView ? "Runner" : "Rider"} Private Invites
              <span>&nbsp;&nbsp;</span>
              <span
                className="bg-black-t75"
                style={{ color: "white", borderRadius: "4px" }}
              >
                &nbsp;&nbsp;{invitesList.length}&nbsp;&nbsp;
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              To invite a new {runnersView ? "runner" : "rider"} on a private
              link, generate a single-use invite link below, then copy and share
              it. Each link will grant automatic entry for the recipient when
              collected.
            </Typography>
          </Box>
          <Box className="box-default">
            {invitesList.map((invite, index) => {
              return (
                <Box key={invite.UserId} className="box-default">
                  <Box
                    className="box-default shadow-default text-left bg-black-t75"
                    sx={{
                      mt: configEntzy.APP_SPACING_MD,
                      p: configEntzy.APP_SPACING_MD,
                      borderRadius: configEntzy.BORDER_SIZE_LG,
                    }}
                  >
                    <Box
                      className="box-default upper-case"
                      sx={{
                        pl: configEntzy.APP_SPACING_SM,
                        pr: configEntzy.APP_SPACING_SM,
                      }}
                    >
                      <Typography variant="body2" color="primary">
                        <span
                          style={{
                            padding: "1px",
                            borderRadius: "4px",
                            border: "1px solid #f90",
                          }}
                        >
                          &nbsp;&nbsp;Available&nbsp;&nbsp;
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span>
                          {runnersView ? "Runner" : "Rider"} Invite Slot
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span style={{ float: "right" }}>
                          <FontAwesomeIcon
                            icon={iconRemove}
                            className="action-pointer"
                            style={{ color: "red" }}
                            transform="grow-2"
                            onClick={(e) => handleRemove(e, invite.UserId)}
                          />
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                        mb: configEntzy.APP_SPACING_MD,
                        borderRadius: configEntzy.BORDER_SIZE_LG,
                      }}
                    >
                      <CopyButton text={invite.Link} color="white" />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
          }}
        >
          {inviteLoading ? (
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_LG2X,
              }}
            >
              <ActionLoader size="md" color="highlight" />
            </Box>
          ) : (
            <Box className="box-default" hidden={loading}>
              <ActionBoxButton
                variant="contained"
                bgColor="primary.light"
                color="black"
                size="medium"
                type="button"
                onClick={handleGenerateInviteLink}
                text={
                  "Create " +
                  (runnersView ? "Runner" : "Rider") +
                  " Invite Link"
                }
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* dialog popup */}
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>

      {/* loading popup */}
      {loading && (
        <Box className="box-fixed top-left full-width">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_HL2X,
            }}
          >
            <ActionLoader size="md" color="highlight" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ActionMembersInvites;
